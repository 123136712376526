import { styled } from '@mui/material/styles';
import { Grid , Typography } from '@mui/material';

export const BrandContainer = styled(Grid)`
    height: 56px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
    background-color: #373435;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #424242;
    }
`

export const ContentContainer = styled(Grid)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
`

export const BrandName = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
`

export const Icon = styled(Grid)`
    color: white;
`

export const ListItem = styled(Grid)`
    width: 100%;
    margin-top: 16px;
`