import React from 'react';
import { Grid } from '@mui/material';

import { ReactComponent as ChangeToChart } from '~/assets/icons/ChangeToChart.svg';

function ChangeToChartButton({ showIcon, handleFlipped }) {
    return (
        <>
            {showIcon && (
                <Grid
                    item
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ChangeToChart onClick={() => handleFlipped()} />
                </Grid>
            )}
        </>
    );
}

export default ChangeToChartButton;
