import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import DateRangePicker from '~/components/DateRangePicker';

import Header from '~/components/Header';
import NPSForm from '~/components/NPSForm';
import SalesTerms from '~/components/SalesTerms';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import {
    onlineChannels,
    physicalChannels,
} from '~/util/mapChannelsPhysicalAndOnline';

import { formatPrice, formatWithCent, formatDateString } from '~/util/format';
import { sortOrdersByDate } from '~/util/sortOrdersByDate';
import getDateRange from '~/util/getDateRange';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';
import { changeShouldShowNPS } from '../../store/modules/user/actions';

function VendedorCorporativo() {
    const dispatch = useDispatch();
    const [capturedOrdersIndicators, setCapturedOrdersIndicators] = useState(
        {}
    );
    const [totalOrdersIndicators, setTotalOrdersIndicators] = useState({});

    const [indicatorsText, setIndicatorsText] = useState({});
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [balanceText, setBalanceText] = useState(0);
    const [panelLoaded, setPanelLoaded] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [brandFilter, setBrandFilter] = useState('allBrands');

    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate, isFaturado, period } = useSelector(
        state => state.status
    );

    const dateRange = getDateRange(initialDate, finalDate, 'DD/MM/YYYY');
    const multimarca = true;

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });

            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;

        if (shouldAnswerNPS) {
            if (!beforeDateLimit) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const newIndicatorsText = {
            totalValue: 0,
            totalAverageValue: 0,
            totalOrderQuantity: 0,
            totalProductQuantity: 0,
            totalAverageQuantity: 0,

            onlineTotalValue: 0,
            onlineAverageValue: 0,
            onlineAverageQuantity: 0,
            onlineOrderQuantity: 0,
            onlineProductQuantity: 0,

            physicalTotalValue: 0,
            physicalAverageValue: 0,
            physicalAverageQuantity: 0,
            physicalOrderQuantity: 0,
            physicalProductQuantity: 0,
        };
        const ordersIndicators = capturedOrdersIndicators;

        Object.entries(ordersIndicators).forEach(
            ([indicatorChannel, indicatorValue]) => {
                if (onlineChannels.includes(indicatorChannel)) {
                    newIndicatorsText.onlineTotalValue += indicatorValue.value;
                    newIndicatorsText.onlineOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.onlineProductQuantity +=
                        indicatorValue.productQuantity;
                }
                if (physicalChannels.includes(indicatorChannel)) {
                    newIndicatorsText.physicalTotalValue +=
                        indicatorValue.value;
                    newIndicatorsText.physicalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.physicalProductQuantity +=
                        indicatorValue.productQuantity;
                }
            }
        );

        newIndicatorsText.onlineAverageQuantity = (
            newIndicatorsText.onlineProductQuantity /
                newIndicatorsText.onlineOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.onlineAverageValue =
            newIndicatorsText.onlineTotalValue /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.physicalAverageQuantity = (
            newIndicatorsText.physicalProductQuantity /
                newIndicatorsText.physicalOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.physicalAverageValue =
            newIndicatorsText.physicalTotalValue /
                newIndicatorsText.physicalOrderQuantity || 0;
        if (totalOrdersIndicators) {
            if (
                userProfile &&
                Object.keys(totalOrdersIndicators)?.length !== 0
            ) {
                const totalIndicators = Object.values(
                    totalOrdersIndicators
                ).reduce((acc, current) => {
                    Object.entries(current).forEach(([prop, value]) => {
                        acc[prop] = (acc[prop] || 0) + value;
                    });
                    return acc;
                }, {});

                if (totalIndicators) {
                    newIndicatorsText.totalAverageValue =
                        totalIndicators.value / totalIndicators.serviceQuantity;
                    newIndicatorsText.totalAverageQuantity = (
                        totalIndicators.productQuantity /
                        totalIndicators.serviceQuantity
                    ).toFixed(2);
                    newIndicatorsText.totalValue = totalIndicators.value;
                    newIndicatorsText.totalOrderQuantity =
                        totalIndicators.serviceQuantity;
                    newIndicatorsText.totalProductQuantity =
                        totalIndicators.productQuantity;
                }
            }
        }

        const canFormat = [
            'onlineTotalValue',
            'physicalTotalValue',
            'totalValue',
            'totalAverageValue',
            'physicalAverageValue',
            'onlineAverageValue',
        ];

        Object.keys(newIndicatorsText).forEach(key => {
            if (canFormat.includes(key)) {
                newIndicatorsText[key] = formatWithCent(newIndicatorsText[key]);
            }
        });

        setIndicatorsText(newIndicatorsText);
    }, [
        capturedOrdersIndicators,
        isFaturado,
        totalOrdersIndicators,
        userProfile,
    ]);

    const getBrandsParamsForRequests = useCallback(() => {
        const allBrandsIds = Array.from(
            { length: 16 },
            (_, brandId) => brandId + 1
        );

        const myBrands = userProfile.users_brands.map(
            brandObj => brandObj.id_marca
        );

        switch (brandFilter) {
            case 'Animale':
                return [1];
            case 'Farm':
                return [2];
            case 'A.Brand':
                return [3];
            case 'Fábula':
                return [5];
            case 'OffPremium':
                return [6];
            case 'Foxton':
                return [7];
            case 'Cris Barros':
                return [9];
            case 'Maria Filó':
                return [15];
            case 'allBrands':
                return myBrands;
            case 'myBrands':
                return myBrands;
            case 'otherBrands':
                return allBrandsIds.filter(
                    allBrandsId => !myBrands.includes(allBrandsId)
                );
            default:
                return [];
        }
    }, [brandFilter, userProfile]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setPanelLoaded(false);

            const getAggregatedCapturedOrders = async () => {
                try {
                    const response = await api.get(
                        `/salesperson/aggregated-orders`,
                        {
                            params: {
                                fatured: 'false',
                                startDate: moment(initialDate).format(
                                    'YYYY-MM-DD'
                                ),
                                endDate: moment(finalDate).format('YYYY-MM-DD'),
                                salesCode: userProfile.users_sales_code.map(
                                    salesCodeObj => salesCodeObj.codigo_vendedor
                                ),
                                transaction: 'true',
                                farmLatam: 'exclude'
                            },
                        }
                    );
                    setBalanceText(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setCapturedOrdersIndicators(
                        response.data.data.aggregatedByChannel
                    );

                    setTotalOrdersIndicators(
                        response.data.data.aggregatedByBrand
                    );
                    setChartData(
                        period === 'day'
                            ? Object.values(
                                  response.data.data.aggregatedByHour
                              ).map(hourObj => ({
                                  value: hourObj.value,
                                  label: hourObj.prettyHour,
                              }))
                            : getDateRange(
                                  initialDate,
                                  finalDate,
                                  'YYYY-MM-DD'
                              ).map(date => ({
                                  value:
                                      response.data.data.aggregatedByDay[
                                          date.label
                                      ]?.value,
                                  label: date.formattedLabel,
                              }))
                    );

                    if (response.data.msg) {
                        Snackbar.info(response.data.msg);
                    }
                } catch (err) {
                    Snackbar.error(
                        'Erro retornando os indicadores de pedidos capturados'
                    );
                }
            };

            Promise.all([getAggregatedCapturedOrders()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => {
                    setPanelLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalDate, checkIfCanMakeRequests, userProfile, isFaturado]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setOrdersLoaded(false);

            const getSalespersonOrders = async () => {
                try {
                    const response = await api.get(`salesperson/orders`, {
                        params: {
                            startDate: moment(initialDate).format('YYYY-MM-DD'),
                            endDate: moment(finalDate).format('YYYY-MM-DD'),
                            salesCode: userProfile.users_sales_code.map(
                                salesCodeObj => salesCodeObj.codigo_vendedor
                            ),
                            orderType: isFaturado ? 'invoiced' : 'captured',
                            brands: getBrandsParamsForRequests(),
                            transaction: 'true',
                            farmLatam: 'exclude'
                        },
                    });

                    const newSalespersonOrders = response.data.data.map(
                        order => ({
                            ...order,
                            formatCreationDate: formatDateString(
                                order.creationDate
                            ),
                            formatInvoicedDate: formatDateString(
                                order.invoicedDate
                            ),
                            value: formatPrice(order.value),
                        })
                    );

                    // FILTRANDO A RESPONSE PARA EXCLUIR CASOS DE VENDA FISICA COM VALOR ZERO. TRATANDO CASOS DE CANCELAMENTOS FISICOS FEITOS EM LOJA
                    // QUE NAO DEVEM SER MOSTRADOS AO USUARIO
                    let filteredNewSalespersonOrders = newSalespersonOrders.filter(
                        order => {
                            if (
                                order.value === formatPrice(0)
                                // order.channel === 'FISICO'
                            ) {
                                return false;
                            }
                            return true;
                        }
                    );

                    for (
                        let i = 0;
                        i < filteredNewSalespersonOrders.length;
                        i += 1
                    ) {
                        if (
                            filteredNewSalespersonOrders[i].channel ===
                                'INSTORE' ||
                            filteredNewSalespersonOrders[i].channel ===
                                'VITRINE' ||
                            filteredNewSalespersonOrders[i].channel ===
                                'EXTERNA' ||
                            filteredNewSalespersonOrders[i].channel ===
                                'ESTOQUE PROPRIO'
                        ) {
                            filteredNewSalespersonOrders[i].channel = 'FISICO';
                        }
                        if (
                            filteredNewSalespersonOrders[i].channel === 'OMNI'
                        ) {
                            filteredNewSalespersonOrders[i].channel = 'ONLINE';
                        }
                        if (
                            filteredNewSalespersonOrders[i].value[0] === '-' &&
                            filteredNewSalespersonOrders[i].channel === 'FISICO'
                        ) {
                            filteredNewSalespersonOrders[i].channel =
                                'DEVOLUCAO';
                        }
                        if (
                            filteredNewSalespersonOrders[i].status_evento ===
                            'CANCELADO'
                        ) {
                            filteredNewSalespersonOrders[i].channel =
                                'CANCELADO';
                        }
                    }

                    filteredNewSalespersonOrders = sortOrdersByDate(
                        filteredNewSalespersonOrders
                    );

                    setOrders(filteredNewSalespersonOrders);

                    if (filteredNewSalespersonOrders) {
                        const clientResponse = await api.post(
                            `salesperson/orders/client-data`,
                            {
                                orderData: filteredNewSalespersonOrders,
                            }
                        );
                        setOrders(clientResponse.data);
                    }
                } catch {
                    Snackbar.error('Erro retornando a lista de pedidos');
                    setOrders([]);
                }
                setOrdersLoaded(true);
            };

            Promise.all([getSalespersonOrders()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => {
                    setOrdersLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        brandFilter,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            <Header />
            <DateRangePicker hidden={!panelLoaded} assessment />
            {panelLoaded || (
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Grid>
            )}
            <DesktopLayout
                balanceText={balanceText}
                indicatorsText={indicatorsText}
                panelLoaded={panelLoaded}
                chartData={chartData}
                dateRange={dateRange}
                multimarca={multimarca}
                orders={orders}
                ordersLoaded={ordersLoaded}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
            />
        </>
    );
}

export default VendedorCorporativo;
