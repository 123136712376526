import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

// Local component import
import FilterIcon from '../FilterIcon';
import RankingListTopPanel from '../RankingListTopPanel';

// Util import
import { timePeriodNames } from '~/util/storeRankingFilters';

import * as Style from './styles';

function StoreRankingHeader({
    orderBy,
    orderByAsc,
    setOrderAsc,
    channelFilter,
    setChannelFilterDialogIsOpen,
    setRankingData,
}) {
    const { period } = useSelector(state => state.status);
    const selectedTimePeriod = timePeriodNames[period];

    return (
        <>
            <Style.TitleContainer container>
                <Grid item xs={6}>
                    <Typography
                        style={{
                            fontWeight: 600,
                            fontSize: '20px',
                        }}
                    >
                        Ranking do {selectedTimePeriod}
                    </Typography>
                </Grid>
                <FilterIcon
                    channelFilter={channelFilter}
                    setChannelFilterDialogIsOpen={setChannelFilterDialogIsOpen}
                />
            </Style.TitleContainer>
            <RankingListTopPanel
                orderBy={orderBy}
                orderByAsc={orderByAsc}
                setOrderAsc={setOrderAsc}
                setRankingData={setRankingData}
            />
        </>
    );
}

export default StoreRankingHeader;
