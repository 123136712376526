import React from 'react';
import * as Style from './styles';

import { Grid } from '@mui/material';
import SalesPersonPagePanel from '~/components/SalesPersonPanelPiloto/SalesPersonPagePanel';
import OrderListPanel from '~/components/OrderListPanel';

const DesktopLayout = ({
    salesNumber,
    balanceText,
    quota,
    salesPercentage,
    indicatorsText,
    panelLoaded,
    ordersLoaded,
    orders,
    aggregatedOrdersByPeriod,
    brandFilter,
    setBrandFilter,
    chartData,
    dateRange,
}) => {

    return (
        <>
            <Style.Container container>
                <Grid item xs={12}>
                    <SalesPersonPagePanel
                        balanceText={balanceText}
                        indicatorsText={indicatorsText}
                        panelLoaded={panelLoaded}
                        aggregatedOrdersByPeriod={aggregatedOrdersByPeriod}
                        chartData={chartData}
                        dateRange={dateRange}
                        salesNumber={salesNumber}
                        quota={quota}
                        salesPercentage={salesPercentage}
                    />
                </Grid>
                <Style.LateralPanelContainer item xs={12}>
                    <OrderListPanel 
                        orders={orders}
                        brandFilter={brandFilter}
                        setBrandFilter={setBrandFilter}
                        ordersLoaded={ordersLoaded}
                    />
                </Style.LateralPanelContainer>
            </Style.Container>
        </>
    );
};

export default DesktopLayout;
