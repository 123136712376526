// STEP 1 - Include Dependencies
// Include react
import React from 'react';

// Include the react-fusioncharts component
import ReactFC from 'react-fusioncharts';

// Include the fusioncharts library
import FusionCharts from 'fusioncharts';

// Include the chart type
import Column2D from 'fusioncharts/fusioncharts.charts';

// Include the theme as fusion
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { Grid, Typography } from '@mui/material';
import barImage from '~/assets/chart/bar.png';

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const ChartPilotoWithValue = ({ chartData, quotaData }) => {
    // STEP 3 - Creating the JSON object to store the chart configurations
    const chartConfigs = {
        type: 'mscombi2d', // The chart type
        width: '100%', // Width of the chart
        height: '140', // Height of the chart
        dataFormat: 'json', // Data type
    };
    const shouldShowLabel = (totalLabelCount, currentLabelIndex) => {
        const START_INDEX = 0;
        const LAST_INDEX = totalLabelCount - 1;
        const MIDDLE_INDEX =
            totalLabelCount % 2 === 0
                ? Math.floor(totalLabelCount / 2) - 1
                : Math.floor(totalLabelCount / 2);
        if (
            [START_INDEX, LAST_INDEX, MIDDLE_INDEX].includes(currentLabelIndex)
        ) {
            return true;
        }
        return false;
    };
    return (
        <>
            <Grid
                style={{
                    width: '100%',
                    height: '124px',
                    overflow: 'hidden',
                }}
            >
                <ReactFC
                    {...chartConfigs}
                    dataSource={{
                        // Chart Configuration
                        chart: {
                            rotateLabels: '0', // x-axis-labels are horizontal
                            canvasPadding: '0', // remove canvas padding
                            theme: 'fusion', // default theme
                            bgColor: '#373435', // change bg color
                            palettecolors: '#DCC6B7', // change bar color
                            baseFontColor: '#9e9e9e', // change base font color
                            showYAxisValues: '0', // hide y-axis labels
                            plotColorinTooltip: '0', // hide color legend on tooltip
                            labelFontSize: '12', // change label font size
                            adjustDiv: '0', // set horizontal div lines settings to manual
                            numDivLines: 1, // set horizontal div lines number to 1
                            drawCustomLegendIcon: '1',
                            showLabels: '0',
                            showLegend: '0',
                        },
                        categories: [
                            {
                                category: chartData.map(data => ({
                                    label: data.label,
                                })),
                            },
                        ],
                        dataset: [
                            {
                                seriesName: 'Vendas',
                                data: chartData.map(data => ({
                                    value: data.value
                                        ? Number(data.value).toFixed(2)
                                        : 0,
                                })),
                                plotToolText: '$label <br> Venda: R$ $value',
                            },
                            {
                                renderAs: 'LINE',
                                seriesName: 'Cota',
                                data:
                                    chartData.length === quotaData.length
                                        ? quotaData.map(data => ({
                                              value: data.value
                                                  ? Number(data.value).toFixed(
                                                        2
                                                    )
                                                  : 0,
                                          }))
                                        : [],
                                lineThickness: '0',
                                anchorImageUrl: barImage,
                                anchorImageAlpha: '100',
                                anchorBgAlpha: '0',
                                anchorBorderThickness: '0',
                                anchorImageScale: '100',
                                plotToolText: 'Cota:  R$ $value',
                            },
                        ],
                    }}
                />
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >
                {chartData
                    .filter((_data, index) =>
                        shouldShowLabel(chartData.length, index)
                    )
                    .map(data => (
                        <Grid item>
                            <Typography
                                style={{
                                    color: '#9E9E9E',
                                    fontSize: '12px',
                                }}
                            >
                                {data.label}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
            {chartData.length === quotaData.length && (
                <Grid
                    container
                    justifyContent="space-evenly"
                    style={{ paddingLeft: '15px', paddingRight: '15px' }}
                >
                    <Grid item container justifyContent="space-evenly">
                        <Grid
                            item
                            container
                            justifyContent="center"
                            style={{ width: 'fit-content' }}
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: '6px',
                                }}
                            >
                                <Grid
                                    style={{
                                        width: '12px',
                                        height: '12px',
                                        backgroundColor: '#DCC6B7',
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        color: '#9E9E9E',
                                        fontSize: '12px',
                                    }}
                                >
                                    Vendas
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="center"
                            style={{ width: 'fit-content' }}
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    paddingRight: '6px',
                                }}
                            >
                                <Grid
                                    style={{
                                        width: '12px',
                                        height: '2px',
                                        backgroundColor: 'white',
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        color: '#9E9E9E',
                                        fontSize: '12px',
                                    }}
                                >
                                    Cota
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ChartPilotoWithValue;
