import moment from 'moment';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import { ROUTES } from '~/consts';

export default marca => {
    const assessmentPages = [
        ROUTES.COTA_PILOTO,
        ROUTES.STORE_COTA,
        ROUTES.STORE_OMNI,
        ROUTES.STORE_OVERVIEW,
        ROUTES.STORE_RANKING,
        ROUTES.VENDEDORA_PILOTO,
    ];
    const pagePathName = window.location.pathname;
    if (assessmentPages.includes(pagePathName)) {
        let assessmentDate = moment().endOf('month');
        if (
            moment().date() > 25 &&
            moment().isAfter(
                moment()
                    .hour(0)
                    .date(1)
                    .month(4)
                    .year(2021)
            )
        ) {
            assessmentDate = assessmentDate.add(1, 'month');
        }
        const { startDate } = getAssessmentDatesByDate(assessmentDate, marca);

        return {
            initialDate: startDate,
            finalDate: moment(),
            period: 'month',
        };
    }

    return {
        initialDate: moment(),
        finalDate: moment(),
        period: 'day',
    };
};
