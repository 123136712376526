import React from 'react';
import { Divider, Grid } from '@mui/material';

import PanelCardSalesInfo from '~/components/PanelCardSalesInfo';

import CardHeader from './CardHeader';

import * as Style from './styles';

import AveragesContainer from './AveragesContainer';

function SecondaryPanelCard({
    cardTitle,
    totalValue,
    averageValue,
    averageProductQuantity,
    productQuantity,
    orderQuantity,
}) {
    return (
        <Style.PanelCardContainer>
            <CardHeader cardTitle={cardTitle} totalValue={totalValue} />
            <Grid item container sx={{ gap: '16px' }}>
                <PanelCardSalesInfo
                    cardTitle={'Atendimentos'}
                    value={orderQuantity}
                />
                <PanelCardSalesInfo
                    cardTitle={'Peças vendidas'}
                    value={productQuantity}
                />
            </Grid>
            <Grid item>
                <Divider
                    style={{
                        height: '2px',
                        margin: '15px 0px',
                        borderColor: '#616161',
                    }}
                />
            </Grid>
            <Grid
                item
                container
                style={{
                    paddingBottom: '16px',
                    justifyContent: 'space-between',
                }}
            >
                <AveragesContainer
                    title={'PA'}
                    value={averageProductQuantity}
                />
                <AveragesContainer title={'VA'} value={averageValue} />
            </Grid>
        </Style.PanelCardContainer>
    );
}

export default SecondaryPanelCard;
