import React from 'react';
import { Grid } from '@mui/material';

import ChartPiloto from '~/components/ChartPiloto';
import ChartPilotoWithValue from '~/components/ChartPilotoWithValue';

import { ReactComponent as ChangeToCurrency } from '~/assets/icons/ChangeToCurrency.svg';

import * as Style from './styles';

function ChartPanelCard({
    balanceText,
    quota,
    chartData,
    setIsSwipeCardDisabled,
    handleFlipped,
}) {
    const getChartComponent = () => {
        if (balanceText) {
            return (
                <ChartPilotoWithValue
                    chartData={chartData}
                    quotaData={
                        quota
                            ? Object.values(quota.dailyQuotas).map(value => ({
                                  value,
                              }))
                            : []
                    }
                />
            );
        }
        return (
            <ChartPiloto
                chartData={chartData}
                quotaData={
                    quota
                        ? Object.values(quota.dailyQuotas).map(value => ({
                              value,
                          }))
                        : []
                }
            />
        );
    };

    const chartStyle = balanceText
        ? {
              display: 'flex',
              flexDirection: 'column',
              height: '160px',
              alignContent: 'space-around',
              justifyContent: 'center',
              width: '100%',
          }
        : { width: '100%' };

    return (
        <Style.PanelCardContainer>
            {!balanceText && (
                <Grid
                    item
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                    }}
                >
                    <ChangeToCurrency onClick={() => handleFlipped()} />
                </Grid>
            )}
            {!!balanceText && (
                <Grid
                    item
                    style={{
                        padding: '18px 0',
                        justifyContent: 'center',
                    }}
                >
                    <Style.BalanceText>{balanceText}</Style.BalanceText>
                </Grid>
            )}
            <Grid
                item
                onTouchStart={e => {
                    if (
                        e.target.tagName === 'rect' &&
                        Number(e.target.attributes.y.value) > 0
                    ) {
                        setIsSwipeCardDisabled(true);
                    }
                }}
                onTouchEnd={() => setIsSwipeCardDisabled(false)}
                style={chartStyle}
            >
                {getChartComponent()}
            </Grid>
        </Style.PanelCardContainer>
    );
}

export default ChartPanelCard;
