import React, { useState } from 'react';
import {
    Typography,
    DialogContent,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    SvgIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';

// Asset import
import { ReactComponent as FilterAltOutlined } from '~/assets/icons/filter_alt_outlined.svg';
import { ReactComponent as FilterAlt } from '~/assets/icons/filter_alt.svg';

// Util import
import { getBrandNameFromBrandId } from '~/util/brands';

// Style import
import * as Style from '../styles';

function MultimarcaBrandFilter({ brandFilter, setBrandFilter }) {
    const userProfile = useSelector(state => state.user.profile);
    const [brandFilterDialogIsOpen, setBrandFilterDialogIsOpen] = useState(
        false
    );
    const handleClickOnRadioButton = event => {
        setBrandFilter(event.target.value);
        setBrandFilterDialogIsOpen(false);
    };

    return (
        <>
            <Style.DialogContainer
                onClose={() => setBrandFilterDialogIsOpen(false)}
                open={brandFilterDialogIsOpen}
            >
                <Style.BrandFilterTitleContainer container>
                    <SvgIcon
                        style={{
                            fill: '#DCC6B7',
                            verticalAlign: 'text-bottom',
                        }}
                        component={FilterAltOutlined}
                    />
                    <Typography display="inline">Exibir Somente</Typography>
                </Style.BrandFilterTitleContainer>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={brandFilter}
                            onChange={e => handleClickOnRadioButton(e)}
                        >
                            <FormControlLabel
                                value="allBrands"
                                control={<Radio />}
                                label="Todos os pedidos"
                            />
                            {userProfile.marcaFilialMultimarca
                            .filter(brand => getBrandNameFromBrandId(brand))
                            .map(brand => (
                                <Style.BrandsFormControlLabel
                                    key={brand}
                                    value={getBrandNameFromBrandId(brand)}
                                    control={
                                        <Radio
                                            sx={{color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label={`Pedidos na ${getBrandNameFromBrandId(brand)}`}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Style.DialogContainer>
            <Style.BrandFilterTextContainer
                item
                container
                onClick={() => {
                    setBrandFilterDialogIsOpen(true);
                }}
            >
                <SvgIcon
                    style={{
                        fill: '#DCC6B7',
                        verticalAlign: 'text-bottom',
                    }}
                    component={
                        brandFilter === 'allBrands'
                            ? FilterAltOutlined
                            : FilterAlt
                    }
                />
                <Style.BrandFilterTextType>Filtrar</Style.BrandFilterTextType>
            </Style.BrandFilterTextContainer>
        </>
    );
}

export default MultimarcaBrandFilter;
