import { styled } from '@mui/material/styles';
import { Typography, CardMedia } from '@mui/material';

export const Logo = styled(CardMedia)`
    width: 6.2rem;
    margin-bottom: 1.3rem;
`;

export const Subtitle = styled(Typography)`
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    color: #dcc6b7;
    margin-bottom: 2.2rem;
`;

export const MainText = styled(Typography)`
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-bottom: ${props => props.primary ? '1rem' :'2rem'};
`;
