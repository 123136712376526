import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
// import { useHistory } from 'react-router-dom';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import { useSelector } from 'react-redux';

import Header from '~/components/Header';

import * as Style from './styles';

function ConteudoParaPosts() {
    // const history = useHistory();

    const profile = useSelector(state => state.user.profile);
    const [alternativeBrands, setAlternativeBrands] = useState([]);
    const [brands, setBrands] = useState([]);
    const [marcaPadrao, setMarcaPadrao] = useState(true);

    useEffect(() => {
        const getBrandsFromIdBrand = () => {
            if (profile.marcaFilial) {
                if (profile.marcaFilial === 1) {
                    setAlternativeBrands([6]); //            ANIMALE
                } else if (profile.marcaFilial === 3) {
                    setAlternativeBrands([1, 6]); //            ABRAND
                } else if (profile.marcaFilial === 6) {
                    setAlternativeBrands([]); //                OFFPREMIUM
                } else if (profile.marcaFilial === 2) {
                    setAlternativeBrands([5, 7]); //         FARM
                } else if (profile.marcaFilial === 5) {
                    setAlternativeBrands([]); //                FABULA
                } else if (profile.marcaFilial === 7) {
                    setAlternativeBrands([]); //                FOXTON
                } else if (profile.marcaFilial === 9) {
                    setBrands([6, 9]); //                       CRIS BARROS
                    setMarcaPadrao(false);
                } else if (profile.marcaFilial === 15) {
                    setBrands([6, 15]); //                      MARIA FILO
                    setMarcaPadrao(false);
                } else if (
                    profile.marcaFilial === 52 ||
                    profile.marcaFilial === 12
                ) {
                    setBrands([1, 2, 3, 5, 6, 7, 9, 15]); //    CORPORATIVO
                    setMarcaPadrao(false);
                }
            } else if (profile.marcaFilialMultimarca) {
                setBrands(
                    profile.marcaFilialMultimarca.filter(brand => brand !== 10)
                ); //    MULTIMARCA
                setMarcaPadrao(false);
            } else if (profile.id_perfil === 14) {
                setBrands([2]);
                setMarcaPadrao(false);
            } else {
                setBrands([1, 2, 3, 5, 6, 7, 9, 15]);
                setMarcaPadrao(false);
            }
        };
        getBrandsFromIdBrand();
    }, [profile.id_perfil, profile.marcaFilial, profile.marcaFilialMultimarca]);

    const getBrandFromBrands = userBrands => {
        switch (userBrands) {
            case 1:
                return 'Animale';
            case 2:
                return 'Farm';
            case 3:
                return 'A.Brand';
            case 5:
                return 'Fábula';
            case 6:
                return 'Off Premium';
            case 7:
                return 'Foxton';
            case 9:
                return 'Cris Barros';
            case 15:
                return 'Maria Filó';
            default:
                return '';
        }
    };

    const getLinkFromBrands = userBrands => {
        switch (userBrands) {
            case 1:
                return 'https://drive.google.com/drive/folders/1KdiYOYzi2-4UCvEOrulrgLVKRZlO8hBZ';
            case 2:
                return 'https://drive.google.com/drive/folders/1wsGGXabuVSY3dOwWz3k6S5sFMs4T1i0R';
            case 3:
                return 'https://drive.google.com/drive/folders/18pY7MZe2bDU58cBG2YNa4CjSdXXiCikg';
            case 5:
                return 'https://drive.google.com/drive/u/0/folders/1T5CBfwkScPBAbKT_ILGKy65AuRz8JOpy';
            case 6:
                return 'https://drive.google.com/open?id=1miko-aZlRo8Kedp_XGotb2PJxWl-HNu5';
            case 7:
                return 'https://drive.google.com/drive/folders/1L865afquac4WgHx593y9phUG9NNTQUEK';
            // case 9:
            //     return 'CRIS BARROS';
            // case 15:
            //     return 'MARIA FILÓ';
            default:
                return '';
        }
    };

    return (
        <>
            <Header />

            {marcaPadrao === true ? (
                <Style.ContentContainer container>
                    <Grid item xs={12}>
                        <Style.BrandContainer
                            container
                            onClick={() => {
                                window.open(
                                    getLinkFromBrands(profile.marcaFilial),
                                    '_blank'
                                );
                            }}
                        >
                            <Grid item>
                                <Style.BrandName>
                                    {getBrandFromBrands(profile.marcaFilial)}
                                </Style.BrandName>
                            </Grid>
                            <Style.Icon item>
                                <ChevronRightOutlinedIcon />
                            </Style.Icon>
                        </Style.BrandContainer>
                    </Grid>

                    {alternativeBrands
                        .filter(
                            prohibitedBrands =>
                                prohibitedBrands !== 9 &&
                                prohibitedBrands !== 15
                        )
                        .map(brand => (
                            <>
                                <Style.ListItem item>
                                    <Style.BrandContainer
                                        container
                                        onClick={() => {
                                            window.open(
                                                getLinkFromBrands(brand),
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <Grid item>
                                            <Style.BrandName>
                                                {getBrandFromBrands(brand)}
                                            </Style.BrandName>
                                        </Grid>
                                        <Style.Icon item>
                                            <ChevronRightOutlinedIcon />
                                        </Style.Icon>
                                    </Style.BrandContainer>
                                </Style.ListItem>
                            </>
                        ))}
                </Style.ContentContainer>
            ) : (
                <Style.ContentContainer container>
                    {brands
                        .filter(
                            prohibitedBrands =>
                                prohibitedBrands !== 9 &&
                                prohibitedBrands !== 15
                        )
                        .map(brand => (
                            <>
                                <Style.ListItem item>
                                    <Style.BrandContainer
                                        container
                                        onClick={() => {
                                            window.open(
                                                getLinkFromBrands(brand),
                                                '_blank'
                                            );
                                        }}
                                    >
                                        <Grid item>
                                            <Style.BrandName>
                                                {getBrandFromBrands(brand)}
                                            </Style.BrandName>
                                        </Grid>
                                        <Style.Icon item>
                                            <ChevronRightOutlinedIcon />
                                        </Style.Icon>
                                    </Style.BrandContainer>
                                </Style.ListItem>
                            </>
                        ))}
                </Style.ContentContainer>
            )}
        </>
    );
}

export default ConteudoParaPosts;
