import { styled } from '@mui/material/styles';
import { CircularProgress, List } from '@mui/material';

export const Root = styled(List)`
    width: 100%;
    background-color: transparent;

    :first-child {
        border-top: 1px solid #9e9e9e;
    }

    &.MuiList-root {
        padding: 0;
    }
`;

export const CircularProgressComponent = styled(CircularProgress)`
    color: #dcc6b7;
`;
