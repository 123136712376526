import { styled } from '@mui/material/styles';
import { ListItem, Box } from '@mui/material';

export const ListItemContainer = styled(ListItem)`
    border-bottom: 1px solid #9e9e9e;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.08);
    }

    width: 100%;

    &.MuiListItem-root {
        padding: 0.5rem 1rem 0.5rem;
    }
`;

export const ListItemContainerGrid = styled(Box)`
    display: flex;
    width: 100%;
    align-items: center;
    color: white;
    justify-content: space-between;
`;