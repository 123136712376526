import React from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import * as Style from './styles';

function ShareCart({ shareCartDialogOpen, setShareCartDialogOpen, link }) {
    return (
        <Style.DialogProps
            secondary
            open={shareCartDialogOpen}
            onClose={() => {
                setShareCartDialogOpen(false);
            }}
        >
            <Style.DialogRoot>
                <Style.DialogTitle>Link Copiado</Style.DialogTitle>

                <Style.IconContainer>
                    <CheckCircleOutlinedIcon
                        style={{
                            color: '#1BB55C',
                            fontSize: '36px',
                        }}
                    />
                </Style.IconContainer>
                <Style.DialogDefaultTypography>
                    O link <b>{link}</b> está na sua área de transferência.
                </Style.DialogDefaultTypography>
                <Style.DialogDefaultTypography>
                    Cole na mensagem que deseja compartilhar.
                </Style.DialogDefaultTypography>

                <Style.ButtonsContainer>
                    <Style.DialogButton
                        variant="outlined"
                        onClick={() => {
                            setShareCartDialogOpen(false);
                        }}
                    >
                        OK
                    </Style.DialogButton>
                </Style.ButtonsContainer>
            </Style.DialogRoot>
        </Style.DialogProps>
    );
}

export default ShareCart;
