export const onlineChannels = ['ONLINE', 'DEVOLUCAO', 'OMNI'];

export const physicalChannels = [
    'INSTORE',
    'VITRINE',
    'FISICO',
    'EXTERNA',
    'SOMASTORE',
    'ESTOQUE PROPRIO',
];
