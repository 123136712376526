import React, { useEffect, useState } from 'react';
import { CardContent, CircularProgress, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// Util import
import { getTotalValue } from '~/util/orderPanelFunctions';

// Local components import
import PanelTitle from './PanelTitle';
import SearchBar from './SearchBar';
import FilterButtons from './FilterButtons';
import PanelTotals from './PanelTotals';
import PanelList from './PanelList';

import * as Style from './styles';

function OrderListPanel({
    orders,
    brandFilter,
    setBrandFilter,
    multimarca,
    ordersLoaded,
    setCurrentPage,
    cartsLoaded,
}) {
    // Hooks
    const { isFaturado } = useSelector(state => state.status);
    const location = useLocation().pathname;

    // Local states
    const [totalFilterValue, setTotalFilterValue] = useState('R$ 0,00');
    const [filteredOrders, setFilteredOrders] = useState(orders);
    const [filter, setFilter] = useState('Todos');
    const [ordersFilter, setOrdersFilter] = useState('allOrders');
    const [hideOrdersFilter, setHideOrdersFilter] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');

    const isAtMaisCarrinho = location === '/maisCarrinho';

    const totalPanelCondition = [].includes(filter) && ['allBrands', 'otherBrands', 'myBrands'].includes(brandFilter);

    useEffect(() => {
        if (isFaturado) {
            setFilter('Todos');
        }
    }, [isFaturado]);

    useEffect(() => {
        setFilteredOrders(orders);

        if (!isAtMaisCarrinho) {
            const ordersTotals = getTotalValue(orders);
            setTotalFilterValue(ordersTotals);
        }
    }, [orders, isAtMaisCarrinho]);

    return (
        <>
            {ordersLoaded ? (
                <>
                    <Style.ComponentRoot>
                        <PanelTitle
                            ordersFilter={ordersFilter}
                            setOrdersFilter={setOrdersFilter}
                            brandFilter={brandFilter}
                            setBrandFilter={setBrandFilter}
                            hideOrdersFilter={hideOrdersFilter}
                            orders={orders}
                            filter={filter}
                            searchFilter={searchFilter}
                            setTotalFilterValue={setTotalFilterValue}
                            setFilteredOrders={setFilteredOrders}
                        />
                        {!isAtMaisCarrinho && (
                            <SearchBar
                                orders={orders}
                                setFilteredOrders={setFilteredOrders}
                                setTotalFilterValue={setTotalFilterValue}
                                filter={filter}
                                ordersFilter={ordersFilter}
                                searchFilter={searchFilter}
                                setSearchFilter={setSearchFilter}
                            />
                        )}
                        <FilterButtons
                            orders={orders}
                            setFilteredOrders={setFilteredOrders}
                            filter={filter}
                            setFilter={setFilter}
                            ordersFilter={ordersFilter}
                            setOrdersFilter={setOrdersFilter}
                            setHideOrdersFilter={setHideOrdersFilter}
                            setTotalFilterValue={setTotalFilterValue}
                            searchFilter={searchFilter}
                            filteredOrders={filteredOrders}
                        />
                        {!totalPanelCondition && !isAtMaisCarrinho && (
                            <PanelTotals
                                ordersFilter={ordersFilter}
                                filter={filter}
                                brandFilter={brandFilter}
                                totalFilterValue={totalFilterValue}
                            />
                        )}
                        <CardContent>
                            {!filteredOrders?.length ? (
                                <Style.ListContainer>
                                    <Style.ListItemContainer>
                                        {isAtMaisCarrinho ? (
                                            <>
                                                {!cartsLoaded ? (
                                                    <Grid
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            marginTop: '1rem',
                                                        }}
                                                    >
                                                        <CircularProgress />
                                                    </Grid>
                                                ) : (
                                                    'Sem carrinhos'
                                                )}
                                            </>
                                        ) : (
                                            'Sem pedidos'
                                        )}
                                    </Style.ListItemContainer>
                                </Style.ListContainer>
                            ) : (
                                <>
                                    <PanelList
                                        filteredOrders={filteredOrders}
                                        multimarca={multimarca}
                                        setCurrentPage={setCurrentPage}
                                        cartsLoaded={cartsLoaded}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Style.ComponentRoot>
                </>
            ) : (
                <Grid
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '1rem',
                    }}
                >
                    <CircularProgress />
                </Grid>
            )}
        </>
    );
}

export default OrderListPanel;
