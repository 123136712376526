import {styled} from '@mui/material/styles';
import {
    Box,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material';

export const CircularProgressRoot = styled(Box)`
    position: relative;
`

export const CircularBottom = styled(CircularProgress)`
    color: #424242;
`

export const CircularTop = styled(CircularProgress)`
    color: #DCC6B7;
    position: absolute;
    left: 0;
`

export const CircularProgressLabelBox = styled(Box)`
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ClickableCard = styled(Grid)`
    cursor: pointer;
    margin: ${props => props.secondary && '16px 0'};
    border: ${props => props.primary && '1px solid #bdbdbd'};
    border-radius: ${props => props.primary && '4px'};
    height: ${props => props.primary ? '64px' : '100%'};
    &:hover {
        background-color: #424242;
    };
`

export const PrimaryTypography = styled(Typography)`
    font-size: 14px;
    font-weight: ${props => props.primary ? '400' : '700'};
    color: ${props => props.primary ? '#9E9E9E' : 'white'};
`

export const SecondaryTypography = styled(Typography)`
    font-size: 24px;
    font-weight: 600;
    line-height: ${props => props.primary && '24px'};
    margin: ${props => props.secondary && '0 8px'};
`

export const PrimaryGridComponent = styled(Grid)`
    height: 102px;
    left: 0;
    top: 0;
    border-radius: 12px;
    background-color: #373435;
    margin-bottom: 8px;
`

export const AlignmentDiv = styled(Grid)`
    width: 30px;
    height: 100%;
`