import {styled} from '@mui/material/styles';
import { Button, CardMedia, Grid, Typography } from '@mui/material';

export const Logo = styled(CardMedia)`
    width: 210px;
    height: 55px;
    margin: 64px;
`

export const MainTextContainer = styled(Grid)`
    margin-top: 64px;
    margin-bottom: 128px;
`

export const MainTextTypography = styled(Typography)`
    color: #DEDEE3;
    text-align: center;
`

export const DefaultButton = styled(Button)`
    background-color: ${props => props.primary ? '#DCC6B7' : '#29292E'};
    color: ${props => props.primary ? 'black' : '#E1E1E6'};
    width: 156px;
    height: 56px;
    font-size: 15px;
    text-transform: none;
`