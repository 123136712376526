import { Dialog, DialogTitle, DialogContent, Button, Typography, CardMedia } from "@mui/material";
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: #2d2b2c;
    color: #d7bcae;
    width: 300px;
    padding: 20px;
    border-radius: 16px;
    z-index: 9999;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.25rem;
  font-weight: bold;
  color: #d7bcae;
  text-align: center;
  margin-top: 4px;
`;

export const StyledDialogContent = styled(DialogContent)`
  color: white;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 16px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 1rem;
  color: white;
  margin-bottom: 16px;
  & strong {
    font-weight: bold;
    color: #ffffff;
  }
`;

export const StyledIcon = styled('div')`
  font-size: 4rem;
  color: #d7bcae;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)`
  background-color: #d7bcae;
  color: #2d2b2c;
  font-weight: bold;
  padding: 12px;
  font-size: 0.875rem;
  &:hover {
    background-color: #bfa798;
  }
  width: 100%;
  box-sizing: border-box;
`;

export const StyledSecondaryButton = styled(Button)`
  color: #d7bcae;
  border: 1px solid #d7bcae;
  padding: 12px;
  font-size: 0.875rem;
  &:hover {
    background-color: rgba(215, 188, 174, 0.2);
  }
  width: 100%;
  box-sizing: border-box;
`;

export const Logo = styled(CardMedia)`
    width: 84px;
    height: 84px;
    margin: 16px 16px 50px;
`