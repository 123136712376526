import { styled } from '@mui/material/styles';
import { Grid, Box, MobileStepper } from '@mui/material';

export const Root = styled(Box)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: -webkit-center;
    margin-top: 16px;
    box-sizing: border-box;
    @media screen and (min-width: 960px) {
        display: none;
    }
`;

export const Stepper = styled(MobileStepper)`
    justify-content: center;
    background-color: inherit;
    & .MuiMobileStepper-dotActive {
        background-color: white;
    }
`;

export const SecondaryDesktopRoot = styled(Grid)`
    justify-content: center;
    width: 100%;
    padding: 8px 32px;
    gap: 2.2vw;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) and (min-width: 960px) {
        gap: 1.7vw;
    }
    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const PanelCardContainer = styled(Grid)`
    display: flex;
    justify-content: flex-start;
`;

export const CenteredPanelCardContainer = styled(Grid)`
    display: flex;
    justify-content: center;
`;
