import {styled} from '@mui/material/styles';
import { Grid , Box , MobileStepper} from '@mui/material';

export const Root = styled(Box)`
    flex-grow: 1;
    text-align: -webkit-center;
    margin-top: 16px;
    box-sizing: border-box;
    @media screen and (min-width: 960px){
        display: none;
    }
`

export const Stepper = styled(MobileStepper)`
    justify-content: center;
    background-color: inherit;
    & .MuiMobileStepper-dotActive {
        background-color: white;
    }
`

export const DesktopRoot = styled(Grid)`
    justify-content: center;
    gap: 22px;
    padding: 8px 32px;
    @media screen and (max-width: 960px) {
        display: none;
    }
`