import { styled } from '@mui/material/styles';
import { Grid, Badge, Typography } from '@mui/material';

export const StyledBadge = styled(Badge)`
    top: 25;
    background-color: transparent;
    padding: 4px;
`;

export const BackgroundSecondary = styled(Grid)`
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(220, 198, 183);
    height: 88px;
    position: sticky;
    top: 0;
    z-index: 1;
`;

export const StyledTypography = styled(Typography)`
    font-weight: ${props => (props.primary ? '600' : '500')};
    font-size: ${props => (props.primary ? '24px' : '16px')};
    color: #373435;
    text-transform: capitalize;
`;
