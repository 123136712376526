import React from 'react';
import { formatNumberToHour, formatPrice } from '~/util/format';

import PanelCardSalesInfo from '~/components/PanelCardSalesInfo';

import IndicatorContainer from './IndicatorContainer';

import * as Style from './styles';

function OmniPanelCard({ omniData, invoicedOrders }) {
    const totalOmni = formatPrice(`${omniData?.receitas}`);

    const TMA =
        isNaN(omniData?.tma_horas) || !omniData?.tma_horas
            ? '-h'
            : formatNumberToHour(omniData?.tma_horas);
    const cancelationRate = `${omniData?.taxa_cancelamento}%`;

    return (
        <Style.PanelContainer>
            <Style.CardTitle>
                <Style.MainText primary>Total</Style.MainText>
                <Style.MainText>{totalOmni}</Style.MainText>
            </Style.CardTitle>
            <Style.InvoiceNumberContainer>
                <PanelCardSalesInfo
                    cardTitle={'Pedidos faturados'}
                    value={invoicedOrders}
                />
            </Style.InvoiceNumberContainer>
            <Style.BottomContainer>
                <IndicatorContainer
                    title={'Pedidos faturados'}
                    value={invoicedOrders}
                />
                <Style.VerticalSeparator></Style.VerticalSeparator>
                <IndicatorContainer
                    title={'Tempo médio de atendimento (TMA)'}
                    value={TMA}
                />
                <Style.VerticalSeparator></Style.VerticalSeparator>
                <IndicatorContainer
                    title={'Taxa de cancelamento'}
                    value={cancelationRate}
                />
            </Style.BottomContainer>
        </Style.PanelContainer>
    );
}

export default OmniPanelCard;
