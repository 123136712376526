import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Divider } from '@mui/material';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import ListItemComponent from './ListItemComponent';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import * as Style from './styles';
import { ANIMALE, FABULA, FOXTON, NV } from '../../util/brands';
import {
    GERENTE_SOMA,
    VENDEDOR_RESPONSAVEL,
    CAIXA_SOMA,
} from '../../util/perfilId';

export default function ListIconPiloto({
    icons,
    onClick,
    showText,
    count,
}) {
    const [showTag, setShowTag] = useState(false);
    const userProfile = useSelector(state => state.user.profile);

    useEffect(() => {
        const codigo_vendedor =
            userProfile?.users_sales_code?.[0]?.codigo_vendedor ||
            userProfile?.users_branches?.[0]?.codigo_filial;

        const checkKeyTagNews = async () => {
            const NUMBER_TO_CLICK_TO_DISAPPEAR = 5;

            try {
                const { data } = await api.get(
                    `/cache/${codigo_vendedor}/checkKey`,
                    {
                        params: {
                            key: 'numberOfClicksTagNews',
                            service: 'extraOrders',
                        },
                    }
                );
                setShowTag(data < NUMBER_TO_CLICK_TO_DISAPPEAR);
            } catch (err) {
                Snackbar.error('Erro retornando statusChave');
            }
        };

        const brandsAllowed =
            userProfile.users_brands.find(value =>
                [ANIMALE, FABULA, FOXTON, NV].includes(value.id_marca)
            ) || null;

        const profilesAllowed = [
            GERENTE_SOMA,
            VENDEDOR_RESPONSAVEL,
            CAIXA_SOMA,
        ].includes(userProfile.id_perfil);

        if (brandsAllowed && profilesAllowed) {
            checkKeyTagNews();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleClick(icon) {
        onClick(icon);
    }

    return (
        <>
            <Style.ListItemContainer container>
                <Grid item>
                    <List style={{ padding: '0' }}>
                        {icons
                            .filter(icon => icon.position === 'top')
                            .map((icon, index) => (
                                <ListItemComponent
                                    icon={icon}
                                    handleClick={handleClick}
                                    index={index}
                                    count={count}
                                    showText={showText}
                                    showTag={showTag}
                                />
                            ))}
                        {icons
                            .filter(
                                icon =>
                                    icon.position !== 'bottom' &&
                                    icon.position !== 'top'
                            )
                            .map((icon, index) => (
                                <ListItemComponent
                                    icon={icon}
                                    handleClick={handleClick}
                                    index={index}
                                    count={count}
                                    showText={showText}
                                    showTag={showTag}
                                />
                            ))}
                    </List>
                </Grid>
                <Grid>
                    <Divider style={{ border: '1px solid #757575' }} />
                    <List style={{ padding: '0' }}>
                        {icons
                            .filter(icon => icon.position === 'bottom')
                            .map((icon, index) => (
                                <ListItemComponent
                                    icon={icon}
                                    handleClick={handleClick}
                                    index={index}
                                    count={count}
                                    showText={showText}
                                    showTag={showTag}
                                />
                            ))}
                    </List>
                </Grid>
            </Style.ListItemContainer>
        </>
    );
}

ListIconPiloto.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
        })
    ).isRequired,
    initialIndexSelect: PropTypes.number,
    showSelected: PropTypes.bool,
    showText: PropTypes.bool,
};
