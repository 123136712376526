import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const Root = styled(Box)`
    display: flex;
    gap: 1rem;
    align-items: center;
    max-width: 50%;
`

export const ClientNameContainer = styled(Grid)`
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const OrderIdComponent = styled(Grid)`
    font-size: 14px;
    @media screen and (max-width: 960px) {
        font-size: 12px;
    }
`;

export const AvatarContainer = styled(Box)`
    width: fit-content;
    box-sizing: border-box;
`
