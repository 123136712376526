import React, { useState, useEffect, useCallback } from 'react';
import 'react-dates/initialize';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import OldTheme from '~/util/MuiV4Theme';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

import { Button, Grid, IconButton, Backdrop } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { DateRangePicker } from 'react-dates';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';

import './react_dates_overrides.css';

import {
    changeFinalDate,
    changeInitialDate,
    changeDatePeriod,
} from '~/store/modules/status/actions';
import { getBrandNameFromBrandId, TURN_AROUND_DATE } from '../../util/brands';
import {
    TREINADOR_ADOROCODIGO,
    VENDEDOR_ADORO_CODIGO,
    LOJA_MULTIMARCA,
    VENDEDOR_MULTIMARCA,
    INFLUENCIADORES_OFF_VENDEDORES,
    INFLUENCIADORES_OFF,
} from '../../util/perfilId';

import style from './styles';

moment.locale('pt-br');

const StyledButtonPill = withStyles({
    root: {
        marginRight: '8px',
        marginBottom: '8px',
        height: '32px',
        minWidth: 'min-content',
    },
    label: {
        width: 'max-content',
        margin: '0px 4px',
    },
})(Button);

const styles = theme => style(theme);

function DateRangePickerComponent({
    hidden,
    classes,
    assessment,
    fixedPeriod = false,
    loading = false,
}) {
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const { initialDate, finalDate, period } = useSelector(
        state => state.status
    );
    const [focusedInput, setFocusedInput] = useState(null);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [dateRangePickerIsOpen, setDateRangePickerIsOpen] = useState(false);
    const [dateMin, setDateMin] = useState({ initialDate: '', finalDate: '' });
    const [isDateButtonDisabled, setIsDateButtonDisabled] = useState({
        previous: false,
        next: false,
    });
    const userProfile = useSelector(state => state.user.profile);
    const availablePeriods = [
        {
            period: 'month',
            ...(userProfile.id_perfil === TREINADOR_ADOROCODIGO
                ? { label: 'Mês' }
                : { label: 'Mês (Cota)' }),
        },
        { period: 'week', label: 'Semana' },
        { period: 'regular_month', label: 'Mês' },
        { period: 'day', label: 'Dia' },
        { period: 'custom', label: 'Personalizado' },
    ];
    const getAvailablePeriodsByUser = useCallback(() => {
        const userBranchBrand = userProfile.marcaFilial;
        if (userBranchBrand === 9) {
            return availablePeriods.filter(
                periodObj => periodObj.period !== 'regular_month'
            );
        }
        if (assessment) {
            return availablePeriods.filter(
                periodObj =>
                    !['week', 'regular_month'].includes(periodObj.period)
            );
        }
        return availablePeriods.filter(
            periodObj => !['week', 'month'].includes(periodObj.period)
        );
    }, [assessment, availablePeriods, userProfile.marcaFilial]);

    useEffect(() => {
        if (['custom', 'month', 'regular_month'].includes(period)) {
            setDateRangePickerIsOpen(!!focusedInput);
        }
    }, [focusedInput, period]);

    useEffect(() => {
        if (focusedInput === 'startDate') {
            dispatch(changeInitialDate(null));
            dispatch(changeFinalDate(null));
        }
    }, [dispatch, focusedInput]);

    useEffect(() => {
        if (finalDate === null || initialDate === null || loading) {
            setIsDateButtonDisabled({
                previous: true,
                next: true,
            });
        } else if (
            moment(initialDate).isSameOrBefore(
                moment(dateMin.initialDate),
                'day'
            )
        ) {
            setIsDateButtonDisabled({
                previous: true,
                next: false,
            });

            const currentYear = moment().year();
            const endOfFirstChallengeMonth = `${currentYear}-10-31`;

            if (
                location === '/adoroCodigoChallenge' &&
                moment().isSameOrBefore(moment(endOfFirstChallengeMonth), 'day')
            ) {
                setIsDateButtonDisabled({
                    previous: true,
                    next: true,
                });
            }
        } else {
            const now = moment();
            const newIsNextDateButtonDisabled = moment(finalDate).isSameOrAfter(
                now,
                'day'
            );
            setIsDateButtonDisabled({
                previous: false,
                next: newIsNextDateButtonDisabled,
            });
        }
    }, [
        dateMin.initialDate,
        finalDate,
        initialDate,
        loading,
        period,
        location,
        userProfile.marcaFilial,
    ]);

    useEffect(() => {
        const getDateMin = () => {
            const brandUser = userProfile.marcaFilial;
            let minDate;

            if (
                userProfile.id_perfil === TREINADOR_ADOROCODIGO ||
                userProfile.id_perfil === VENDEDOR_ADORO_CODIGO ||
                userProfile.id_perfil === INFLUENCIADORES_OFF_VENDEDORES ||
                userProfile.id_perfil === INFLUENCIADORES_OFF ||
                userProfile.id_perfil === VENDEDOR_MULTIMARCA ||
                userProfile.id_perfil === LOJA_MULTIMARCA
            ) {
                minDate = {
                    initialDate: TURN_AROUND_DATE.adoroCodigo,
                    finalDate: TURN_AROUND_DATE.adoroCodigo,
                };

                if (location === '/adoroCodigoChallenge') {
                    const currentYear = moment().year();
                    minDate = {
                        initialDate: `${currentYear}-10-01`,
                        finalDate: `${currentYear}-10-01`,
                    };
                }
            } else {
                switch (getBrandNameFromBrandId(brandUser)) {
                    case 'Animale':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.animale.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.animale.rangeDateMax,
                        };
                        break;
                    case 'Farm':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.farm.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.farm.rangeDateMax,
                        };
                        break;
                    case 'Fábula':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.fabula,
                            finalDate: TURN_AROUND_DATE.fabula,
                        };
                        break;
                    case 'Foxton':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.foxton.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.foxton.rangeDateMax,
                        };
                        break;
                    case 'OffPremium':
                        minDate = {
                            initialDate:
                                TURN_AROUND_DATE.offpremium.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.offpremium.rangeDateMax,
                        };
                        break;
                    case 'Cris Barros':
                        minDate = {
                            initialDate:
                                TURN_AROUND_DATE.crisbarros.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.crisbarros.rangeDateMax,
                        };
                        break;
                    case 'Maria Filó':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.mariafilo,
                            finalDate: TURN_AROUND_DATE.mariafilo,
                        };
                        break;

                    case 'NV':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.nv.rangeDateMin,
                            finalDate: TURN_AROUND_DATE.nv.rangeDateMax,
                        };
                        break;
                    case 'Marcas Corporativo':
                        minDate = {
                            initialDate: TURN_AROUND_DATE.corporativo,
                            finalDate: TURN_AROUND_DATE.corporativo,
                        };
                        break;
                    default:
                        minDate = {
                            initialDate: null,
                            finalDate: null,
                        };
                }
            }
            setDateMin(minDate);
        };

        getDateMin();
    }, [userProfile.id_perfil, userProfile.marcaFilial, location]);

    const handleDateChange = date => {
        if (period === 'day') {
            dispatch(changeInitialDate(date));
            dispatch(changeFinalDate(date));
        }
        if (period === 'month') {
            const endOfMonth = moment(date).endOf('month');
            const { startDate, endDate } = getAssessmentDatesByDate(endOfMonth);

            const isTodayInCurrentAfferitionPeriod =
                moment().isSameOrAfter(startDate, 'day') &&
                moment().isSameOrBefore(endDate, 'day');

            if (isTodayInCurrentAfferitionPeriod) {
                dispatch(changeDatePeriod('month'));
                dispatch(changeInitialDate(startDate));
                dispatch(changeFinalDate(moment()));
            } else {
                dispatch(changeInitialDate(startDate));
                dispatch(changeFinalDate(endDate));
            }
            if (
                moment(date).isSameOrBefore(moment(dateMin.initialDate), 'day')
            ) {
                dispatch(changeInitialDate(moment(dateMin.initialDate)));
                dispatch(changeFinalDate(moment(dateMin.finalDate)));
            }
        }
        if (period === 'regular_month') {
            const startDateOfMonth = moment(date).startOf('month');
            let endDateOfMonth = moment(date).endOf('month');
            if (moment().isSame(moment(date), 'month')) {
                endDateOfMonth = moment();
            }
            dispatch(changeInitialDate(startDateOfMonth));
            dispatch(changeFinalDate(endDateOfMonth));
        }
    };

    const handleClickOnChangeDateButton = increment => {
        const incrementPeriod = period;

        function getNewDate(date) {
            if (incrementPeriod === 'regular_month') {
                return moment(date).add(increment, 'month');
            }
            return moment(date).add(
                increment,
                incrementPeriod === 'custom' ? 'days' : incrementPeriod
            );
        }
        let newInitialDate = getNewDate(initialDate);

        let newFinalDate = getNewDate(finalDate);

        if (period === 'month') {
            if (
                moment().isSameOrAfter(newInitialDate) &&
                moment().isSameOrBefore(newFinalDate)
            ) {
                const { startDate, endDate } = getAssessmentDatesByDate(
                    newFinalDate
                );
                newInitialDate = startDate;
                newFinalDate = endDate;
            } else if (
                moment().date() > 25 &&
                moment().date() <= 31 &&
                increment === -1
            ) {
                const newDates = getAssessmentDatesByDate(initialDate);
                newInitialDate = newDates.startDate;
                newFinalDate = newDates.endDate;
            } else {
                const { startDate, endDate } = getAssessmentDatesByDate(
                    newFinalDate
                );
                newInitialDate = startDate;
                newFinalDate = endDate;
            }
        }

        if (period === 'regular_month') {
            newFinalDate = newFinalDate.endOf('month');
        }

        dispatch(changeInitialDate(newInitialDate));
        dispatch(changeFinalDate(newFinalDate));
    };

    function enableDate(date) {
        const endOfMonth = moment().endOf('month');
        return endOfMonth.isSameOrBefore(date);
    }

    function handleDateRangeChange(startDate, endDate) {
        if (period === 'week') {
            dispatch(changeInitialDate(startDate));
            dispatch(changeFinalDate(moment(startDate).add('days', 6)));
            return;
        }
        // Situação inicial, quando você muda a data inicial e escolhe a mesma data final
        if (
            moment(initialDate).format('YYYY-MM-DD') ===
                moment(startDate).format('YYYY-MM-DD') &&
            moment(finalDate).format('YYYY-MM-DD') ===
                moment(endDate).format('YYYY-MM-DD')
        ) {
            dispatch(changeFinalDate(endDate));
            return;
        }
        if (
            !startDate &&
            moment(endDate).format('YYYY-MM-DD') ===
                moment(finalDate).format('YYYY-MM-DD')
        ) {
            dispatch(changeFinalDate(endDate));
        }
        if (
            moment(endDate).format('YYYY-MM-DD') ===
                moment(finalDate).format('YYYY-MM-DD') &&
            moment(startDate).format('YYYY-MM-DD') ===
                moment(initialDate).format('YYYY-MM-DD')
        ) {
            return;
        }
        if (
            (moment(endDate).format('YYYY-MM-DD') !==
                moment(finalDate).format('YYYY-MM-DD') &&
                endDate) ||
            moment(startDate).format('YYYY-MM-DD') ===
                moment(initialDate).format('YYYY-MM-DD')
        ) {
            dispatch(changeFinalDate(endDate));
            return;
        }

        dispatch(changeInitialDate(startDate));
    }
    const setDateFromPeriodChange = useCallback(
        newPeriod => {
            if (newPeriod === 'day') {
                dispatch(changeInitialDate(moment()));
                dispatch(changeFinalDate(moment()));
                return;
            }
            if (newPeriod === 'month') {
                let assessmentDate = moment().endOf('month');
                if (
                    moment().date() > 25 &&
                    moment().isAfter(
                        moment()
                            .hour(0)
                            .date(1)
                            .month(4)
                            .year(2021)
                    )
                ) {
                    assessmentDate = assessmentDate.add(1, 'month');
                }
                const { startDate } = getAssessmentDatesByDate(assessmentDate);
                dispatch(changeInitialDate(startDate));
                dispatch(changeFinalDate(moment()));
                return;
            }
            if (newPeriod === 'regular_month') {
                dispatch(changeInitialDate(moment().startOf('month')));
                dispatch(changeFinalDate(moment()));
                return;
            }
            if (newPeriod === 'week') {
                dispatch(changeInitialDate(moment().startOf('week')));
                dispatch(changeFinalDate(moment().endOf('week')));
            }
            if (newPeriod === 'custom') {
                dispatch(changeInitialDate(null));
                dispatch(changeFinalDate(null));
            }
        },
        [dispatch]
    );

    const getDatePickerLabel = useCallback(
        date => {
            if (!date) {
                return '';
            }
            if (period === 'day') {
                return moment(date).format('DD MMM, yyyy');
            }
            if (period === 'month') {
                const endOfMonth = moment(date).endOf('month');
                const { startDate, endDate } = getAssessmentDatesByDate(
                    endOfMonth
                );
                return `${startDate.format('DD MMM, yyyy')} - ${endDate.format(
                    'DD MMM, yyyy'
                )}`;
            }
            if (period === 'regular_month') {
                return moment(date).format('MMM, yyyy');
            }
        },
        [period]
    );

    const getDatePickerValue = useCallback(() => {
        if (period === 'month') {
            if (
                initialDate.isBefore(
                    moment()
                        .hour(0)
                        .date(1)
                        .month(4)
                        .year(2021)
                )
            ) {
                return initialDate;
            }
            return initialDate.clone().add(1, 'month');
        }
        if (period === 'regular_month') {
            return initialDate;
        }
        return finalDate;
    }, [finalDate, initialDate, period]);

    return (
        <ThemeProvider theme={OldTheme}>
            <Backdrop
                open={fixedPeriod && dateRangePickerIsOpen}
                style={{ zIndex: 1 }}
            />
            {hidden || (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    className={
                        fixedPeriod
                            ? classes.datePickerContainerFixed
                            : classes.datePickerContainer
                    }
                    style={{
                        height: !fixedPeriod && '120px',
                        position: 'sticky',
                        top: '88px',
                        zIndex: 1,
                        backgroundColor: 'inherit',
                    }}
                >
                    {!fixedPeriod && (
                        <Grid className={classes.periodPillsContainer}>
                            {getAvailablePeriodsByUser().map(filterName => (
                                <StyledButtonPill
                                    key={`pill-filter-${filterName.period} `}
                                    className={
                                        filterName.period === period
                                            ? classes.mainButton2
                                            : classes.mainButton
                                    }
                                    variant={
                                        filterName.period === period
                                            ? 'text'
                                            : 'outlined'
                                    }
                                    size="medium"
                                    onClick={() => {
                                        dispatch(
                                            changeDatePeriod(filterName.period)
                                        );
                                        setDateFromPeriodChange(
                                            filterName.period
                                        );
                                    }}
                                >
                                    {filterName.label}
                                </StyledButtonPill>
                            ))}
                        </Grid>
                    )}
                    <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{
                            maxWidth: '400px',
                            width: 'fit-content',
                            paddingRight: fixedPeriod ? '27px' : '32px',
                        }}
                    >
                        {!['custom', 'week'].includes(period) ? (
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    onClick={() => setDatePickerIsOpen(true)}
                                    style={{
                                        padding: fixedPeriod && 0,
                                    }}
                                >
                                    <CalendarTodayOutlinedIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    onClick={() =>
                                        handleClickOnChangeDateButton(-1)
                                    }
                                    style={{ padding: '0px' }}
                                    disabled={isDateButtonDisabled.previous}
                                >
                                    <ChevronLeft fontSize="small" />
                                </IconButton>
                                <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}
                                    locale="pt-br"
                                >
                                    <DatePicker
                                        id="date-picker-dialog"
                                        labelFunc={date =>
                                            getDatePickerLabel(date)
                                        }
                                        format={
                                            period === 'day'
                                                ? 'DD MMM, yyyy'
                                                : 'MMM, yyyy'
                                        }
                                        views={
                                            period === 'day'
                                                ? ['date']
                                                : ['year', 'month']
                                        }
                                        autoOk
                                        disableFuture
                                        value={getDatePickerValue()}
                                        maxDateMessage=""
                                        onChange={handleDateChange}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                textAlignLast: 'center',
                                                width: 'fit-content',
                                            },
                                            inputProps: {
                                                style: {
                                                    textTransform: 'capitalize',
                                                    paddingBottom: '6px',
                                                },
                                            },
                                        }}
                                        openTo={
                                            period !== 'day' &&
                                            ('month' || 'regular_month')
                                        }
                                        open={datePickerIsOpen}
                                        onOpen={() => setDatePickerIsOpen(true)}
                                        onClose={() =>
                                            setDatePickerIsOpen(false)
                                        }
                                        disableToolbar={period === 'day'}
                                        cancelLabel=""
                                        okLabel=""
                                        emptyLabel="Data Inválida"
                                        minDate={dateMin.initialDate}
                                    />
                                </MuiPickersUtilsProvider>

                                <IconButton
                                    onClick={() =>
                                        handleClickOnChangeDateButton(1)
                                    }
                                    style={{ padding: '0px' }}
                                    disabled={isDateButtonDisabled.next}
                                >
                                    <ChevronRight fontSize="small" />
                                </IconButton>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <IconButton
                                    onClick={() =>
                                        handleClickOnChangeDateButton(-1)
                                    }
                                    style={{ padding: '0px' }}
                                    disabled={isDateButtonDisabled.previous}
                                >
                                    <ChevronLeft fontSize="small" />
                                </IconButton>
                                <DateRangePicker
                                    {...(period === 'week' && {
                                        startDateOffset: day =>
                                            moment(day).startOf('week'),
                                        endDateOffset: day =>
                                            moment(day).endOf('week'),
                                        disabled: 'endDate',
                                    })}
                                    readOnly
                                    block
                                    minimumNights={period === 'week' ? 7 : 0}
                                    numberOfMonths={
                                        window.innerWidth <= 600 ? 1 : 2
                                    }
                                    orientation={
                                        window.innerWidth <= 600
                                            ? 'horizontal'
                                            : 'horizontal'
                                    }
                                    customInputIcon={
                                        <CalendarTodayOutlinedIcon
                                            fontSize="small"
                                            style={{
                                                verticalAlign: 'bottom',
                                                color: 'white',
                                            }}
                                        />
                                    }
                                    startDatePlaceholderText="Início"
                                    endDatePlaceholderText="Final"
                                    noBorder
                                    small
                                    initialVisibleMonth={() => moment()}
                                    isOutsideRange={date => enableDate(date)}
                                    startDate={
                                        initialDate ? moment(initialDate) : null
                                    } // momentPropTypes.momentObj or null,
                                    startDateId="startDateID" // PropTypes.string.isRequired,
                                    endDate={
                                        finalDate ? moment(finalDate) : null
                                    } // momentPropTypes.momentObj or null,
                                    endDateId="endDateID" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        handleDateRangeChange(
                                            startDate,
                                            endDate
                                        );
                                    }} // PropTypes.func.isRequired,
                                    focusedInput={focusedInput}
                                    onFocusChange={focusInput =>
                                        setFocusedInput(focusInput)
                                    } // PropTypes.func.isRequired,
                                    displayFormat="DD MMM, yyyy"
                                    minDate={moment(dateMin.initialDate)}
                                />
                                <IconButton
                                    onClick={() =>
                                        handleClickOnChangeDateButton(1)
                                    }
                                    style={{ padding: '0px' }}
                                    disabled={isDateButtonDisabled.next}
                                >
                                    <ChevronRight fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </ThemeProvider>
    );
}

export default withStyles(styles)(DateRangePickerComponent);
