import SnackBar from './SnackBar';
import { formatDateString, formatPrice } from './format';
import { sortOrdersByDate } from './sortOrdersByDate';

import api from '~/services/api';

const handleOrdersResponse = async (data, omni) => {
    let newOrderData;

    newOrderData = data.map(order => ({
        ...order,
        formatCreationDate: formatDateString(order.creationDate),
        formatInvoicedDate: formatDateString(order.invoicedDate),
        value: formatPrice(order.value),
        ...(omni && {
            valueAsNumber: order?.value,
            status_evento: order?.ultimo_status,
            channel:
                order?.ultimo_status === 'DEVOLUCAO'
                    ? order?.ultimo_status
                    : order?.channel,
        }),
    }));

    // FILTRANDO A RESPONSE PARA EXCLUIR CASOS DE VENDA FISICA COM VALOR ZERO. TRATANDO CASOS DE CANCELAMENTOS FISICOS FEITOS EM LOJA
    // QUE NAO DEVEM SER MOSTRADOS AO USUARIO

    newOrderData = newOrderData.filter(order => {
        if (order.value === formatPrice(0)) {
            return false;
        }
        return true;
    });

    for (let i = 0; i < newOrderData.length; i += 1) {
        if (
            newOrderData[i].value[0] === '-' &&
            newOrderData[i].channel === 'FISICO'
        ) {
            newOrderData[i].channel = 'DEVOLUCAO';
        }
        if (newOrderData[i].status_evento === 'CANCELADO') {
            newOrderData[i].channel = 'CANCELADO';
        }
        if (newOrderData[i].channel === 'ESTOQUE PROPRIO') {
            newOrderData[i].channel = 'FISICO';
        }
    }

    newOrderData = sortOrdersByDate(newOrderData, false);

    if (newOrderData.length) {
        try {
            const clientResponse = await api.post(
                `salesperson/orders/client-data`,
                {
                    orderData: newOrderData.map(order => ({
                        orderId: order.orderId,
                        clientId: order.clientId,
                    })),
                }
            );

            newOrderData = newOrderData.map(order => ({
                ...order,
                clientData: clientResponse.data.find(
                    client =>
                        client.orderId === order.orderId &&
                        client.clientId === order.clientId
                ).clientData,
            }));
        } catch {
            SnackBar.error('Erro retornando dados do cliente');
            newOrderData = newOrderData.map(order => ({
                ...order,
                clientData: order.clientId,
            }));
        }
    }

    return newOrderData;
};

export { handleOrdersResponse };
