import {
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

import 'moment/locale/pt-br';
import React from 'react';
import chatImage from '~/assets/firstAccess/chat.png';
import * as Style from './styles';

function Onboard({ ...props }) {
    const history = useHistory();

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <IconButton onClick={() => history.push('/login')}>
                            <Style.StyledIcon />
                        </IconButton>
                    </Grid>
                    <Style.HeaderContainer container>
                        <Grid item>
                            <Style.Logo
                                component="img"
                                src={chatImage}
                                title="Grupo Soma"
                            />
                        </Grid>
                        <Grid item>
                            <Style.HeaderStepNumber
                                variant="h2"
                            >
                                01
                            </Style.HeaderStepNumber>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid container justifyContent="center">
                        <Grid item xs={9}>
                            <Typography align="left" variant="h3">
                                Você tem código de vendedor?
                            </Typography>
                        </Grid>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                O código de vendedor tem 4 ou mais dígitos e é
                                usado para identificar vendas feitas nas marcas
                                do Grupo Soma{' '}
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={12} container justifyContent="space-evenly">
                            <Style.DefaultButton
                                primary
                                onClick={() => props.setCurrentPage('login')}
                            >
                                {' '}
                                Sim
                            </Style.DefaultButton>
                            <Style.DefaultButton
                                onClick={() =>
                                    props.setCurrentPage('onboardAffiliates')
                                }
                            >
                                Não
                            </Style.DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Onboard;