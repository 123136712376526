import React from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import AssignmentOutlined from '@mui/icons-material/AssignmentOutlined';
import AssignmentTurnedInOutlined from '@mui/icons-material/AssignmentTurnedInOutlined';

import Header from '~/components/Header';

import * as Style from './styles';

function OmniOperational() {
    const history = useHistory();

    const handleClickExtraOrders = () => {
        history.push(`/extraOrders`);
    };

    return (
        <>
            <Header />
            <Style.PrimaryContainer container primary>
                <Style.SecondaryContainer item>
                    <Style.Text primary>Operacional Omni</Style.Text>
                </Style.SecondaryContainer>

                <Style.SecondaryContainer item primary>
                    <Style.BrandContainer
                        container
                        onClick={() => {
                            history.push(`/storeOmni`);
                        }}
                    >
                        <Grid item>
                            <Style.PrimaryContainer>
                                <Style.IconContainer item primary>
                                    <AssignmentTurnedInOutlined />
                                </Style.IconContainer>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Style.Text>
                                                Pedidos Realizados
                                            </Style.Text>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.PrimaryContainer>
                        </Grid>
                        <Style.IconContainer item>
                            <ChevronRightOutlinedIcon />
                        </Style.IconContainer>
                    </Style.BrandContainer>
                </Style.SecondaryContainer>

                <Style.SecondaryContainer item primary>
                    <Style.BrandContainer
                        container
                        onClick={() => {
                            handleClickExtraOrders();
                        }}
                    >
                        <Grid item>
                            <Style.PrimaryContainer>
                                <Style.IconContainer item primary>
                                    <AssignmentOutlined />
                                </Style.IconContainer>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Style.Text>
                                                Pedidos Extras
                                            </Style.Text>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.PrimaryContainer>
                        </Grid>

                        <Grid item>
                            <Style.PrimaryContainer>
                                <Grid item>
                                    <Style.IconContainer item>
                                        <ChevronRightOutlinedIcon />
                                    </Style.IconContainer>
                                </Grid>
                            </Style.PrimaryContainer>
                        </Grid>
                    </Style.BrandContainer>
                </Style.SecondaryContainer>
            </Style.PrimaryContainer>
        </>
    );
}

export default OmniOperational;
