import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const CardContainer = styled(Box)`
    background-color: #373435;
`;

export const TitleContainer = styled(Grid)`
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 24px;
`;