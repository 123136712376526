import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    background-color: #373435;
    height: 100%;
    width: 100%;
    padding: 16px;
`;

export const CloseButtonContainer = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

export const ModalDataContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled(Box)`
    color: #dcc6b7;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
`;

export const Icon = styled(Box)`
    background: #dcc6b7;
    width: 125px;
    height: 125px;
    border-radius: 100%;
    margin: 26px 0px;
    display: flex;
    justify-content: center;
`;

export const Subtitle = styled(Box)`
    color: #dcc6b7;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
`;

export const ModalText = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const ActionButton = styled(Button)`
    border-radius: 8px;
    margin-bottom: 24px;
    width: 60%;
    background-color: #dcc6b7;
    color: #373435;
    height: 40px;
    text-transform: capitalize;
`;
