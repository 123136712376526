import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import DateRangePicker from '~/components/DateRangePicker';
import Header from '~/components/Header';
import CustomHeader from '~/components/CustomHeader';
import SalesTerms from '~/components/SalesTerms';
import NPSForm from '~/components/NPSForm';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';

import { formatPrice, formatWithCent } from '~/util/format';
import {
    onlineChannels,
    physicalChannels,
} from '~/util/mapChannelsPhysicalAndOnline';
import { changeShouldShowNPS } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';
import getDateRange from '~/util/getDateRange';

function Cota({ location }) {
    const dispatch = useDispatch();
    const [capturedOrdersIndicators, setCapturedOrdersIndicators] = useState(
        {}
    );
    const [indicatorsText, setIndicatorsText] = useState({});
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [balanceText, setBalanceText] = useState(0);
    const [panelLoaded, setPanelLoaded] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [brandFilter, setBrandFilter] = useState('allBrands');

    const multimarca = true;

    const userProfileFromStore = useSelector(state => state.user.profile);
    const [userProfile, setUserProfile] = useState(undefined);
    const { initialDate, finalDate, isFaturado, period } = useSelector(
        state => state.status
    );
    const dateRange = getDateRange(initialDate, finalDate, 'DD/MM/YYYY');
    const [salesCodeFromParams, setSalesCodeFromParams] = useState('');

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });
            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        const isFarm = userProfile?.marcaFilial === 2;
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;
        if (shouldAnswerNPS) {
            if (!(beforeDateLimit && isFarm)) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const salesCode = query.get('salesCode');
        setSalesCodeFromParams(salesCode);
        if (
            salesCode &&
            [6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 21].includes(
                userProfileFromStore.id_perfil
            )
        ) {
            const salesCodeFromQueryParams = salesCode.toUpperCase();
            const getUserData = async () => {
                const response = await api.post(`session/user-data`, {
                    salesCode: salesCodeFromQueryParams,
                });
                setUserProfile(response.data.user);
            };
            getUserData();
        } else {
            setUserProfile(userProfileFromStore);
        }
        // eslint-disable-next-line react/destructuring-assignment
    }, [location.search, userProfileFromStore]);

    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const newIndicatorsText = {
            totalValue: 0,
            averageValue: 0,
            averageQuantity: 0,
            orderQuantity: 0,
            productQuantity: 0,
            onlineTotalValue: 0,
            onlineAverageValue: 0,
            onlineAverageQuantity: 0,
            onlineOrderQuantity: 0,
            onlineProductQuantity: 0,
            physicalTotalValue: 0,
            physicalAverageValue: 0,
            physicalAverageQuantity: 0,
            physicalOrderQuantity: 0,
            physicalProductQuantity: 0,
        };
        const ordersIndicators = capturedOrdersIndicators;

        Object.entries(ordersIndicators).forEach(
            ([indicatorChannel, indicatorValue]) => {
                if (onlineChannels.includes(indicatorChannel)) {
                    newIndicatorsText.onlineTotalValue += indicatorValue.value;
                    newIndicatorsText.onlineOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.onlineProductQuantity +=
                        indicatorValue.productQuantity;
                }
                if (physicalChannels.includes(indicatorChannel)) {
                    newIndicatorsText.physicalTotalValue +=
                        indicatorValue.value;
                    newIndicatorsText.physicalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.physicalProductQuantity +=
                        indicatorValue.productQuantity;
                }
                newIndicatorsText.totalValue += indicatorValue.value;
                newIndicatorsText.orderQuantity +=
                    indicatorValue.serviceQuantity;
                newIndicatorsText.productQuantity +=
                    indicatorValue.productQuantity;
            }
        );

        newIndicatorsText.onlineAverageQuantity =
            newIndicatorsText.onlineProductQuantity /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.onlineAverageValue =
            newIndicatorsText.onlineTotalValue /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.physicalAverageQuantity =
            newIndicatorsText.physicalProductQuantity /
                newIndicatorsText.physicalOrderQuantity || 0;

        newIndicatorsText.physicalAverageValue =
            newIndicatorsText.physicalTotalValue /
                newIndicatorsText.physicalOrderQuantity || 0;

        newIndicatorsText.averageQuantity =
            newIndicatorsText.productQuantity /
                newIndicatorsText.orderQuantity || 0;

        newIndicatorsText.averageValue =
            newIndicatorsText.totalValue / newIndicatorsText.orderQuantity || 0;

        const cantFormat = [
            'onlineAverageQuantity',
            'physicalAverageQuantity',
            'averageQuantity',
            'orderQuantity',
            'productQuantity',
        ];

        Object.keys(newIndicatorsText).forEach(key => {
            if (!cantFormat.includes(key)) {
                newIndicatorsText[key] = formatWithCent(newIndicatorsText[key]);
            }
        });
        setIndicatorsText(newIndicatorsText);
    }, [capturedOrdersIndicators, isFaturado]);

    const getBrandsParamsForRequests = useCallback(() => {
        const allBrandsIds = Array.from(
            { length: 16 },
            (_, brandId) => brandId + 1
        );

        const myBrands = userProfile.marcaFilialMultimarca.map(
            brandObj => brandObj
        );

        switch (brandFilter) {
            case 'Animale':
                return [1];
            case 'Farm':
                return [2];
            case 'A.Brand':
                return [3];
            case 'Fábula':
                return [5];
            case 'OffPremium':
                return [6];
            case 'Foxton':
                return [7];
            case 'Cris Barros':
                return [9];
            case 'Maria Filó':
                return [15];
            case 'allBrands':
                return [];
            case 'myBrands':
                return myBrands;
            case 'otherBrands':
                return allBrandsIds.filter(
                    allBrandsId => !myBrands.includes(allBrandsId)
                );
            default:
                return [];
        }
    }, [brandFilter, userProfile]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setPanelLoaded(false);
            setOrdersLoaded(false);
            const getAggregatedCapturedOrders = async () => {
                try {
                    const query = new URLSearchParams(location.search);
                    const salesCode = query.get('salesCode');

                    const requestParams = {
                        fatured: 'false',
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        brands: getBrandsParamsForRequests(),
                        transaction: 'true',
                        farmLatam: 'exclude'
                    };

                    if (salesCode) {
                        requestParams.filialCode =
                            userProfileFromStore.users_branches[0].codigo_filial;
                    }

                    const response = await api.get(
                        `/salesperson/aggregated-orders`,
                        {
                            params: requestParams,
                        }
                    );

                    setBalanceText(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setCapturedOrdersIndicators(
                        response.data.data.aggregatedByChannel
                    );
                    setChartData(
                        period === 'day'
                            ? Object.values(
                                  response.data.data.aggregatedByHour
                              ).map(hourObj => ({
                                  value: hourObj.value,
                                  label: hourObj.prettyHour,
                              }))
                            : getDateRange(
                                  initialDate,
                                  finalDate,
                                  'YYYY-MM-DD'
                              ).map(date => ({
                                  value:
                                      response.data.data.aggregatedByDay[
                                          date.label
                                      ]?.value,
                                  label: date.formattedLabel,
                              }))
                    );

                    if (response.data.msg) {
                        Snackbar.info(response.data.msg);
                    }
                } catch (e) {
                    Snackbar.error(
                        'Erro retornando os indicadores de pedidos capturados'
                    );
                    console.log(e);
                }
            };
            const getSalespersonOrders = async () => {
                try {
                    const query = new URLSearchParams(location.search);
                    const salesCode = query.get('salesCode');

                    const requestParams = {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        salesCode: userProfile.users_sales_code.map(
                            salesCodeObj => salesCodeObj.codigo_vendedor
                        ),
                        orderType: isFaturado ? 'invoiced' : 'captured',
                        brands: getBrandsParamsForRequests(),
                        transaction: 'true',
                        farmLatam: 'exclude'
                    };

                    if (salesCode) {
                        requestParams.filialCode =
                            userProfileFromStore.users_branches[0].codigo_filial;
                    }

                    const response = await api.get(`salesperson/orders`, {
                        params: requestParams,
                    });

                    let formattedOrders = await handleOrdersResponse(
                        response.data.data,
                        false
                    );

                    setOrders(formattedOrders);
                    setOrdersLoaded(true);
                } catch {
                    Snackbar.error('Erro retornando a lista de pedidos');
                    setOrders([]);
                }
            };
            Promise.all([getAggregatedCapturedOrders(), getSalespersonOrders()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => {
                    setPanelLoaded(true);
                    setOrdersLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        getBrandsParamsForRequests,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            {location.search.includes('salesCode') ? (
                <CustomHeader
                    title={
                        userProfile &&
                        `${userProfile?.nome
                            .split(' ')[0]
                            .toLowerCase()} (${salesCodeFromParams})`
                    }
                />
            ) : (
                <Header />
            )}
            <DateRangePicker hidden={!panelLoaded} />
            {panelLoaded || (
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Grid>
            )}
            <DesktopLayout
                balanceText={balanceText}
                indicatorsText={indicatorsText}
                panelLoaded={panelLoaded}
                chartData={chartData}
                dateRange={dateRange}
                orders={orders}
                ordersLoaded={ordersLoaded}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
                multimarca={multimarca}
            />
        </>
    );
}

export default Cota;
