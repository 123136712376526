import React from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';

function RecalculatedQuotaCheckbox({showRecalculatedQuota, setShowRecalculatedQuota}) {
    return (
        <Grid item container style={{ marginTop: '24px' }}>
            <Checkbox
                checked={showRecalculatedQuota}
                onChange={e => {
                    setShowRecalculatedQuota(e.target.checked);
                }}
                style={{
                    padding: '8px 8px 8px 0px',
                }}
            />
            <Typography
                style={{
                    fontSize: '16px',
                    fontWeight: showRecalculatedQuota && '600',
                    textAlign: 'left',
                    color: showRecalculatedQuota && '#DCC6B7',
                    alignSelf: 'center',
                }}
            >
                Ver cota recalculada
            </Typography>
        </Grid>
    );
}

export default RecalculatedQuotaCheckbox;
