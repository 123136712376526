import React from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const getIconFromCarts = cart => {
    switch (cart.status) {
        case 'PENDENTE':
            return (
                <AccessTimeOutlinedIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'FINALIZADO':
            return (
                <CheckCircleOutlinedIcon
                    style={{
                        color: '#1BB55C',
                    }}
                />
            );
        default:
            return (
                <AccessTimeOutlinedIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
    }
};

export { getIconFromCarts };
