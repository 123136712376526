import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

function RankingTotalValue({totalValue}) {
    return (
        <Grid item xs={12} style={{ margin: '16px', marginBottom: '0px' }}>
            <Box style={{ backgroundColor: '#373435', padding: '16px' }}>
                <Typography
                    style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'left',
                    }}
                    display="inline"
                >
                    Total:
                </Typography>
                <Typography
                    display="inline"
                    style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        float: 'right',
                    }}
                >
                    {totalValue}
                </Typography>
            </Box>
        </Grid>
    );
}

export default RankingTotalValue;
