import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';

import {
    Dialog,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import salesTerms from './salesTerms.pdf';
import api from '~/services/api';
import * as Style from './styles';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function SalesTerms() {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);

    const userProfile = useSelector(state => state.user.profile);

    useEffect(() => {
        if (userProfile.id_perfil === 14) {
            const getTermsStatus = async () => {
                const affiliateId =
                    userProfile.users_affiliates[0].id_affiliates;
                const termsResponse = await api.get(
                    `/salesperson/terms?id_affiliates=${encodeURIComponent(
                        affiliateId
                    )}`
                );
                if (!termsResponse.data.id_contracts) {
                    setDialogOpen(true);
                }
            };
            getTermsStatus();
        }
    }, [userProfile]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);
    };

    const goToPrevPage = () => setCurrentPage(currentPage - 1);
    const goToNextPage = () => setCurrentPage(currentPage + 1);

    const updateTerms = () => {
        const affiliateId = userProfile.users_affiliates[0].id_affiliates;
        api.post(
            `/salesperson/terms?id_affiliates=${encodeURIComponent(
                affiliateId
            )}`
        );
    };

    const acceptTerms = () => {
        updateTerms();
        setDialogOpen(false);
    };

    return (
        <>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Style.DialogTitleContainer>Termo de Aceite</Style.DialogTitleContainer>
                <div style={{ overflow: 'scroll' }}>
                    <Document
                        file={salesTerms}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={currentPage} />
                    </Document>
                </div>
                <Style.DialogActionsContainer>
                    <Typography>
                        Página: {currentPage}/{totalPages}
                    </Typography>
                    {currentPage === totalPages ? (
                        <Style.DialogButton onClick={acceptTerms}>Aceitar</Style.DialogButton>
                    ) : (
                        <>
                            <Style.DialogButton
                                onClick={goToPrevPage}
                                disabled={currentPage === 1}
                            >
                                Anterior
                            </Style.DialogButton>
                            <Style.DialogButton
                                onClick={goToNextPage}
                                disables={currentPage === totalPages}
                            >
                                Próxima
                            </Style.DialogButton>
                        </>
                    )}
                </Style.DialogActionsContainer>
            </Dialog>
        </>
    );
}

export default SalesTerms;
