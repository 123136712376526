import {styled} from '@mui/material/styles';
import { TextField, MenuItem } from '@mui/material';

export const CssTextField = styled(TextField)`
    border-radius: 8px;
    border-color: #DCC6B7;
    color: #DCC6B7;
    font-weight: 600;
    font-family: 'Inter';
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #DCC6B7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #DCC6B7;
    };
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #DCC6B7;
            border-radius: 8px;
        };
        &:hover fieldset {
            border-color: #DCC6B7;
        };
        & .Mui-focused fieldset {
            border-color: #DCC6B7;
        };
    };
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0px;
    };
    & .MuiInputLabel-root {
        color: #DCC6B7;
        font-family: 'Inter';
    };
    & .MuiInputLabel-root.Mui-focused {
        color: #DCC6B7;
    };
    & .Mui-focused {
        border-color: #DCC6B7;
        color: #DCC6B7;
    };
    & .MuiInputBase-root {
        color: white;
        font-family: 'Inter';
    };
    & .MuiSelect-root {
        padding: 0;
        color: white;
        background-color: #424242;
        font-family: "Inter";
    };
    & .MuiSvgIcon-root {
        color: white;
    };
`

export const StyledMenuItem = styled(MenuItem)`
    color: white;
    font-family: 'Inter';
`