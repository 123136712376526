import {
    Grid,
    Typography,
    IconButton,
} from '@mui/material';
import 'moment/locale/pt-br';
import React from 'react';
import chatImage from '~/assets/firstAccess/chat.png';
import * as Style from './styles';

function Onboard({ classes, ...props }) {
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <IconButton
                            onClick={() => props.setCurrentPage('onboard')}
                        >
                            <Style.StyledIcon/>
                        </IconButton>
                    </Grid>
                    <Style.HeaderContainer container>
                        <Grid item>
                            <Style.Logo
                                component="img"
                                src={chatImage}
                                title="Grupo Soma"
                            />
                        </Grid>
                        <Grid item>
                            <Style.HeaderStepNumber
                                variant="h2"
                            >
                                02
                            </Style.HeaderStepNumber>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid container justifyContent="center">
                        <Grid item xs={9}>
                            <Typography align="left" variant="h3">
                                Você tem um código clifor?
                            </Typography>
                        </Grid>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                O código clifor tem pelo menos 6 dígitos e é
                                usado para identificar a sua multimarca ou
                                cadastro como membro do programa Loja Virtual da
                                Animale. Caso não saiba qual é, entre em contato
                                a Assistência Afiliados Soma.{' '}
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={12} container justifyContent="space-evenly">
                            <Style.DefaultButton
                                primary
                                onClick={() =>
                                    props.setCurrentPage('loginAffiliates')
                                }
                            >
                                {' '}
                                Sim
                            </Style.DefaultButton>
                            <Style.DefaultButton
                                onClick={() =>
                                    props.setCurrentPage('cannotRegister')
                                }
                            >
                                Não
                            </Style.DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Onboard;
