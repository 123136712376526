import moment from 'moment';

const didSalespersonEndBeforePeriod = (salespersonEnd, endDate) => {
    if (salespersonEnd) {
        return salespersonEnd.isBefore(moment(endDate));
    }
    return false;
};

const getSalespersonQuotaList = (
    storeQuotasByDay,
    salespersonStartedMidPeriod,
    salespersonLeftMidPeriod,
    salesperson
) => {
    const storeQuotaDays = Object.keys(storeQuotasByDay);
    if (salespersonStartedMidPeriod) {
        const formattedStartDate = salesperson.data_inicio.slice(0, 10);
        const getIndexOfStartDate = storeQuotaDays.indexOf(formattedStartDate);
        const newQuotaDaysList = storeQuotaDays.slice(getIndexOfStartDate);
        return newQuotaDaysList;
    }
    if (salespersonLeftMidPeriod) {
        const formattedEndDate = salesperson.data_fim.slice(0, 10);
        const getIndexOfEndDate = storeQuotaDays.indexOf(formattedEndDate);
        const newQuotaDaysList = storeQuotaDays.slice(0, getIndexOfEndDate);
        return newQuotaDaysList;
    }
    return storeQuotaDays;
};

const getSalespersonQuotaDays = (
    salesperson,
    startDate,
    endDate,
    storeQuotasByDay
) => {
    let DAYS_IN_PERIOD;
    const salespersonStart = moment(salesperson.data_inicio.slice(0, 10));
    const salespersonEnd = salesperson.data_fim
        ? moment(salesperson.data_fim.slice(0, 10))
        : undefined;

    const salespersonStartedMidPeriod = salespersonStart.isAfter(
        moment(startDate),
        'day'
    );
    const salespersonLeftMidPeriod = didSalespersonEndBeforePeriod(
        salespersonEnd,
        endDate
    );

    const quotaDays = getSalespersonQuotaList(
        storeQuotasByDay,
        salespersonStartedMidPeriod,
        salespersonLeftMidPeriod,
        salesperson
    );

    if (salespersonStartedMidPeriod || salespersonLeftMidPeriod) {
        DAYS_IN_PERIOD = quotaDays.length;
    } else {
        DAYS_IN_PERIOD = endDate.diff(startDate, 'days') + 1;
    }

    return {
        quotaDays,
        DAYS_IN_PERIOD,
    };
};

export default getSalespersonQuotaDays;
