import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';

export const DialogButton = styled(Button)`
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    border-color: #212121;
    background-color: white;
    color: #212121;
    border-radius: 8px;
    margin-top: 16px;
    &:hover {
        background-color: #a99384;
    }
`;

export const DefaultContainer = styled(Box)`
    width: 100%;
    padding: 8px 0;
`;

export const Title = styled(Typography)`
    color: '#373435';
    font-weight: bold;
    font-size: 24px;
`