import moment from 'moment';

export default (date, brandId) => {
    if (Number(brandId) === 16) {
        if (
            date.isBefore(
                moment()
                    .hour(0)
                    .date(1)
                    .month(7)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date).startOf('month'),
                endDate: moment(date).endOf('month'),
            };
        }
        if (
            date.isSameOrBefore(
                moment()
                    .hour(0)
                    .date(1)
                    .month(8)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date).startOf('month'),
                endDate: moment(date).date(25),
            };
        }
        if (
            date.isAfter(
                moment()
                    .hour(0)
                    .date(1)
                    .month(8)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date)
                    .subtract(1, 'month')
                    .date(26),
                endDate: moment(date).date(25),
            };
        }
    } else {
        if (
            date.isBefore(
                moment()
                    .hour(0)
                    .date(1)
                    .month(4)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date).startOf('month'),
                endDate: moment(date).endOf('month'),
            };
        }
        if (
            date.isSameOrBefore(
                moment()
                    .hour(0)
                    .date(1)
                    .month(5)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date).startOf('month'),
                endDate: moment(date).date(25),
            };
        }
        if (
            date.isAfter(
                moment()
                    .hour(0)
                    .date(1)
                    .month(5)
                    .year(2021)
            )
        ) {
            return {
                startDate: moment(date)
                    .subtract(1, 'month')
                    .date(26),
                endDate: moment(date).date(25),
            };
        }
    }
};
