import React from 'react';

import * as Style from './styles';

function Tag({ classes, title, ...props }) {
    return (
        <>
            <Style.TagContainer>
                <Style.TagTypography>
                    {title}
                </Style.TagTypography>
            </Style.TagContainer>
        </>
    );
}

export default Tag;