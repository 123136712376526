import { styled } from '@mui/material/styles';
import { ListItemText, Box , IconButton} from '@mui/material';

export const Container = styled(Box)`
    display: flex;
    align-items: center;
    gap: 1rem;
`

export const ListItemTextComponent = styled(ListItemText)`
    margin-left: 16px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    @media screen and (max-width: 960px) {
        font-size: 14px;
    }
`;

export const ValueContainer = styled(Box)`
    color: #e26257;
`;

export const MobileCreationDate = styled(Box)`
    font-size: 12px;
    text-align: right;
`;

export const MobileDataValuesContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    color: white;
    text-align: right;
    max-width: 100%;
    box-sizing: border-box;
`;

export const CloseButton = styled(IconButton)`
    padding: 0px;
    @media screen and (max-width: 960px) {
        margin-right: 0;
    }
`;
