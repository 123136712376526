import {styled} from '@mui/material/styles';
import { Grid } from '@mui/material';

export const Chart = styled(Grid)`
    padding: 5%;
`

export const LateralPanelContainer = styled(Grid)`
    @media screen and (min-width: 960px){
        height: fit-content;
        margin: 16px;
    }
`