import axios from 'axios';

const apiDatabaseConnectionLinx = axios.create({
    baseURL: 'https://linx-gateway.somalabs.com.br'
});

apiDatabaseConnectionLinx.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (!error.response) return error;
        if (error.response.status === 401) {
            localStorage.removeItem('persist:+vendas');
            if (window.location.pathname !== '/') {
                window.location = '/';
            }
            return error;
        }
        return Promise.reject(error);
    }
);

export default apiDatabaseConnectionLinx;
