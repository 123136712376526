import {styled} from '@mui/material/styles';
import {
    Button,
    Typography,
    TextField,
    Box,
} from '@mui/material';

export const SuccessLinkText = styled(Typography)`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #DCC6B7;
    margin-top: 48px;
`

export const ShareLinkInput = styled(TextField)`
    border: 1px solid white;
    border-radius: 8px;
    padding: 10px;
    width: 145px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
`

export const ShareLinkButton = styled(Button)`
    width: 132px;
    height: 56px;
    padding: 8px;
    background-color: #DCC6B7;
    border: 1px solid #DCC6B7;
    border-radius: 0px 8px 8px 0px;
    text-transform: none;
    color: black;
    &:hover {
        background-color: #A99384;
    };
`

export const MainButton = styled(Button)`
    height: 56px;
    font-weight: bold;
    border-radius: 8px;
    background-color: #DCC6B7;
    color: #212121;
    &:hover {
        background-color: #A99384;
    };
    width: 344px;
    position: absolute;
    bottom: 24px;
    max-width: 90%;
`

export const ShareButtonContainer = styled(Box)`
    height: 104px;
    background-color: #212121;
    position: sticky;
    width: 100%;
    justify-content: center;
    bottom: 0px;
    display: flex;
    align-items: center;
`

export const ShareButton = styled(Button)`
    background-color: #DCC6B7;
    border-radius: 8px;
    color: black;
    width: 346px;
    height: 56px;
    text-transform: none;
`