import React, { useState } from 'react';

import CotasMensais from './pages/CotasMensais';
import CotasDiarias from './pages/CotasDiarias';
import CadastroAusencias from './pages/CadastroAusencias';
import ResumoAusenciasFilial from './pages/ResumoAusenciasFilial';

function CadastroDeCotas() {
    const [currentPage, setCurrentPage] = useState('CotasMensais');
    const [salesTeamData, setSalesTeamData] = useState([]);
    const [quotaValues, setQuotaValues] = useState(undefined);
    const [quotaDistribution, setQuotaDistribution] = useState('uniform');
    const [activeSalesperson, setActiveSalesperson] = useState(undefined);

    const getCurrentPage = () => {
        switch (currentPage) {
            case 'CotasMensais':
                return (
                    <CotasMensais
                        setCurrentPage={setCurrentPage}
                        salesTeamData={salesTeamData}
                        setSalesTeamData={setSalesTeamData}
                        quotaValues={quotaValues}
                        setQuotaValues={setQuotaValues}
                    />
                );
            case 'CotasDiarias':
                return (
                    <CotasDiarias
                        setCurrentPage={setCurrentPage}
                        salesTeamData={salesTeamData}
                        setSalesTeamData={setSalesTeamData}
                        quotaValues={quotaValues}
                        quotaDistribution={quotaDistribution}
                        setQuotaDistribution={setQuotaDistribution}
                    />
                );
            case 'CadastroAusencias':
                return (
                    <CadastroAusencias
                        setCurrentPage={setCurrentPage}
                        salesTeamData={salesTeamData}
                        setSalesTeamData={setSalesTeamData}
                        quotaValues={quotaValues}
                        activeSalesperson={activeSalesperson}
                        quotaDistribution={quotaDistribution}
                    />
                );
            case 'ResumoAusenciasFilial':
                return (
                    <ResumoAusenciasFilial
                        setCurrentPage={setCurrentPage}
                        salesTeamData={salesTeamData}
                        setSalesTeamData={setSalesTeamData}
                        quotaValues={quotaValues}
                        quotaDistribution={quotaDistribution}
                        setQuotaDistribution={setQuotaDistribution}
                        setActiveSalesperson={setActiveSalesperson}
                    />
                );

            default:
                return <CotasMensais />;
        }
    };

    return <>{getCurrentPage()}</>;
}

export default CadastroDeCotas;