import React from 'react';
import { useSelector } from 'react-redux';

import { formatDecimalToPercentage, formatPrice } from '~/util/format';

import PanelBodyText from './PanelBodyText';
import QuotaPercentageContainer from './QuotaPercentageContainer';

import * as Style from './styles';

function CelebrationContainer({ salesNumber, monthlyQuotas }) {
    const userProfile = useSelector(state => state.user.profile);

    const profileName = userProfile?.nome.split(' ')[0].toLowerCase();
    const getQuotaText = monthlyQuotas?.quota
        ? formatPrice(monthlyQuotas?.quota)
        : 'Indisponível';
    const getQuotaPercentage = formatDecimalToPercentage(
        salesNumber / monthlyQuotas?.quota / 100
    );

    return (
        <>
            <Style.CongratulationsText>
                Parabéns, {profileName}
            </Style.CongratulationsText>
            <Style.MainPanelComponentContainer mt={2}>
                <Style.InnerMainPanelComponent mr={2}>
                    <PanelBodyText
                        text={'Cota do mês batida'}
                        value={getQuotaText}
                    />
                </Style.InnerMainPanelComponent>
                <QuotaPercentageContainer value={getQuotaPercentage} />
            </Style.MainPanelComponentContainer>
        </>
    );
}

export default CelebrationContainer;
