import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, SvgIcon } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LinkIcon from '@mui/icons-material/Link';
import Header from '~/components/Header';
import SnackBar from '~/util/SnackBar';
import { maisCarrinhoApi } from '~/services/api';
import ShareEcommerceDialog from '~/components/ShareEcommerceDialog';
import { ReactComponent as CopyIcon } from '~/assets/icons/copy_icon.svg';
import * as Style from './styles';

const offPremiumEcommerceURL = 'https://www.offpremium.com.br';

function ShareEcommerce() {
    const profile = useSelector(state => state.user.profile);
    const [iframeCurrentURL, setIframeCurrentURL] = useState(
        `${offPremiumEcommerceURL}&utmi_cp=${encodeURIComponent(
            profile?.users_sales_code[0]?.codigo_vendedor
        )}`
    );
    const [isPageSelected, setIsPageSelected] = useState(false);

    function getShortUrl(originalUrl) {
        const shortnerApi = `/url-shortner/${encodeURIComponent(originalUrl)}`;
        return maisCarrinhoApi
            .get(shortnerApi)
            .then(response => response.data.url.shortLink);
    }

    useEffect(() => {
        const handleMessageReceived = async event => {
            if (event.origin !== offPremiumEcommerceURL) {
                return;
            }
            const currentEcommerceUrl = event.data.curURL;
            if (currentEcommerceUrl) {
                const url = new URL(currentEcommerceUrl);
                const params = new URLSearchParams(url.search);
                params.delete('mobile');
                params.delete('uam');
                const desktopEcommerceUrl = `${url.origin +
                    url.pathname}?${params.toString()}`;
                const longUrl = `${desktopEcommerceUrl}&utm_campaign=${profile?.users_sales_code[0]?.codigo_vendedor}&utm_source=vendedora&utm_medium=linksdosite`;
                const currentEcommerceUrlShort = await getShortUrl(longUrl);
                setIframeCurrentURL(currentEcommerceUrlShort);
            }
        };
        window.addEventListener('message', handleMessageReceived, false);

        return () => {
            window.removeEventListener('message', handleMessageReceived);
        };
    }, [profile]);

    const handleShareButtonClick = useCallback(e => {
        e.stopPropagation();
        setIsPageSelected(true);
    }, []);

    function copyToClipboard(textToCopy) {
        let textArea;
        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.readOnly = true;
            textArea.contentEditable = true;
            textArea.value = text;
            document.body.appendChild(textArea);
        }
        function selectText() {
            textArea.select();
        }
        function copyTo() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }
        createTextArea(textToCopy);
        selectText();
        copyTo();
        SnackBar.success('Link copiado  com sucesso');
    }

    async function shareCart() {
        const shareUrl = iframeCurrentURL;
        // eslint-disable-next-line no-console
        if (shareUrl) {
            if (navigator.share) {
                const shareData = {
                    title: 'Link Compartilhado',
                    text: 'Link do Carrinho:',
                    url: shareUrl,
                };
                try {
                    navigator.share(shareData);
                } catch (err) {
                    window.alert(err);
                }
            } else {
                try {
                    copyToClipboard(shareUrl);
                } catch (e) {
                    SnackBar.error('Erro ao copiar o Link');
                }
            }
        }
    }
    const onEcommerceIframeLoad = () => {
        const iframeElement = document.getElementById('ecommerce_iframe');
        const iframeWindow = iframeElement.contentWindow;
        iframeWindow.postMessage({ vendedora: false }, offPremiumEcommerceURL);
    };

    return (
        <>
            {isPageSelected ? (
                <>
                    <div
                        style={{
                            minHeight: '100vh',
                            backgroundColor: '#212121',
                        }}
                    >
                        <Header />
                        <Grid container justifyContent="center">
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <CheckCircleOutlineIcon
                                    style={{
                                        color: '#1BB55C',
                                        width: '70px',
                                        height: '70px',
                                        marginTop: '72px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Style.SuccessLinkText display="block">
                                    Link criado com sucesso!
                                </Style.SuccessLinkText>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Style.SuccessLinkText display="block">
                                    Utilize-o em suas publicações.
                                </Style.SuccessLinkText>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="center"
                                style={{
                                    marginTop: '48px',
                                }}
                            >
                                <Style.ShareLinkInput
                                    value={iframeCurrentURL}
                                    InputProps={{ disableUnderline: true }}
                                />
                                <Style.ShareLinkButton
                                    onClick={() => {
                                        copyToClipboard(iframeCurrentURL);
                                        setIsPageSelected(false);
                                    }}
                                >
                                    <SvgIcon
                                        component={CopyIcon}
                                        style={{ marginRight: '8px' }}
                                    />
                                    Copiar
                                </Style.ShareLinkButton>
                            </Grid>
                            <Style.MainButton
                                onClick={() => {
                                    shareCart();
                                    setIsPageSelected(false);
                                }}
                            >
                                <ShareOutlinedIcon />
                                Compartilhar
                            </Style.MainButton>
                        </Grid>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ height: 'calc(100vh - 104px)' }}>
                        <Header />
                        <ShareEcommerceDialog />
                        <iframe
                            title="ShareEcommerce Panel"
                            src={offPremiumEcommerceURL}
                            frameBorder="0"
                            allowFullScreen="true"
                            style={{
                                width: '100%',
                                height: 'calc(100vh - 192px)',
                            }}
                            id="ecommerce_iframe"
                            onLoad={onEcommerceIframeLoad}
                        />
                    </div>
                    <Style.ShareButtonContainer>
                        <Style.ShareButton onClick={handleShareButtonClick}>
                            <LinkIcon style={{ marginRight: '8px' }} />
                            Criar link da página
                        </Style.ShareButton>
                    </Style.ShareButtonContainer>
                </>
            )}
        </>
    );
}

export default ShareEcommerce;
