import React from 'react';
import { Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import * as Style from './styles';

function CustomHeader({ title, goBackPage }) {
    const history = useHistory();

    const goBackPageUsed = () => (goBackPage ? goBackPage() : history.goBack());

    return (
        <>
            <Style.HeaderContainer>
                <Grid item>
                    <IconButton
                        onClick={() => {
                            goBackPageUsed();
                        }}
                    >
                        <ArrowBackIosIcon
                            style={{ color: '#373435', marginLeft: '8px' }}
                        />
                    </IconButton>
                </Grid>
                <Style.HeaderTypography>
                    {title}
                </Style.HeaderTypography>
            </Style.HeaderContainer>
        </>
    );
}

export default CustomHeader;