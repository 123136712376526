import React from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// Filters import
import BrandFilter from '../Filters/BrandFilter';
import OrdersFilter from '../Filters/OrdersFilter';
import MultimarcaBrandFilter from '../Filters/MultimarcaBrandFilter';
import TreinadoraAdoroCodigoBrandFilter from '../Filters/TreinadoraAdoroCodigoBrandFilter';

// Util import
import { getPeriodNames } from '~/util/ordersPanelArrays';
import {
    GERENTE_SOMA,
    LOJA_MULTIMARCA,
    VENDEDOR_ADORO_CODIGO,
    VENDEDOR_MULTIMARCA,
} from '~/util/perfilId';

// Style import
import * as Style from './styles';

function PanelTitle({
    hideOrdersFilter,
    brandFilter,
    setBrandFilter,
    ordersFilter,
    setOrdersFilter,
    orders,
    filter,
    searchFilter,
    setTotalFilterValue,
    setFilteredOrders,
}) {
    // Hooks
    const user = useSelector(state => state.user.profile);
    const location = useLocation().pathname;
    const { period } = useSelector(state => state.status);

    const urlSearch = useLocation().search;
    const salesCode = urlSearch.includes('salesCode');

    const periodName = getPeriodNames[period];
    const affiliateProfiles = [VENDEDOR_MULTIMARCA, LOJA_MULTIMARCA];
    const shouldShowRuleWarning =
        !affiliateProfiles.includes(user.id_perfil) &&
        location !== '/maisCarrinho';

    const shouldHideBrandFilter = () => {
        if (
            location === '/maisCarrinho' ||
            location === '/adoroCodigoChallenge'
        )
            return true;
        if (
            location === '/storeOmni' ||
            user.id_perfil === VENDEDOR_ADORO_CODIGO
        )
            return true;

        if (location === '/vendedoraAdoroCodigo' && salesCode) {
            return true;
        }

        return false;
    };
    const hideBrandFilter = shouldHideBrandFilter();

    const getBrandFilter = () => {
        const managerSalesPage =
            location.includes('vendedoraPiloto') &&
            user.id_perfil === GERENTE_SOMA;
        if (
            location === '/storeCota' ||
            location === '/cotaPiloto' ||
            managerSalesPage
        ) {
            return (
                <OrdersFilter
                    ordersFilter={ordersFilter}
                    setOrdersFilter={setOrdersFilter}
                    setFilteredOrders={setFilteredOrders}
                    orders={orders}
                    filter={filter}
                    searchFilter={searchFilter}
                    setTotalFilterValue={setTotalFilterValue}
                />
            );
        }
        if (location.includes('/vendedoraAdoroCodigo')) {
            return (
                <TreinadoraAdoroCodigoBrandFilter
                    brandFilter={brandFilter}
                    setBrandFilter={setBrandFilter}
                />
            );
        }
        if (location.toLowerCase().includes('multimarca')) {
            return (
                <MultimarcaBrandFilter
                    brandFilter={brandFilter}
                    setBrandFilter={setBrandFilter}
                />
            );
        }
        return (
            <BrandFilter
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
            />
        );
    };

    const getPanelTitle = () => {
        if (location === '/storeOmni') return 'Pedidos';
        if (location === '/maisCarrinho') return 'Carrinhos';
        return `Pedidos ${periodName || ''}`;
    };

    return (
        <Style.Container>
            <Style.OrdersPanelTitleContainer container>
                <Grid item>
                    <Style.TitleTypography>
                        {getPanelTitle()}
                    </Style.TitleTypography>
                </Grid>
                {hideBrandFilter || hideOrdersFilter ? null : (
                    <Grid item>
                        <Grid container justifyContent="flex-end">
                            {getBrandFilter()}
                        </Grid>
                    </Grid>
                )}
            </Style.OrdersPanelTitleContainer>
            {shouldShowRuleWarning && (
                <Style.Warning>
                    <Style.WarningTitle>
                        <strong>Regra geral de comissionamento -</strong> Até o
                        fim do primeiro dia útil após o fechamento da
                        competência, podem ocorrer alterações em suas vendas.
                    </Style.WarningTitle>
                </Style.Warning>
            )}
        </Style.Container>
    );
}

export default PanelTitle;
