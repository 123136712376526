import { styled } from '@mui/material/styles';
import { SvgIcon, Box } from '@mui/material';

export const ModalIcon = styled(SvgIcon)`
    width: 126px;
    height: auto;
    margin: auto 0;
`;

export const ModalText = styled(Box)`
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
    max-width: 280px;
`;
