import {styled} from '@mui/material/styles';
import {
    Button,
    CardMedia,
    Grid,
    Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const StyledIcon = styled(ArrowBackIosIcon)`
    color: #AEAEB3;
    margin: 32px;
`

export const HeaderContainer = styled(Grid)`
    justify-content: space-between;
    margin: 64px 0;
    padding: 0 32px;
`

export const Logo = styled(CardMedia)`
    width: 80px;
`

export const HeaderStepNumber = styled(Typography)`
    color: #3D3D4D;
`

export const MainTextContainer = styled(Grid)`
    margin: 64px 0;
`

export const MainTextTypography = styled(Typography)`
    color: #DEDEE3;
`

export const DefaultButton = styled(Button)`
    background-color: ${props => props.primary ? '#DCC6B7' : '#29292E'};
    color: ${props => props.primary ? 'black' : '#E1E1E6'};
    width: 156px;
    height: 56px;
    font-size: 15px;
    text-transform: none;
`