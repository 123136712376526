import {styled} from '@mui/material/styles';
import { Grid , Typography , Box } from '@mui/material';

export const PageWrapper = styled(Grid)`
    padding: 0px 32px;
`

export const HeaderTitle = styled(Grid)`
    display: flex;
    flex-direction: row; 
    gap: 5px;
    margin-top: 16px;
`

export const SmallTypography = styled(Typography)`
    font-size: 1rem;
    font-weight: ${props => props.heavy ? '600' : '400'};
    font-family: inherit;
`

export const MediumTypography = styled(Typography)`
    font-size: 14px;
    font-weight: 600;
    color: #9E9E9E;
    margin-top: 12px;
    letter-spacing: 0.5px;
    font-family: inherit;
`

export const SectionContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    margin: ${props => props.lowerContainer ? '0 0 30px' : '16px 0'};
    flex-wrap: wrap;
`

export const BigTypography = styled(Typography)`
    font-size: 2.125rem;
    font-weight: 600;
    margin: 24px 0 8px;
    font-family: inherit;
`

export const HalfPageContainer = styled(Grid)`
    width: ${props => props.left ? '50%' : '100%'};
    border-radius: 12px;
    background-color: #373435;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.paddingVariation ? '0px 20px 0px 12px' : '0px 12px'};
    height: ${props => props.lower ? '70px' : '60px'};
    margin-right: ${props => props.left ? '8px' : 0};
    letter-spacing: 1px;
    cursor: pointer;
    @media screen and (max-width: 820px) {
        width: 100%;
        margin: ${props => props.inputMargin || 0};
    }
`

export const HalfContainerTitle = styled(Grid)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.fullPage ? '10px' : '8px'};
    width: fit-content;
    margin-right: ${props => props.fullPage || '8px'};
    justify-content: ${props => props.fullPage && 'flex-start'};
    padding: ${props => props.inputPadding || 0};
    font-family: inherit;
`

export const ColoredCircles = styled(Box)`
    border-radius: 50%;
    background-color: ${props => props.quota ? '#1BB55C' : '#055998'};
    height: 15px;
    width: 15px;
`

export const LineElement = styled(Grid)`
    height: ${props => props.primary ? '1px' : 0};
    border: ${props => props.primary || '1px solid #616161'};
    border-radius: ${props => props.primary && '4px'};
    background-color: ${props => props.primary && '#BDBDBD'};
`

export const HalfContainerValues = styled(Grid)`
    display: flex;
    width: fit-content;
    gap: 0px;
    align-items: center;
    margin-left: ${props => props.omni || '8px'};
    @media screen and (max-width: 820px){
        align-items: ${props => props.omni && 'flex-start'};
    }
`

export const IndicatorTypography = styled(Typography)`
    color: white;
    font-size: ${props => props.primary ? '16px' : '19px'};
    font-weight: 600;
    min-width: fit-content;
    font-family: inherit;
    letter-spacing: ${props => props.primary && '1px'};
    @media screen and (max-width: 600px){
        font-size: 14px;
        letter-spacing: ${props => props.primary && '1px'};
    }
`

export const FullPageContainer = styled(Grid)`
    border-radius: 12px;
    background-color: #373435;
    box-sizing: border-box;
    padding: 0px 20px 0px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin: 16px 0 8px;
    align-items: center;
    @media screen and (max-width: 820px){
        padding: 15px 12px;
        margin: 16px 0 8px;
        gap: 20px;
        height: fit-content;
    }
`

export const OmniInnerContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: right ;
    width: fit-content;
    border-left:  ${props => props.rightContainer && '1px solid #616161'};
    padding-left: ${props => props.rightContainer && '16px'};
    margin-left: ${props => props.rightContainer && '16px'};
    @media screen and (max-width: 820px){
        max-width: ${props => props.rightContainer && '125px'};
    }
    @media screen and (max-width: 380px){
        margin-left: ${props => props.rightContainer && '2px'};
    }
`

export const OmniValuesTitle = styled(Grid)`
    display: flex;
    align-items: ${props => props.primary && 'baseline'};
    gap: 3px;
    font-family: inherit;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    color: #DCC6B7;
    font-size: 13px;
    font-weight: 500;
    min-width:${props => props.primary && 'fit-content'};
    line-height: 1.4;
    @media screen and (max-width: 820px){
        max-width: ${props => props.primary && '150px'};
    }
`

export const OmniValues = styled(Typography)`
    color: white;
    font-size: 19px;
    font-family: inherit;
    font-weight: 600;
    min-width: fit-content;
`

export const Chevron = styled(Grid)`
    display: flex;
    justify-content: flex-end;
`

export const RankingTitle = styled(Grid)`
    display: flex;
    flex-direction: ${props => props.subtitle ? 'column' : 'row'};
    align-items: ${props => props.subtitle || 'center'};
    gap: ${props => props.subtitle || '10px'};
    width: fit-content;
`

export const RankingText = styled(Typography)`
    font-size: 12px;
    font-weight: 400;
    font-family: inherit;
    color: #9E9E9E;
    letter-spacing: 0.5px;
`

export const OmniProgressText = styled(Box)`
    position: relative;
    top: 23px;
    left: 96%;
    color: white;
    font-size: 13px;
    font-weight: 600;
    font-family: inherit;
    margin-left: 6px;
    margin-right: 6px;
    @media screen and (max-width: 1200px){
        left: 96%;
    };
    @media screen and (max-width: 900px){
        left: 92%;
    };
    @media screen and (max-width: 500px){
        left: 91%;
    };
    @media screen and (max-width: 400px){
        left: 87%;
    }
`

export const OmniLineProgress = styled(Box)`
    width: ${props => props.inputWidth}%;
    background-color: #1BB55C;
    height: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 20px;
    color: white;
    font-size: 13px;
    max-width: 94%;
    font-weight: 600;
`

export const LineProgressContainer = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 50px;
    height: 30px;
    background-color: #055998;
    color: white;
    font-size: 13px;
    font-family: inherit;
`