import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Util import
import { formatDecimalToPercentage, formatPrice } from '~/util/format';
import { getLinearProgressValue } from '~/util/convertFunctions';

import PanelBodyText from './PanelBodyText';
import QuotaPercentageContainer from './QuotaPercentageContainer';

// Style import
import * as Style from './styles';

function PanelBody({
    quota,
    salesNumber,
    hasAchievedMonthlySuperQuota,
    shouldShowCongratulationsMessage,
    showRecalculatedQuota,
    salesPercentage,
    recalculatedQuota,
    isLastDay,
    secondaryProgressView,
    hideSuperQuota,
}) {
    const { finalDate, period } = useSelector(state => state.status);

    const monthlyQuota = quota?.periodQuotas.find(
        periodQuota => periodQuota?.period === 'month'
    );
    const isLastDayOfPeriod = period === 'month' && isLastDay;
    const incompleteMonthlyQuotaProgress =
        !showRecalculatedQuota &&
        !isLastDayOfPeriod &&
        !shouldShowCongratulationsMessage &&
        !secondaryProgressView;
    const NVCompletedQuotaCondition =
        shouldShowCongratulationsMessage &&
        period !== 'month' &&
        hideSuperQuota;
    const canApplyHyperQuota  = 
        hasAchievedMonthlySuperQuota && 
        monthlyQuota?.hiperQuotaMultiplier ? true : false;

    const getIsQuotaAvailable = () => {
        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition)
            return !!quota?.totalQuota;
        if (period === 'month') return !!monthlyQuota?.quota;
        if (period === 'day' && showRecalculatedQuota)
            return !!recalculatedQuota;
        if (shouldShowCongratulationsMessage) return !!quota?.totalQuotaAsNumber;
    };

    const getPanelQuotaText = () => {
        const formattedQuota = formatPrice(
            quota?.totalQuotaAsNumber * monthlyQuota?.superQuotaMultiplier
        );

        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition)
            return quota?.totalQuota;
        if (period === 'month' && shouldShowCongratulationsMessage && canApplyHyperQuota) {
            const calculatedQuota =
                monthlyQuota?.quota * monthlyQuota?.hiperQuotaMultiplier;
            return formatPrice(calculatedQuota);
        }
        if (period === 'month' && shouldShowCongratulationsMessage && !canApplyHyperQuota) {
            const calculatedQuota =
                monthlyQuota?.quota * monthlyQuota?.superQuotaMultiplier;
            return formatPrice(calculatedQuota);
        }
        if (period === 'month') {
            return formatPrice(monthlyQuota?.quota);
        }
        if (period === 'day' && showRecalculatedQuota) {
            return formatPrice(recalculatedQuota);
        }
        return formattedQuota;
    };

    const getQuotaText = () => {
        const isQuotaAvailable = getIsQuotaAvailable();

        if (!isQuotaAvailable) return 'Indisponível';

        const quotaText = getPanelQuotaText();
        return quotaText;
    };

    const getQuotaTitleText = () => {
        switch (period) {
            case 'month':
                return `Cota até ${moment(finalDate).format('D MMM')}`;
            case 'day':
                return `Cota do dia`;
            case 'week':
                return `Cota da semana`;
            case 'custom':
                return `Cota`;
            default:
                return `Cota até ${moment(finalDate).format('D MMM')}`;
        }
    };

    const getBodyTitle = () => {
        if (period === 'month' && shouldShowCongratulationsMessage && canApplyHyperQuota)
            return 'Hipercota';
        if (period === 'month' && shouldShowCongratulationsMessage)
            return 'Supercota';
        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition) {
            const getText = getQuotaTitleText();
            return getText;
        }
        if (period === 'month') return 'Cota do mês';
        if (showRecalculatedQuota) return 'Cota recalculada do dia';
        return 'Supercota do dia';
    };

    const handleLinearProgressQuota = () => {
        if (period === 'month' && shouldShowCongratulationsMessage && canApplyHyperQuota) {
            const totalQuota =
                monthlyQuota?.quota * monthlyQuota?.hiperQuotaMultiplier;
            return totalQuota;
        }
        if (period === 'month' && shouldShowCongratulationsMessage) {
            const totalQuota =
                monthlyQuota?.quota * monthlyQuota?.superQuotaMultiplier;
            return totalQuota;
        }
        if (period !== 'month' && shouldShowCongratulationsMessage) {
            const totalQuota =
                quota?.totalQuotaAsNumber * monthlyQuota?.superQuotaMultiplier;
            return totalQuota;
        }
        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition) {
            const totalQuota = quota?.totalQuota ? quota?.totalQuota : 0;
            return totalQuota;
        }
        if (period === 'month') {
            const totalQuota = monthlyQuota?.quota;
            return totalQuota;
        }
        return recalculatedQuota;
    };
    const linearProgressQuota = handleLinearProgressQuota();

    const handleLinearProgressValue = () => {
        let defaultProgressValue;
        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition) {
            defaultProgressValue = salesPercentage;
            return defaultProgressValue;
        }
        if (
            period === 'day' &&
            showRecalculatedQuota &&
            !shouldShowCongratulationsMessage
        ) {
            defaultProgressValue =
                (parseFloat(salesPercentage) * quota?.totalQuotaAsNumber) /
                recalculatedQuota;
            return defaultProgressValue;
        }

        defaultProgressValue = salesNumber / linearProgressQuota;
        return defaultProgressValue;
    };
    const quotaProgress = handleLinearProgressValue();
    const linearProgressValue = getLinearProgressValue(quotaProgress);

    const getQuotaProgressPercentage = () => {
        if (
            period === 'day' &&
            showRecalculatedQuota &&
            !shouldShowCongratulationsMessage
        ) {
            return formatDecimalToPercentage(
                salesNumber / recalculatedQuota / 100
            );
        }
        if (incompleteMonthlyQuotaProgress || NVCompletedQuotaCondition) {
            return salesPercentage;
        }

        return formatDecimalToPercentage(quotaProgress / 100);
    };
    const quotaProgressPercentage = getQuotaProgressPercentage();

    return (
        <Style.MainPanelComponentContainer primary={!secondaryProgressView}>
            <Style.InnerMainPanelComponent mr={2}>
                <PanelBodyText text={getBodyTitle()} value={getQuotaText()} />
                <Style.StyledLinearProgress
                    variant="determinate"
                    value={linearProgressValue}
                    quota={linearProgressQuota}
                />
            </Style.InnerMainPanelComponent>
            <QuotaPercentageContainer value={quotaProgressPercentage} />
        </Style.MainPanelComponentContainer>
    );
}

export default PanelBody;
