import React from 'react';
import { CircularProgress, CardContent, List, ListItem } from '@mui/material';

import RankingListItem from '../RankingListItem';

function RankingList({
    loadingRankingData,
    rankingData,
    orderBy,
    channelFilter,
}) {
    return (
        <CardContent
            style={{
                paddingTop: '0px',
                textAlign: 'center',
            }}
        >
            {loadingRankingData ? (
                <CircularProgress />
            ) : (
                <List>
                    {rankingData.length === 0 ? (
                        <ListItem
                            style={{
                                height: '72px',
                                borderTop: '1px solid #9E9E9E',
                                borderBottom: '1px solid #9E9E9E',
                                placeContent: 'center',
                            }}
                        >
                            Sem Vendedores
                        </ListItem>
                    ) : (
                        <>
                            <RankingListItem
                                rankingData={rankingData}
                                orderBy={orderBy}
                                channelFilter={channelFilter}
                            />
                        </>
                    )}
                </List>
            )}
        </CardContent>
    );
}

export default RankingList;
