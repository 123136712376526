import React, { useState } from 'react';
import {
    Dialog,
    Button,
    Typography,
    IconButton,
    Checkbox,
} from '@mui/material';

import { useHistory } from 'react-router-dom';
import Close from '@mui/icons-material/Close';

// eslint-disable-next-line no-empty-pattern
export default function ShareEcommerceDialog({}) {
    const history = useHistory();
    const [dialogIsOpen, setDialogIsOpen] = useState(
        localStorage.getItem('neverShowShareEcommerceDialog') !== 'true'
    );
    const [neverShowCheckbox, setNeverShowCheckBox] = useState(false);

    const closeDialog = () => {
        setDialogIsOpen(false);
        if (neverShowCheckbox) {
            localStorage.setItem('neverShowShareEcommerceDialog', 'true');
        }
    };

    return (
        <Dialog
            open={dialogIsOpen}
            onClose={closeDialog}
            PaperProps={{
                style: {
                    width: '328px',
                    borderRadius: '16px',
                    padding: '16px'
                },
            }}
            disableScrollLock
        >
            <IconButton style={{ width: 'fit-content', placeSelf: 'flex-end' }}>
                <Close onClick={closeDialog} />
            </IconButton>
            <Typography
                style={{
                    fontWeight: '600',
                    fontSize: '24px',
                    lineHeight: '29px',
                    textAlign: 'center',

                    color: '#DCC6B7',
                    marginBottom: '26px',
                }}
            >
                Você está no site da OFF PREMIUM
            </Typography>
            <Typography
                style={{
                    width: '264px',
                    fontSize: '16px',
                    lineHeight: '19px',
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: '400',
                    marginBottom: '40px',
                    alignSelf: 'center',
                    justifySelf: 'center',
                }}
            >
                Navegue para a página do produto que deseja utilizar na
                publicação em suas redes sociais.
            </Typography>
            <Button
                style={{
                    padding: '8px',
                    width: '96px',
                    height: '40px',
                    background: '#DCC6B7',
                    borderRadius: '8px',
                    color: 'black',
                    alignSelf: 'center',
                    textTransform: 'unset',
                    marginBottom: '24px',
                }}
                onClick={() => {
                    closeDialog();
                    history.push('/shareEcommerce');
                }}
            >
                Ok
            </Button>
            <div style={{ alignSelf: 'center' }}>
                <Checkbox
                    style={{ paddingLeft: '0px' }}
                    checked={neverShowCheckbox}
                    onChange={event =>
                        setNeverShowCheckBox(event.target.checked)
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography display="inline">Não mostrar novamente</Typography>
            </div>
        </Dialog>
    );
}
