import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography, List, ListItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import moment from 'moment';
import api from '~/services/api';
import CustomHeader from '~/components/CustomHeader';
import DesktopLayout from './DesktopLayout';
import { formatPrice, groupBy } from '~/util/format';

import 'moment/locale/pt-br';
import * as Style from './styles';

function ConsultarCotasVendedor({ ...props }) {
    const userProfile = useSelector(state => state.user.profile);
    const [dailyQuotaValues, setDailyQuotaValues] = useState(undefined);
    const query = new URLSearchParams(props.location.search);
    const salesCode = query.get('salesCode');
    const initialDate = query.get('initialDate');
    const finalDate = query.get('finalDate');

    const isUniform = array => {
        const filtered = array.filter(function(elem, pos, arr) {
            return arr.indexOf(elem) === pos;
        });

        return filtered.length <= 2;
    };

    useEffect(() => {
        const getSalespersonQuota = async () => {
            const response = await api.get(`salesperson/quota`, {
                params: {
                    startDate: moment(initialDate).format('YYYY-MM-DD'),
                    endDate: moment(finalDate).format('YYYY-MM-DD'),
                    salesCode,
                    brand: userProfile.marcaFilial,
                },
            });

            const groupedAbsences = groupBy(response.data.absences, 'month');
            const formattedAbsences = Object.keys(groupedAbsences).map(
                value => {
                    const days = groupedAbsences[value].map(item => {
                        return new Date(`${item.date} 00:01`).getDate();
                    });

                    return `${days.join(', ')} ${value}`;
                }
            );

            const newSalespersonQuota = {
                ...response.data,
                totalQuotaAsNumber: response.data.totalQuota,
                totalQuota: formatPrice(response.data.totalQuota),
                formattedAbsences,
            };

            setDailyQuotaValues(newSalespersonQuota);
        };

        getSalespersonQuota();
    }, [finalDate, initialDate, salesCode, userProfile.marcaFilial]);

    return (
        <>
            <CustomHeader title="Consultar Cotas" />

            <Style.Main container>
                {dailyQuotaValues ? (
                    <>
                        <Grid item xs={12}>
                            <Style.BrandContainer primary secondary container>
                                <DesktopLayout
                                    dailyQuotaValues={dailyQuotaValues}
                                    finalDate={finalDate}
                                    isUniform={isUniform}
                                />
                            </Style.BrandContainer>
                        </Grid>

                        {Object.keys(dailyQuotaValues.dailyQuotas).length ? (
                            <Grid item xs={12}>
                                <Style.BrandContainer container>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '20px',
                                            }}
                                        >
                                            Diárias
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <List>
                                            {Object.keys(
                                                dailyQuotaValues.dailyQuotas
                                            ).map((key, index) => (
                                                <ListItem
                                                    key={`list-item-${key}`}
                                                    style={{
                                                        padding: '0px',
                                                        height: '72px',
                                                        borderBottom:
                                                            '1px solid #9E9E9E',
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        justifyContent="space-between"
                                                        xs={12}
                                                    >
                                                        <Grid item>
                                                            <Style.MainTypography primary>
                                                                {moment(
                                                                    key
                                                                ).format(
                                                                    'DD MMM'
                                                                )}
                                                            </Style.MainTypography>
                                                        </Grid>

                                                        <Grid item>
                                                            <Style.ColorTypography
                                                                inputColor={
                                                                    moment(key) < moment() ? 
                                                                    '#9E9E9E'
                                                                    : '#DCC6B7'
                                                                }
                                                            >
                                                                {(
                                                                    dailyQuotaValues
                                                                        .dailyQuotas[
                                                                        key
                                                                    ] || 0
                                                                ).toLocaleString(
                                                                    'pt-br',
                                                                    {
                                                                        style:
                                                                            'currency',
                                                                        currency:
                                                                            'BRL',
                                                                    }
                                                                )}
                                                            </Style.ColorTypography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Style.BrandContainer>
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Style.BrandContainer container>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    fontSize: '20px',
                                                }}
                                            >
                                                Diárias
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <List>
                                                <ListItem
                                                    style={{
                                                        padding: '0px',
                                                        height: '72px',
                                                        borderBottom:
                                                            '1px solid #9E9E9E',
                                                        borderTop:
                                                            '1px solid #9E9E9E',
                                                        marginTop: '10px',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Grid item>
                                                        <Style.MainTypography>
                                                            Indisponível
                                                        </Style.MainTypography>
                                                    </Grid>
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Style.BrandContainer>
                                </Grid>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Style.BrandContainer primary container>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={3}>
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={6}>
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="space-evenly"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={5}>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            {/* <Skeleton animation="wave" /> */}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.BrandContainer>
                        </Grid>

                        <Grid item xs={12}>
                            <Style.BrandContainer container>
                                <Grid container spacing={1}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={3}>
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={6}>
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="space-evenly"
                                        item
                                        xs={12}
                                    >
                                        <Grid item xs={5}>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            {/* <Skeleton animation="wave" /> */}
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.BrandContainer>
                        </Grid>
                    </>
                )}
            </Style.Main>
        </>
    );
}

export default ConsultarCotasVendedor;
