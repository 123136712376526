import { styled } from '@mui/material/styles';
import { Box, Divider, Grid, LinearProgress, Typography } from '@mui/material';

export const PanelCardContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    background-color: #373435;
    height: 305px;
    border-radius: 4px;
    padding: ${props => (props.primary ? '16px 16px 0px' : '16px')};
    color: white;
    font-family: 'Inter';
    width: 22vw;
    @media screen and (max-width: 960px) {
        margin: 0px 16px 0px 16px;
        width: auto;
    }
`;

export const DefaultTypography = styled(Typography)`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: inherit;
    color: white;

    @media screen and (max-width: 1380px) and (min-width: 960px) {
        font-size: 12px;
    }
`;

export const BalanceTextContainer = styled(Grid)`
    justify-content: center;
    padding-top: 29px;
    font-family: inherit;
    font-size: 32px;
    text-align: center;
    font-weight: 600;
    color: white;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 28px;
        padding-top: ${props => (props.primary ? '10px' : '29px')};
    }
`;

export const InnerPanelCardContainer = styled(Grid)`
    align-self: center;
    box-sizing: border-box;
    margin-top: ${props => props.primary && '30px'};

    @media screen and (max-width: 1500px) and (min-width: 960px) {
        margin-top: ${props => props.primary && '15px'};
    }
`;

export const CongratulationsText = styled(Typography)`
    font-size: 24px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 18px;
    }
`;

export const QuotaPercentageTypography = styled(Typography)`
    font-weight: 600;
    font-size: 16px;
    color: ${props => props.inputColor};
`;

export const FooterTypography = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    width: 100%;
    padding-left: 8px;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 12px;
    }
`;

export const MainPanelComponentContainer = styled(Box)`
    display: flex;
    margin-top: ${props => props.primary && '24px'};
    margin-bottom: ${props => (props.primary ? '16px' : '25px')};
    align-items: flex-end;
    width: 100%;

    @media screen and (max-width: 1300px) and (min-width: 960px) {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        margin-top: ${props => props.primary && '5px'};
    }

    @media screen and (max-width: 320px) {
        margin-bottom: 10px;
        margin-top: ${props => props.primary && '5px'};
    }
`;

export const InnerMainPanelComponent = styled(Box)`
    width: 100%;
`;

export const DividerComponent = styled(Divider)`
    width: 100%;
    border-color: #616161;
    margin-bottom: 14px;
`;

export const CardTitleTypography = styled(Typography)`
    font-size: 20px;
    text-align: left;
    font-weight: 600;
    font-family: inherit;
`;

export const BalanceTextTypography = styled(Typography)`
    font-size: 32px;
    text-align: center;
    font-family: inherit;
    margin: ${props => props.primary && '24px 0 16px'};
    font-weight: 600;
`;

export const StyledLinearProgress = styled(LinearProgress)`
    height: 8px;
    border-radius: 4px;
    background-color: ${props => (props.value > 100 ? '#1BB55C' : '#e26257')};
    & .MuiLinearProgress-barColorPrimary {
        background-color: ${props => (props.quota ? 'white' : '#757575')};
    }
`;
