import React, { useEffect, useRef, useState, useCallback } from 'react';
import InputMask from 'react-input-mask';
import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';
import { TextField } from '@mui/material';
import { Container, Error } from './styles';

function FirstAccessInput({
    name,
    icon: Icon,
    containerStyle = {},
    mask,
    ...rest
}) {
    const inputRef = useRef(null);
    const [isFocused, setIsFocus] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [value, setValue] = useState('');

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return value;
            },
            setValue: (_, newValue) => {
                setValue(newValue);
            },
        });
    }, [fieldName, registerField, value]);

    const handleInputFocus = useCallback(() => {
        setIsFocus(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocus(false);
        setIsFilled(!!inputRef.current?.value);
    }, []);
    return (
        <Container
            isErrored={!!error}
            isFocused={isFocused}
            isFilled={isFilled}
            style={containerStyle}
        >
            {Icon && <Icon size={20} />}
            {mask ? (
                <>
                    <InputMask
                        mask={mask}
                        value={value}
                        maskChar=""
                        onChange={e => setValue(e.target.value)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        defaultValue={defaultValue}
                        ref={inputRef}
                        type="text"
                        variant="outlined"
                        {...rest}
                        color="primary"
                        fullWidth
                    >
                        {inputProps => <TextField {...rest} {...inputProps} />}
                    </InputMask>
                </>
            ) : (
                <>
                    <TextField
                        value={value}
                        maskChar=""
                        onChange={e => setValue(e.target.value)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        defaultValue={defaultValue}
                        ref={inputRef}
                        type="text"
                        variant="outlined"
                        {...rest}
                        color="primary"
                        fullWidth
                    />
                </>
            )}
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                    {error}
                </Error>
            )}
        </Container>
    );
}

export default FirstAccessInput;
