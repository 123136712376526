import {styled} from '@mui/material/styles';
import {
    Button,
    Grid,
    Typography,
    Box
} from '@mui/material';

export const CartNameComponent = styled(Typography)`
    position: absolute;
    left: 50%;
    top: 50%;
    font-family: 'Inter';
    transform: translate(-50%, -50%);
    color: #373435;
    font-size: 20px;
    font-weight: 600;
`

export const PageContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 0 16px;
`

export const PageRoot = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 100vh;
    color: white;
    font-family: 'Inter';

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`

export const SecondaryTitle= styled(Grid)`
    font-size: 24px;
    font-weight: 600;
    font-family: 'Inter';
    padding: 16px 0;
`

export const ShareButton = styled(Button)`
    width: 100%;
    height: 56px;
    font-weight: 500;
    font-family: 'Inter';
    border-radius: 8px;
    margin-top: 3px;
    background-color: ${props => props.secondary ? 'gray' : '#DCC6B7'};
    color: #212121;
    &:hover {
        background-color: ${props => props.secondary ? '#616161' : '#A99384'};
    };
`

export const ButtonContainer = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding: 16px 0;
    background-color: #212121;
`