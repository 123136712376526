import React from 'react';

import { useSelector } from 'react-redux';

import MaisCarrinhoDropdown from '~/components/MaisCarrinhoDropdown';

import { getCartBrandSettings } from '~/util/getCartBrandSettings';

import * as Style from './styles';

function CreationArea({
    setCartBrand,
    setCreateCartName,
    setActiveButton,
}) {
    const profile = useSelector(state => state.user.profile);
    const { defaultCartBrand, availableBrands} = getCartBrandSettings(profile);

    const uniqueBrand = availableBrands.length === 1 ? defaultCartBrand : undefined;

    return (
        <Style.TopMenuGrid>
            <Style.DefaultContainer>
                <Style.TitleTypography>
                    Escolha um nome para este Carrinho
                </Style.TitleTypography>
            </Style.DefaultContainer>
            <Style.DefaultContainer>
                <Style.CssTextField
                    shrink="true"
                    variant="outlined"
                    fullWidth
                    label="Nome do Carrinho"
                    onChange={e => {
                        setCreateCartName(e.target.value);
                    }}
                    inputProps={{ maxLength: 30 }}
                />
            </Style.DefaultContainer>
            <Style.DefaultContainer>
                <MaisCarrinhoDropdown
                    setCartBrand={setCartBrand}
                    setActiveButton={setActiveButton}
                    brands={availableBrands}
                    defaultBrand={defaultCartBrand}
                    uniqueBrand={uniqueBrand}
                />
            </Style.DefaultContainer>
        </Style.TopMenuGrid>
    );
}

export default CreationArea;
