import React from 'react';
import { Grid } from '@mui/material';

import * as Style from './styles';

function PanelBody({ brands, countsForQuota, value }) {
    const quotaObservation = countsForQuota
        ? 'Conta para Cota'
        : 'Não conta para Cota';

    return (
        <Style.DataContainer>
            <Grid item xs={6}>
                <Style.PanelCardListKey>{brands}</Style.PanelCardListKey>
                <Style.ObservationTypography>
                    {quotaObservation}
                </Style.ObservationTypography>
            </Grid>
            <Grid item xs={6}>
                <Style.MainVendedoraTypography>
                    {value}
                </Style.MainVendedoraTypography>
            </Grid>
        </Style.DataContainer>
    );
}

export default PanelBody;
