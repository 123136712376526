import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const InnerTopContainer = styled(Box)`
    padding: 16px;
    margin: 16px 0;
    border-radius: 4px;
    width: 100%;
    background-color: #373435;
    height: 160px;
    box-sizing: border-box;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const PrimaryDataDiv = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.primary && '16px'};
`;

export const TitleContainer = styled(Box)`
    width: fit-content;
    display: flex;
    gap: 8px;
`;

export const MediumTypography = styled(Typography)`
    font-family: 'Inter';
    font-size: 16px;
    /* font-weight: ${props => (props.lightFont ? '400' : '600')}; */
    /* text-align: ${props => props.primary && 'end'}; */
    text-transform: capitalize;
`;

export const CartStatusContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    gap: 6px;
`;
