import React from 'react';

import LoadingComponent from '~/components/SalesPersonPanelPiloto/LoadingComponent';
import OmniPanelCard from '~/components/PanelCards/OmniPanelCard';

import * as Style from './styles';

function OmniPagePanel({ panelLoaded, omniData, invoicedOrders }) {

    return (
        <>
            {panelLoaded ? (
                <Style.Wrap>
                    <OmniPanelCard omniData={omniData} invoicedOrders={invoicedOrders} />
                    <Style.StyledStepper
                        variant="dots"
                        steps={1}
                        position="static"
                        activeStep={0}
                    />
                </Style.Wrap>
            ) : (
                <LoadingComponent />
            )}
        </>
    );
}

export default OmniPagePanel;
