import { styled } from '@mui/material/styles';
import { Box, Grid, LinearProgress, Typography} from '@mui/material';

export const PanelCardContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.primary && 'space-between'};
    background-color: #373435;
    height: 305px;
    border-radius: 4px;
    padding: 16px;
    color: white;
    font-family: 'Inter';
    width: 100%;
    @media screen and (max-width: 960px) {
        margin: 0px 16px 0px 16px;
    }
    @media screen and (min-width: 480px) {
        max-width: 350px;
    }
`;

export const MainPanelComponentContainer = styled(Box)`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    @media screen and (max-width: 1300px) and (min-width: 960px) {
        margin-bottom: 10px;
    }

    @media screen and (max-width: 320px) {
        margin-bottom: 10px;
    }
`;

export const StyledLinearProgress = styled(LinearProgress)`
    height: 8px;
    border-radius: 4px;
    background-color: ${props => (props.value > 100 ? '#1BB55C' : '#e26257')};
    & .MuiLinearProgress-barColorPrimary {
        background-color: ${props => (props.quota ? 'white' : '#757575')};
    }
`;

export const InnerMainPanelComponent = styled(Box)`
    width: 80%;
`;

export const CongratulationsText = styled(Typography)`
    font-size: 24px;
    font-weight: 600;
    margin-top: 0.75rem;
    text-transform: capitalize;
    text-align: center;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 18px;
    }
`;