const getPrettyChannelName = order => {
    if (order.status === 'CANCELADO') {
        return 'Cancelamento';
    }
    const prettyChannelNameMapper = {
        ONLINE: 'Online',
        DEVOLUCAO: 'Devolução',
        FISICO: 'Física',
        SOMASTORE: 'Soma Store',
        ESTOQUE_PROPRIO: 'Estoque Próprio',
        EXPRESSA: 'Expressa',
        SHIPFROMSTORE: 'Ship From Store',
        RETIRADA: 'Retirada',
        VITRINE: 'Vitrine',
        EXTERNA: 'Externa',
    };

    const handleChannelName = order.channel.replace(' ', '_');

    const prettyName = prettyChannelNameMapper[handleChannelName];
    return prettyName;
};

export default getPrettyChannelName;
