import React from 'react';
import { Grid } from '@mui/material';

import * as Style from './styles';

function PanelCardSalesInfo({ cardTitle, value }) {
    return (
        <Style.PanelCardList item>
            <Grid item>
                <Style.PanelCardListKey>{cardTitle}</Style.PanelCardListKey>
            </Grid>
            <Style.PanelCardListLine />
            <Grid item>
                <Style.PanelCardListValue>
                    {value || 0}
                </Style.PanelCardListValue>
            </Grid>
        </Style.PanelCardList>
    );
}

export default PanelCardSalesInfo;
