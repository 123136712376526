import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const LateralPanelContainer = styled(Grid)`
    @media screen and (min-width: 960px) {
        height: fit-content;
        margin: 16px;
    }
`;

export const PageTitle = styled(Box)`
    display: flex;
    margin: 1rem 32px 0 32px;
    font-size: 1.5rem;
`;
