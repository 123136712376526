import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { maisCarrinhoApi } from '~/services/api';

import SnackBar from '~/util/SnackBar';

import * as Style from './styles';

function CreationButtons({
    setLoading,
    createCartName,
    cartBrand,
    setSelectedCart,
    setDialogCarrinhoCriado,
    activeButton
}) {
    const profile = useSelector(state => state.user.profile);
    const history = useHistory();

    const createCart = async () => {
        try {
            setLoading(true);
            const response = await maisCarrinhoApi.post(`/saved-cart`, {
                id_label: cartBrand,
                cart_name: createCartName,
                vendedorx_code: profile.users_sales_code[0].codigo_vendedor,
                active_cart: 1,
                saved_cart_products: [],
            });
            setSelectedCart(response.data);
            setLoading(false);
            setDialogCarrinhoCriado(true);
        } catch (err) {
            SnackBar.error('Erro ao criar carrinho');
        }
    };

    return (
        <Style.ButtonsContainer>
            <Style.MainButton
                variant="outlined"
                fullWidth
                onClick={() => history.push('/maisCarrinho')}
            >
                Cancelar
            </Style.MainButton>
            <Style.MainButton
                primary
                fullWidth
                disabled={!createCartName.length || !activeButton}
                onClick={() => {
                    createCart();
                }}
            >
                Criar
            </Style.MainButton>
        </Style.ButtonsContainer>
    );
}

export default CreationButtons;
