import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/queryClient';
// import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

// if (process.env.REACT_APP_ENV === 'production') {
//     Sentry.init({
//         dsn: process.env.REACT_APP_SENTRY_DSN,
//         environment: process.env.REACT_APP_ENV || 'production',
//         release: 'mais-vendas-frontend@1.2.0',
//         integrations: [new BrowserTracing()],

//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 0.7,
//     });
// }

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <App />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>,
    document.getElementById('root')
);
