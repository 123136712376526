import { formatWithCent, formatDecimalToPercentage } from './format';

const handleShortName = nickname => {
    const splitName = nickname.split(' ');
    const firstName = splitName[0];
    const getLastNamePosition = splitName.length - 1;
    const lastName = splitName[getLastNamePosition];
    return `${firstName} ${lastName}`;
};

export const handleRankingData = rankingList => {
    const newRankingData = rankingList.map(salesperson => ({
        ...salesperson,
        onlineValueAsPrice: formatWithCent(salesperson.onlineValue),
        physicalValueAsPrice: formatWithCent(salesperson.physicalValue),
        valueAsPrice: formatWithCent(salesperson.value),
        shortName: handleShortName(salesperson.vendedor_apelido),
        quotaPercentage: formatDecimalToPercentage(
            salesperson.value / salesperson.cota
        ),
        showFirstQuotaStar: Boolean(
            salesperson.cota && salesperson.value >= salesperson.cota
        ),
        showSecondQuotaStart: Boolean(
            salesperson.cota &&
                salesperson.value >=
                    salesperson.cota * salesperson.superQuotaMultiplier
        ),
    }));

    return newRankingData;
};
