import React from 'react';

import * as Style from './styles';

function PanelTotals({ ordersFilter, filter, brandFilter, totalFilterValue }) {
    const getTotalsText = () => {
        const userBrandIsSelected = [
            'allBrands',
            'otherBrands',
            'myBrands',
        ].includes(brandFilter);

        const hasSalesChannelBeenSelected = ordersFilter !== 'allOrders';

        if (hasSalesChannelBeenSelected && filter === 'Todos') {
            return ordersFilter;
        }
        if (!userBrandIsSelected && brandFilter) {
            const filterText = filter === 'Todos' ? '' : filter;
            return `${filterText} ${brandFilter}`;
        }
        if (filter !== 'Todos') {
            return filter;
        }
        return '';
    };

    return (
        <Style.TotalValueContainer item container>
            <Style.TitleTypography subtitle>
                Total {getTotalsText()}:
            </Style.TitleTypography>
            <Style.TitleTypography
                subtitle
                negativeValue={totalFilterValue.includes('-')}
                valueText
            >
                {totalFilterValue}
            </Style.TitleTypography>
        </Style.TotalValueContainer>
    );
}

export default PanelTotals;
