import { styled } from '@mui/material/styles';
import { Button, CardActions } from '@mui/material';

export const PillsFilterContainer = styled(CardActions)`
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
        color: red;
    }
`;

export const StyledButtonPill = styled(Button)`
    min-width: fit-content;
    background-color: ${props => (props.primary ? '#DCC6B7' : 'transparent')};
    border-color: #dcc6b7;
    border-radius: 18px;
    font-weight: 500;
    font-family: inherit;
    color: ${props => (props.primary ? '#373435' : '#DCC6B7')};
    text-transform: capitalize;
    &:hover {
        background-color: ${props => props.primary && '#DCC6B7'};
        border-color: #dcc6b7;
    }
    & .MuiButton-label {
        width: max-content;
        white-space: nowrap;
    }
`;
