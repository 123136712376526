import {
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    Typography,
    IconButton,
} from '@mui/material';
import { Form } from '@unform/web';
import 'moment/locale/pt-br';
import React, { useEffect, useRef, useState } from 'react';
import FirstAccessInput from '~/components/FirstAccessInput';
import api from '~/services/api';
import * as Style from './styles';
import SnackBar from '~/util/SnackBar';

function Register({ userData, ...props }) {
    const fetchedForm = useRef(null);
    const insertedForm = useRef(null);
    const [profileId, setProfileId] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    function submitForm() {
        insertedForm.current.submitForm();
    }

    useEffect(() => {
        function setFormData() {
            fetchedForm.current.setData({
                name: userData.name,
                salesCode: userData.codigo_vendedor.join(','),
                profile: userData.id_perfil,
                cpf: userData.cpf,
                store: userData.filiais.map(filial => filial.nome).join(','),
                brands: userData.marcas.map(marca => marca.nome).join(','),
            });
        }
        setFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleSubmit(formData) {
        try {
            insertedForm.current.setErrors({});

            formData.email = formData.email.trim();
            formData.emailConfirmation = formData.emailConfirmation.trim();

            if (formData.email !== formData.emailConfirmation) {
                insertedForm.current.setFieldError(
                    'emailConfirmation',
                    'Email e confirmação de email são diferentes'
                );
                return;
            }

            if (formData.password !== formData.passwordConfirmation) {
                insertedForm.current.setFieldError(
                    'passwordConfirmation',
                    'Senha e confirmação de senha são diferentes'
                );
                return;
            }
            if (
                formData.password.length < 6 ||
                formData.passwordConfirmation.length < 6
            ) {
                insertedForm.current.setFieldError(
                    'password',
                    'Senha deve ter no mínimo 6 caracteres'
                );
                return;
            }
            setIsButtonDisabled(true);
            await api.post('/manage-users/create', {
                filiais: userData.filiais,
                codigo_vendedor: userData.codigo_vendedor,
                marcas: userData.marcas,
                id_affiliates: userData.id_affiliates,
                id_perfil: userData.id_perfil || profileId,
                name: userData.name,
                email: formData.email,
                cpf: userData.cpf,
                login: formData.login,
                password: formData.password,
            });
            SnackBar.success('Cadastro criado');
            props.setCurrentPage('done');
        } catch (error) {
            if (error?.message === 'Erro de conexão') {
                SnackBar.error(
                    'Falha na conexão. Atualize a página e tente novamente.'
                );
            }
            const errorMessage = error?.response?.data?.error;
            if (errorMessage) {
                SnackBar.error(errorMessage);
            } else {
                SnackBar.error('Erro ao criar cadastro');
            }
        } finally {
            setIsButtonDisabled(false);
        }
    }

    const getProfileIdFromProfileName = profileName => {
        const profileMapper = {
            vendedorSoma: 1,
            vendedorCorporativo: 3,
            gerenteSoma: 6,
            caixaSoma: 15,
            estoquistaSoma: 17,
        };
        return profileMapper[profileName];
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <IconButton
                            onClick={() => props.setCurrentPage('onboard')}
                        >
                            <Style.StyledIcon/>
                        </IconButton>
                    </Grid>
                    <Style.HeaderContainer container>
                        <Grid item xs={8}>
                            <Typography align="left" variant="h4">
                                Olá {userData.name?.split(' ')[0]}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Style.HeaderStepNumber
                                variant="h2"
                                align="right"
                            >
                                03
                            </Style.HeaderStepNumber>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid container justifyContent="center" spacing={2}>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Achamos essas informações aqui no nosso
                                cadastro:
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={10}>
                            <Form ref={fetchedForm}>
                                <FirstAccessInput
                                    label="Nome"
                                    name="name"
                                    disabled
                                />
                                <FirstAccessInput
                                    label="Código de Vendedor"
                                    name="salesCode"
                                    disabled
                                />
                                {userData.id_perfil && (
                                    <FirstAccessInput
                                        label="Perfil"
                                        name="profile"
                                        disabled
                                    />
                                )}
                                <FirstAccessInput
                                    label="CPF"
                                    name="cpf"
                                    disabled
                                />
                                <FirstAccessInput
                                    label="Loja"
                                    name="store"
                                    disabled
                                />
                                <FirstAccessInput
                                    label="Marcas"
                                    name="brands"
                                    disabled
                                />
                            </Form>
                        </Grid>
                        {(!userData.id_perfil || userData.id_perfil === 99) && (
                            <>
                                <Style.HeaderContainer container>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4">
                                            Qual opção melhor descreve sua
                                            função no Grupo Soma?
                                        </Typography>
                                    </Grid>
                                </Style.HeaderContainer>
                                <Grid container justifyContent="center" item xs={12}>
                                    <FormControl style={{ width: '80%' }}>
                                        <FormLabel>Função</FormLabel>
                                        <Select
                                            onChange={e => {
                                                setProfileId(
                                                    getProfileIdFromProfileName(
                                                        e.target.value
                                                    )
                                                );
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value="vendedorSoma">
                                                Vendedor de lojas Grupo Soma
                                            </MenuItem>
                                            <MenuItem value="vendedorCorporativo">
                                                Vendedor Corporativo
                                            </MenuItem>
                                            <MenuItem value="gerenteSoma">
                                                Gerente de loja
                                            </MenuItem>
                                            <MenuItem value="caixaSoma">
                                                Caixas / ADMs
                                            </MenuItem>
                                            <MenuItem value="estoquistaSoma">
                                                Estoquistas
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        <Style.HeaderContainer container>
                            <Grid item xs={12}>
                                <Typography align="left" variant="h4">
                                    Agora é você que manda!
                                </Typography>
                            </Grid>
                        </Style.HeaderContainer>

                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Atenção com o email. Será atraves dele que
                                entraremos em contato caso você precise
                                recuprerar sua senha.
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={10}>
                                <Form
                                    ref={insertedForm}
                                    onSubmit={handleSubmit}
                                >
                                    <Typography style={{ padding: '8px' }}>
                                        Dados Pessoais
                                    </Typography>
                                    <FirstAccessInput
                                        label="Email"
                                        name="email"
                                    />
                                    <FirstAccessInput
                                        label="Confirmação de Email"
                                        name="emailConfirmation"
                                    />
                                    <Typography style={{ padding: '8px' }}>
                                        Dados Cadastrais
                                    </Typography>
                                    <FirstAccessInput
                                        label="Nome de Usuário"
                                        name="login"
                                    />
                                    <FirstAccessInput
                                        label="Senha"
                                        name="password"
                                        type="password"
                                    />
                                    <FirstAccessInput
                                        label="Confirmação de Senha"
                                        name="passwordConfirmation"
                                        type="password"
                                    />
                                </Form>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} container justifyContent="space-evenly">
                            <Style.DefaultButton
                                onClick={submitForm}
                                disabled={isButtonDisabled}
                            >
                                Próximo
                            </Style.DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Register;
