import React, { useEffect, useState } from 'react';

import {
    Grid,
    CircularProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomHeader from '~/components/CustomHeader';

import CreationArea from './CreationArea';
import CreationButtons from './CreationButtons';
import CreateCartModal from './CreateCartModal';

import { getCartBrandSettings } from '~/util/getCartBrandSettings';

import * as Style from './styles';

function CreateCart() {
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(false);
    const [createCartName, setCreateCartName] = useState('');
    const [selectedCart, setSelectedCart] = useState({});
    const [cartBrand, setCartBrand] = useState();
    const profile = useSelector(state => state.user.profile);
    const history = useHistory();
    const [dialogCarrinhoCriado, setDialogCarrinhoCriado] = useState(false);

    useEffect(() => {
        const { defaultCartBrand } = getCartBrandSettings(profile);

        if (defaultCartBrand) {
            setCartBrand(defaultCartBrand);
            setActiveButton(true);
        }
    }, [profile]);

    return (
        <>
            <CustomHeader
                goBackPage={() => history.push('/maisCarrinho')}
                title={'Novo Carrinho'}
            />
            {loading ? (
                <Grid
                    container
                    justifyContent="center"
                    style={{ paddingTop: '32px' }}
                >
                    <CircularProgress size={50} />
                </Grid>
            ) : (
                <>
                    <Style.PageBodyContainer>
                        <CreationArea 
                            setCartBrand={setCartBrand}
                            setCreateCartName={setCreateCartName}
                            setActiveButton={setActiveButton}
                        />
                        <CreationButtons
                            setLoading={setLoading}
                            createCartName={createCartName}
                            cartBrand={cartBrand}
                            setSelectedCart={setSelectedCart}
                            setDialogCarrinhoCriado={setDialogCarrinhoCriado}
                            activeButton={activeButton}
                        />
                    </Style.PageBodyContainer>

                    <CreateCartModal
                        dialogCarrinhoCriado={dialogCarrinhoCriado}
                        setDialogCarrinhoCriado={setDialogCarrinhoCriado}
                        selectedCart={selectedCart}
                        createCartName={createCartName}
                    />
                </>
            )}
        </>
    );
}

export default CreateCart;
