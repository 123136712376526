import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SnackBar from '~/util/SnackBar';
import { STANDARD_PROFILES } from '../util/perfilId';

import { ROUTES } from '../consts';
import { store } from '../store';
import { signOut } from '../store/modules/auth/actions';
import getDefaultRouteForUser from '../util/getDefaultRouteForUser';
import { apiV2 } from '../services/api';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    isRestrictedForStandardProfile,
    ...rest
}) {
    const dispatch = useDispatch();
    const { signed } = store.getState().auth;
    const profile = useSelector(state => state.user.profile);
    const userID = profile?.id_usuario_mais_vendas;
    const utmParams =
        profile && userID
            ? `?utm_campaign=${userID}&utm_source=maisvendas`
            : '';
    const userSalesCode = profile?.users_sales_code[0]?.codigo_vendedor;

    window.dataLayer.push({
        event: 'custom-dimension',
        filial: `${profile?.users_branches[0]?.codigo_filial}`,
        marca: `${profile?.marcaFilial}`,
        perfil: `${profile?.id_perfil}`,
        'codigo-vendedor': `${profile?.users_sales_code[0]?.codigo_vendedor}`,
    });

    const [isActive, setIsActive] = React.useState(true);

    useEffect(() => {
        const checkIfActive = async () => {
            await apiV2
                .get(
                    `/users/check_active?cpf=${profile.cpf}&salesCode=${userSalesCode}`
                )
                .then(res => {
                    setIsActive(res.data.active);
                })
                .catch(err => {
                    console.log({ err });
                });
        };
        if (profile) {
            checkIfActive();
        }
    });

    // -------------------------------------------
    if (profile && !isActive) {
        SnackBar.error('Conta desativada');
        dispatch(signOut());
        return <Redirect to={ROUTES.LOGIN} />;
    }

    if (isPrivate) {
        if (!profile || !signed) {
            return <Redirect to={ROUTES.LOGIN} />;
        }
        if (profile && !profile.id_usuario_mais_vendas) {
            dispatch(signOut());
            return <Redirect to={ROUTES.LOGIN} />;
        }
        if (
            profile &&
            STANDARD_PROFILES.includes(profile.id_perfil) &&
            isRestrictedForStandardProfile
        ) {
            const defaultRouteForUser = getDefaultRouteForUser(profile);
            return <Redirect to={`${defaultRouteForUser}${utmParams}`} />;
        }
        return <Route {...rest} render={props => <Component {...props} />} />;
    }
    if (signed) {
        const defaultRouteForUser = getDefaultRouteForUser(profile);
        return <Redirect to={`${defaultRouteForUser}${utmParams}`} />;
    }
    return <Route {...rest} render={props => <Component {...props} />} />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
};
