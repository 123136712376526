import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import { IoMdMenu } from 'react-icons/io';

import SalesChangeModal from '../SalesChangeModal';

import LeftMenuDrawer from '../LeftMenuDrawer';

import * as Style from './styles';

function Header({ count }) {
    const [open, setOpen] = useState(false);
    const [showSalesModal, setShowSalesModal] = useState(false);
    const profile = useSelector(state => state.user.profile);
    const { period } = useSelector(state => state.status);

    function handleToggleMenu() {
        setOpen(!open);
    }

    const handleModalClose = () => {
        const date = new Date();
        localStorage.setItem('SalesChangeWarning', date);
        setShowSalesModal(false);
    };

    useEffect(() => {
        const date = new Date();
        const getLastModalShowDate = localStorage.getItem('SalesChangeWarning');

        const currentDay = date.getDate();

        if ([24, 25, 26].includes(currentDay) && period === 'month') {
            if (!getLastModalShowDate) {
                setShowSalesModal(true);
                return;
            }

            const lastModalShownDate = new Date(getLastModalShowDate);
            const lastModalShownDay = lastModalShownDate.getDate();
            const lastModalShownMonth = lastModalShownDate.getMonth();

            if (
                lastModalShownMonth !== date.getMonth() ||
                lastModalShownDay !== currentDay
            ) {
                setShowSalesModal(true);
            }
        }
    }, [period]);

    return (
        <>
            <SalesChangeModal
                showSalesModal={showSalesModal}
                onClose={() => handleModalClose()}
            />
            <LeftMenuDrawer
                open={open}
                openMenu={handleToggleMenu}
                count={count}
            />
            <Grid />
            <Style.BackgroundSecondary container>
                <IconButton onClick={handleToggleMenu}>
                    <Style.StyledBadge badgeContent={count}>
                        <IoMdMenu size={30} color="#212121" />
                    </Style.StyledBadge>
                </IconButton>
                <Grid item style={{ marginLeft: '24px' }}>
                    <Style.StyledTypography primary>
                        {`Olá, ${profile?.nome.split(' ')[0].toLowerCase()}`}
                    </Style.StyledTypography>
                    <Style.StyledTypography>
                        {`${profile?.users_sales_code[0]?.codigo_vendedor ||
                            profile?.users_branches[0]?.codigo_filial ||
                            ''}`}
                    </Style.StyledTypography>
                </Grid>
            </Style.BackgroundSecondary>
        </>
    );
}

export default Header;
