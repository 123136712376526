import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const SearchBar = styled(TextField)`
    border-radius: 24px;
    border-color: #dcc6b7;
    margin-bottom: 16px;
    padding-right: 16px;
    font-weight: 500;
    font-family: inherit;
    padding-left: 16px;
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #dcc6b7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #dcc6b7;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #dcc6b7;
            border-radius: 24px;
        }
        &:hover fieldset {
            border-color: #dcc6b7;
        }
        & .Mui-focused {
            border-color: #dcc6b7;
        }
    }
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
    }
    & .MuiInputLabel-root {
        color: #dcc6b7;
    }
    & .Mui-focused {
        border-color: #dcc6b7;
    }
    & .MuiInputBase-root {
        color: white;
    }
`;
