import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import StorefrontIcon from '@mui/icons-material/Storefront';
import LanguageIcon from '@mui/icons-material/Language';
import UndoIcon from '@mui/icons-material/Undo';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TimerIcon from '@mui/icons-material/Timer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ReactComponent as RetiradaIcon } from '~/assets/icons/retirada.svg';
import { ReactComponent as ExternaIcon } from '~/assets/icons/externa.svg';
import { ReactComponent as VitrineIcon } from '~/assets/icons/vitrine.svg';

const getIconFromOrder = order => {
    if (order.status_evento === 'CANCELADO' || order.status === 'CANCELADO') {
        return (
            <CancelOutlinedIcon
                style={{
                    color: '#E26257',
                }}
            />
        );
    }
    switch (order.channel) {
        case 'ONLINE':
            return (
                <LanguageIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'DEVOLUCAO':
            return (
                <UndoIcon
                    style={{
                        color: '#E26257',
                    }}
                />
            );
        case 'FISICO':
            return (
                <StorefrontIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'ESTOQUE PROPRIO':
            return (
                <StorefrontIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'SOMASTORE':
            return (
                <AddCircleOutlineIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'EXPRESSA':
            return (
                <TimerIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'SHIPFROMSTORE':
            return (
                <LocalShippingIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
        case 'RETIRADA':
            return <SvgIcon component={RetiradaIcon} />;
        case 'VITRINE':
            return <SvgIcon component={VitrineIcon} />;
        case 'EXTERNA':
            return <SvgIcon component={ExternaIcon} />;
        default:
            return (
                <StorefrontIcon
                    style={{
                        color: '#DCC6B7',
                    }}
                />
            );
    }
};

export default getIconFromOrder;
