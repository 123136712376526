import React from 'react';
import * as Style from './styles';
import { Grid } from '@mui/material';
import DefaultPagePanel from '~/components/SalesPersonPanelPiloto/DefaultPagePanel';
import OrderListPanel from '~/components/OrderListPanel';

const DesktopLayout = ({
    balanceText,
    indicatorsText,
    panelLoaded,
    ordersLoaded,
    orders,
    brandFilter,
    setBrandFilter,
    chartData,
    multimarca,
    dateRange,
}) => (
    <>
        <Grid container justifyContent="space-evenly">
            <Grid item xs={12}>
                <DefaultPagePanel
                    balanceText={balanceText}
                    indicatorsText={indicatorsText}
                    panelLoaded={panelLoaded}
                    chartData={chartData}
                    dateRange={dateRange}
                />
            </Grid>
            <Style.LateralPanelContainer item xs={12}>
                <OrderListPanel
                    multimarca={multimarca}
                    ordersLoaded={ordersLoaded}
                    orders={orders}
                    brandFilter={brandFilter}
                    setBrandFilter={setBrandFilter}
                />
            </Style.LateralPanelContainer>
        </Grid>
    </>
);

export default DesktopLayout;