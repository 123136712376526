import { Grid } from '@mui/material';
import React from 'react';

import * as Style from './styles';

function PanelBodyText({ text, value }) {
    return (
        <Grid container justifyContent="space-between">
            <Style.DefaultTypography>{text}</Style.DefaultTypography>
            <Style.DefaultTypography>{value}</Style.DefaultTypography>
        </Grid>
    );
}

export default PanelBodyText;
