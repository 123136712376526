import React from 'react';
import { Grid } from '@mui/material';

import * as Style from './styles';

function CardHeader({ cardTitle, totalValue }) {
    return (
        <>
            <Grid item>
                <Style.CardTitleTypography>
                    {cardTitle}
                </Style.CardTitleTypography>
            </Grid>
            <Grid item sx={{ paddingTop: '16px', paddingBottom: '32px' }}>
                <Style.BalanceTextTypography>
                    {totalValue}
                </Style.BalanceTextTypography>
            </Grid>
        </>
    );
}

export default CardHeader;
