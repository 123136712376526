import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
`

export const MainButton = styled(Button)`
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-color: ${props => props.typeDelete ? '#E26257' : '#DCC6B7'};
    background-color: #212121;
    color: ${props => props.typeDelete ? '#E26257' : '#DCC6B7'};
    &:hover {
        background-color: ${props => props.typeDelete ? '#757575' : '#616161'};
        border-color: #DCC6B7;
    };
`