export const getBrandNameFromBrandId = brandId => {
    const brandIdToBrandNameMapper = {
        1: 'Animale',
        2: 'Farm',
        3: 'A.Brand',
        4: 'F.Y.I.',
        5: 'Fábula',
        6: 'OffPremium',
        7: 'Foxton',
        9: 'Cris Barros',
        15: 'Maria Filó',
        16: 'NV',
        52: 'Marcas Corporativo',
        12: 'Marcas Corporativo',
    };
    const brandIdAsNumber = Number(brandId);
    return brandIdToBrandNameMapper[brandIdAsNumber];
};

export const ANIMALE = 1;
export const FARM = 2;
export const FABULA = 5;
export const OFFPREMIUM = 6;
export const FOXTON = 7;
export const CRISBARROS = 9;
export const MARIAFILO = 15;
export const NV = 16;

export const TURN_AROUND_DATE = {
    animale: {
        rangeDateMin: '2021-08-26',
        rangeDateMax: '2021-09-26',
    },
    farm: {
        rangeDateMin: '2023-04-26',
        rangeDateMax: '2023-05-26',
    },
    fabula: '2021-05-01',
    foxton: {
        rangeDateMin: '2021-06-26',
        rangeDateMax: '2021-07-26',
    },

    offpremium: {
        rangeDateMin: '2021-07-26',
        rangeDateMax: '2021-08-26',
    },
    crisbarros: {
        rangeDateMin: '2021-06-26',
        rangeDateMax: '2021-07-26',
    },
    mariafilo: '2022-05-26',
    nv: {
        rangeDateMin: '2021-06-26',
        rangeDateMax: '2021-07-26',
    },
    adoroCodigo: '2021-05-01',
    corporativo: '2021-06-26',
};
