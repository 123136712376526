import { styled } from '@mui/material/styles';
import {
    Button,
    Dialog,
    Grid,
    Typography,
    Box
} from '@mui/material';

export const DialogProps = styled(Dialog)`
    & .MuiDialog-paper {
        background-color: white;
        color: #424242;
        padding: 14px;
        border-radius: 12px;
    }
`;

export const DialogRoot = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
`;

export const DialogTitle = styled(Typography)`
    color: #373435;
    font-weight: bold;
    font-size: 24px;
    font-family: 'Inter';
    text-align: left;
`;

export const IconContainer = styled(Box)`
    display: flex;
    margin: 8px 0;
    justify-content: center;
    align-items: center;
`

export const DialogDefaultTypography = styled(Box)`
    color: #373435;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter';
    text-align: center;
    padding: 0 16px;
    white-space: pre-line;
    margin-top: 8px;
`;

export const DialogButton = styled(Button)`
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-family: 'Inter';
    border-color: #212121;
    background-color: white;
    color: #212121;
    border-radius: 8px;
    margin-top: 3px;
    &:hover {
        background-color: #a99384;
    }
`;

export const ButtonsContainer = styled(Box)`
    display: flex;
    justify-content: center;
`
