import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const PanelCardContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #373435;
    height: 305px;
    border-radius: 4px;
    padding: 16px;
    color: white;
    font-family: 'Inter';
    width: 22vw;
    @media screen and (max-width: 960px) {
        margin: 0px 16px 0px 16px;
        width: auto;
    }
`;

export const PanelCardList = styled(Grid)`
    display: flex;
    width: 95%;
    align-items: center;
    gap: 14px;
`;

export const PanelCardListKey = styled(Typography)`
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
`;

export const PanelCardListValue = styled(Typography)`
    font-size: 14px;
    font-family: inherit;
    text-align: right;
    color: #dcc6b7;
`;

export const PanelCardListLine = styled('hr')`
    width: 95%;
    border: none;
    border-top: 1px solid #616161;
`;

export const PanelCardField = styled(Grid)`
    border: 1px solid #dcc6b7;
    display: flex;
    justify-content: space-between;
    width: 48%;
    padding: 6px;
    border-radius: 8px;
    color: white;
`;

export const PanelCardText = styled(Typography)`
    font-size: 16px;
    text-align: ${props => (props.isValue ? 'right' : 'left')};
    font-weight: 400;
    font-family: inherit;
    color: ${props => (props.isValue ? '#DCC6B7' : 'white')};
`;

export const CardTitleTypography = styled(Typography)`
    font-size: 20px;
    text-align: left;
    font-weight: 600;
    font-family: inherit;
`;

export const BalanceTextTypography = styled(Typography)`
    font-size: 32px;
    text-align: center;
    font-family: inherit;
    margin: ${props => props.primary && '24px 0 16px'};
    font-weight: 600;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 28px;
    }
`;
