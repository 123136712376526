import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: 32px;
  width: 100%;
  height: 56px;
  color: #666360;
  display: flex;
  flex-direction: column;
  align-items: center;
  & + div {
    margin-top: 24px;
  }
  ${props =>
      props.isErrored &&
      css`
          border-color: #c53030;
      `}
  ${props => props.isFocused && css``}
  ${props => props.isFilled && css``}
  .MuiOutlinedInput-root{
    border-radius: 32px;
    & fieldset {
      border-color: #151715;
    };
    &:hover fieldset {
      border-color: #151715;
    };
    & .Mui-focused {
      border-color: #151715;
    };
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #151715;
  }
  .MuiInputLabel-outlined{
    color: #151715;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #151715;
  }
  .MuiOutlinedInput-colorSecondary {

    .MuiOutlinedInput-notchedOutline {
        border-color: #151715;
    }

  }
  .MuiInputBase-input{
    color: #000000;
  }

  input {
    flex: 1;
    background: transparent;

    border: 0;
    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
    display: contents;
    width: 100%;
    height: 20px;
    margin-left: 16px;
    color: #fe6f66;
    p {
        text-align: center;
        margin-top: 4px;
    }
    svg {
        margin: 0;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
