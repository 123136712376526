import { styled } from '@mui/material/styles';
import { TextField, Typography, Box } from '@mui/material';

export const TopMenuGrid = styled(Box)`
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 50%;
    @media screen and (max-width: 960px) {
        min-width: 100%;
    }
`;

export const CssTextField = styled(TextField)`
    border-radius: 8px;
    border-color: #dcc6b7;
    color: #dcc6b7;
    font-weight: 500;
    font-family: 'Inter';
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #dcc6b7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #dcc6b7;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #dcc6b7;
            border-radius: 8px;
        }
        &:hover fieldset {
            border-color: #dcc6b7;
        }
        & .Mui-focused fieldset {
            border-color: #dcc6b7;
        }
    }
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0px;
    }
    & .MuiInputLabel-root {
        color: #dcc6b7;
        font-family: 'Inter';
    }
    & .MuiInputLabel-root.Mui-focused {
        color: #dcc6b7;
        font-family: 'Inter';
    }
    & .Mui-focused {
        border-color: #dcc6b7;
        color: #dcc6b7;
    }
    & .MuiInputBase-root {
        color: white;
        font-family: 'Inter';
    }
`;

export const DefaultContainer = styled(Box)`
    width: 100%;
    padding: 8px 0;
`;

export const TitleTypography = styled(Typography)`
    font-weight: bold;
    font-family: 'Inter';
    color: white;
    font-size: 24px;
`;