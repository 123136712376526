import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import SalesPersonPagePanel from '~/components/SalesPersonPanelPiloto/SalesPersonPagePanel';
import OrderListPanel from '~/components/OrderListPanel';
import * as Style from './styles';

const DesktopLayout = ({
    salesNumber,
    balanceText,
    quota,
    salesPercentage,
    indicatorsText,
    panelLoaded,
    ordersLoaded,
    orders,
    aggregatedOrdersByPeriod,
    brandFilter,
    setBrandFilter,
    chartData,
    dateRange,
}) => {
    const userProfile = useSelector(state => state.user.profile);

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <SalesPersonPagePanel
                        balanceText={balanceText}
                        indicatorsText={indicatorsText}
                        panelLoaded={panelLoaded}
                        aggregatedOrdersByPeriod={aggregatedOrdersByPeriod}
                        chartData={chartData}
                        dateRange={dateRange}
                        salesNumber={salesNumber}
                        quota={quota}
                        salesPercentage={salesPercentage}
                        hideSuperQuota={userProfile?.users_brands
                            ?.map(userBrandObj => userBrandObj.id_marca)
                            .includes(16)}
                    />
                </Grid>
                <Style.LateralPanelContainer item xs={12}>
                    <OrderListPanel
                        orders={orders}
                        ordersLoaded={ordersLoaded}
                        brandFilter={brandFilter}
                        setBrandFilter={setBrandFilter}
                    />
                </Style.LateralPanelContainer>
            </Grid>
        </>
    );
};

export default DesktopLayout;