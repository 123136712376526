export const CAIXA = 3;
export const VENDEDOR = 4;
export const ESTOQUISTA = 5;
export const GERENTE_SOMA = 6;
export const TREINADOR_ADOROCODIGO = 12;
export const VENDEDOR_ADOROCODIGO = 14;
export const VENDEDOR_RESPONSAVEL = 18;
export const VENDEDOR_MAIS_VENDAS = 1;

export const VENDEDOR_SOMA = 1;
export const VENDEDOR_FRANQUIA = 2;
export const VENDEDOR_CORPORATIVO = 3;
export const VENDEDOR_MULTIMARCA = 4;
export const VENDEDOR_LOJA_VIRTUAL_ANIMALE = 5;
export const LOJA_SOMA = 6;
export const LOJA_MULTIMARCA = 7;
export const SUPERVISAO = 8;
export const PLANEJAMENTO = 9;
export const DIRETOR = 10;
export const LOJA_FRANQUIA = 11;
export const TREINADOR_ADORO_CODIGO = 12;
export const TREINADOR_LOJA_VIRTUAL_ANIMALE = 13;
export const VENDEDOR_ADORO_CODIGO = 14;
export const CAIXA_SOMA = 15;
export const CAIXA_MULTIMARCA = 16;
export const ESTOQUISTA_SOMA = 17;
export const SUBGERENTE_SOMA = 18;
export const INFLUENCIADORES_OFF = 19;
export const INFLUENCIADORES_OFF_VENDEDORES = 20;

export const STANDARD_PROFILES = [
    VENDEDOR_SOMA,
    VENDEDOR_FRANQUIA,
    VENDEDOR_CORPORATIVO,
    VENDEDOR_MULTIMARCA,
    VENDEDOR_LOJA_VIRTUAL_ANIMALE,
    VENDEDOR_ADORO_CODIGO,
    INFLUENCIADORES_OFF_VENDEDORES,
    ESTOQUISTA_SOMA,
];
