import React from 'react';
import { useSelector } from 'react-redux';

import CardHeader from '../SecondaryPanelCard/CardHeader';
import ChallengeProgress from './ChallengeProgress';

import * as Style from './styles';
import { formatPrice } from '~/util/format';

function ChallengePanelCard({ challengeData, totalSalesValue }) {
    const userProfile = useSelector(state => state.user.profile);

    const profileName = userProfile?.nome.split(' ')[0].toLowerCase();
    const formattedSalesValue = formatPrice(totalSalesValue);
    const hasChallengeBeenBeaten = totalSalesValue >= challengeData.total;

    return (
        <Style.PanelCardContainer>
            <CardHeader
                cardTitle={'Desafio'}
                totalValue={formattedSalesValue}
            />
            {hasChallengeBeenBeaten && (
                <Style.CongratulationsText>
                    Parabéns, {profileName}
                </Style.CongratulationsText>
            )}
            <ChallengeProgress
                challengeData={challengeData}
                totalSalesValue={totalSalesValue}
            />
        </Style.PanelCardContainer>
    );
}

export default ChallengePanelCard;
