import * as moment from 'moment';

moment.locale('pt-br');

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export const formatWithoutCent = num => {
    const monetary = formatPrice(num);
    return monetary.split(',')[0];
};
export const formatWithCent = num => {
    const monetary = formatPrice(num);
    return monetary;
};

export const formatDateString = dateString => {
    if (dateString) {
        const originalDate = moment(dateString);
        return originalDate.format('DD/MM/YYYY');
    }
    return undefined;
};

export const formatCurrecyStringToNumberString = currencyString => {
    if (currencyString) {
        return `${currencyString}`.replace(/[^0-9]/g, '');
    }
    return undefined;
};

export const formatDecimalToPercentage = value => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
        return '-%';
    }
    if (Math.abs(value) === Infinity) {
        return '-%';
    }
    if (Number(value) === 0) {
        return '0%';
    }
    if (value) {
        value = Math.floor(value * 100).toFixed(0);
        return `${value}%`;
    }
    return undefined;
};

export const namesDictionary = marca => {
    const nomes = new Map();
    nomes.set('FABULA', 'FÁBULA');
    nomes.set('OUTLET', 'OFF PREMIUM');
    nomes.set('MARIA FILO', 'MARIA FILÓ');

    if (!nomes.has(marca.marca)) {
        return marca.marca;
    }
    return nomes.get(marca.marca);
};

export const abbreviateCurrency = currencyText => {
    if (currencyText) {
        const digitsCount = currencyText.length;
        if (digitsCount > 13) {
            return `${currencyText.substring(0, 6)} Mi`;
        }
        if (digitsCount > 10) {
            return `${currencyText.substring(0, 6)} k`;
        }
        return currencyText;
    }
    return '';
};

export const abbreviateCurrency2 = currencyText => {
    // Essa função esta preparada para lidar com "strings" apenas no formato da resposta
    // da função formatWithoutCent. Exemplos: "R$ 93.010.007" e "R$ 8.656" (sem centavos)
    // Ela retorna os valores de exemplo: "R$ 93,01 Mi" e "R$ 8,65 k"
    if (currencyText) {
        const digitsCount = currencyText.length;
        const displayLimitK = digitsCount - 1;
        const displayLimitM = digitsCount - 5;
        if (digitsCount >= 12 && digitsCount < 16) {
            currencyText = currencyText.replace('.', ',');
            return `${currencyText.substring(0, displayLimitM)} Mi`;
        }
        if (digitsCount > 7 && digitsCount < 12) {
            currencyText = currencyText.replace('.', ',');
            return `${currencyText.substring(0, displayLimitK)} k`;
        }
        return currencyText;
    }
    return '';
};

export const abbreviateFormatDate = dateString => {
    switch (dateString[5] + dateString[6]) {
        case '01':
            return `${dateString[8] + dateString[9]} Jan`;
        case '02':
            return `${dateString[8] + dateString[9]} Fev`;
        case '03':
            return `${dateString[8] + dateString[9]} Mar`;
        case '04':
            return `${dateString[8] + dateString[9]} Abr`;
        case '05':
            return `${dateString[8] + dateString[9]} Mai`;
        case '06':
            return `${dateString[8] + dateString[9]} Jun`;
        case '07':
            return `${dateString[8] + dateString[9]} Jul`;
        case '08':
            return `${dateString[8] + dateString[9]} Ago`;
        case '09':
            return `${dateString[8] + dateString[9]} Set`;
        case '10':
            return `${dateString[8] + dateString[9]} Out`;
        case '11':
            return `${dateString[8] + dateString[9]} Nov`;
        case '12':
            return `${dateString[8] + dateString[9]} Dez`;
        default:
            return 'undefined';
    }
};

export const productFormat = productQuantity => {
    if (productQuantity < 10 && productQuantity > 0) {
        return `0${productQuantity}`;
    }
    return productQuantity;
};

export const formatDateStringToText = dateString => {
    return moment(dateString).format('D MMM YYYY');
};

export const formatNumberToHour = hoursQuantity => {
    if (hoursQuantity == null) {
        return '-h';
    }
    const integerPart = Math.trunc(hoursQuantity);
    const integerPartString = Math.trunc(hoursQuantity).toString();
    const decimalPart = hoursQuantity - integerPart;
    const minutesPart = Math.floor(decimalPart * 60);
    const minutesPartString = minutesPart.toString();
    if (minutesPartString === '0') {
        return `${integerPartString}h`;
    }
    if (minutesPartString.length === 1) {
        return `${integerPartString}h0${minutesPartString}`;
    }
    return `${integerPartString}h${minutesPartString}`;
};

export const formatNumberToMinutes = hoursQuantity => {
    return hoursQuantity * 60;
};

export const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const formatPhoneNumber = phoneNumberString => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
};
