import {styled} from '@mui/material/styles';
import { Grid } from '@mui/material';

export const SalesPersonContainer = styled(Grid)`
    background-color: #373435;
    position: relative;
    height: 56px;
    padding: 8px 8px 8px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: ${props => props.primary && 'pointer'};
    &:hover {
        background-color: ${props => props.primary && '#424242'};
    };
`

export const OriginalOrderContainer = styled(Grid)`
    background-color: #373435;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 8px 8px 8px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover {
        background-color: #424242;
    };
`