import {styled} from '@mui/material/styles';
import {
    Typography,
    IconButton,
    TextField,
} from '@mui/material';

export const CssTextField = styled(TextField)`
    border-radius: 8px;
    border-color: white;
    color: white;
    outline: none;
    mix-blend-mode: screen;
    font-family: 'Inter';
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    };
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: white;
            border-radius: 8px;
        };
        &:hover fieldset {
            border-color: white;
        };
        & .Mui-focused fieldset {
            border-color: white;
        };
    };
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0px;
    };
    & .MuiInputLabel-root {
        color: #9E9E9E;
        font-weight: 600;
    };
    & .MuiInputLabel-root.Mui-focused {
        color: #DCC6B7;
        font-weight: 600;
    };
    & .Mui-focused {
        border-color: white;
        color: white;
    };
    & .MuiInputBase-root {
        color: white;
        min-width: 80px;
    };
`

export const IconButtonComponent = styled(IconButton)`
    height: 56px;
    width: 56px;
    color: ${props => props.primary ? '#1BB55C' : '#757575' };
    background-color: ${props => props.primary ? '#1BB55C' : 'rgba(0,0,0,0)' };
    border: 1px solid ${props => props.primary ? '#1BB55C' : '#757575' };
    border-radius: 8px;
    cursor: ${props => props.primary ? 'pointer' : 'auto' };
    &:hover {
        background-color: ${props => props.primary && '#1BB55C'};   
    }
`

export const PrimaryTypography = styled(Typography)`
    font-weight: 600;
    font-family: inherit;
    font-size: ${props => props.inputSize};
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

export const SecondaryTypography = styled(Typography)`
    font-weight: 500;
    font-family: inherit;
    font-size: ${props => props.inputSize || '14px'};
    color: #9E9E9E;
`