import React from 'react';
import * as Style from './styles';

function LinearProgressWithLabel({salesQuotaValue, omniQuotaValue, salesTotalValue}){
    return(
        <>
        <Style.OmniProgressText>
            {omniQuotaValue}%
        </Style.OmniProgressText>
        <Style.LineProgressContainer>
            <Style.OmniLineProgress inputWidth={salesTotalValue}>
                {salesQuotaValue}%
            </Style.OmniLineProgress>
        </Style.LineProgressContainer>
        </>
    )
}

export default LinearProgressWithLabel;