import { styled } from '@mui/material/styles';
import {
    Button,
    Grid,
    Typography,
    Box,
} from '@mui/material';

export const MainContainer = styled(Grid)`
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px){
        justify-content: flex-start;
        padding-top: 42px;
    };
`

export const HeaderContainer = styled(Grid)`
    justify-content: center;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
`

export const Logo = styled(Box)`
    width: 256px;
    @media screen and (max-width: 600px){
        width: 204px;
    };
`

export const MainTextContainer = styled(Grid)`
    width: 100%;
    margin-bottom: 40px;
`

export const MainTextTypography = styled(Typography)`
    color: #151715;
    font-size: 32px;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 24px;
    };
`

export const FormContainer = styled(Grid)`
    width: 548px;
    @media screen and (max-width: 600px){
        width: 326px;
    };
`

export const ButtonContainer = styled(Grid)`
    width: 548px;
    margin-top: 40px;
    @media screen and (max-width: 600px){
        width: 326px;
        margin-top: 56px;
    };
`

export const PrimaryButton = styled(Button)`
    background-color: ${props => props.primary ? '#DCC6B7' : '#EEEEEE'};
    border-radius: 100px;
    color: ${props => props.primary ? 'black' : '#8B8B8B'};
    width: 100%;
    height: 56px;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;
    &:hover {
        background-color: ${props => props.primary && '#DCC6B7'};
        opacity: ${props => props.primary && '0.6'};
    };
`