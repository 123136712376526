import React from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import * as Style from './styles';

function ButtonsContainer({setDeleteCartDialogOpen, selectedCart}) {
    const history = useHistory();

    return (
        <Style.Container>
            <Style.MainButton
                fullWidth
                variant="outlined"
                onClick={() => {
                    history.push({
                        pathname: '/maisCarrinho/searchProduct',
                        state: { selectedCart },
                    })
                }}
            >
                <AddIcon />
                Produtos
            </Style.MainButton>
            <Style.MainButton
                typeDelete
                fullWidth
                variant="outlined"
                onClick={() => {
                    setDeleteCartDialogOpen(true);
                }}
            >
                <DeleteOutlinedIcon />
                Excluir
            </Style.MainButton>
        </Style.Container>
    );
}

export default ButtonsContainer;
