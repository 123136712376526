import React, { useCallback, useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Box,
    CardActions,
    CardContent,
    ListItem,
    CircularProgress,
} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';

import moment from 'moment';
import CustomHeader from '~/components/CustomHeader';
import QuotaAbsenceRegisterConfirmationModal from '~/components/QuotaAbsenceRegisterConfirmationModal';

import * as Style from './styles';
import { formatPrice } from '~/util/format';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import api from '~/services/api';
import SnackBar from '~/util/SnackBar';

let assessmentDate = moment().endOf('month');
if (
    moment().date() > 25 &&
    moment().isAfter(
        moment()
            .hour(0)
            .date(1)
            .month(4)
            .year(2021)
    )
) {
    assessmentDate = assessmentDate.add(1, 'month');
}

const initialDate = getAssessmentDatesByDate(assessmentDate).startDate;
const finalDate = getAssessmentDatesByDate(assessmentDate).endDate;

function ResumoAusenciasFilial({
    setCurrentPage,
    salesTeamData,
    setSalesTeamData,
    quotaValues,
    quotaDistribution,
    setActiveSalesperson,
}) {
    const history = useHistory();
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [loadingSalesTeamData, setLoadingSalesTeamData] = useState(false);

    const userProfile = useSelector(state => state.user.profile);

    const updateDailyDistribution = useCallback(() => {
        let assessmentDate = moment().endOf('month');
        if (
            moment().date() > 25 &&
            moment().isAfter(
                moment()
                    .hour(0)
                    .date(1)
                    .month(4)
                    .year(2021)
            )
        ) {
            assessmentDate = assessmentDate.add(1, 'month');
        }
        const { startDate, endDate } = getAssessmentDatesByDate(assessmentDate);
        const DAYS_IN_PERIOD = endDate.diff(startDate, 'days') + 1;
        if (quotaDistribution === 'uniform') {
            const storeQuotasByDay = { ...quotaValues.dailyQuotas };
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(salesperson => ({
                    ...salesperson,
                    dailyQuota: salesperson.quotaValueMonth / DAYS_IN_PERIOD,
                    quotasByDay: Object.keys(storeQuotasByDay).reduce(
                        (attrs, date) => ({
                            ...attrs,
                            [date]:
                                salesperson.quotaValueMonth / DAYS_IN_PERIOD,
                        }),
                        {}
                    ),
                }))
            );
            // eslint-disable-next-line no-empty
        } else if (quotaDistribution === 'proportional') {
            const storeQuotasByDay = { ...quotaValues.dailyQuotas };
            const storeMonthQuota = quotaValues.quota;
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(salesperson => ({
                    ...salesperson,
                    dailyQuota: salesperson.quotaValueMonth / DAYS_IN_PERIOD,
                    quotasByDay: Object.keys(storeQuotasByDay).reduce(
                        (attrs, date) => ({
                            ...attrs,
                            [date]:
                                salesperson.quotaValueMonth *
                                (storeQuotasByDay[date] / storeMonthQuota),
                        }),
                        {}
                    ),
                }))
            );
        }
    }, [quotaDistribution, quotaValues, setSalesTeamData]);

    useEffect(() => {
        const getRankingData = async () => {
            try {
                if (initialDate && finalDate && quotaValues) {
                    setLoadingSalesTeamData(true);
                    const rankingResponse = await api.get(
                        '/store-manager/sales-team',
                        {
                            params: {
                                dateInit: moment(initialDate).format(
                                    'YYYY-MM-DD'
                                ),
                                dateEnd: moment(finalDate).format('YYYY-MM-DD'),
                                filialCode:
                                    userProfile.users_branches[0].codigo_filial
                                        .length === 3
                                        ? `000${userProfile.users_branches[0].codigo_filial}`
                                        : userProfile.users_branches[0]
                                              .codigo_filial,
                                filialBrandId: userProfile.marcaFilial,
                            },
                        }
                    );
                    const newRankingData = rankingResponse.data.map(
                        salesperson => ({
                            ...salesperson,
                            shortName: `${
                                salesperson.vendedor_apelido.split(' ')[0]
                            } ${
                                salesperson.vendedor_apelido.split(' ')[
                                    salesperson.vendedor_apelido.split(' ')
                                        .length - 1
                                ]
                            }`,
                            quotaValueMonth:
                                Math.round(
                                    (salesperson.quota + Number.EPSILON) * 100
                                ) / 100 || 0,
                            quotaPercentageMonth:
                                Math.round(
                                    (salesperson.quota / quotaValues?.quota) *
                                        10000
                                ) / 100 || 0,
                        })
                    );
                    setSalesTeamData(newRankingData);
                    updateDailyDistribution();
                }
            } catch (e) {
                setSalesTeamData([]);
                // eslint-disable-next-line no-console
                console.log(e);
                SnackBar.warning('Falha ao buscar a lista de vendedores');
            } finally {
                setLoadingSalesTeamData(false);
            }
        };
        getRankingData();
    }, [quotaValues, setSalesTeamData, updateDailyDistribution, userProfile]);

    const handleClickOnNextPageButton = () => {
        setOpenConfirmationDialog(true);
    };
    return (
        <>
            <QuotaAbsenceRegisterConfirmationModal
                open={openConfirmationDialog}
                onClose={() => {
                    setOpenConfirmationDialog(false);
                }}
                // onAccept={() => mountQuotaObjectAndUpdateQuotas(false)}
                onFinish={() => history.push('/consultarCotasEquipe')}
            />
            <CustomHeader
                title="Cadastro de Cotas"
                goBackPage={() => setCurrentPage('CotasDiarias')}
            />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    padding: '16px',
                }}
            >
                <Grid item xs={12}>
                    <Style.BrandContainer container>
                        <Grid
                            item
                            style={{ margin: '16px 0' }}
                        >
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                }}
                            >
                                {salesTeamData && salesTeamData[0].filial}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <DragIndicatorIcon
                                style={{ color: 'white', marginRight: '8px' }}
                            />
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                {
                                    {
                                        uniform:
                                            'Cotas iguais em todos os dias',
                                        proportional:
                                            'Cotas proporcionais às da loja',
                                    }[quotaDistribution]
                                }
                            </Typography>
                        </Grid>
                    </Style.BrandContainer>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        style={{
                            backgroundColor: '#373435',
                            marginTop: '16px',
                        }}
                    >
                        <CardActions
                            style={{
                                padding: '0 16px',
                            }}
                        >
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            margin: '8px 0'
                                        }}
                                    >
                                        Cadastro de Ausências
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>

                        <CardContent
                            style={{
                                paddingTop: '0px',
                                textAlign: 'center',
                            }}
                        >
                            {!loadingSalesTeamData ? (
                                salesTeamData.map(salesperson => (
                                    <>
                                        <ListItem
                                            style={{
                                                padding: '0px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setActiveSalesperson(
                                                    salesperson
                                                );
                                                setCurrentPage(
                                                    'CadastroAusencias'
                                                );
                                            }}
                                        >
                                            <Grid
                                                container
                                                style={{
                                                    marginBottom: '16px',
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    style={{
                                                        marginBottom: '8px',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        {salesperson?.hasAbsenceRegistered ? (
                                                            <EventAvailableIcon
                                                                style={{
                                                                    color:
                                                                        '#1BB55C',
                                                                }}
                                                            />
                                                        ) : (
                                                            <CalendarTodayOutlinedIcon
                                                                style={{
                                                                    color:
                                                                        '#DCC6B7',
                                                                }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography
                                                            style={{
                                                                fontSize:
                                                                    '16px',
                                                                fontWeight:
                                                                    '600',
                                                                textAlign:
                                                                    'left',
                                                                textTransform:
                                                                    'capitalize',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                overflow:
                                                                    'hidden',
                                                                textOverflow:
                                                                    'ellipsis',
                                                            }}
                                                        >
                                                            {salesperson.vendedor_apelido.toLowerCase()}
                                                        </Typography>
                                                        <Typography
                                                            style={{
                                                                color:
                                                                    '#9E9E9E',
                                                                fontSize:
                                                                    '12px',
                                                                fontStyle:
                                                                    'normal',
                                                                fontWeight:
                                                                    '400',
                                                                textAlign:
                                                                    'left',
                                                            }}
                                                        >
                                                            {`${formatPrice(
                                                                salesperson.quotaValueMonth
                                                            )} | Diárias:

                                                    ${
                                                        quotaDistribution ===
                                                            'uniform' &&
                                                        !salesperson.hasAbsenceRegistered
                                                            ? formatPrice(
                                                                  salesperson.dailyQuota
                                                              )
                                                            : 'Variável'
                                                    }`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        sm={2}
                                                        style={{
                                                            textAlign: 'end',
                                                            alignSelf: 'center',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        <NavigateNextOutlinedIcon />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider variant="fullWidth" />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Style.PrimaryButton
                        primary
                        onClick={handleClickOnNextPageButton}
                    >
                        Avançar
                    </Style.PrimaryButton>
                    <Style.PrimaryButton
                        onClick={() => {
                            setCurrentPage('CotasDiarias');
                        }}
                    >
                        Voltar
                    </Style.PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default ResumoAusenciasFilial;