import { styled } from '@mui/material/styles';
import { Avatar, IconButton, Typography, Box } from '@mui/material';

export const CardContainer = styled(Box)`
    display: flex;
    width: 100% !important;
    justify-content: center;
    margin-bottom: 16px;
`;

export const DetailCartContainer = styled(Box)`
    display: flex;
    height: 218px;
    width: 100%;
    justify-content: flex-start;
    background-color: #373435;
    border-radius: 12px;
    flex-flow: row;
`;

export const AvatarComponent = styled(Avatar)`
    color: #757575;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #757575;
    border-radius: 8px;
    font-weight: 600;
    height: ${props => (props.smallerType ? '42px' : '50px')};
    width: ${props => (props.smallerType ? '42px' : '50px')};
    font-family: 'Inter';
`;

export const RemoveButton = styled(IconButton)`
    color: #757575;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #e26257;
    font-weight: 600;
    border-radius: 8px;
    height: ${props => (props.smallerType ? '42px' : '50px')};
    width: ${props => (props.smallerType ? '42px' : '50px')};
    font-size: 14px;
    font-family: 'Inter';
    margin-left: 40px;
`;

export const DetailCartTypography = styled(Typography)`
    font-weight: 600;
    font-size: ${props => props.inputSize || '20px'};
    font-family: 'Inter';
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 350px) {
        font-size: 16px;
    }
`;

export const ImgContainer = styled(Box)`
    cursor: pointer;
`;

export const ContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 8px 8px 16px;

    @media screen and (max-width: 350px) {
        padding: 8px;
    }
`;

export const ReferenceTypography = styled(Typography)`
    font-weight: 500;
    font-family: 'Inter';
    font-size: 14px;
    color: #9e9e9e;

    @media screen and (max-width: 350px) {
        font-size: 12px;
    }
`;
