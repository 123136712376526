import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

export const MainButton = styled(Button)`
    height: 56px;
    width: 100%;
    border-radius: 8px;
    margin-top: 3px;
    background-color: ${props => (props.primary ? '#DCC6B7' : '#212121')};
    border-color: #dcc6b7;
    font-family: 'Inter';
    color: ${props => (props.primary ? '#212121' : '#DCC6B7')};
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: #a99384;
        border-color: #dcc6b7;
    }

    &.Mui-disabled {
        background-color: #616161;
        border-color: #616161;
        color: #212121;
        cursor: auto;
        &:hover {
            background-color: #616161;
            border-color: #616161;
        }
    }
`;

export const ButtonsContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    background: #212121;
    position: sticky;
    padding: 16px;
    bottom: 0;
    width: 100%;
    z-index: 1;
    gap: 16px;
    max-width: 50vw;

    @media screen and (max-width: 960px) {
        max-width: 100vw;
    }
`;