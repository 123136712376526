import React from 'react';
import { withStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Router } from 'react-router-dom';
import './config/ReactotronConfig';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { SnackbarProvider } from 'notistack';

// import OneSignal from 'react-onesignal';
import {
    SnackbarUtilsConfigurator,
    snackBarStyles,
    snackBarClasses,
} from './util/SnackBar';
import GlobalStyle from './styles/global';

import Routes from './routes';
import ScrollToTop from './routes/ScrollToTop';
import { store, persistor } from './store';

import ErrorBoundary from './components/ErrorBoundary';

import history from './services/history';

const styles = theme => ({});

const theme = createTheme({
    typography: {
        fontFamily: 'Inter',
    },
    palette: {
        mode: 'dark',
        primary: {
            light: '#fafafa',
            main: '#dcc6b7',
            dark: '#b2b2b2',
            contrastText: '#222222',
        },
        secondary: {
            main: '#DCC6B7',
        },
    },
    components: {
        MuiSkeleton: {
            styleOverrides: {
                root: { backgroundColor: 'rgba(255,255,255,0.15)' },
            },
        },
        MuiDivider: {
            styleOverrides: {
                light: {
                    borderColor: 'white',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeLarge: {
                    fontSize: '3rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        '-webkit-box-shadow': '0 0 0 100px transparent inset',
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: { color: '#fafafa', fontFamily: 'Inter' },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '& .Mui-disabled': {
                        opacity: '0.5',
                    },
                },
            },
        },
    },
});

function App() {

    return (
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline enableColorScheme />
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            autoHideDuration={3500}
                            classes={snackBarClasses(snackBarStyles)}
                        >
                            <SnackbarUtilsConfigurator />
                            <Router history={history}>
                                <ScrollToTop />
                                <Routes />
                                <GlobalStyle />
                            </Router>
                        </SnackbarProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

export default withStyles(styles)(App);
