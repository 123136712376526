import axios from 'axios';

require('dotenv').config();

const api = axios.create({
    baseURL:
        process.env.REACT_APP_API_URL ||
        'https://soma-mais-vendas-wlavxis5jq-ue.a.run.app',
    // 'https://private-anon-48db81aec5-vendas5.apiary-mock.com',
});

export const apiV2 = axios.create({
    baseURL:
        `${process.env.REACT_APP_API_URL}/v2` ||
        'https://soma-mais-vendas-wlavxis5jq-ue.a.run.app/v2',
});

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.message === 'Network Error') {
            throw new Error('Erro de conexão');
        }
        if (!error.response) return error;
        if (error.response.status === 401) {
            localStorage.removeItem('persist:+vendas');
            if (window.location.pathname !== '/') {
                window.location = '/';
            }
            return error;
        }
        return Promise.reject(error);
    }
);

export const maisCarrinhoApi = axios.create({
    baseURL:
        process.env.REACT_APP_MAIS_CARRINHO_API_URL ||
        'https://mais-carrinho-dkwr66gouq-uc.a.run.app', // backend prod
    // 'https://mais-carrinho-homolog-dkwr66gouq-uc.a.run.app', // backend homolog
    // 'http://localhost:8000',
});

export const tryAtHomeApi = axios.create({
    baseURL: 'https://api-tryathome-dot-apt-bonbon-179602.ue.r.appspot.com',
});

export const omniApi = axios.create({
    baseURL: 'https://mais-omni-v1-mdstf32ukq-uc.a.run.app',
});

export const extraOrdersApi = axios.create({
    baseURL:
        'https://extra-orders-api-fh5tb5wcfa-uc.a.run.app/v1/api/extra',
    headers: {
        Authorization: process.env.REACT_APP_API_EXTRAORDERS_TOKEN,
    },
});

export default api;
