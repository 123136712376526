import React from 'react';

import { Grid, Skeleton } from '@mui/material';

function LoadingComponent() {
    return (
        <Grid
            container
            justifyContent="center"
            spacing={1}
            style={{ paddingTop: '10px'}}
        >
            <Grid container justifyContent="center" item xs={12}>
                <Skeleton animation="wave" width="150px" />
            </Grid>
            <Grid container justifyContent="center" item xs={12}>
                <Skeleton animation="wave" width="200px" />
            </Grid>
        </Grid>
    );
}

export default LoadingComponent;
