import React from 'react';
import { Grid, Divider, Typography, List, ListItem } from '@mui/material';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import SvgIcon from '@mui/material/SvgIcon';

import moment from 'moment';
import 'moment/locale/pt-br';

import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import { ReactComponent as BarChartOutlinedIcon1 } from '~/assets/icons/BarChartOutlinedIcon1.svg';
import { ReactComponent as BarChartOutlinedIcon2 } from '~/assets/icons/BarChartOutlinedIcon2.svg';
import * as Style from './styles';

moment.locale('pt-br');

function DesktopLayout({ dailyQuotaValues, finalDate, isUniform }) {

    const endOfMonth = moment(finalDate).endOf('month');
    const { startDate, endDate } = getAssessmentDatesByDate(
        endOfMonth
    );

    return (
        <>
            {dailyQuotaValues ? (
                <>
                    <Grid item xs={12}>
                        <Style.PrimaryGridContainer container>
                            <Grid item>
                                <PersonOutlinedIcon
                                    style={{
                                        color: '#DCC6B7',
                                        marginRight: '16px',
                                    }}
                                    justifyContent='flex-start'
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                xs={10}
                            >
                                <Grid item>
                                    <Style.SecondaryTypography>
                                        {dailyQuotaValues.salespersonName ?
                                            dailyQuotaValues.salespersonName.toLowerCase() :
                                            "Nome Indisponível"
                                        }
                                    </Style.SecondaryTypography>
                                </Grid>

                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '400'
                                        }}
                                    >
                                        {dailyQuotaValues.salesCode ?
                                            dailyQuotaValues.salesCode :
                                            "Código Indisponível"
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Style.PrimaryGridContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>

                    <Grid item xs={12}>

                        {isUniform(Object.values(dailyQuotaValues.dailyQuotas)) ? (
                            <Style.PrimaryGridContainer container >
                                <Grid item>
                                    <SvgIcon
                                        component={BarChartOutlinedIcon1}
                                        style={{
                                            margin: '5px auto'
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="column"
                                    xs={10}
                                >
                                    <Grid item>
                                        <Style.MainTypography
                                            primary
                                            inputMargin='15px'
                                        >
                                            Diárias iguais para todos os dias
                                        </Style.MainTypography>
                                    </Grid>
                                </Grid>

                            </Style.PrimaryGridContainer>
                        ) : (
                            <Style.PrimaryGridContainer container>
                                <Grid item>
                                    <SvgIcon
                                        component={BarChartOutlinedIcon2}
                                        style={{
                                            margin: '5px auto'
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="column"
                                    xs={10}
                                >
                                    <Grid item>
                                        <Style.MainTypography
                                            primary
                                            inputMargin='15px'
                                        >
                                            Diárias proporcionais às da loja
                                        </Style.MainTypography>
                                    </Grid>
                                </Grid>
                            </Style.PrimaryGridContainer>
                        )}

                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent='space-between'
                        >
                            <Grid
                                item
                                style={{
                                    marginRight: '16px'
                                }}
                            >
                                <Style.MainTypography>
                                    Período
                                </Style.MainTypography>
                            </Grid>

                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                xs={8}
                            >
                                <Grid item>
                                    <Style.MainTypography primary>
                                        {startDate.format('DD MMM')} - {endDate.format('DD MMM, yyyy')}
                                    </Style.MainTypography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid
                            container
                            justifyContent='space-between'
                        >
                            <Grid
                                item
                                style={{
                                    marginRight: '16px'
                                }}
                            >
                                <Style.MainTypography>
                                    Cota mensal
                                </Style.MainTypography>
                            </Grid>

                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                md={8}
                                xs={6}
                            >
                                <Grid item>
                                    <Style.MainTypography primary>
                                        {dailyQuotaValues.totalQuota}
                                    </Style.MainTypography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="fullWidth" />
                    </Grid>

                    <Grid item xs={12}>
                        <Style.PrimaryGridContainer container>
                            <Grid item>
                                <CalendarTodayOutlinedIcon
                                    style={{
                                        color: '#DCC6B7',
                                        marginRight: '16px',
                                    }}
                                    justifyContent='flex-start'
                                />
                            </Grid>

                            <Grid
                                item
                                container
                                direction="column"
                                xs={10}
                            >
                                <Grid item>
                                    <Style.MainTypography>
                                        Folgas e Ausências
                                    </Style.MainTypography>
                                </Grid>

                                <Grid item>
                                    <List>
                                        {dailyQuotaValues.formattedAbsences.length ? dailyQuotaValues.formattedAbsences.map((item, index) => (
                                            <ListItem
                                                style={{
                                                    padding: "0px"
                                                }}
                                            >
                                                <Style.MainTypography primary>
                                                    {item}
                                                </Style.MainTypography>
                                            </ListItem>
                                        )) : (
                                            <ListItem
                                                style={{
                                                    padding: "0px"
                                                }}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: '400'
                                                    }}
                                                >
                                                    Sem datas cadastradas
                                                </Typography>
                                            </ListItem>
                                        )}
                                    </List>
                                </Grid>
                            </Grid>

                        </Style.PrimaryGridContainer>
                    </Grid>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default DesktopLayout;
