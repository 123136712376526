import React, { useEffect, useState } from 'react';
import { Typography, Grid, CircularProgress, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SvgIcon from '@mui/material/SvgIcon';

import { useHistory } from 'react-router-dom';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { ReactComponent as TrophyIcon } from '~/assets/icons/trofeu_pink.svg';
import api from '~/services/api';

import Header from '~/components/Header';

import * as Style from './styles';

function Vendedores() {
    const [allowedToUpdateQuotas, setAllowedToUpdateQuotas] = useState(false);
    const [topicExpanded, setTopicExpanded] = useState(false);
    const [
        loadingAllowedToUpdateQuotas,
        setLoadingAllowedToUpdateQuotas,
    ] = useState(false);
    const history = useHistory();

    const profile = useSelector(state => state.user.profile);

    // const [marcaPadrao, setMarcaPadrao] = useState(true);

    const filialCode = profile.users_branches[0].codigo_filial;
    const formattedFilialCode =
        filialCode.length === 3 ? `000${filialCode}` : filialCode;

    const { initialDate, finalDate } = useSelector(state => state.status);

    useEffect(() => {
        const getQuotaVerification = async () => {
            setLoadingAllowedToUpdateQuotas(true);
            const quotaVerificationResponse = await api.get(
                '/store/quota-verification',
                {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        filialCode:
                            profile.users_branches[0].codigo_filial.length === 3
                                ? `000${profile.users_branches[0].codigo_filial}`
                                : profile.users_branches[0].codigo_filial,
                        filialBrandId: profile?.marcaFilial,
                    },
                }
            );
            setAllowedToUpdateQuotas(
                quotaVerificationResponse.data.isAllowedToUpdateQuotas
            );
            setLoadingAllowedToUpdateQuotas(false);
        };
        getQuotaVerification();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeTopic = panel => (event, isExpanded) => {
        setTopicExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Header />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    padding: '16px',
                }}
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        margin: '6px 0 8px 16px',
                    }}
                >
                    <Typography
                        style={{
                            fontSize: '24px',
                            fontWeight: '600',
                            textTransform: 'capitalize',
                        }}
                    >
                        Vendedores
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: '16px',
                    }}
                >
                    <Style.BrandContainer
                        container
                        onClick={() => {
                            history.push(
                                `/storeRanking/${formattedFilialCode}`
                            );
                        }}
                    >
                        <Grid item>
                            <Grid
                                container
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item>
                                    <SvgIcon
                                        component={TrophyIcon}
                                        style={{
                                            marginRight: '16px',
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Style.PrimaryTypography>
                                                Ranking de vendedores
                                            </Style.PrimaryTypography>
                                        </Grid>
                                        <Grid item>
                                            <Style.SecondaryTypography>
                                                Ordenação por resultado vendas
                                            </Style.SecondaryTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                color: 'white',
                            }}
                        >
                            <ChevronRightOutlinedIcon />
                        </Grid>
                    </Style.BrandContainer>
                </Grid>

                {loadingAllowedToUpdateQuotas ? (
                    <Grid
                        item
                        xs={12}
                        style={{
                            marginTop: '16px',
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                                height: '56px',
                                borderRadius: '4px',
                                backgroundColor: '#373435',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                            }}
                        >
                            <Grid item>
                                <CircularProgress size={30} />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {allowedToUpdateQuotas ? (
                            <Style.AccordionContainer
                                expanded={
                                    topicExpanded === 'panelCotaVendedores'
                                }
                                key="panelCotaVendedores"
                                onChange={handleChangeTopic(
                                    'panelCotaVendedores'
                                )}
                            >
                                <Style.StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <PersonOutlinedIcon
                                                style={{
                                                    color: '#DCC6B7',
                                                    marginRight: '16px',
                                                }}
                                                justifyContent="flex-start"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Style.PrimaryTypography>
                                                        Cotas de Vendedores
                                                    </Style.PrimaryTypography>
                                                </Grid>
                                                <Grid item>
                                                    <Style.SecondaryTypography>
                                                        Lançamento e edição
                                                    </Style.SecondaryTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Style.StyledAccordionSummary>

                                <Style.PanelDetails>
                                    <Grid item xs={12}>
                                        <Style.BrandContainer
                                            container
                                            primary
                                            onClick={() => {
                                                history.push(
                                                    `/cadastroDeCotas/${formattedFilialCode}`
                                                );
                                            }}
                                        >
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                        >
                                                            <Grid item>
                                                                <Style.PrimaryTypography>
                                                                    Cadastro de
                                                                    cotas
                                                                </Style.PrimaryTypography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    color: 'white',
                                                }}
                                            >
                                                <ChevronRightOutlinedIcon />
                                            </Grid>
                                        </Style.BrandContainer>

                                        <Style.BrandContainer
                                            container
                                            onClick={() => {
                                                history.push(
                                                    `consultarCotasEquipe`
                                                );
                                            }}
                                        >
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                        >
                                                            <Grid item>
                                                                <Style.PrimaryTypography>
                                                                    Consultar
                                                                    cotas
                                                                </Style.PrimaryTypography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    color: 'white',
                                                }}
                                            >
                                                <ChevronRightOutlinedIcon />
                                            </Grid>
                                        </Style.BrandContainer>
                                    </Grid>
                                </Style.PanelDetails>
                            </Style.AccordionContainer>
                        ) : (
                            <Accordion
                                expanded={
                                    topicExpanded === 'panelCotaVendedores'
                                }
                                key="panelCotaVendedores"
                                onChange={handleChangeTopic(
                                    'panelCotaVendedores'
                                )}
                            >
                                <Style.StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <PersonOutlinedIcon
                                                style={{
                                                    color: '#DCC6B7',
                                                    marginRight: '16px',
                                                }}
                                                justifyContent="flex-start"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Style.PrimaryTypography>
                                                        Cotas de Vendedores
                                                    </Style.PrimaryTypography>
                                                </Grid>
                                                <Grid item>
                                                    <Style.SecondaryTypography>
                                                        Lançamento e edição
                                                    </Style.SecondaryTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Style.StyledAccordionSummary>

                                <Style.PanelDetails>
                                    <Grid item xs={12}>
                                        <Style.BrandContainer container primary>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                        >
                                                            <Grid item>
                                                                <Style.PrimaryTypography
                                                                    primary
                                                                >
                                                                    Cadastro de
                                                                    cotas
                                                                </Style.PrimaryTypography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    color: '#616161',
                                                }}
                                            >
                                                <ChevronRightOutlinedIcon />
                                            </Grid>
                                        </Style.BrandContainer>

                                        <Style.BrandContainer
                                            container
                                            onClick={() => {
                                                history.push(
                                                    `consultarCotasEquipe`
                                                );
                                            }}
                                        >
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                        >
                                                            <Grid item>
                                                                <Style.PrimaryTypography>
                                                                    Consultar
                                                                    cotas
                                                                </Style.PrimaryTypography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    color: 'white',
                                                }}
                                            >
                                                <ChevronRightOutlinedIcon />
                                            </Grid>
                                        </Style.BrandContainer>
                                    </Grid>
                                </Style.PanelDetails>
                            </Accordion>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
}

export default Vendedores;
