import React from 'react';
import { Grid } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import {  formatWithCent } from '~/util/format';

import * as Style from './styles';

function ProductCard({
    updatedSelectedCart,
    setProductToBeRemoved,
    setRemoveProductDialog,
    setProductName,
}) {
    const resizeProductImageUrl = originalUrl => {
        if (originalUrl) {
            const splitOriginalUrl = originalUrl.split('/');
            const IMAGE_SIZE_PARAM_INDEX = 5;
            splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX] = `${
                splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX].split('-')[0]
            }-148-224`;
            const resizedOriginalUrl = splitOriginalUrl.join('/');
            return resizedOriginalUrl;
        }
        return '';
    };

    return (
        <>
            {updatedSelectedCart?.saved_cart_products?.map((product, index) => (
                <Style.CardContainer
                    key={`product-item-${product.vtexData.productReference}-${index}`}
                >
                    <Style.DetailCartContainer>
                        <Style.ImgContainer
                            onClick={() =>
                                window.open(
                                    product?.vtexData.ecommerceUrl,
                                    '_blank'
                                )
                            }
                        >
                            <img
                                src={resizeProductImageUrl(
                                    product?.vtexData.items[0].images[0]
                                        .imageUrl
                                )}
                                alt="imagem do produto"
                                style={{
                                    width: '144px',
                                    height: '218px',
                                    borderRadius: '12px 0px 0px 12px',
                                }}
                            />
                        </Style.ImgContainer>

                        <Style.ContentContainer>
                            <Style.DetailCartTypography>
                                {product?.vtexData.productName?.toLowerCase()}
                            </Style.DetailCartTypography>
                            <Style.ReferenceTypography>
                                Ref: {product?.vtexData.productReference}
                            </Style.ReferenceTypography>

                            <Style.DetailCartTypography>
                                {formatWithCent(product?.price)}
                            </Style.DetailCartTypography>
                            <Grid container justifyContent="flex-start">
                                <Style.AvatarComponent>
                                    {product?.size}
                                </Style.AvatarComponent>
                                <Style.RemoveButton
                                    onClick={() => {
                                        setProductToBeRemoved(
                                            product.id_saved_cart_product_color
                                        );
                                        setRemoveProductDialog(true);
                                        setProductName(
                                            product.vtexData.productName
                                        );
                                    }}
                                >
                                    <RemoveCircleOutlineOutlinedIcon
                                        style={{
                                            color: '#E26257',
                                        }}
                                    />
                                </Style.RemoveButton>
                            </Grid>
                        </Style.ContentContainer>
                    </Style.DetailCartContainer>
                </Style.CardContainer>
            ))}
        </>
    );
}

export default ProductCard;
