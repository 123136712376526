import React from 'react';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UndoIcon from '@mui/icons-material/Undo';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

const getIconFromOmniOrder = order => {
    if (order.ultimo_status === 'CANCELADO') {
        return (
            <CancelOutlinedIcon
                style={{
                    color: '#E26257',
                }}
            />
        );
    }

    if (order.ultimo_status === 'DEVOLUCAO') {
        return (
            <UndoIcon
                style={{
                    color: '#E26257',
                }}
            />
        );
    }
    return (
        <LocalShippingOutlinedIcon
            style={{
                color: '#DCC6B7',
            }}
        />
    );
};

export default getIconFromOmniOrder;
