import {styled} from '@mui/material/styles';
import {
    Grid,
    Button,
} from '@mui/material';

export const BrandContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 26px 26px;
    border-radius: 4px;
    background-color: #373435;
`

export const PrimaryButton = styled(Button)`
    background-color: ${props => props.primary ? '#DCC6B7' : 'rgba(0,0,0,0)'};
    color: ${props => props.primary ? '#373435' : '#DCC6B7'};
    width: 100%;
    height: 40px;
    font-size: 15px;
    margin-top: 16px;
    text-transform: none;
    border: ${props => props.primary ? 'none' : '1px solid #DCC6B7'};
    &:disabled { 
        background-color: ${props => props.primary && '#373435'};
        color: ${props => props.primary && '#616161'};
    };
    &:hover { 
        background-color: ${props => props.primary && '#b8a598'};
    };
`