import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Grid, Button } from '@mui/material';

// Import component
import ListItem from '../ListItem';

import * as Style from './styles';

function PanelList({
    filteredOrders,
    multimarca,
    setCurrentPage,
    cartsLoaded,
}) {
    // Hooks
    const location = useLocation().pathname;

    const [itemQuantity, setItemQuantity] = useState(10);

    const isListHidden = filteredOrders.length >= itemQuantity;
    const isAtMaisCarrinho = location === '/maisCarrinho';

    const orderList = isAtMaisCarrinho
        ? filteredOrders
        : filteredOrders.slice(0, itemQuantity);

    return (
        <>
            <Style.Root>
                {orderList.map((order, index) => (
                    <div key={`list-item-orders-${index}`}>
                        <ListItem
                            order={order}
                            multimarca={multimarca}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                ))}
                {!cartsLoaded && isAtMaisCarrinho && (
                    <Grid
                        container
                        style={{
                            marginTop: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Style.CircularProgressComponent size={50} />
                    </Grid>
                )}
            </Style.Root>
            {isListHidden && !isAtMaisCarrinho && (
                <Grid style={{ textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setItemQuantity(
                                currentItemQuantity => currentItemQuantity + 10
                            );
                        }}
                        sx={{
                            border: 'none',
                            color: '#DCC6B7',
                            marginTop: '0.5rem',
                        }}
                    >
                        Ver Mais
                    </Button>
                </Grid>
            )}
        </>
    );
}

export default PanelList;
