import {styled} from '@mui/material/styles';
import { Grid } from '@mui/material';

export const LateralPanelContainer = styled(Grid)`
    height: fit-content;
    margin: 16px;
    @media screen and (max-width: 960px){
        margin: 0;
    }
`

export const MainPanelContainer = styled(Grid)`
`

export const PageContainer = styled(Grid)`
    justify-content: space-evenly;
`