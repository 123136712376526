import React, {useEffect} from 'react';
import {
    CircularProgress,
    IconButton,
    InputAdornment,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import MaisCarrinhoProductCard from '~/components/MaisCarrinhoProductCard';

import SnackBar from '~/util/SnackBar';

import { maisCarrinhoApi } from '~/services/api';

import * as Style from './styles';

function SearchArea({
    searchProductName,
    setSearchProductName,
    loading,
    searchedProducts,
    selectedCart,
    setAddedProduct,
    searchParam,
    setLoading,
    setSearchedProducts
}) {
    const addProductToCart = async itemId => {
        if (itemId === null) {
            SnackBar.warning(
                'Favor escolher um tamanho para adicionar o produto'
            );
        }
        try {
            await maisCarrinhoApi.patch(
                `/saved-cart?id_saved_cart=${selectedCart.id_saved_cart}`,
                {
                    saved_cart_products: [
                        { id_product_color: itemId, quantity: 1 },
                    ],
                }
            );
            setAddedProduct(true);
            SnackBar.success('Produto adicionado ao carrinho', {
                autoHideDuration: 1500,
            });
        } catch (err) {
            SnackBar.error('Erro ao adicionar produto');
        }
    };

    // FUNCAO QUE BUSCA A IMAGEM DO PRODUTO
    const resizeProductImageUrl = originalUrl => {
        if (originalUrl) {
            const splitOriginalUrl = originalUrl.split('/');
            const IMAGE_SIZE_PARAM_INDEX = 5;
            splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX] = `${
                splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX].split('-')[0]
            }-148-224`;
            const resizedOriginalUrl = splitOriginalUrl.join('/');
            return resizedOriginalUrl;
        }
        return '';
    };

    useEffect(() => {
        if (searchParam) {
            setLoading(true);
            const searchProductByName = async productName => {
                const productResponse = await maisCarrinhoApi.get(
                    `/search-products/${productName}?id_brand=${selectedCart.id_label}`
                );
                if (selectedCart.id_label === 9) {
                    setSearchedProducts(
                        productResponse.data.filter(
                            item => item.categoryId !== '42'
                        )
                    );
                } else {
                    setSearchedProducts(productResponse.data);
                }

                const isStringProductReference = productName.includes('.');
                const searchType = isStringProductReference
                    ? 'codigo de referencia'
                    : 'nome do produto';
                window.dataLayer.push({
                    event: 'search_cart',
                    search_type: searchType,
                });

                setLoading(false);
            };
            searchProductByName(searchParam);
        } else {
            setSearchedProducts([]);
        }
    }, [selectedCart.id_label, searchParam, setLoading, setSearchedProducts]);

    return (
        <Style.SearchAreaContainer>
            <Style.Title>Insira produtos neste Carrinho</Style.Title>
            <Style.CssTextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <SearchOutlinedIcon
                                style={{
                                    color: '#DCC6B7',
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <>
                            {searchProductName.length !== 0 && (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        paddingLeft: '0px',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            setSearchProductName('');
                                        }}
                                    >
                                        <CancelIcon
                                            style={{
                                                color: '#DCC6B7',
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        </>
                    ),
                }}
                variant="outlined"
                label="Produto ou Ref. (00.00.0000)"
                fullWidth
                value={searchProductName}
                onChange={e =>
                    setSearchProductName(
                        e.target.value
                            .replace(/[ÀÁÂÃÄÅ]/, 'A')
                            .replace(/[àáâãäå]/, 'a')
                            .replace(/[ÈÉÊË]/, 'E')
                            .replace(/[Ç]/, 'C')
                            .replace(/[ç]/, 'c')
                    )
                }
            />
            {loading ? (
                <Style.LoadingContainer>
                    <CircularProgress size={50} />
                </Style.LoadingContainer>
            ) : (
                <>
                    {searchedProducts.map((product, index) => (
                        <MaisCarrinhoProductCard
                            index={index}
                            product={product}
                            resizeProductImageUrl={resizeProductImageUrl}
                            addProductToCart={addProductToCart}
                        />
                    ))}
                </>
            )}
        </Style.SearchAreaContainer>
    );
}

export default SearchArea;
