import React from 'react';
import { Grid, Skeleton } from '@mui/material';

import * as Style from './styles';

function LoadingComponent() {
    return (
        <>
            <Style.SkeletonTopBox container spacing={2}>
                <Grid container spacing={1} item xs={12}>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="50%" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="30%" />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-evenly" item xs={8}>
                    <Skeleton animation="wave" width="80%" />
                    <Skeleton animation="wave" width="80%" />
                </Grid>
                <Grid container alignItems="center" item xs={4}>
                    <Skeleton animation="wave" width="50%" height="100%" />
                </Grid>
                <Grid container justifyContent="center" item xs={12}>
                    <Skeleton animation="wave" width="60%" />
                </Grid>
                <Grid container justifyContent="space-evenly" item xs={6}>
                    <Skeleton animation="wave" width="70%" />
                    <Skeleton animation="wave" width="40%" />
                    <Skeleton animation="wave" width="40%" />
                </Grid>
                <Grid container justifyContent="space-evenly" item xs={6}>
                    <Skeleton animation="wave" width="70%" />
                    <Skeleton animation="wave" width="40%" />
                    <Skeleton animation="wave" width="40%" />
                </Grid>
            </Style.SkeletonTopBox>
            <Style.DesktopSkeletonContainer container>
                <Grid container item xs={12}>
                    <Grid container justifyContent="center" item xs={3}>
                        <Skeleton animation="wave" width="90%" height="300px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={3}>
                        <Skeleton animation="wave" width="90%" height="300px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={3}>
                        <Skeleton animation="wave" width="90%" height="300px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={3}>
                        <Skeleton animation="wave" width="90%" height="300px" />
                    </Grid>
                </Grid>
            </Style.DesktopSkeletonContainer>
        </>
    );
}

export default LoadingComponent;
