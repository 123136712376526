import React from 'react';
import LogoMaisVendas from '../../assets/logo_mais_vendas.png';

import NewFeatureAlert from '../NewFeatureAlert';

import { Logo, MainText, Subtitle } from './styles';

function SalesChangeModal({ showSalesModal, onClose }) {
    return (
        <NewFeatureAlert
            open={showSalesModal}
            onClose={onClose}
            handleButtonClick={onClose}
            buttonText="Entendi"
            size="32rem"
        >
            <Logo component="img" src={LogoMaisVendas} title="Grupo Soma" />
            <Subtitle>REGRA GERAL DE COMISSIONAMENTO</Subtitle>
            <MainText primary>
                Até o fim do primeiro dia útil após o fechamento da competência,
                podem ocorrer alterações em suas vendas.
            </MainText>
            <MainText>
                Em caso de dúvida, entre em contato com o atendimento!
            </MainText>
        </NewFeatureAlert>
    );
}

export default SalesChangeModal;
