import React from 'react';
import {
    DialogTitle,
    DialogContent,
    Dialog,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';

// Util import
import {
    availableChannelFilters,
    availableOrderFilters,
} from '~/util/storeRankingFilters';
import { sortByFunction } from '~/util/sortByFunction';

// Style import
import * as Style from './styles';

function RankingFilter({
    channelFilterDialogIsOpen,
    setChannelFilterDialogIsOpen,
    channelFilter,
    setChannelFilter,
    orderBy,
    setOrderBy,
    setRankingData,
    orderByAsc,
}) {
    const handleClickOnRadioButton = (event, selectType) => {
        const clickOnChannelType = selectType === 'channel';
        let functionSorter;
        if (clickOnChannelType && event !== 'all' && orderBy === 'quota') {
            setOrderBy('money');
            functionSorter = sortByFunction(event, 'money');
        }
        if (clickOnChannelType) {
            setChannelFilter(event);
            functionSorter = sortByFunction(event, orderBy);
        }
        if (!clickOnChannelType){
            setOrderBy(event);
            functionSorter = sortByFunction(channelFilter, event);
        }

        setRankingData(currentRankingData => {
            const newRankingData = [...currentRankingData];
            const newRankingSort = newRankingData.sort(functionSorter);
            if (orderByAsc === 'desc') return newRankingSort.reverse();
            return newRankingSort;
        });
        setChannelFilterDialogIsOpen(false);
    };

    return (
        <Dialog
            open={channelFilterDialogIsOpen}
            onClose={() => setChannelFilterDialogIsOpen(false)}
        >
            <DialogTitle style={{ paddingLeft: '16px' }}>Filtros</DialogTitle>
            <DialogContent>
                <FormControl
                    component="fieldset"
                    style={{ marginBottom: '40px' }}
                >
                    <Style.TitleFilter primary>Tipo de Venda</Style.TitleFilter>

                    <RadioGroup
                        value={channelFilter}
                        onChange={e => handleClickOnRadioButton(e.target.value, 'channel')}
                    >
                        {availableChannelFilters.map(availableChannelFilter => (
                            <FormControlLabel
                                value={availableChannelFilter.channel}
                                control={<Radio />}
                                label={availableChannelFilter.label}
                                key={availableChannelFilter.channel}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>

                <FormControl
                    component="fieldset"
                    style={{ marginBottom: '40px' }}
                >
                    <Style.TitleFilter primary>
                        Visualizar por:
                    </Style.TitleFilter>

                    <RadioGroup
                        value={orderBy}
                        onChange={e => {handleClickOnRadioButton(e.target.value, 'orderBy')}}
                    >
                        {availableOrderFilters.map(availableOrderFilter => (
                            <FormControlLabel
                                value={availableOrderFilter.valueParam}
                                control={<Radio />}
                                label={availableOrderFilter.label}
                                key={availableOrderFilter.valueParam}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
        </Dialog>
    );
}

export default RankingFilter;
