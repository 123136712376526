import React from 'react';
import { Grid, ListItemText } from '@mui/material';
import { useLocation } from 'react-router';

// Util import
import { abbreviateFormatDate } from '~/util/format';
import getIconFromOrder from '~/util/getIconFromOrder';
import getIconFromMultimarcaOrder from '~/util/getIconFromMultimarcaOrder';
import getIconFromOmniOrder from '~/util/getIconFromOmniOrder';
import { getIconFromCarts } from '~/util/getIconFromCarts';

import * as Style from './styles';

function ClientData({ order, multimarca }) {
    const location = useLocation().pathname;
    const identification =
        location === '/maisCarrinho' ? order?.cart_name : order?.orderId;

    const storeOmniDateInfo = order?.devolutionDate
        ? order?.devolutionDate
        : order?.invoicedDate;

    const renderData = () => {
        if (location === '/storeOmni') {
            return (
                <Style.ClientNameContainer>
                    {abbreviateFormatDate(storeOmniDateInfo)}
                </Style.ClientNameContainer>
            );
        }

        if (location === '/maisCarrinho') {
            return (
                <Grid
                    style={{
                        fontSize: '12px',
                    }}
                >
                    {abbreviateFormatDate(order?.creation_date)}
                </Grid>
            );
        }
        return (
            <Style.ClientNameContainer>
                {order?.clientData?.nome || 'Nome indisponível'}
            </Style.ClientNameContainer>
        );
    };

    const renderAvatar = () => {
        if (multimarca) {
            const icon = getIconFromMultimarcaOrder(order);
            return icon;
        }
        if (location === '/storeOmni') {
            const icon = getIconFromOmniOrder(order);
            return icon;
        }
        if (location === '/maisCarrinho') {
            const icon = getIconFromCarts(order);
            return icon;
        }
        const icon = getIconFromOrder(order);
        return icon;
    };

    return (
        <Style.Root>
            <Style.AvatarContainer>{renderAvatar()}</Style.AvatarContainer>
            <ListItemText>
                {renderData()}
                <Style.OrderIdComponent>
                    {identification}
                </Style.OrderIdComponent>
            </ListItemText>
        </Style.Root>
    );
}

export default ClientData;
