import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import 'moment/locale/pt-br';
import React from 'react';
import chatImage from '~/assets/firstAccess/chat.png';
import * as Style from './styles';

function CannotRegister({ ...props }) {
    const history = useHistory();

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Style.HeaderContainer container>
                        <Grid item>
                            <Style.Logo
                                component="img"
                                src={chatImage}
                                title="Grupo Soma"
                            />
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid container justifyContent="center">
                        <Grid item xs={9}>
                            <Typography align="left" variant="h3">
                                Não foi possível prosseguir                            
                            </Typography>
                        </Grid>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Que pena, não podemos fazer seu cadastro.
                                Só é possível realizar cadastro no +Vendas com <b>código de vendedor</b> ou <b>clifor</b>.
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={12} container justifyContent="center">
                            <Style.PrimaryButton
                                onClick={() => {window.open(
                                    'https://api.whatsapp.com/send/?phone=552137337703&text&app_absent=0',
                                    '_blank'
                                );
                                }}
                            >
                                Falar com o suporte
                            </Style.PrimaryButton>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Style.SecondaryButton
                                onClick={() => history.push('/login')}
                            >
                                {' '}
                                Voltar
                            </Style.SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CannotRegister;
