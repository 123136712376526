import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const PanelCardContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    background-color: #373435;
    height: 305px;
    border-radius: 4px;
    padding: 16px;
    color: white;
    font-family: inherit;
    width: 22vw;
    gap: 0;
    @media screen and (max-width: 960px) {
        margin: 0px 16px;
        width: auto;
    }
`;

export const DataContainer = styled(Box)`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
`;

export const PanelCardListKey = styled(Typography)`
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
`;

export const BalanceTextTypography = styled(Typography)`
    font-size: 2em;
    text-align: center;
    font-family: inherit;
    margin: 41px 0 32px;
    font-weight: 600;

    @media screen and (max-width: 1180px) and (min-width: 960px) {
        font-size: 28px;
    }
`;

export const ObservationTypography = styled(Typography)`
    font-size: 12px;
    text-align: left;
    font-weight: 400;
    color: #e0e0e0;
`;

export const MainVendedoraTypography = styled(Typography)`
    font-size: 14px;
    text-align: right;
    color: #dcc6b7;
    font-weight: 500;
`;
