import React from 'react';
import { Dialog, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import * as Style from './styles';

function CreateCartModal({
    dialogCarrinhoCriado,
    setDialogCarrinhoCriado,
    selectedCart,
    createCartName,
}) {
    const history = useHistory();

    return (
        <Dialog
            open={dialogCarrinhoCriado}
            onClose={() => {
                setDialogCarrinhoCriado(false);
                history.push({
                    pathname: '/maisCarrinho/searchProduct',
                    state: {selectedCart},
                });
            }}
            PaperProps={{
                style: {
                    backgroundColor: '#FFFFFF',
                    color: '#424242',
                    padding: '16px',
                    borderRadius: '12px',
                },
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="stretch"
            >
                <Grid item>
                    <Style.Title>Carrinho Criado</Style.Title>
                </Grid>

                <Style.DefaultContainer item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <CheckCircleOutlinedIcon
                                style={{
                                    color: '#1BB55C',
                                    fontSize: '36px',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Style.DefaultContainer>
                <Grid
                    item
                    style={{
                        color: '#373435',
                        textAlign: 'center',
                        fontSize: '14px',
                        padding: '0 16px',
                    }}
                >
                    <b>{createCartName}</b> foi criado com sucesso.{' '}
                </Grid>
                <Grid
                    item
                    style={{
                        color: '#373435',
                        textAlign: 'center',
                        fontSize: '14px',
                        padding: '0 16px',
                        marginBottom: '12px',
                    }}
                >
                    Adicione produtos a seguir.
                </Grid>

                <Grid
                    item
                    style={{
                        marginTop: '16px 0 12px',
                    }}
                >
                    <Grid container direction="row" justifyContent="center">
                        <Grid item>
                            <Style.DialogButton
                                variant="outlined"
                                onClick={() => {
                                    setDialogCarrinhoCriado(false);
                                    history.push({
                                        pathname: '/maisCarrinho/searchProduct',
                                        state: {selectedCart},
                                    });
                                }}
                            >
                                OK
                            </Style.DialogButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

export default CreateCartModal;
