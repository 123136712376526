import {styled} from '@mui/material/styles';
import { Grid, Button, CircularProgress } from '@mui/material';

export const Root = styled(Grid)`
    justify-content: space-between;
    min-height: 100vh;
`

export const LowerPanelContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-self: flex-end;
    background-color: #212121;
    position: sticky;
    padding: 16px;
    bottom: 0;
    z-index: 1;
`

export const ButtonComponent = styled(Button)`
    width: 100%;
    height: 56px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 3px;
    font-family: 'Inter';
    background-color: #DCC6B7;
    color: #212121;
    &:hover {
        background-color: #A99384;
    };
`

export const CircularProgressComponent = styled(CircularProgress)`
    color: #DCC6B7;
`