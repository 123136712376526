import { availableChannelFilters } from "./storeRankingFilters"

export const sortByFunction = (channelFilter, orderBy) => {
    const findSelectedChannel = availableChannelFilters.find(
        channelObj => channelObj.channel === channelFilter
    );
    const mapOrderByToTargetValue = {
        quota: 'quotaPercentage',
        money: findSelectedChannel.valueParam,
        pa: findSelectedChannel.valueParamPA,
        va: findSelectedChannel.valueParamVA,
    };

    const targetValue = mapOrderByToTargetValue[orderBy];

    return (salespersonA, salespersonB) => {
        const valueA = parseFloat(salespersonA[targetValue]);
        const valueB = parseFloat(salespersonB[targetValue]);
        if (Number.isNaN(valueA) && Number.isNaN(valueB)) return 0;
        if (Number.isNaN(valueA)) return 1;
        if (Number.isNaN(valueB)) return -1;
        if (!valueA && !valueB) return 0;
        return valueB - valueA;
    };
}