import styled, { css } from 'styled-components';
// import Tooltip from '../Tooltip';

export const Container = styled.div`
  background: #232129;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  border: 2px solid #232129;
  color: #666360;
  display: flex;
  flex-direction: column;
  align-items: center;
  & + div {
    margin-top: 8px;
  }
  ${props =>
      props.isErrored &&
      css`
          border-color: #c53030;
      `}
  ${props => props.isFocused && css``}
  ${props => props.isFilled && css``}
  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #f4ede8;
    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
    display: contents;
    width: 100%;
    height: 20px;
    margin-left: 16px;
    svg {
        margin: 0;
    }
    span {
        background: #c53030;
        color: #fff;
        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
