import { styled } from '@mui/material/styles';
import { Grid, Typography, Box } from '@mui/material';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    margin-bottom: 16px;
`;

export const OrdersPanelTitleContainer = styled(Grid)`
    justify-content: space-between;
    padding: 16px 16px 0 16px;
`;

export const TitleTypography = styled(Typography)`
    font-weight: 600;
    font-family: inherit;
    font-size: 20px;
    color: white;
    text-align: left;
`;

export const Warning = styled(Box)`
    display: flex;
    align-items: center;
    padding: 0 16px;
`;

export const WarningTitle = styled(Typography)`
    color: #dcc6b7;
    font-size: 14px;
    font-weight: 400;

    strong {
        font-weight: 600;
        color: white;
    }
`;
