import { Grid, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import * as Style from './styles';

function SuccessPage({ ...props }) {
    const history = useHistory();
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Style.HeaderContainer container>
                        <Grid item xs={12}>
                            <CheckCircleOutlineOutlinedIcon
                                fontSize="large"
                                style={{ color: '#1BB55C' }}
                            />
                            <Typography variant="h4">
                                Recuperação de senha
                            </Typography>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid item container justifyContent="center" spacing={2}>
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />

                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Um link para redefinir sua senha foi enviado
                                para seu endereço de E-mail. Se você não
                                encontrar o email na sua caixa de entrada,
                                verifique a lixeira ou a pasta de spam.{' '}
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} md={3} />
                        <Grid item xs={10} />
                        <Grid item xs={10} justifyContent="space-evenly">
                            <Style.PrimaryButton
                                onClick={() => history.push('/login')}
                            >
                                {' '}
                                OK
                            </Style.PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SuccessPage;