export const CHANNELS = {
    devolucao: 'DEVOLUCAO',
    fisico: 'FISICO',
    vitrine: 'VITRINE',
    online: 'ONLINE',
    somastore: 'SOMASTORE',
    estoque_proprio: 'ESTOQUE PROPRIO',
};

export const STATUSORDER = {
    cancelado: 'CANCELADO',
    capturado: 'CAPTURADO',
    devolucao: 'DEVOLUCAO',
};
