import {styled} from '@mui/material/styles';
import {
    TextField,
    Typography,
    Button,
    Grid,
    CardMedia,
    Container,
    CircularProgress
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Root = styled(Container)`
    flex-grow: 1;
`

export const GridContainer = styled(Grid)`
    justify-content: center;
    align-items: center;
    margin-top: 16px;
`

export const Main = styled(Container)`
    margin-top: 8px;
`

export const Logo = styled(CardMedia)`
    width: 84px;
    height: 84px;
    margin: 16px 16px 50px;
`

export const MarginTextField = styled(TextField)`
    margin-top: 20px;
`

export const PrimaryButton = styled(Button)`
    background-color: #DCC6B7;
    color: black;
    height: 56px;
    font-size: 15px;
    text-transform: none;
    margin: 16px 0;
`

export const StyledChevron = styled(ChevronRightIcon)`
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0%, -50%);
`

export const ChangeKeyButton = styled(Button)`
    background-color: black;
    color: #DCC6B7;
    font-size: 14px;
    text-transform: none;
    margin-bottom: 16px;
    justify-content: ${props => props.inputJustify || 'center'};
`

export const ForgotPasswordText = styled(Typography)`
    transition: opacity 0.2s;
    color: #737380;
    &:hover {
        opacity: 0.6;
    };
`

export const LogoSoma = styled(CardMedia)`
    width: 108px;
`

export const StyledCircularProgress = styled(CircularProgress)`
    color: #000000;
`