import React, { useCallback, useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    CardActions,
    CardContent,
    ListItem,
    Box,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { useHistory } from 'react-router-dom';

import Divider from '@mui/material/Divider';

import moment from 'moment';
import CustomHeader from '~/components/CustomHeader';
import QuotaRegisterConfirmationModal from '~/components/QuotaRegisterConfirmationModal';

import { formatPrice } from '~/util/format';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import api from '~/services/api';
import SnackBar from '~/util/SnackBar';
import getSalespersonQuotaDays from '~/util/getSalespersonQuotaDays';
import * as Style from './styles';

function CotasDiarias({
    setCurrentPage,
    salesTeamData,
    setSalesTeamData,
    quotaValues,
    quotaDistribution,
    setQuotaDistribution,
}) {
    const history = useHistory();
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const handleChange = event => {
        setQuotaDistribution(event.target.value);
    };

    useEffect(() => {
        let assessmentDate = moment().endOf('month');
        if (
            moment().date() > 25 &&
            moment().isAfter(
                moment()
                    .hour(0)
                    .date(1)
                    .month(4)
                    .year(2021)
            )
        ) {
            assessmentDate = assessmentDate.add(1, 'month');
        }
        const { startDate, endDate } = getAssessmentDatesByDate(assessmentDate);
        const storeQuotasByDay = { ...quotaValues.dailyQuotas };

        if (quotaDistribution === 'uniform') {
            setSalesTeamData(currentSalesTeamData => {
                const newSalesTeamData = currentSalesTeamData.map(
                    salesperson => {
                        const {
                            quotaDays,
                            DAYS_IN_PERIOD,
                        } = getSalespersonQuotaDays(
                            salesperson,
                            startDate,
                            endDate,
                            storeQuotasByDay
                        );

                        return {
                            ...salesperson,
                            dailyQuota:
                                salesperson.quotaValueMonth / DAYS_IN_PERIOD,
                            quotasByDay: quotaDays.reduce(
                                (attrs, date, index, dateArray) => {
                                    const roundedDailyQuota =
                                        Math.round(
                                            (salesperson.quotaValueMonth /
                                                DAYS_IN_PERIOD) *
                                                100
                                        ) / 100;
                                    const roundedlastDayQuotaDifference =
                                        Math.round(
                                            (salesperson.quotaValueMonth -
                                                roundedDailyQuota *
                                                    DAYS_IN_PERIOD) *
                                                100
                                        ) / 100;
                                    return {
                                        ...attrs,
                                        [date]:
                                            index === dateArray.length - 1
                                                ? Math.round(
                                                      (roundedDailyQuota +
                                                          roundedlastDayQuotaDifference) *
                                                          100
                                                  ) / 100
                                                : roundedDailyQuota,
                                    };
                                },
                                {}
                            ),
                        };
                    }
                );

                return newSalesTeamData;
            });
            // eslint-disable-next-line no-empty
        } else if (quotaDistribution === 'proportional') {
            const storeMonthQuota = quotaValues.quota;
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(salesperson => {
                    let totalSumRoundedQuotaValues = 0;
                    const {
                        quotaDays,
                        DAYS_IN_PERIOD,
                    } = getSalespersonQuotaDays(
                        salesperson,
                        startDate,
                        endDate,
                        storeQuotasByDay
                    );

                    return {
                        ...salesperson,
                        dailyQuota:
                            salesperson.quotaValueMonth / DAYS_IN_PERIOD,
                        quotasByDay: quotaDays.reduce(
                            (attrs, date, index, dateArray) => {
                                const storeQuotaWeight =
                                    storeQuotasByDay[date] / storeMonthQuota;
                                const roundedQuotaValue =
                                    Math.round(
                                        salesperson.quotaValueMonth *
                                            storeQuotaWeight *
                                            100
                                    ) / 100;
                                let currentDayRoundedQuotaValue = roundedQuotaValue;
                                if (index === dateArray.length - 1) {
                                    currentDayRoundedQuotaValue =
                                        Math.round(
                                            (salesperson.quotaValueMonth -
                                                totalSumRoundedQuotaValues) *
                                                100
                                        ) / 100;
                                } else {
                                    totalSumRoundedQuotaValues += roundedQuotaValue;
                                }
                                return {
                                    ...attrs,
                                    [date]: currentDayRoundedQuotaValue,
                                };
                            },
                            {}
                        ),
                    };
                })
            );
        }
    }, [quotaDistribution, quotaValues, setSalesTeamData]);

    const handleClickOnNextPageButton = () => {
        setOpenConfirmationDialog(true);
    };
    const mountQuotaObjectAndUpdateQuotas = useCallback(
        async endAction => {
            try {
                const formattedRequestBody = salesTeamData.map(salesperson => {
                    return {
                        salesCode: salesperson.vendedor,
                        dailyQuotas: Object.keys(
                            salesperson.quotasByDay
                        ).reduce(
                            (attrs, date) => ({
                                ...attrs,
                                [date]: {
                                    value: salesperson.quotasByDay[date],
                                },
                            }),
                            {}
                        ),
                    };
                });
                await api.post(
                    'store-manager/sales-team-quota',
                    formattedRequestBody
                );
                setOpenConfirmationDialog(false);
                SnackBar.success('Cotas cadastradas com sucesso');
                if (endAction) {
                    endAction();
                }
            } catch (e) {
                SnackBar.error('Erro ao cadastrar cotas');
            }
        },
        [salesTeamData]
    );
    return (
        <>
            <QuotaRegisterConfirmationModal
                open={openConfirmationDialog}
                onClose={() => {
                    setOpenConfirmationDialog(false);
                }}
                onAccept={() =>
                    mountQuotaObjectAndUpdateQuotas(() => {
                        setCurrentPage('ResumoAusenciasFilial');
                    })
                }
                onFinish={() =>
                    mountQuotaObjectAndUpdateQuotas(() => {
                        setTimeout(() => {
                            history.push('/consultarCotasEquipe');
                        }, 1000);
                    })
                }
            />
            <CustomHeader
                title="Cadastro de Cotas"
                goBackPage={() => setCurrentPage('CotasMensais')}
            />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    padding: '16px',
                }}
            >
                <Grid item xs={12}>
                    <Style.BrandContainer container>
                        <Grid item style={{ margin: '16px 0' }}>
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                }}
                            >
                                Distribuição de cotas diárias
                            </Typography>
                        </Grid>
                        <Grid item style={{ marginBottom: '16px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="distribution"
                                    name="distribution1"
                                    value={quotaDistribution}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        value="uniform"
                                        control={<Radio />}
                                        label="Cotas iguais em todos os dias"
                                        style={{ marginBottom: '8px' }}
                                    />
                                    <FormControlLabel
                                        value="proportional"
                                        control={<Radio />}
                                        label="Cotas proporcionais às da loja"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Style.BrandContainer>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        style={{
                            backgroundColor: '#373435',
                            marginTop: '16px',
                        }}
                    >
                        <CardActions
                            style={{
                                padding: '0 16px',
                            }}
                        >
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            margin: '8px 0',
                                        }}
                                    >
                                        Lista de Vendedores
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>

                        <CardContent
                            style={{
                                paddingTop: '0px',
                                textAlign: 'center',
                            }}
                        >
                            {salesTeamData.map(salesperson => (
                                <>
                                    <ListItem style={{ padding: '0px' }}>
                                        <Grid
                                            container
                                            style={{
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                justifyContent="space-evenly"
                                                style={{ marginBottom: '8px' }}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            textAlign: 'left',
                                                            textTransform:
                                                                'capitalize',
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        }}
                                                    >
                                                        {salesperson.vendedor_apelido.toLowerCase()}
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            color: '#9E9E9E',
                                                            fontSize: '12px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        {`${formatPrice(
                                                            salesperson.quotaValueMonth
                                                        )} | Diárias:
                                                    ${
                                                        quotaDistribution ===
                                                        'uniform'
                                                            ? formatPrice(
                                                                  salesperson.dailyQuota
                                                              )
                                                            : 'Variável'
                                                    }`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider variant="fullWidth" />
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                </>
                            ))}
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Style.PrimaryButton
                        primary
                        onClick={handleClickOnNextPageButton}
                    >
                        Avançar
                    </Style.PrimaryButton>
                    <Style.PrimaryButton
                        onClick={() => {
                            setCurrentPage('CotasMensais');
                        }}
                    >
                        Voltar
                    </Style.PrimaryButton>
                </Grid>
            </Grid>
        </>
    );
}

export default CotasDiarias;
