import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Button, TextField, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Snackbar from '~/util/SnackBar';
import { changeShouldShowNPS } from '../../store/modules/user/actions';
import StarIcon from '@mui/icons-material/Star';

import api from '~/services/api';

const labels = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Regular',
    4: 'Bom',
    5: 'Excelente',
};

export default function HoverRating({ open, onClose }) {
    const [value, setValue] = React.useState();
    const [hover, setHover] = React.useState(-1);
    const [formResponse, setFormResponse] = React.useState('');
    const userProfile = useSelector(state => state.user.profile);
    const dispatch = useDispatch();

    async function handleSubmit() {
        if (!value) {
            Snackbar.error('Por favor selecione uma nota');
        } else {
            try {
                await api.post('/nps', {
                    answer: value,
                    comment: formResponse,
                    isSalesperson: userProfile.isSalesperson,
                    login: userProfile.isSalesperson
                        ? userProfile.salesCode
                        : userProfile.login,
                });
                onClose();
                Snackbar.success('Sua resposta foi enviada com sucesso!');

                dispatch(changeShouldShowNPS(false));
            } catch (error) {
                Snackbar.error('Erro enviando sua avaliação');
                onClose();
            }
        }
    }

    function handleKeyPress(eve) {
        if (eve.key === 'Enter') {
            handleSubmit();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '70vw',
                    padding: '5%'
                },
            }}
            disableScrollLock
        >
            <Typography align="center" variant="h6" style={{ lineHeight: '1' , color: 'white' , fontWeight: '600'}}>
                O que você está achando do +vendas até agora?
            </Typography>
            <div
                style={{
                    justifyContent: 'center',
                    marginTop: '15px',
                    alignSelf: 'center',
                }}
            >
                <Rating
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    onChange={(_, newValue) => {
                        setValue(newValue);
                    }}
                    onChangeActive={(_, newHover) => {
                        setHover(newHover);
                    }}
                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
            </div>
            {value !== null && (
                <Box
                    ml={2}
                    style={{
                        textAlign: 'center',
                        margin: 'unset',
                        marginTop: '5px',
                    }}
                >
                    {labels[hover !== -1 ? hover : value]}
                </Box>
            )}
            <TextField
                id="comment"
                color="primary"
                inputProps={{ maxLength: 250 }}
                multiline
                variant="outlined"
                fullWidth
                autoFocus
                label="Deixe seu comentário"
                value={formResponse}
                onChange={e => setFormResponse(e.target.value)}
                onKeyUp={e => handleKeyPress(e)}
                style={{ marginTop: '20px', width: '1' , color: 'white'}}
            />
            <Button
                onClick={() => handleSubmit()}
                style={{
                    backgroundColor: '#dcc6b7',
                    color: 'black',
                    borderRadius: 'inherit',
                    height: '120%',
                    marginTop: '15px',
                }}
            >
                Enviar
            </Button>
        </Dialog>
    );
}
