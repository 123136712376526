import React from 'react';
import { Grid } from '@mui/material';

// Asset import
import { ReactComponent as OrderList } from '~/assets/icons/orderList.svg';

// Util import
import { availableOrderFilters } from '~/util/storeRankingFilters';

// Style import
import * as Style from './styles';

function RankingListTopPanel({
    orderBy,
    orderByAsc,
    setOrderAsc,
    setRankingData,
}) {
    const getListParams = () => {
        const listParams = availableOrderFilters.find(
            order => order.valueParam === orderBy
        ).label;

        return listParams;
    };

    const handleOrderByAsc = () => {
        if (orderByAsc === 'asc') setOrderAsc('desc');
        else setOrderAsc('asc');
        setRankingData(currentRankingData => currentRankingData.reverse());
    };

    return (
        <Grid
            container
            justifyContent="space-between"
            style={{ padding: '0px 16px' }}
        >
            <Grid item xs={8}>
                <Style.TitleFilter>Nome</Style.TitleFilter>
            </Grid>
            <Grid item xs={4}>
                <Style.PreferenceContainer style={{ justifyContent: 'right' }}>
                    {getListParams()}
                    <OrderList onClick={() => handleOrderByAsc()} />
                </Style.PreferenceContainer>
            </Grid>
        </Grid>
    );
}

export default RankingListTopPanel;
