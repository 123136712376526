import React, { useState } from 'react';
import {
    Typography,
    DialogContent,
    FormControl,
    RadioGroup,
    Radio,
    SvgIcon,
} from '@mui/material';
import { useSelector } from 'react-redux';

// Asset import
import { ReactComponent as FilterAltOutlined } from '~/assets/icons/filter_alt_outlined.svg';
import { ReactComponent as FilterAlt } from '~/assets/icons/filter_alt.svg';

// Util import
import { getBrandNameFromBrandId, FARM, OFFPREMIUM } from '~/util/brands';
import getBrandsFromIdBrand from '~/util/getBrandsFromIdBrand';

import * as Style from '../styles';

function BrandFilter({ brandFilter, setBrandFilter }) {
    const userProfile = useSelector(state => state.user.profile);
    const [brandFilterDialogIsOpen, setBrandFilterDialogIsOpen] = useState(
        false
    );
    const userAllowedBrands = getBrandsFromIdBrand(userProfile);
    const userOwnBrand = getBrandNameFromBrandId(userProfile.marcaFilial);

    const handleClickOnRadioButton = event => {
        setBrandFilter(event.target.value);
        setBrandFilterDialogIsOpen(false);
    };

    return (
        <>
            <Style.DialogContainer
                onClose={() => setBrandFilterDialogIsOpen(false)}
                open={brandFilterDialogIsOpen}
            >
                <Style.BrandFilterTitleContainer container>
                    <SvgIcon
                        style={{
                            fill: '#DCC6B7',
                            verticalAlign: 'text-bottom',
                        }}
                        component={FilterAltOutlined}
                    />
                    <Typography display="inline">Exibir Somente</Typography>
                </Style.BrandFilterTitleContainer>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={brandFilter}
                            onChange={e => handleClickOnRadioButton(e)}
                        >
                            <Style.BrandsFormControlLabel
                                value="allBrands"
                                control={
                                    <Radio
                                        sx={{
                                            color: '#DCC6B7',
                                            '&.Mui-checked': {
                                                color: '#DCC6B7',
                                            },
                                        }}
                                    />
                                }
                                label="Todas as marcas"
                            />
                            {userOwnBrand && (
                                <Style.BrandsFormControlLabel
                                    value={`${userOwnBrand}`}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label={`${userOwnBrand}`}
                                />
                            )}
                            {userAllowedBrands.map(brand => (
                                <Style.BrandsFormControlLabel
                                    value={`${getBrandNameFromBrandId(brand)}`}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label={`${getBrandNameFromBrandId(brand)}`}
                                />
                            ))}
                            {userProfile.marcaFilial === FARM && (
                                <Style.BrandsFormControlLabel
                                    value="farmLatam"
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label="Farm Latam"
                                />
                            )}
                            {userProfile.marcaFilial === OFFPREMIUM && (
                                <Style.BrandsFormControlLabel
                                    value="OffExternal"
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label="Externo"
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Style.DialogContainer>
            <Style.BrandFilterTextContainer
                item
                container
                primary
                onClick={() => {
                    setBrandFilterDialogIsOpen(true);
                }}
            >
                <SvgIcon
                    style={{
                        fill: '#DCC6B7',
                        verticalAlign: 'text-bottom',
                    }}
                    component={
                        brandFilter === 'allBrands'
                            ? FilterAltOutlined
                            : FilterAlt
                    }
                />
                <Style.BrandFilterTextType>
                    {{
                        allBrands: 'Filtrar',
                        otherBrands: 'Não Co...',
                        myBrands: 'Cota',
                    }[brandFilter] || brandFilter}
                </Style.BrandFilterTextType>
            </Style.BrandFilterTextContainer>
        </>
    );
}

export default BrandFilter;
