import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from '@mui/material';

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
    setUseSnackbarRef(useSnackbar());
    return null;
};

let useSnackbarRef;
const setUseSnackbarRef = useSnackbarRefProp => {
    useSnackbarRef = useSnackbarRefProp;
};

const action = (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
        href="#"
        component="button"
        // variant="body2"
        style={{
            fontWeight: 'bold',
            marginRight: '8px',
            color: 'black',
            textDecoration: 'underline',
        }}
        onClick={() => {
            window.open('https://maisvendas.somalabs.com.br/firstAccess');
        }}
    >
        Cadastre-se aqui
    </Link>
);

export const SnackbarUtilsConfigurator = () => {
    return (
        <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    );
};

export const snackBarStyles = {
    success: { backgroundColor: '#1BB55C' },
    error: { backgroundColor: '#E26257' },
    warning: { backgroundColor: '#FBDD00' },
    info: { backgroundColor: '#055998' },
};

export const snackBarClasses = classes => ({
    variantSuccess: classes.success,
    variantError: classes.error,
    variantWarning: classes.warning,
    variantInfo: classes.info,
});

export default {
    success(msg, options) {
        this.toast(msg, { ...options, variant: 'success' });
    },
    warning(msg, options) {
        this.toast(msg, { ...options, variant: 'warning' });
    },
    info(msg, options) {
        this.toast(msg, { ...options, variant: 'info' });
    },
    error(msg, options) {
        this.toast(msg, { ...options, variant: 'error' });
    },
    error2(msg, options) {
        this.toast(msg, { ...options, variant: 'error', action });
    },
    toast(msg, options) {
        useSnackbarRef.enqueueSnackbar(msg, options);
    },
};
