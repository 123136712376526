import {styled} from '@mui/material/styles';
import { Grid } from '@mui/material';

export const Chart= styled(Grid)`
    padding: 5%;
`

export const LateralPanelContainer = styled(Grid)`
    height: fit-content;
    margin: 16px;
    @media screen and (max-width: 600px) {
        margin: 0;
    }
`

export const Container = styled(Grid)`
    justify-content: space-evenly;
    @media screen and (max-width: 600px) {
        justify-content: initial;
    }
`

export const PanelLoadingContainer = styled(Grid)`
    justify-content: center;
    padding-top: 10px;
`