import { Grid, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import React from 'react';
import logoSoma from '~/assets/logo_grupo_soma_final_BRANCO.png';
import * as Style from './styles';

function Welcome({ ...props }) {
    return (
        <>
            <Grid container justifyContent="center">
                <Style.Logo
                    component="img"
                    src={logoSoma}
                    title="Grupo Soma"
                />
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={9}>
                    <Typography align="center" variant="h4">
                        Bem Vindo!
                    </Typography>
                </Grid>
                <Style.MainTextContainer item xs={9}>
                    <Style.MainTextTypography
                        variant="body1"
                    >
                        Mudamos o login pra trazer ainda mais segurança. Se é
                        seu primeiro acesso neste formato, por favor,
                        cadastre-se!
                    </Style.MainTextTypography>
                </Style.MainTextContainer>
                <Grid item xs={12} container justifyContent="space-evenly">
                    <Style.DefaultButton primary > Login</Style.DefaultButton>
                    <Style.DefaultButton
                        onClick={() => props.setCurrentPage('onboard')}
                    >
                        Cadastre-se
                    </Style.DefaultButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Welcome;