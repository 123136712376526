import { takeLatest, call, put, all } from 'redux-saga/effects';
import { signInSuccess, signFailure, signOut } from './actions';
import api, { apiV2 } from '../../../services/api';
import apiDatabaseConnectionLinx from '../../../services/apiConnectionDatabaseLinx';
import history from '../../../services/history';
import SnackBar from '../../../util/SnackBar';
import {
    changeFinalDate,
    changeInitialDate,
    changeDatePeriod,
} from '../status/actions';
import getDefaultDatesByPage from '~/util/getDefaultDatesByPage';

export function* signIn({ payload }) {
    try {
        const { login, senha, isSalesperson, deviceToken } = payload;

        const response = yield call(api.post, 'session', {
            login,
            password: senha,
            isSalesperson,
            deviceToken,
        });

        const { token, user } = response.data; //eslint-disable-line

        apiV2.defaults.headers.Authorization = `Bearer ${token}`;
        const isActive = yield call(
            apiV2.get,
            `/users/check_active?cpf=${user.cpf}&salesCode=${user.users_sales_code[0]?.codigo_vendedor}`
        );

        if (!isActive.data.active) {
            SnackBar.error('Conta desativada');
            yield put(signFailure());
            return;
        }

        if (user.pendente_aprovacao.data[0] === 1) {
            SnackBar.error('Cadastro com aprovação pendente');
            yield put(signFailure());
            return;
        }

        api.defaults.headers.Authorization = `Bearer ${token}`;
        apiDatabaseConnectionLinx.defaults.headers.Authorization = `Bearer ${token}`;
        yield put(signInSuccess(token, user));
        const { initialDate, finalDate, period } = getDefaultDatesByPage(
            user.marcaFilial
        );
        yield put(changeDatePeriod(period));
        yield put(changeInitialDate(initialDate));
        yield put(changeFinalDate(finalDate));

        history.push('/');
    } catch (err) {
        console.log(err);
        if (!err.response) {
            SnackBar.error('Problemas no servidor');
            return;
        }

        SnackBar.error2(err.response.data.error);
        yield put(signFailure());
    }
}

export function* setToken({ payload }) {
    if (!payload) return;

    const { token } = payload.auth;
    const localStorageUser = localStorage.getItem('persist:+vendas');
    if (localStorageUser) {
        const user = JSON.parse(localStorageUser);
        if (user.user) {
            const parsedUser = JSON.parse(user.user);
            if (parsedUser.profile) {
                if (!parsedUser.profile.permissions) {
                    yield put(signOut());
                }
            }
        }
    }

    if (token) {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        apiV2.defaults.headers.Authorization = `Bearer ${token}`;
        apiDatabaseConnectionLinx.defaults.headers.Authorization = `Bearer ${token}`;
    }
}

export function redirect() {
    history.push('/login');
}
export default all([
    takeLatest('persist/REHYDRATE', setToken),
    takeLatest('@auth/SIGN_IN_REQUEST', signIn),
    takeLatest('@auth/SIGN_OUT', redirect),
]);
