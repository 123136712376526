const compareDates = (a, b) => {
    if (a.creationDate > b.creationDate) return -1;
    if (a.creationDate < b.creationDate) return 1;
    return 0;
};

const compareCartsDates = (a, b) => {
    if (a.creation_date > b.creation_date) return -1;
    if (a.creation_date < b.creation_date) return 1;
    return 0;
};

const sortOrdersByDate = (orders, isCartsList) => {
    let newList;

    if (isCartsList) {
        newList = orders.sort(compareCartsDates);
    } else {
        newList = orders.sort(compareDates);
    }

    return newList;
};

export { sortOrdersByDate };
