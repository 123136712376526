import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ESTOQUISTA_SOMA } from '~/util/perfilId';
import { FARM, ANIMALE, OFFPREMIUM } from '~/util/brands';
import AdoroCodigoChallenge from '~/pages/AdoroCodigoChallenge';
import HomePage from '~/pages/MaisCarrinho/pages/HomePage';
import CreateCart from '~/pages/MaisCarrinho/pages/CreateCart';
import DetailCarts from '~/pages/MaisCarrinho/pages/DetailCarts';
import SearchProduct from '~/pages/MaisCarrinho/pages/SearchProduct';
import Route from './Routes';

// Const import
import { ROUTES } from '../consts';

// Access page import
import FirstAccess from '../pages/FirstAccess';
import ForgotPassword from '../pages/ForgotPassword';
import Login from '../pages/Login';
import Logoff from '../pages/Logoff';
import ResetPassword from '../pages/ResetPassword';

// Primary page import
import ConteudoParaPosts from '../pages/ConteudoParaPosts';
import CotaPiloto from '../pages/CotaPiloto';
import OmniOperational from '../pages/OmniOperational';
import StoreCota from '../pages/StoreCota';
import StoreMultimarcaPiloto from '../pages/StoreMultimarcaPiloto';
import StoreOmni from '../pages/StoreOmni';
import StoreOverview from '../pages/StoreOverview';
import StoreOverviewOmni from '../pages/StoreOverviewOmni';
import StoreRanking from '../pages/StoreRanking';
import StoreRankingAffiliates from '../pages/StoreRankingAffiliates';
import VendedoraAdoroCodigo from '../pages/VendedoraAdoroCodigo';
import VendedoraMultimarcaPiloto from '../pages/VendedoraMultimarcaPiloto';
import VendedoraPiloto from '../pages/VendedoraPiloto';
import VendedorCorporativo from '../pages/VendedorCorporativo';
import Vendedores from '../pages/Vendedores';

// Secondary page import
import CadastroDeCotas from '../pages/CadastroDeCotas';
import ConsultarCotas from '../pages/ConsultarCotasEquipe';
import ConsultarCotasVendedor from '../pages/ConsultarCotasVendedor';
import DetailOrders from '../pages/DetailOrders';
import ExtraOrders from '../pages/ExtraOrders';

// Discontinued page import
import ShareEcommerce from '../pages/ShareEcommerce';

export default function Routes() {
    const profile = useSelector(state => state.user.profile);

    return (
        <Switch>
            <Route exact path="/firstAccess" component={FirstAccess} />

            <Route exact path="/forgotPassword" component={ForgotPassword} />

            <Route path="/login" component={Login} />

            <Route exact path="/logoff" component={Logoff} isPrivate />

            <Route
                exact
                path="/resetPassword/:token"
                component={ResetPassword}
            />

            <Route
                path="/adoroCodigoChallenge"
                component={AdoroCodigoChallenge}
                isPrivate
            />

            <Route
                exact
                path="/conteudoParaPosts"
                component={ConteudoParaPosts}
                isPrivate
            />

            <Route path="/cotaPiloto" component={CotaPiloto} isPrivate />

            <Route exact path="/maisCarrinho" component={HomePage} isPrivate />

            <Route
                exact
                path="/maisCarrinho/createCart"
                component={CreateCart}
                isPrivate
            />

            <Route
                exact
                path="/maisCarrinho/detailCarts"
                component={DetailCarts}
                isPrivate
            />

            <Route
                exact
                path="/maisCarrinho/searchProduct"
                component={SearchProduct}
                isPrivate
            />

            <Route
                path="/omniOperational"
                component={OmniOperational}
                isPrivate
                isRestrictedForStandardProfile={
                    !(
                        profile?.id_perfil === ESTOQUISTA_SOMA &&
                        profile?.marcaFilial === FARM
                    )
                }
            />

            <Route
                path="/storeCota"
                component={StoreCota}
                isPrivate
                isRestrictedForStandardProfile
            />

            <Route
                exact
                path="/storeMultimarcaPiloto"
                component={StoreMultimarcaPiloto}
                isPrivate
            />

            <Route
                path="/storeOmni"
                component={StoreOmni}
                isPrivate
                isRestrictedForStandardProfile={
                    !(
                        profile?.id_perfil === ESTOQUISTA_SOMA &&
                        profile?.marcaFilial === FARM
                    )
                }
            />

            <Route
                path="/storeOverview"
                component={
                    profile?.marcaFilial === ANIMALE ||
                    profile?.marcaFilial === OFFPREMIUM
                        ? StoreOverviewOmni
                        : StoreOverview
                }
                isPrivate
                isRestrictedForStandardProfile
            />

            <Route
                path="/storeRanking/:filialCode"
                component={StoreRanking}
                isPrivate
                isRestrictedForStandardProfile
            />

            <Route
                path="/storeRankingAffiliates"
                component={StoreRankingAffiliates}
                isPrivate
                isRestrictedForStandardProfile
            />

            <Route
                path="/vendedoraAdoroCodigo"
                component={VendedoraAdoroCodigo}
                isPrivate
            />

            <Route
                path="/vendedoraMultimarcaPiloto"
                component={VendedoraMultimarcaPiloto}
                isPrivate
            />

            <Route
                path="/vendedoraPiloto"
                component={VendedoraPiloto}
                isPrivate
            />

            <Route
                path="/vendedorCorporativo"
                component={VendedorCorporativo}
                isPrivate
            />

            <Route path="/vendedores" component={Vendedores} isPrivate />

            <Route
                path="/cadastroDeCotas"
                component={CadastroDeCotas}
                isPrivate
            />

            <Route
                path="/consultarCotasEquipe"
                component={ConsultarCotas}
                isPrivate
            />

            <Route
                path="/consultarCotasDiariasVendedor"
                component={ConsultarCotasVendedor}
                isPrivate
            />

            <Route
                path="/detailOrder/:orderId/:salesCode/:orderCreationDate/:packageId?"
                component={DetailOrders}
                isPrivate
            />

            <Route
                path="/extraOrders"
                component={ExtraOrders}
                isPrivate
                isRestrictedForStandardProfile={
                    !(
                        profile?.id_perfil === ESTOQUISTA_SOMA &&
                        profile?.marcaFilial === FARM
                    )
                }
            />

            <Route
                exact
                path={ROUTES.SHARE_ECOMMERCE}
                component={ShareEcommerce}
                isPrivate
            />

            <Redirect to="/login" />
        </Switch>
    );
}
