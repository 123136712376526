import React, { useEffect, useState } from 'react';
import {
    Avatar,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Skeleton from '@mui/material/Skeleton';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChevronRight, LocalPlayOutlined } from '@mui/icons-material';
import moment from 'moment';
import { formatDateStringToText, formatWithCent } from '~/util/format';
import getIconFromOrder from '~/util/getIconFromOrder';
import getPrettyChannelName from '~/util/getPrettyChannelName';
import { CHANNELS, STATUSORDER } from '~/util/channelsAndStatus';
import CardTracking from './Cards/CardTracking';
import api from '../../services/api';
import * as Style from './styles';

function DetailOrders({ match }) {
    const profile = useSelector(state => state.user.profile);
    const history = useHistory();
    const [orderData, setOrderData] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [salespersonInfo, setSalespersonInfo] = useState({});
    const [correspondingOrder, setCorrespondingOrder] = useState({});
    const { orderId, salesCode, orderCreationDate, packageId } = match.params;
    const order = history.location.state?.order || null;

    const getOriginalOrderPackageId = () => {
        if (orderData.idPacote) {
            return `${orderData.idPacote}`;
        }
        return '';
    };

    useEffect(() => {
        const orderDataLength = Object.entries(orderData).length;
        if (orderDataLength) {
            setLoaded(true);
        } else {
            setLoaded(false);
        }
    }, [orderData]);

    useEffect(() => {
        const getSalespersonInfo = async () => {
            setLoaded(false);
            const infoResponse = await api.get(`salesperson/${salesCode}`);
            setSalespersonInfo(infoResponse.data.codigo_filial);
        };
        getSalespersonInfo();
    }, [salesCode]);

    useEffect(() => {
        const getCorrespondingOrder = async orderResponse => {
            const wantedStatus =
                orderResponse.data.prevStatus === STATUSORDER.cancelado
                    ? 'isCaptured'
                    : 'isCancelled';
            
            const addPackageIdToRequest = packageId ? `&idPacote=${packageId}` : '';

            const res = await api.get(
                `salesperson/order-detail?orderId=${orderId}&salesCode=${salesCode}${addPackageIdToRequest}&${wantedStatus}=1`
            );

            setCorrespondingOrder(res.data);
        };

        const getOrderData = async () => {
            setLoaded(false);
            const addPackageIdToRequest = packageId ? `&idPacote=${packageId}` : '';

            const orderResponse = await api.get(
                `salesperson/order-detail?orderId=${orderId}&salesCode=${salesCode}&orderCreationDate=${orderCreationDate}${addPackageIdToRequest}`,
                {
                    params: {
                        omni: !!order,
                        orderOriginal: order?.orderOriginal
                            ? order?.orderOriginal
                            : '',
                    },
                }
            );

            let newOrderData;
            if (salesCode !== 'null') {
                const salespersonInitials = orderResponse.data.salespersonName
                    ?.split(' ')
                    .map((n, i, a) =>
                        i === 0 || i + 1 === a.length ? n[0] : null
                    )
                    .join('');
                newOrderData = {
                    ...orderResponse.data,
                    salespersonInitials,
                };
            } else {
                newOrderData = {
                    ...orderResponse.data,
                };
            }

            if (order?.ultimo_status === STATUSORDER.devolucao) {
                newOrderData.status = STATUSORDER.devolucao;
                newOrderData.channel = CHANNELS.devolucao;
            }

            if (orderResponse?.data.status === STATUSORDER.cancelado)
                getCorrespondingOrder(orderResponse);

            setOrderData(newOrderData);
            const orderResponseDetailed = await api.get(
                `salesperson/order-detail-info`,
                {
                    params: {
                        brandId: orderResponse.data.brandId,
                        clientCpf: orderResponse.data.clientCpf,
                        refIds: orderResponse.data.products.map(
                            product => product.productReference
                        ),
                        billingFilialCode: orderResponse.data.billingFilialCode,
                        orderId: orderResponse.orderId,
                    },
                }
            );
            setOrderData(currentOrderData => ({
                ...currentOrderData,
                billingFilialName: orderResponseDetailed.data.billingFilialName,
                clientName: orderResponseDetailed.data.clientName,
                products: newOrderData.products.map(product => {
                    const matchedProductDetails = orderResponseDetailed.data.products.find(
                        detailedProduct =>
                            detailedProduct.productReference ===
                            product.productReference
                    );
                    return { ...product, ...matchedProductDetails };
                }),
            }));
        };

        getOrderData();
    }, [order, orderCreationDate, orderId, salesCode, packageId]);

    const resizeProductImageUrl = originalUrl => {
        if (originalUrl) {
            const splitOriginalUrl = originalUrl.split('/');
            const IMAGE_SIZE_PARAM_INDEX = 5;
            splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX] = `${
                splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX].split('-')[0]
            }-148-224`;
            const resizedOriginalUrl = splitOriginalUrl.join('/');
            return resizedOriginalUrl;
        }
        return '';
    };

    const getOrderCardTitleFromOrder = order => {
        const titleStringFirst =
            order?.channel === CHANNELS.devolucao ||
            order?.status === STATUSORDER.cancelado
                ? ''
                : 'Venda';
        const titleStringSecond = getPrettyChannelName(order);
        return `${titleStringFirst} ${titleStringSecond}`;
    };

    const formatBillingFilialName = billingFilialName => {
        if (billingFilialName) {
            const lowerCaseBillingFilialName = billingFilialName.toLowerCase();
            if (lowerCaseBillingFilialName.includes('ecommerce')) {
                return 'Centro de Distribuição';
            }
            return lowerCaseBillingFilialName;
        }
        return 'Indisponível';
    };

    const formatProductName = product => {
        if (product.productReference === 'VALE_VALE') return 'Vale Compras';
        return product?.name?.toLowerCase();
    };

    const getPrettyStatusFromOrderStatus = order => {
        if (order?.status === STATUSORDER.capturado) {
            return 'Captado';
        }
        if (order?.channel === CHANNELS.devolucao) {
            return 'Processado';
        }
        return order?.status.toLowerCase();
    };

    const getLabelCardDetails = dataOrder => {
        if (dataOrder.channel === CHANNELS.devolucao) return 'Venda';
        if (dataOrder.status === STATUSORDER.cancelado) return 'Venda Original';
        return 'Captação';
    };

    const getOriginalSaleDate = () => {
        const getDate =
            orderData?.prevStatus === STATUSORDER.cancelado
                ? correspondingOrder?.creationDate
                : orderData?.creationDate;

        return formatDateStringToText(getDate);
    };

    const getCancellationDate = () => {
        const getDate =
            orderData?.prevStatus === STATUSORDER.cancelado
                ? orderData?.creationDate
                : correspondingOrder?.creationDate;

        return formatDateStringToText(getDate);
    };

    return (
        <>
            <Grid
                container
                style={{
                    height: '88px',
                    backgroundColor: '#DCC6B7',
                    position: 'sticky',
                    zIndex: 1,
                    top: 0,
                }}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Grid item>
                    <IconButton
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <ArrowBackIosIcon
                            style={{ color: '#373435', marginLeft: '8px' }}
                        />
                    </IconButton>
                </Grid>
                <Typography
                    style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#373435',
                        fontSize: '20px',
                        fontWeight: '600',
                    }}
                >
                    {orderId}
                </Typography>
            </Grid>
            {loaded ? (
                <Grid
                    container
                    style={{ padding: '16px' }}
                    justifyContent="space-evenly"
                >
                    <Grid
                        container
                        item
                        sm={4}
                        style={{ alignSelf: 'flex-start' }}
                    >
                        <Grid item xs={12} style={{ padding: '16px' }}>
                            <Typography
                                style={{ fontSize: '24px', fontWeight: '600' }}
                            >
                                Detalhes
                            </Typography>
                        </Grid>
                        {profile?.id_perfil >= 6 &&
                            profile?.id_perfil &&
                            salesCode !== 'null' && (
                                <Style.SalesPersonContainer
                                    item
                                    xs={12}
                                    primary={
                                        orderData?.salespersonHasMaisVendasUser &&
                                        salespersonInfo ===
                                            profile.users_branches[0]
                                                ?.codigo_filial
                                    }
                                    container
                                    onClick={() =>
                                        orderData?.salespersonHasMaisVendasUser &&
                                        salespersonInfo ===
                                            profile.users_branches[0]
                                                .codigo_filial
                                            ? history.push(
                                                  `/cotaPiloto?salesCode=${orderData?.salesCode}`
                                              )
                                            : null
                                    }
                                >
                                    <Grid
                                        item
                                        style={{
                                            alignSelf: 'center',
                                            marginRight: '16px',
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                color: '#DCC6B7',
                                                backgroundColor:
                                                    'rgba(0,0,0,0)',
                                                border: '1px solid #DCC6B7',
                                                width: '24px',
                                                height: '24px',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {orderData?.salespersonInitials}
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {orderData?.salespersonName?.toLowerCase()}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                color: '#9E9E9E',
                                            }}
                                        >
                                            {orderData?.salesCode}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            right: '8px',
                                            position: 'absolute',
                                            top: '50%',
                                            transform: 'translate(0px, -50%)',
                                            color:
                                                orderData?.salespersonHasMaisVendasUser &&
                                                salespersonInfo ===
                                                    profile.users_branches[0]
                                                        ?.codigo_filial
                                                    ? 'white'
                                                    : '#757575',
                                        }}
                                    >
                                        <ChevronRight />
                                    </Grid>
                                </Style.SalesPersonContainer>
                            )}
                        <Grid
                            item
                            xs={12}
                            style={{
                                backgroundColor: '#373435',
                                height: '56px',
                                padding: '8px',
                                paddingLeft: '16px',
                                borderRadius: '4px',
                                marginBottom: '16px',
                            }}
                            container
                            alignItems="center"
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    marginRight: '16px',
                                }}
                            >
                                <PersonOutlineOutlinedIcon
                                    style={{
                                        color: '#DCC6B7',
                                        width: '24px',
                                        height: '24px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {orderData?.clientName
                                        ? orderData?.clientName?.toLowerCase()
                                        : 'Nome indisponível'}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: '#9E9E9E',
                                    }}
                                >
                                    {`CPF: ${orderData?.clientCpf?.replace(
                                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                        '$1.$2.$3-$4'
                                    )}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                backgroundColor: '#373435',
                                gap: '20px',
                                padding: '16px',
                                paddingLeft: '16px',
                                borderRadius: '4px',
                                marginBottom: '16px',
                            }}
                            container
                        >
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="space-between"
                            >
                                <Grid
                                    item
                                    container
                                    style={{ width: 'fit-content' }}
                                >
                                    <div
                                        style={{
                                            marginRight: '8px',
                                        }}
                                    >
                                        {getIconFromOrder(orderData)}
                                    </div>

                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                        }}
                                    >
                                        {getOrderCardTitleFromOrder(orderData)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            textAlign: 'end',
                                        }}
                                    >
                                        {order?.ultimo_status ===
                                        STATUSORDER.devolucao
                                            ? `${formatWithCent(
                                                  orderData?.totalValue
                                              )}`
                                            : formatWithCent(
                                                  orderData?.totalValue
                                              )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {orderData?.channel !== CHANNELS.fisico && (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                >
                                    <Grid item container xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {getLabelCardDetails(orderData)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                textAlign: 'end',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {orderData?.status ===
                                            STATUSORDER.cancelado
                                                ? getOriginalSaleDate()
                                                : formatDateStringToText(
                                                      orderData?.creationDate
                                                  )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {orderData?.status === STATUSORDER.cancelado ? (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                >
                                    <Grid item container xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            Data de Cancelamento
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                textAlign: 'end',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {getCancellationDate()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                >
                                    <Grid item container xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {orderData?.channel ===
                                            CHANNELS.devolucao
                                                ? 'Devolução'
                                                : 'Faturamento'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '400',
                                                textAlign: 'end',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {orderData?.invoicedDate &&
                                            orderData?.status !==
                                                STATUSORDER.cancelado
                                                ? formatDateStringToText(
                                                      orderData.invoicedDate
                                                  )
                                                : 'Em processamento'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={12} container alignItems="center">
                                <Grid item container xs={6}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            textAlign: 'end',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {getPrettyStatusFromOrderStatus(
                                            orderData
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {orderData?.billingFilialCode &&
                                orderData?.billingFilialName !==
                                    profile.nomeFilial &&
                                orderData?.status !== STATUSORDER.cancelado && (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        alignItems="flex-start"
                                    >
                                        <Grid item container xs={6}>
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                Enviado Por
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography
                                                style={{
                                                    fontSize: '16px',
                                                    fontWeight: '400',
                                                    textAlign: 'end',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {formatBillingFilialName(
                                                    orderData?.billingFilialName
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                        </Grid>
                        {orderData?.originalOrder && (
                            <>
                                <Style.OriginalOrderContainer
                                    item
                                    xs={12}
                                    container
                                    onClick={() => {
                                        history.push(
                                            `/detailOrder/${
                                                orderData?.originalOrder
                                                    ?.orderId
                                            }/${
                                                orderData?.originalOrder
                                                    ?.salesCode
                                            }/${moment
                                                .utc(
                                                    orderData?.originalOrder
                                                        ?.creationDate
                                                )
                                                .format('YYYY-MM-DD')}/${getOriginalOrderPackageId()}`
                                        );
                                    }}
                                >
                                    <Grid item xs={10}>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                textTransform: 'capitalize',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            Pedido original
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                color: '#9E9E9E',
                                            }}
                                        >
                                            {orderData?.originalOrder?.orderId}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            alignSelf: 'center',
                                        }}
                                        xs={1}
                                    >
                                        <ChevronRight
                                            style={{ color: '#FFFFFF' }}
                                        />
                                    </Grid>
                                </Style.OriginalOrderContainer>
                            </>
                        )}
                        {[
                            CHANNELS.online,
                            CHANNELS.vitrine,
                            CHANNELS.somastore,
                        ].includes(orderData?.channel) &&
                            orderData?.status !== STATUSORDER.cancelado && (
                                <CardTracking orderData={orderData} />
                            )}
                    </Grid>

                    <Grid
                        container
                        item
                        sm={7}
                        style={{ height: 'fit-content' }}
                    >
                        <Grid item xs={12} style={{ padding: '16px' }}>
                            <Typography
                                style={{ fontSize: '24px', fontWeight: '600' }}
                            >
                                Itens
                            </Typography>
                        </Grid>
                        {orderData.products.map((product, index) => (
                            <Grid
                                item
                                xs={12}
                                xl={6}
                                container
                                // eslint-disable-next-line react/no-array-index-key
                                key={`product-item-${product.productReference}-${index}`}
                                style={{ marginBottom: '16px' }}
                            >
                                <Grid
                                    item
                                    style={{
                                        height: '224px',
                                        backgroundColor: '#373435',
                                        borderRadius: '12px',
                                        flexFlow: 'row',
                                    }}
                                    container
                                    justifyContent="flex-start"
                                    xs={12}
                                    xl={11}
                                >
                                    <Grid
                                        item
                                        onClick={() =>
                                            window.open(
                                                product?.ecommerceUrl,
                                                '_blank'
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {// eslint-disable-next-line no-nested-ternary
                                        product.productReference ===
                                        'VALE_VALE' ? (
                                            <Grid
                                                style={{
                                                    width: '148px',
                                                    height: '224px',
                                                    borderRadius:
                                                        '12px 0px 0px 12px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <LocalPlayOutlined
                                                    style={{
                                                        width: '100px',
                                                        height: '200px',
                                                        color: '#DCC6B7',
                                                    }}
                                                />
                                            </Grid>
                                        ) : product?.imageUrl ? (
                                            <img
                                                src={resizeProductImageUrl(
                                                    product?.imageUrl
                                                )}
                                                alt="imagem do produto"
                                                style={{
                                                    width: '148px',
                                                    height: '224px',
                                                    borderRadius:
                                                        '12px 0px 0px 12px',
                                                }}
                                            />
                                        ) : (
                                            <CircularProgress
                                                style={{ margin: '48px' }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={7}
                                        xl={8}
                                        container
                                        direction="column"
                                        justifyContent="space-evenly"
                                        style={{
                                            paddingLeft: '24px',
                                            paddingRight: '8px',
                                        }}
                                    >
                                        {product?.name ? (
                                            <Typography
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '20px',
                                                    textTransform: 'capitalize',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '3',
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {formatProductName(product)}
                                            </Typography>
                                        ) : (
                                            <CircularProgress />
                                        )}

                                        <Typography
                                            style={{
                                                fontWeight: '500',
                                                fontSize: '14px',
                                                color: '#9E9E9E',
                                            }}
                                        >
                                            {product.productReference ===
                                            'VALE_VALE'
                                                ? ''
                                                : `Ref: ${product?.productReference}`}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '20px',
                                            }}
                                        >
                                            {formatWithCent(product?.value)}
                                        </Typography>
                                        {product.productReference ===
                                            'VALE_VALE' || (
                                            <Avatar
                                                style={{
                                                    color: '#757575',
                                                    backgroundColor:
                                                        'rgba(0,0,0,0)',
                                                    border: '1px solid #757575',
                                                    borderRadius: '8px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {product?.productSize}
                                            </Avatar>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        justifyContent="center"
                        style={{ paddingTop: '32px' }}
                    >
                        <Skeleton animation="wave" width="30%" height="32px" />
                        <Skeleton animation="wave" width="90%" height="56px" />
                        <Skeleton animation="wave" width="90%" height="56px" />
                        <Skeleton animation="wave" width="90%" height="184px" />
                        <Skeleton animation="wave" width="90%" height="184px" />
                    </Grid>
                </>
            )}
        </>
    );
}

export default DetailOrders;
