import {styled} from '@mui/material/styles';
import { Grid, Typography, Avatar, Box } from '@mui/material';

export const UserInfoContainer = styled(Grid)`
    height: 152px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: #DCC6B7;
    padding: 16px;
`

export const MenuAvatar = styled(Avatar)`
    background-color: rgba(0,0,0,0);
    color: #373435;
    border: 1px solid #373435;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-family: 'Inter';
`

export const Login = styled(Typography)`
    font-size: 16px;
    color: #373435;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: capitalize;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const SalesCode = styled(Typography)`
    font-size: 12px;
    color: #373435;
    font-weight: 400;
    font-family: 'Inter';
`

export const ListComponent = styled(Box)`
    width: 250px;
    height: 100%;
`