/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import {
    Box,
    CardActions,
    CardContent,
    CircularProgress,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Typography,
    Grid,
    ListItem,
    InputAdornment,
} from '@mui/material';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import * as Style from './styles';
import { formatPrice } from '~/util/format';

const handleInputRegex = currencyString => {
    if (!currencyString) return '';
    currencyString = currencyString.replace(/[^0-9,-]+/g, '');
    return currencyString;
};

function MonthlyQuotaPanel({
    setSalesTeamData,
    salesTeamData,
    loadingSalesTeamData,
    quotaValues,
    isAllQuotaDistributed,
    distributionModeInCurrency,
    setDistributionModeInCurrency,
}) {
    const [equalQuotas, setEqualQuotas] = useState(false);

    useEffect(() => {
        if (equalQuotas) {
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(currentSalesperson => {
                    const equalQuotaValue =
                        quotaValues.quota / currentSalesTeamData.length;
                    const equalPercentageValue =
                        100 / currentSalesTeamData.length;
                    currentSalesperson.quotaValueMonth = equalQuotaValue;
                    currentSalesperson.quotaPercentageMonth = equalPercentageValue;
                    if (distributionModeInCurrency) {
                        currentSalesperson.quotaInputLabel = equalQuotaValue?.toFixed(
                            2
                        );
                    } else {
                        currentSalesperson.quotaInputLabel = equalPercentageValue?.toFixed(
                            2
                        );
                    }
                    return currentSalesperson;
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equalQuotas, quotaValues, setSalesTeamData]);

    useEffect(() => {
        if (distributionModeInCurrency) {
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(currentSalesperson => {
                    currentSalesperson.quotaValueMonth =
                        (currentSalesperson.quotaPercentageMonth / 100) *
                        quotaValues.quota;
                    currentSalesperson.quotaInputLabel = currentSalesperson.quotaValueMonth?.toFixed(
                        2
                    );
                    return currentSalesperson;
                })
            );
        } else {
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(currentSalesperson => {
                    currentSalesperson.quotaPercentageMonth =
                        (currentSalesperson.quotaValueMonth * 100) /
                        quotaValues.quota;
                    currentSalesperson.quotaInputLabel = currentSalesperson.quotaPercentageMonth?.toFixed(
                        2
                    );
                    return currentSalesperson;
                })
            );
        }
    }, [distributionModeInCurrency, quotaValues, setSalesTeamData]);

    const handleChangeOnQuotaValueTextField = (event, salesperson) => {
        const formattedInputValue = handleInputRegex(event.target.value);
        const inputValueToNumber = Number(
            formattedInputValue.replace(',', '.')
        );

        if (distributionModeInCurrency) {
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(currentSalesperson => {
                    if (currentSalesperson.cpf === salesperson.cpf) {
                        currentSalesperson.quotaValueMonth = inputValueToNumber;
                        currentSalesperson.quotaPercentageMonth =
                            (inputValueToNumber * 100) / quotaValues.quota;
                        currentSalesperson.quotaInputLabel = formattedInputValue;
                    }
                    return currentSalesperson;
                })
            );
        } else {
            setSalesTeamData(currentSalesTeamData =>
                currentSalesTeamData.map(currentSalesperson => {
                    if (currentSalesperson.cpf === salesperson.cpf) {
                        currentSalesperson.quotaPercentageMonth = inputValueToNumber;
                        currentSalesperson.quotaValueMonth =
                            (inputValueToNumber / 100) * quotaValues.quota;
                        currentSalesperson.quotaInputLabel = formattedInputValue;
                    }
                    return currentSalesperson;
                })
            );
        }
    };

    const getValueInCurrency = () => {
        return formatPrice(
            quotaValues?.quota -
                Math.round(
                    salesTeamData.reduce(
                        (total, salesperson) =>
                            total + Number(salesperson.quotaValueMonth || 0),
                        0
                    ) * 100
                ) /
                    100
        );
    };

    const getValueInPercent = () => {
        return (
            100 -
            Math.round(
                salesTeamData.reduce(
                    (total, salesperson) =>
                        total + Number(salesperson.quotaPercentageMonth || 0),

                    0
                ) * 100
            ) /
                100
        )
            .toFixed(2)
            .replace('.', ',');
    };

    return (
        <>
            <Box style={{ backgroundColor: '#373435' }}>
                <CardActions
                    style={{ paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={equalQuotas}
                                    onChange={() =>
                                        setEqualQuotas(
                                            currentEqualQuota =>
                                                !currentEqualQuota
                                        )
                                    }
                                />
                            }
                            label="Definir cotas iguais para todos"
                        />
                    </FormGroup>
                </CardActions>
                <Grid item xs={12}>
                    <Divider
                        variant="fullWidth"
                        style={{
                            height: '2px',
                            marginLeft: '16px',
                            marginRight: '16px',
                        }}
                    />
                </Grid>

                <CardActions
                    style={{ paddingLeft: '16px', paddingRight: '16px' }}
                >
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                }}
                            >
                                Modo de distribuição
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                border: '1px solid #DCC6B7',
                                borderRadius: '16px',
                            }}
                        >
                            <Switch
                                checked={distributionModeInCurrency}
                                onChange={() => {
                                    setDistributionModeInCurrency(
                                        currentDistributionModeInCurrency =>
                                            !currentDistributionModeInCurrency
                                    );
                                }}
                                handleDiameter={35}
                                offColor="#373435"
                                onColor="#373435"
                                offHandleColor="#DCC6B7"
                                onHandleColor="#DCC6B7"
                                height={35}
                                width={70}
                                borderRadius={35}
                                activeBoxShadow="0px 0px 1px 1px #373435"
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            fontSize: 15,
                                            color: '#DCC6B7',
                                            paddingRight: 2,
                                        }}
                                    >
                                        R$
                                    </div>
                                }
                                checkedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            color: '#DCC6B7',
                                            fontSize: 20,
                                        }}
                                    >
                                        %
                                    </div>
                                }
                                uncheckedHandleIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            color: '#373435',
                                            fontSize: 20,
                                        }}
                                    >
                                        %
                                    </div>
                                }
                                checkedHandleIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            color: '#373435',
                                            fontSize: 18,
                                        }}
                                    >
                                        R$
                                    </div>
                                }
                                className="reactSwitch"
                                id="small-radius-switch"
                            />
                        </Grid>
                    </Grid>
                </CardActions>

                <CardContent
                    style={{
                        paddingTop: '0px',
                        textAlign: 'center',
                    }}
                >
                    {loadingSalesTeamData ? (
                        <CircularProgress />
                    ) : (
                        <List>
                            {salesTeamData.length === 0 ? (
                                <ListItem
                                    style={{
                                        height: '72px',
                                        borderTop: '1px solid #9E9E9E',
                                        borderBottom: '1px solid #9E9E9E',
                                        placeContent: 'center',
                                    }}
                                >
                                    Sem Vendedores Nessa Filial
                                </ListItem>
                            ) : (
                                <>
                                    {salesTeamData.map(salesperson => (
                                        <ListItem style={{ padding: '0px' }}>
                                            <Grid
                                                container
                                                style={{ marginBottom: '16px' }}
                                            >
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        style={{
                                                            marginBottom: '8px',
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    fontSize:
                                                                        '16px',
                                                                    color:
                                                                        'white',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    overflow:
                                                                        'hidden',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    width:
                                                                        '120px',
                                                                }}
                                                            >
                                                                {salesperson.shortName.toLowerCase()}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid>
                                                                <Style.StyledTextField
                                                                    autoComplete="off"
                                                                    InputProps={{
                                                                        endAdornment: !distributionModeInCurrency && (
                                                                            <InputAdornment position="end">
                                                                                %
                                                                            </InputAdornment>
                                                                        ),
                                                                        startAdornment: distributionModeInCurrency && (
                                                                            <InputAdornment position="start">
                                                                                R$
                                                                            </InputAdornment>
                                                                        ),
                                                                        inputProps: {
                                                                            style: {
                                                                                textAlign:
                                                                                    'right',
                                                                            },
                                                                        },
                                                                    }}
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    value={
                                                                        salesperson?.quotaInputLabel ||
                                                                        ''
                                                                    }
                                                                    onChange={event =>
                                                                        handleChangeOnQuotaValueTextField(
                                                                            event,
                                                                            salesperson
                                                                        )
                                                                    }
                                                                    placeholder={
                                                                        distributionModeInCurrency
                                                                            ? '0,00'
                                                                            : '0'
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider variant="fullWidth" />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                </>
                            )}
                        </List>
                    )}
                    <Grid item container justifyContent="space-between">
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                            display="inline"
                        >
                            Total distribuído:
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                                color: isAllQuotaDistributed
                                    ? '#1BB55C'
                                    : '#E26257',
                            }}
                            display="inline"
                        >
                            {distributionModeInCurrency
                                ? formatPrice(
                                      Math.round(
                                          salesTeamData.reduce(
                                              (total, salesperson) =>
                                                  total +
                                                  Number(
                                                      salesperson.quotaValueMonth ||
                                                          0
                                                  ),
                                              0
                                          ) * 100
                                      ) / 100
                                  )
                                : `${salesTeamData
                                      .reduce(
                                          (total, salesperson) =>
                                              total +
                                              Number(
                                                  salesperson.quotaPercentageMonth ||
                                                      0
                                              ),
                                          0
                                      )
                                      .toFixed(2)
                                      .replace('.', ',')}%`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        style={{ marginTop: '16px' }}
                    >
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                            display="inline"
                        >
                            Total a distribuir:
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                            display="inline"
                        >
                            {distributionModeInCurrency
                                ? getValueInCurrency()
                                : `${getValueInPercent()}%`}
                        </Typography>
                    </Grid>
                </CardContent>
            </Box>
        </>
    );
}

export default MonthlyQuotaPanel;
