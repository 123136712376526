import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const PanelCardContainer = styled(Grid)`
    display: flex;
    flex-direction: column;
    background-color: #373435;
    height: 305px;
    border-radius: 4px;
    padding: 16px;
    color: white;
    font-family: 'Inter';
    width: 22vw;
    @media screen and (max-width: 960px) {
        margin: 0px 16px 0px 16px;
        width: auto;
    }
`;

export const BalanceText = styled(Typography)`
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    font-family: inherit;
`;
