import {styled} from '@mui/material/styles';
import {
    Grid,
    Typography,
} from '@mui/material';

export const Main = styled(Grid)`
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
`

export const BrandContainer = styled(Grid)`
    justify-content: space-between;
    align-items: center;
    height: 112px;
    padding: 0 26px 12px;
    border-radius: 4px;
    background-color: #373435;
`

export const PrimaryTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
`

export const SalesPersonName = styled(Typography)`
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: white;
    width: 100px;
`