import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const PanelCardList = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 14px;
`;

export const PanelCardListKey = styled(Typography)`
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
`;

export const PanelCardListValue = styled(Typography)`
    font-size: 14px;
    font-family: inherit;
    text-align: right;
    color: #dcc6b7;
`;

export const PanelCardListLine = styled('hr')`
    width: 95%;
    border: none;
    border-top: 1px solid #616161;
`;