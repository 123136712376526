import React from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
    CloseButtonContainer,
    Container,
    ModalDataContainer,
    Title,
    Subtitle,
    Icon,
    ActionButton,
} from './styles';

function NewFeatureAlert({
    open,
    onClose,
    title,
    subtitle,
    icon,
    buttonText,
    handleButtonClick,
    size,
    children,
}) {
    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            PaperProps={{
                style: {
                    width:`${size || '328px'}`,
                    borderRadius: '12px',
                },
            }}
        >
            <Container>
                <CloseButtonContainer>
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </CloseButtonContainer>
                <ModalDataContainer>
                    {!!title && <Title>{title}</Title>}
                    {!!icon && <Icon>{icon}</Icon>}
                    {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
                    {children}
                    <ActionButton
                        onClick={() => handleButtonClick()}
                    >{buttonText || 'OK'}</ActionButton>
                </ModalDataContainer>
            </Container>
        </Dialog>
    );
}

export default NewFeatureAlert;
