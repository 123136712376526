import {styled} from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const LateralPanelContainer = styled(Grid)`
    height: fit-content;
    @media screen and (min-width: 960px){
        margin: 16px;
    }
`

export const DatePickerContainer = styled(Box)`
    width: 100%;
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 4;
    background-color: #212121;
`