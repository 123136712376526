import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import SnackBar from '~/util/SnackBar';
import Header from '~/components/Header';

import OrderListPanel from '~/components/OrderListPanel';

import { sortOrdersByDate } from '~/util/sortOrdersByDate';

import api, { maisCarrinhoApi } from '~/services/api';
import * as Style from './styles';

function HomePage() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [carts, setCarts] = useState([]);
    const [hasMoreItems, setHasMoreItems] = useState(1);

    const profile = useSelector(state => state.user.profile);

    const getCarts = useCallback(async () => {
        setLoading(true);
        try {
            const productResponse = await maisCarrinhoApi.get(
                `/saved-cart?vendedorx_code=${profile.users_sales_code[0].codigo_vendedor}&page=${page}&pageSize=10`
            );

            const productResponseData = productResponse.data.data;
            const productResponseMetaData = productResponse.data.metaData;

            setHasMoreItems(productResponseMetaData.hasMore);

            const savedCartsIdsParams = productResponseData.reduce(
                (params, cart) =>
                    `${params}&id_mais_carrinho=${cart.id_saved_cart}`,
                ''
            );

            const cartStatusResponse = await api.get(
                `/mais-carrinho/status?${savedCartsIdsParams}`
            );

            const cartResponseWithStatus = productResponseData.map(cart => {
                let cartStatus = 'PENDENTE';
                if (cartStatusResponse.data) {
                    const cartStatusMatch = cartStatusResponse.data.find(
                        currentCartStatus =>
                            cart.id_saved_cart ===
                            currentCartStatus.id_mais_carrinho
                    );
                    if (
                        cartStatusMatch &&
                        ['CAPTURADO', 'FATURADO'].includes(
                            cartStatusMatch.status
                        )
                    ) {
                        cartStatus = 'FINALIZADO';
                    }
                }
                return { ...cart, status: cartStatus };
            });

            const sortedCarts = sortOrdersByDate(cartResponseWithStatus, true);

            setCarts(prevCarts => [...prevCarts, ...sortedCarts]);
        } catch (err) {
            SnackBar.error('Erro ao carregar carrinhos');
        }

        setLoading(false);
    }, [page, profile.users_sales_code, setCarts]);

    useEffect(() => {
        getCarts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, getCarts]);

    const handleScroll = useCallback(() => {
        const windowHeight = window.innerHeight;
        const { scrollHeight } = document.documentElement;
        const { scrollTop } = document.documentElement;
        if (windowHeight + scrollTop >= scrollHeight) {
            if (!loading && hasMoreItems) {
                setLoading(true);
                setPage(page + 1);
            }
        }
    }, [hasMoreItems, loading, page]);

    const handleTouchMove = useCallback(() => {
        const windowHeight = window.innerHeight;
        const { scrollHeight } = document.documentElement;
        const { scrollTop } = document.documentElement;
        if (windowHeight + scrollTop >= scrollHeight) {
            if (!loading && hasMoreItems) {
                setLoading(true);
                setPage(page + 1);
            }
        }
    }, [hasMoreItems, loading, page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [handleScroll, handleTouchMove, loading, page]);

    return (
        <>
            <Header />
            <>
                <Grid container justifyContent="center">
                    <Style.Root item sm={6} container>
                        <Grid item xs={12} style={{ marginBottom: '90px' }}>
                            <OrderListPanel
                                orders={carts}
                                setCurrentPage={selectedCart =>
                                    history.push({
                                        pathname: '/maisCarrinho/detailCarts',
                                        state: { selectedCart },
                                    })
                                }
                                cartsLoaded={!loading}
                                ordersLoaded={true}
                            />
                        </Grid>

                        <Style.LowerPanelContainer item xs={12}>
                            <Style.ButtonComponent
                                onClick={() => {
                                    history.push('/maisCarrinho/createCart');
                                }}
                            >
                                Novo Carrinho
                            </Style.ButtonComponent>
                        </Style.LowerPanelContainer>
                    </Style.Root>
                </Grid>
            </>
        </>
    );
}

export default HomePage;
