import { styled } from '@mui/material/styles';
import { Grid, MobileStepper, Box, Typography } from '@mui/material';

export const PanelContainer = styled(Box)`
    height: fit-content;
    width: 100%;
    padding: 24px 26px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    margin: 16px;
    background-color: #373435;
    border-radius: 4px;
    justify-content: flex-start;
    align-items: baseline;

    @media screen and (min-width: 960px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.75rem;
    }
`;

export const CardTitle = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    @media screen and (max-width: 960px) {
        margin-bottom: 40px;
    }
`;

export const MainText = styled(Box)`
    font-weight: 600;
    color: white;
    font-size: ${props => (props.primary ? '20px' : '34px')};

    @media screen and (min-width: 960px) {
        color: ${props => (props.primary ? '#dcc6b7' : 'white')};
    }
`;

export const BottomContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 960px) {
        align-items: flex-start;
        height: 100%;
        width: fit-content;
    }

    div:first-child,
    div:nth-child(2) {
        @media screen and (max-width: 960px) {
            display: none;
        }
    }
`;

export const VerticalSeparator = styled(Box)`
    height: 100px;
    margin: 0 20px;
    border-left: 1px solid #dcc6b7;
`;

export const IndicatorContainer = styled(Box)`
    max-width: 150px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 100%;

    @media screen and (min-width: 960px) {
        max-width: 100%;
        height: 90px;
    }
`;

export const Subtitles = styled(Typography)`
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    color: #dcc6b7;
    align-items: left;
    text-align: left;
`;

export const IndicatorText = styled(Typography)`
    font-weight: 600;
    font-size: 24px;
`;

export const StyledStepper = styled(MobileStepper)`
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    @media screen and (min-width: 600px) {
        display: none;
    }
`;

export const SkeletonContainer = styled(Grid)`
    padding: 5% 0%;
    display: ${props => props.primary || 'none'};
    justify-content: center;
    @media screen and (max-width: 960px) {
        padding: 0;
        display: ${props => props.primary && 'none'};
    }
`;

export const InvoiceNumberContainer = styled(Box)`
    width: 100%;
    margin-bottom: 32px;
    @media screen and (min-width: 960px) {
        display: none;
    }
`;
