import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const TitleTypography = styled(Typography)`
    font-weight: 600;
    font-family: inherit;
    font-size: 16px;
    color: ${props => (props.negativeValue ? '#E26257' : 'white')};
    text-align: right;
`;

export const TotalValueContainer = styled(Grid)`
    justify-content: space-between;
    padding: 16px 16px 0px 16px;
`;
