import React, { useState } from 'react';
import {
    MenuItem,
    Grid,
    InputAdornment,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { formatWithCent } from '~/util/format';

import * as Style from './styles';

function MaisCarrinhoProductCard({
    index,
    product,
    resizeProductImageUrl,
    addProductToCart,
}) {
    const [selectedSize, setSelectedSize] = useState(null);

    const sendAddProductToGTM = () => {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'BRL',
                'add': {
                    'products': [{
                        'name': `${product?.productName}`,
                        'id': `${product?.productId}`,
                        'price': `${product?.items[0]?.sellers[0]?.commertialOffer?.Price}`,
                        'brand': `${product?.brand}`,
                        'quantity': '1',
                        'category': `${product?.categoryId}`,
                    }]
                }
            }
        })
    }

    return (
        <>
            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                // eslint-disable-next-line react/no-array-index-key
                key={`product-item-${product.productReference}-${index}`}
                style={{ marginBottom: '16px' }}
            >
                <Grid
                    item
                    style={{
                        height: '218px',
                        backgroundColor: '#373435',
                        borderRadius: '12px',
                        flexFlow: 'row',
                    }}
                    container
                    justifyContent="flex-start"
                    xs={12}
                >
                    <Grid item style={{ cursor: 'pointer' }}>
                        <img
                            src={resizeProductImageUrl(
                                product?.items[0].images[0].imageUrl
                            )}
                            alt="imagem do produto"
                            style={{
                                width: '144px',
                                height: '218px',
                                borderRadius: '12px 0px 0px 12px',
                            }}
                        />
                    </Grid>
                    {window.innerWidth > 350 ? (
                        <Grid
                            item
                            xs={7}
                            container
                            direction="column"
                            justifyContent="space-between"
                            style={{
                                padding: '8px 8px 8px 16px',
                            }}
                        >
                            <Style.PrimaryTypography inputSize='20px'>
                                {product?.productName?.toLowerCase()}
                            </Style.PrimaryTypography>

                            <Style.SecondaryTypography>
                                Ref: {product?.productReference}
                            </Style.SecondaryTypography>

                            <Style.PrimaryTypography inputSize='20px'>
                                {formatWithCent(
                                    product?.items[0].sellers[0].commertialOffer
                                        .Price
                                )}
                            </Style.PrimaryTypography>
                            {/* ----------------------------------------------------------------------- */}
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={7}>
                                    <Style.CssTextField
                                        select
                                        label="Tamanho"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end" />
                                            ),
                                        }}
                                        onChange={e => {
                                            setSelectedSize(e.target.value);
                                        }}
                                        variant="outlined"
                                    >
                                        {product.items
                                            .filter(
                                                item =>
                                                    item.sellers[0]
                                                        .commertialOffer
                                                        .AvailableQuantity !== 0
                                            )
                                            .map(options => (
                                                <MenuItem value={options.itemId}>
                                                    {options.Tamanho}
                                                </MenuItem>
                                            ))}
                                    </Style.CssTextField>
                                </Grid>
                                <Grid item xs={4}>
                                    {selectedSize ? (
                                        <Style.IconButtonComponent
                                            primary
                                            onClick={() => {
                                                addProductToCart(selectedSize);
                                                sendAddProductToGTM();
                                            }}
                                        >
                                            <AddCircleOutlineOutlinedIcon
                                                style={{
                                                    color: '#FFFFFF',
                                                }}
                                            />
                                        </Style.IconButtonComponent>
                                    ) : (
                                        <Style.IconButtonComponent disabled>
                                            <AddCircleOutlineOutlinedIcon
                                                style={{
                                                    color: '#757575',
                                                }}
                                            />
                                        </Style.IconButtonComponent>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            xs={7}
                            container
                            direction="column"
                            justifyContent="space-evenly"
                            style={{
                                padding: '0px 8px',
                            }}
                        >
                            <Style.PrimaryTypography inputSize='18px'>
                                {product?.productName?.toLowerCase()}
                            </Style.PrimaryTypography>

                            <Style.SecondaryTypography inputSize='12px'>
                                Ref: {product?.productReference.slice(0, 10)}
                            </Style.SecondaryTypography>

                            <Style.SecondaryTypography inputSize='12px'>
                                {product?.productReference.slice(
                                    10,
                                    product?.productReference.length
                                )}
                            </Style.SecondaryTypography>

                            <Style.PrimaryTypography inputSize='16px'>
                                {formatWithCent(
                                    product?.items[0].sellers[0].commertialOffer
                                        .Price
                                )}
                            </Style.PrimaryTypography>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={7}>
                                    <Style.CssTextField
                                        select
                                        label="Tamanho"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end" />
                                            ),
                                        }}
                                        onChange={e => {
                                            setSelectedSize(e.target.value);
                                        }}
                                        variant="outlined"
                                    >
                                        {product.items
                                            .filter(
                                                item =>
                                                    item.sellers[0]
                                                        .commertialOffer
                                                        .AvailableQuantity !== 0
                                            )
                                            .map(options => (
                                                <MenuItem value={options.itemId}>
                                                    {options.Tamanho}
                                                </MenuItem>
                                            ))}
                                    </Style.CssTextField>
                                </Grid>
                                <Grid item xs={4} style={{ marginLeft: '8px' }}>
                                    {selectedSize ? (
                                        <Style.IconButtonComponent
                                            primary
                                            onClick={() => {
                                                addProductToCart(selectedSize);
                                                sendAddProductToGTM();
                                            }}
                                        >
                                            <AddCircleOutlineOutlinedIcon
                                                style={{
                                                    size: 5,
                                                    color: '#FFFFFF',
                                                }}
                                            />
                                        </Style.IconButtonComponent>
                                    ) : (
                                        <Style.IconButtonComponent>
                                            <AddCircleOutlineOutlinedIcon
                                                style={{
                                                    color: '#757575',
                                                }}
                                            />
                                        </Style.IconButtonComponent>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default MaisCarrinhoProductCard;