import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import DateRangePicker from '~/components/DateRangePicker';
import Header from '~/components/Header';
import NPSForm from '~/components/NPSForm';
import SalesTerms from '~/components/SalesTerms';
import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import {
    onlineChannels,
    physicalChannels,
} from '~/util/mapChannelsPhysicalAndOnline';
import {
    formatPrice,
    formatWithCent,
    formatCurrecyStringToNumberString,
    formatDecimalToPercentage,
} from '~/util/format';
import { shouldRemoveExternalProducts } from '~/util/functions';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';
import getDateRange from '~/util/getDateRange';
import { changeShouldShowNPS } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';

function StoreCota() {
    const dispatch = useDispatch();
    const [aggregatedCapturedOrders, setAggregatedCapturedOrders] = useState(0);
    const [capturedOrdersIndicators, setCapturedOrdersIndicators] = useState(
        {}
    );
    const [totalOrdersIndicators, setTotalOrdersIndicators] = useState({});
    const [indicatorsText, setIndicatorsText] = useState({});
    const [quota, setQuota] = useState({});
    const [salesPercentage, setSalesPercentage] = useState('');
    const [salesValueAsNumber, setSalesValueAsNumber] = useState(0);
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [balanceText, setBalanceText] = useState(0);
    const [panelLoaded, setPanelLoaded] = useState(false);

    const [chartData, setChartData] = useState([]);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [brandFilter, setBrandFilter] = useState('myBrands');

    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate } = useSelector(state => state.status);
    const dateRange = getDateRange(initialDate, finalDate, 'DD/MM/YYYY');

    const [aggregatedOrdersByPeriod, setAggregatedOrdersByPeriod] = useState(
        []
    );

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });

            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const isFarm = userProfile?.marcaFilial === 2;
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;

        if (shouldAnswerNPS) {
            if (!(beforeDateLimit && isFarm)) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const newIndicatorsText = {
            totalValue: 0,
            totalAverageValue: 0,
            totalOrderQuantity: 0,
            totalProductQuantity: 0,
            totalAverageQuantity: 0,

            onlineTotalValue: 0,
            onlineAverageValue: 0,
            onlineAverageQuantity: 0,
            onlineOrderQuantity: 0,
            onlineProductQuantity: 0,

            physicalTotalValue: 0,
            physicalAverageValue: 0,
            physicalAverageQuantity: 0,
            physicalOrderQuantity: 0,
            physicalProductQuantity: 0,
        };
        const ordersIndicators = capturedOrdersIndicators;

        Object.entries(ordersIndicators).forEach(
            ([indicatorChannel, indicatorValue]) => {
                if (onlineChannels.includes(indicatorChannel)) {
                    newIndicatorsText.onlineTotalValue += indicatorValue.value;
                    newIndicatorsText.onlineOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.onlineProductQuantity +=
                        indicatorValue.productQuantity;
                }
                if (physicalChannels.includes(indicatorChannel)) {
                    newIndicatorsText.physicalTotalValue +=
                        indicatorValue.value;
                    newIndicatorsText.physicalOrderQuantity +=
                        indicatorValue.serviceQuantity;
                    newIndicatorsText.physicalProductQuantity +=
                        indicatorValue.productQuantity;
                }
            }
        );

        newIndicatorsText.onlineAverageQuantity = (
            newIndicatorsText.onlineProductQuantity /
                newIndicatorsText.onlineOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.onlineAverageValue =
            newIndicatorsText.onlineTotalValue /
                newIndicatorsText.onlineOrderQuantity || 0;

        newIndicatorsText.physicalAverageQuantity = (
            newIndicatorsText.physicalProductQuantity /
                newIndicatorsText.physicalOrderQuantity || 0
        ).toFixed(2);

        newIndicatorsText.physicalAverageValue =
            newIndicatorsText.physicalTotalValue /
                newIndicatorsText.physicalOrderQuantity || 0;

        if (totalOrdersIndicators) {
            if (
                userProfile &&
                Object.keys(totalOrdersIndicators).length !== 0
            ) {
                const totalIndicators =
                    totalOrdersIndicators[userProfile.marcaFilial];

                newIndicatorsText.totalAverageValue =
                    totalIndicators.value / totalIndicators.serviceQuantity;
                newIndicatorsText.totalAverageQuantity = (
                    totalIndicators.productQuantity /
                    totalIndicators.serviceQuantity
                ).toFixed(2);
                newIndicatorsText.totalValue = totalIndicators.value;
                newIndicatorsText.totalOrderQuantity =
                    totalIndicators.serviceQuantity;
                newIndicatorsText.totalProductQuantity =
                    totalIndicators.productQuantity;
            }
        }

        const canFormat = [
            'onlineTotalValue',
            'physicalTotalValue',
            'totalValue',
            'totalAverageValue',
            'physicalAverageValue',
            'onlineAverageValue',
        ];

        Object.keys(newIndicatorsText).forEach(key => {
            if (canFormat.includes(key)) {
                newIndicatorsText[key] = formatWithCent(newIndicatorsText[key]);
            }
        });

        setIndicatorsText(newIndicatorsText);
    }, [capturedOrdersIndicators, totalOrdersIndicators, userProfile]);

    useEffect(() => {
        if (aggregatedCapturedOrders && aggregatedCapturedOrders[0] === '-') {
            return setSalesPercentage('0%');
        }
        const salesValue = formatCurrecyStringToNumberString(
            aggregatedCapturedOrders
        );
        const quotaValue = formatCurrecyStringToNumberString(quota.totalQuota);
        const newSalesPercentage = formatDecimalToPercentage(
            Number(salesValue) / Number(quotaValue)
        );
        setSalesPercentage(newSalesPercentage);
        setSalesValueAsNumber(Number(salesValue));
    }, [aggregatedCapturedOrders, quota.totalQuota]);

    const getBrandsParamsForRequests = useCallback(() => {
        const allBrandsIds = Array.from(
            { length: 16 },
            (_, brandId) => brandId + 1
        );

        const myBrands = userProfile.users_brands.map(
            brandObj => brandObj.id_marca
        );

        switch (brandFilter) {
            case 'allBrands':
                return [];
            case 'myBrands':
                return myBrands;
            case 'otherBrands':
                return allBrandsIds.filter(
                    allBrandsId => !myBrands.includes(allBrandsId)
                );
            default:
                return [];
        }
    }, [brandFilter, userProfile]);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setOrders([]);
            setPanelLoaded(false);
            setOrdersLoaded(false);

            const getSalespersonQuota = async () => {
                const response = await api.get(`store/quota`, {
                    params: {
                        startDate: moment(initialDate).format('YYYY-MM-DD'),
                        endDate: moment(finalDate).format('YYYY-MM-DD'),
                        filialCode:
                            userProfile.users_branches[0].codigo_filial
                                .length === 3
                                ? `000${userProfile.users_branches[0].codigo_filial}`
                                : userProfile.users_branches[0].codigo_filial,
                        brand: userProfile.marcaFilial,
                        farmLatam: 'exclude'
                    },
                });
                const newSalespersonQuota = {
                    ...response.data,
                    totalQuotaAsNumber: response.data.totalQuota,
                    totalQuota: formatPrice(response.data.totalQuota),
                };
                setQuota(newSalespersonQuota);
            };
            const getAggregatedCapturedOrders = async () => {
                try {
                    const response = await api.get(`/store/aggregated-orders`, {
                        params: {
                            startDate: moment(initialDate).format('YYYY-MM-DD'),
                            endDate: moment(finalDate).format('YYYY-MM-DD'),
                            filialCode:
                                userProfile.users_branches[0].codigo_filial
                                    .length === 3
                                    ? `000${userProfile.users_branches[0].codigo_filial}`
                                    : userProfile.users_branches[0]
                                          .codigo_filial,
                            filialBrandId: userProfile.marcaFilial,
                            removeExternalProducts: shouldRemoveExternalProducts(
                                userProfile.marcaFilial
                            ),
                            farmLatam: 'exclude'
                        },
                    });
                    // console.log(response.data.msg);
                    setAggregatedCapturedOrders(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setBalanceText(
                        formatPrice(
                            response.data.data.total.value +
                                response.data.data.returns.value
                        )
                    );
                    setCapturedOrdersIndicators(
                        response.data.data.aggregatedByChannel
                    );

                    setTotalOrdersIndicators(
                        response.data.data.aggregatedByBrand
                    );
                    setChartData(
                        getDateRange(initialDate, finalDate, 'YYYY-MM-DD').map(
                            date => ({
                                value:
                                    response.data.data.aggregatedByDay[
                                        date.label
                                    ]?.value,
                                label: date.formattedLabel,
                            })
                        )
                    );
                    setAggregatedOrdersByPeriod(response.data.periodSales);

                    if (response.data.msg) {
                        Snackbar.info(response.data.msg);
                    }
                } catch {
                    Snackbar.error(
                        'Erro retornando os indicadores de pedidos capturados'
                    );
                }
            };
            const getSalespersonOrders = async () => {
                try {
                    const response = await api.get(`store/orders-list`, {
                        params: {
                            startDate: moment(initialDate).format('YYYY-MM-DD'),
                            endDate: moment(finalDate).format('YYYY-MM-DD'),
                            codigo_filial:
                                userProfile.users_branches[0].codigo_filial
                                    .length === 3
                                    ? `000${userProfile.users_branches[0].codigo_filial}`
                                    : userProfile.users_branches[0]
                                          .codigo_filial,
                            brands: userProfile.marcaFilial,
                            removeExternalProducts: shouldRemoveExternalProducts(
                                userProfile.marcaFilial
                            ),
                            farmLatam: 'exclude'
                        },
                    });

                    const formattedOrderData = await handleOrdersResponse(
                        response.data.data,
                        false
                    );

                    setOrders(formattedOrderData);
                } catch {
                    Snackbar.error('Erro retornando a lista de pedidos');
                    setOrders([]);
                }
            };

            Promise.all([
                getAggregatedCapturedOrders(),
                getSalespersonQuota(),
                getSalespersonOrders(),
            ])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => {
                    setPanelLoaded(true);
                    setOrdersLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        getBrandsParamsForRequests,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            <Header />

            <DateRangePicker hidden={!panelLoaded} assessment />

            {panelLoaded || (
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Grid>
            )}
            <DesktopLayout
                salesNumber={salesValueAsNumber}
                balanceText={balanceText}
                quota={quota}
                salesPercentage={salesPercentage}
                indicatorsText={indicatorsText}
                panelLoaded={panelLoaded}
                ordersLoaded={ordersLoaded}
                orders={orders}
                aggregatedOrdersByPeriod={aggregatedOrdersByPeriod}
                brandFilter={brandFilter}
                setBrandFilter={setBrandFilter}
                chartData={chartData}
                dateRange={dateRange}
            />
        </>
    );
}

export default StoreCota;
