import React from 'react';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { formatDateStringToText, formatWithCent } from '~/util/format';

import * as Style from './styles';

function CartData({ updatedSelectedCart, amount }) {
    const getIconFromCart = cart => {
        switch (cart?.status) {
            case 'PENDENTE':
                return (
                    <AccessTimeOutlinedIcon
                        style={{
                            color: '#DCC6B7',
                        }}
                    />
                );
            case 'FINALIZADO':
                return (
                    <CheckCircleOutlinedIcon
                        style={{
                            color: '#1BB55C',
                        }}
                    />
                );
            default:
        }
    };

    return (
        <Style.InnerTopContainer>
            <Style.PrimaryDataDiv primary>
                <Style.TitleContainer>
                    <ShoppingCartOutlinedIcon
                        style={{
                            color: '#DCC6B7',
                        }}
                    />

                    <Style.MediumTypography>Total</Style.MediumTypography>
                </Style.TitleContainer>
                <Style.MediumTypography primary>
                    {formatWithCent(amount)}
                </Style.MediumTypography>
            </Style.PrimaryDataDiv>
            <Style.PrimaryDataDiv>
                <Style.MediumTypography lightFont>
                    Criação
                </Style.MediumTypography>
                <Style.MediumTypography lightFont primary>
                    {formatDateStringToText(updatedSelectedCart?.creation_date)}
                </Style.MediumTypography>
            </Style.PrimaryDataDiv>
            <Style.PrimaryDataDiv>
                <Style.MediumTypography lightFont>
                    Status
                </Style.MediumTypography>
                <Style.CartStatusContainer>
                    <Style.MediumTypography lightFont primary>
                        {getIconFromCart(updatedSelectedCart)}
                    </Style.MediumTypography>
                    <Style.MediumTypography lightFont primary>
                        {updatedSelectedCart?.status}
                    </Style.MediumTypography>
                </Style.CartStatusContainer>
            </Style.PrimaryDataDiv>
        </Style.InnerTopContainer>
    );
}

export default CartData;
