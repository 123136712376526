import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const TitleFilter = styled(Typography)`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: ${props => props.primary && '24px'};
`;

export const PreferenceContainer = styled(Grid)`
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
`;
