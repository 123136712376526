import {styled} from '@mui/material/styles';
import {
    TextField
} from '@mui/material';

export const StyledTextField = styled(TextField)`
    color: white;
    font-family: 'Inter';
    border-color: white;
    width: 150px;
    & .MuiInputBase-root {
        text-align: right;
    };
`