import React from "react";
import { Box } from "@mui/material";
import * as Style from './styles';
import logoMaisVendas from '~/assets/logo_mais_vendas.png';

const RedirectModal = ({ isOpen, onClose }) => {
  const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/i.test(navigator.userAgent);
  const somaBoxAndroidUrl = "https://play.google.com/store/apps/details?id=app.somalabs.somabox"
  const somaBoxIOSUrl = "https://apps.apple.com/br/app/somabox/id1636403657"

  const getAppStoreUrl = () => {
    if (isIOS) return somaBoxIOSUrl;
    if (isAndroid) return somaBoxAndroidUrl;
    return somaBoxAndroidUrl; // Default to Android if unable to determine
  };

  const handleRedirect = () => {
    const url = getAppStoreUrl();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Style.StyledDialog open={isOpen} onClose={onClose}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Style.Logo
        component="img"
        src={logoMaisVendas}
        title="Grupo Soma"
      />
      </Box>
      <Style.StyledDialogContent dividers>
        <Style.StyledTypography gutterBottom>
        Agora vocês possuem o aplicativo <strong>SomaBox</strong>, para continuar realizando seus atendimentos!
        </Style.StyledTypography>
        <Style.StyledTypography gutterBottom>
        Abaixo os links do Somabox para você baixar.
        </Style.StyledTypography>
      </Style.StyledDialogContent>
      <Box >
        <Style.StyledButton
          variant="contained"
          onClick={handleRedirect}
        >
          BAIXAR O SOMABOX
        </Style.StyledButton>
        <Style.StyledSecondaryButton variant="outlined" onClick={onClose} sx={{ mt: 2 }}>
          FAZER ISSO MAIS TARDE
        </Style.StyledSecondaryButton>
      </Box>
    </Style.StyledDialog>
  );
};

export default RedirectModal;
