const pagesBackgroundColor = [
    {
        color: '#212121',
        pages: [
            'adoroCodigoChallenge',
            'cotaPiloto',
            'vendedoraPiloto',
            'vendedoraMultimarcaPiloto',
            'vendedoraAdoroCodigo',
            'detailOrder',
            'storeRanking',
            'storeCota',
            'omniOperational',
            'extraOrders',
            'storeOmni',
            'storeOverview',
            'maisCarrinho',
            'storeMultimarcaPiloto',
            'storeRankingAffiliates',
            'storeMultimarca',
            'conteudoParaPosts',
            'vendedorCorporativo',
            'vendedores',
            'cadastroDeCotas',
            'consultarCotasEquipe',
            'consultarCotasDiariasVendedor',
        ],
    },
    {
        color: '#FFFFFF',
        pages: ['shareEcommerce'],
    },
];

const defaultColor = '#000000';

const getPageBackgroundColorByUrl = url => {
    const backgroundColor = pagesBackgroundColor.find(colorObj => {
        if (colorObj.pages.includes(url)) {
            return true;
        }
        return false;
    });
    return backgroundColor?.color || defaultColor;
};

export default getPageBackgroundColorByUrl;
