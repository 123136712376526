import 'moment/locale/pt-br';
import React, { useState } from 'react';

import LoginInsert from './LoginInsert';
import SuccessPage from './SuccessPage';

function ResetPassword({ match }) {
    const [currentPage, setCurrentPage] = useState('loginInsert');
    const [userData, setUserData] = useState(null);
    const { token } = match.params;

    const getCurrentStepPage = () => {
        switch (currentPage) {
            case 'loginInsert':
                return (
                    <LoginInsert
                        setCurrentPage={setCurrentPage}
                        setUserData={setUserData}
                        userData={userData}
                        token={token}
                    />
                );
            case 'successPage':
                return (
                    <SuccessPage
                        setCurrentPage={setCurrentPage}
                        setUserData={setUserData}
                    />
                );
            default:
                return (
                    <LoginInsert
                        setCurrentPage={setCurrentPage}
                        setUserData={setUserData}
                    />
                );
        }
    };
    return <>{getCurrentStepPage()}</>;
}

export default ResetPassword;
