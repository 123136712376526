import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';

// Import local components
import { TREINADOR_ADORO_CODIGO } from '~/util/perfilId';
import RankingFilter from './RankingFilter';
import StoreRankingHeader from './StoreRankingHeader';
import RankingTotalValue from './RankingTotalValue';
import TextFieldHeader from './TextFieldHeader';
import RankingList from './RankingList';

// Component import
import Header from '../Header';
import DateRangePicker from '../DateRangePicker';

// Util import

import * as Style from './styles';

function StoreRankingPanel({
    loadingRankingData,
    rankingData,
    setRankingData,
    orderBy,
    setOrderBy,
    orderByAsc,
    setOrderAsc,
    channelFilter,
    setChannelFilter,
    totalValue,
    initialRankingData,
}) {
    // Hooks
    const location = useLocation();

    // Local states
    const [channelFilterDialogIsOpen, setChannelFilterDialogIsOpen] = useState(
        false
    );

    const profile = useSelector(state => state.user.profile);

    const currentLocation = location.pathname.split('/')[1];

    return (
        <>
            <Header />
            <DateRangePicker
                hidden={loadingRankingData}
                assessment={
                    currentLocation === 'storeRanking' ||
                    profile?.id_perfil === TREINADOR_ADORO_CODIGO
                }
            />
            <RankingFilter
                channelFilterDialogIsOpen={channelFilterDialogIsOpen}
                setChannelFilterDialogIsOpen={setChannelFilterDialogIsOpen}
                channelFilter={channelFilter}
                setChannelFilter={setChannelFilter}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                orderByAsc={orderByAsc}
                setRankingData={setRankingData}
            />
            <Grid container>
                {currentLocation !== 'storeRanking' && (
                    <RankingTotalValue totalValue={totalValue} />
                )}
                <Grid item xs={12} style={{ margin: '16px' }}>
                    <Style.CardContainer>
                        {currentLocation === 'storeRanking' ? (
                            <StoreRankingHeader
                                orderBy={orderBy}
                                orderByAsc={orderByAsc}
                                setOrderAsc={setOrderAsc}
                                channelFilter={channelFilter}
                                setChannelFilterDialogIsOpen={
                                    setChannelFilterDialogIsOpen
                                }
                                setRankingData={setRankingData}
                            />
                        ) : (
                            <TextFieldHeader
                                initialRankingData={initialRankingData}
                                setRankingData={setRankingData}
                            />
                        )}
                        <RankingList
                            loadingRankingData={loadingRankingData}
                            rankingData={rankingData}
                            orderBy={orderBy}
                            channelFilter={channelFilter}
                        />
                    </Style.CardContainer>
                </Grid>
            </Grid>
        </>
    );
}

export default StoreRankingPanel;
