import * as moment from 'moment';

const getDateRange = (initialDate, finalDate, dateFormat) => {
    const newDateRange = [];

    const currentDate = moment(initialDate);

    while (currentDate.isSameOrBefore(finalDate, 'day')) {
        newDateRange.push({
            label: moment(currentDate).format(dateFormat),
            formattedLabel: moment(currentDate).format('DD/MMM'),
        });
        currentDate.add(1, 'days');
    }
    return newDateRange;
};

export default getDateRange;
