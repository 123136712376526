import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import 'moment/locale/pt-br';
import { ChevronRight } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import * as Style from './styles';
import { formatPrice } from '~/util/format';
import Snackbar from '~/util/SnackBar';
import { availableChannelFilters } from '~/util/storeRankingFilters';
import { TREINADOR_ADORO_CODIGO } from '~/util/perfilId';

function RankingListItem({
    rankingData,
    orderBy,
    channelFilter,
    startIndex = 1,
}) {
    const history = useHistory();
    const location = useLocation();
    const profile = useSelector(state => state.user.profile);

    const currentLocation = location.pathname.split('/')[1];

    const getColorFromPercentage = percentage => {
        const percentageAsFloat = parseFloat(percentage);
        if (percentageAsFloat === 0) {
            return '#E26257';
        }
        if (!percentageAsFloat) {
            return '#757575';
        }
        if (percentageAsFloat >= 100) {
            return '#1BB55C';
        }
        return '#E26257';
    };

    const getValue = salesperson => {
        const filterAvailableChannels = availableChannelFilters.find(
            channelObj => channelObj.channel === channelFilter
        );
        switch (orderBy) {
            case 'pa':
                return salesperson[
                    filterAvailableChannels.valueParamPA
                ]?.toFixed(2);
            case 'va':
                return formatPrice(
                    salesperson[filterAvailableChannels.valueParamVA]
                );
            default:
                return salesperson[filterAvailableChannels.priceParam];
        }
    };

    const redirectUserToSalespersonQuota = (
        salesPersonHasAccount,
        salesPersonName
    ) => {
        const willFunctionRedirectUser = salesPersonHasAccount
            ? history.push(`/cotaPiloto?salesCode=${salesPersonName}`)
            : null;

        return willFunctionRedirectUser;
    };

    const handleClick = salesperson => {
        if (!salesperson.hasMaisVendasUser) {
            Snackbar.error('Vendedor não tem cadastro no +Vendas');
            return;
        }
        if (currentLocation === 'storeRanking') {
            redirectUserToSalespersonQuota(
                salesperson.hasMaisVendasUser,
                salesperson.vendedor
            );
            return;
        }
        if (profile?.id_perfil === TREINADOR_ADORO_CODIGO) {
            history.push(
                `/vendedoraAdoroCodigo?salesCode=${salesperson.vendedor}`
            );
            return;
        }
        history.push(
            `/vendedoraMultimarcaPiloto?salesCode=${salesperson.vendedor}`
        );
    };

    return (
        <>
            {rankingData.map((salesperson, index) => (
                <Style.RankingListItem
                    key={`list-item-${salesperson.vendedor}-${salesperson.cota}`}
                    primary={salesperson.hasMaisVendasUser}
                    onClick={() => {
                        handleClick(salesperson);
                    }}
                >
                    <Grid container justifyContent="space-between">
                        <Grid
                            item
                            container
                            style={{
                                width: 'fit-content',
                            }}
                            xs={6}
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    marginRight: '16px',
                                    color: 'white',
                                    fontFamily: 'Inter',
                                }}
                            >
                                {index + startIndex}º
                            </Grid>
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <Style.SalesPersonName>
                                    {salesperson.shortName.toLowerCase()}
                                </Style.SalesPersonName>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: '#9e9e9e',
                                    }}
                                >
                                    {salesperson.vendedor}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            style={{
                                width: 'fit-content',
                            }}
                            xs={6}
                        >
                            {orderBy === 'quota' ? (
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            textAlign: 'end',
                                            color: getColorFromPercentage(
                                                salesperson.quotaPercentage
                                            ),
                                        }}
                                    >
                                        {salesperson.showFirstQuotaStar && (
                                            <Style.StarIcon />
                                        )}
                                        {salesperson.showSecondQuotaStart && (
                                            <Style.StarIcon />
                                        )}

                                        {salesperson.quotaPercentage}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            textAlign: 'end',
                                            color: '#BDBDBD',
                                        }}
                                    >
                                        {salesperson.valueAsPrice}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    style={{
                                        alignSelf: 'center',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            textAlign: 'end',
                                        }}
                                    >
                                        {getValue(salesperson)}
                                    </Typography>
                                </Grid>
                            )}

                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    marginLeft: '16px',
                                }}
                            >
                                <ChevronRight
                                    style={{
                                        color: salesperson.hasMaisVendasUser
                                            ? 'white'
                                            : '#757575',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Style.RankingListItem>
            ))}
        </>
    );
}

export default RankingListItem;
