import { createTheme } from '@material-ui/core/styles';

const OldTheme = createTheme({
    typography: {
        fontFamily: 'Inter',
    },
    palette: {
        type: 'dark',
        primary: {
            light: '#fafafa',
            main: '#dcc6b7',
            dark: '#b2b2b2',
            contrastText: '#222222',
        },
        secondary: {
            main: '#DCC6B7',
        },
    },
    overrides: {
        MuiSkeleton: {
            root: { backgroundColor: 'rgba(255,255,255,0.15)' },
        },

        MuiDivider: {
            light: {
                backgroundColor: 'white',
            },
        },
        MuiSvgIcon: {
            fontSizeLarge: {
                fontSize: '3rem',
            },
        },
        MuiOutlinedInput: {
            input: {
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px transparent inset',
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
        MuiTypography: {
            root: { color: '#fafafa', fontFamily: 'Inter' },
        },
        MuiInputBase: {
            input: {
                '&.Mui-disabled': {
                    opacity: '0.5',
                },
            },
        },
    },
})

export default OldTheme;