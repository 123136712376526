import React from 'react';

import * as Style from './styles';

function IndicatorContainer({ title, value }) {
    return (
        <Style.IndicatorContainer>
            <Style.Subtitles>{title}</Style.Subtitles>
            <Style.IndicatorText>{value}</Style.IndicatorText>
        </Style.IndicatorContainer>
    );
}

export default IndicatorContainer;
