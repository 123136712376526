import {styled} from '@mui/material/styles';
import { Button, CardMedia, Grid, Typography } from '@mui/material';

export const HeaderContainer = styled(Grid)`
    justify-content: space-between;
    margin: 64px 0;
    padding: 0 32px;
`

export const Logo = styled(CardMedia)`
    width: 80px;
`

export const HeaderStepNumber = styled(Typography)`
    color: #3D3D4D;
`

export const MainTextContainer = styled(Grid)`
    margin: 64px 0;
`

export const MainTextTypography = styled(Typography)`
    color: #DEDEE3;
`

export const PrimaryButton = styled(Button)`
    border: 1px solid #DCC6B7;
    color: #DCC6B7;
    width: 300px;
    height: 56px;
    font-size: 15px;
    text-transform: none;
`

export const SecondaryButton = styled(Button)`
    color: #7A7A80;
    width: 156px;
    height: 56px;
    font-size: 15px;
    text-transform: none;
`