import { styled } from '@mui/material/styles';
import { ListItem, Typography } from '@mui/material';
import { Star } from '@mui/icons-material';

export const RankingListItem = styled(ListItem)`
    cursor: ${props => (props.primary ? 'pointer' : 'unset')};
    height: 72px;
    border-bottom: 1px solid #9e9e9e;
    &:hover {
        background-color: ${props => props.primary && '#424242'};
    }

    :first-child {
        border-top: 1px solid #9E9E9E;;
    }
`;

export const StarIcon = styled(Star)`
    color: #fbdd00;
    width: 16px;
    height: 16px;
`;

export const SalesPersonName = styled(Typography)`
    font-weight: 700;
    font-size: 14px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'Inter';
    color: white;
    @media screen and (max-width: 350px) {
        max-width: 80px;
    }
`;
