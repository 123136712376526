import { ROUTES } from '~/consts';
import { FARM } from './brands';
import {
    VENDEDOR_SOMA,
    VENDEDOR_FRANQUIA,
    VENDEDOR_CORPORATIVO,
    VENDEDOR_MULTIMARCA,
    VENDEDOR_LOJA_VIRTUAL_ANIMALE,
    LOJA_SOMA,
    LOJA_MULTIMARCA,
    SUPERVISAO,
    PLANEJAMENTO,
    DIRETOR,
    LOJA_FRANQUIA,
    TREINADOR_ADORO_CODIGO,
    TREINADOR_LOJA_VIRTUAL_ANIMALE,
    VENDEDOR_ADORO_CODIGO,
    CAIXA_SOMA,
    CAIXA_MULTIMARCA,
    ESTOQUISTA_SOMA,
    SUBGERENTE_SOMA,
    INFLUENCIADORES_OFF,
    INFLUENCIADORES_OFF_VENDEDORES,
} from './perfilId';
import getAdoroCodigoChallengePeriod from './getAdoroCodigoChallengePeriod';

const EXCEPTION_BRANCHES = [
    '000048',
    '000136',
    '000908',
    '160003',
    '700003',
    '802100',
    '805025',
    '805038',
    '805041',
    '805092',
    '930',
    '990',
    '999',
];
const getDefaultRouteForUser = userProfile => {
    const adoroCodigoChallengePeriod = getAdoroCodigoChallengePeriod(
        userProfile.id_perfil
    );

    if (
        userProfile?.users_branches[0].codigo_filial.includes(
            EXCEPTION_BRANCHES
        )
    ) {
        return ROUTES.VENDEDOR_CORPORATIVO;
    }
    if (
        userProfile.id_perfil === TREINADOR_ADORO_CODIGO &&
        adoroCodigoChallengePeriod
    ) {
        return ROUTES.ADORO_CODIGO_CHALLENGE;
    }
    switch (userProfile.id_perfil) {
        case VENDEDOR_SOMA:
            return ROUTES.COTA_PILOTO;
        case VENDEDOR_FRANQUIA:
            return ROUTES.VENDEDORA_PILOTO;
        case VENDEDOR_CORPORATIVO:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case VENDEDOR_MULTIMARCA:
            return ROUTES.VENDEDORA_MULTIMARCA_PILOTO;
        case VENDEDOR_LOJA_VIRTUAL_ANIMALE:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case LOJA_SOMA:
            return ROUTES.STORE_OVERVIEW;
        case LOJA_MULTIMARCA:
            return ROUTES.STORE_MULTIMARCA_PILOTO;
        case SUPERVISAO:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case PLANEJAMENTO:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case DIRETOR:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case LOJA_FRANQUIA:
            return ROUTES.STORE_OVERVIEW;
        case TREINADOR_ADORO_CODIGO:
            return ROUTES.VENDEDORA_ADORO_CODIGO;
        case TREINADOR_LOJA_VIRTUAL_ANIMALE:
            return ROUTES.VENDEDORA_ADORO_CODIGO;
        case VENDEDOR_ADORO_CODIGO:
            return ROUTES.VENDEDORA_ADORO_CODIGO;
        case CAIXA_SOMA:
            if (userProfile?.marcaFilial === FARM) {
                return ROUTES.VENDEDORA_PILOTO;
            }
            return ROUTES.STORE_OVERVIEW;
        case CAIXA_MULTIMARCA:
            return ROUTES.STORE_OVERVIEW;
        case ESTOQUISTA_SOMA:
            return ROUTES.VENDEDORA_PILOTO;
        case SUBGERENTE_SOMA:
            return ROUTES.STORE_OVERVIEW;
        case INFLUENCIADORES_OFF:
            return ROUTES.VENDEDOR_CORPORATIVO;
        case INFLUENCIADORES_OFF_VENDEDORES:
            return ROUTES.VENDEDORA_MULTIMARCA_PILOTO;
        default:
            return ROUTES.LOGIN;
    }
};

export default getDefaultRouteForUser;
