import {styled} from '@mui/material/styles';
import {
    Button,
    Grid,
    Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const StyledIcon = styled(ArrowBackIosIcon)`
    color: #AEAEB3;
    margin: 32px;
`

export const HeaderContainer = styled(Grid)`
    justify-content: space-between;
    margin: 64px 0;
    padding: 0 32px;
`

export const HeaderStepNumber = styled(Typography)`
    color: #3D3D4D;
`

export const MainTextContainer = styled(Grid)`
    margin-bottom: 32px;
`

export const MainTextTypography = styled(Typography)`
    color: #DEDEE3;
`

export const DefaultButton = styled(Button)`
    background-color: #DCC6B7;
    color: black;
    width: 100%;
    height: 56px;
    font-size: 15px;
    text-transform: none;
    margin-bottom: 5px;
`