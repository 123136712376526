import React from 'react';
import {
    Grid,
    Typography,
    SvgIcon,
} from '@mui/material';

// Asset import
import { ReactComponent as FilterAltOutlined } from '~/assets/icons/filter_alt_outlined.svg';
import { ReactComponent as FilterAlt } from '~/assets/icons/filter_alt.svg';

function FilterIcon({ setChannelFilterDialogIsOpen , channelFilter}) {
    const filterNames = {
        all: 'Filtrar',
        online: 'Online',
        physical: 'Físico',
    }
    const getFilterName = filterNames[channelFilter];

    return (
        <Grid
            item
            container
            style={{
                cursor: 'pointer',
                width: 'auto',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
            onClick={() => {
                setChannelFilterDialogIsOpen(true);
            }}
            xs={6}
        >
            <SvgIcon
                style={{ fill: '#DCC6B7' }}
                component={
                    channelFilter === 'all' ? FilterAltOutlined : FilterAlt
                }
            />
            <Typography
                style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#DCC6B7',
                    margin: '0px 8px 0px 8px',
                }}
            >
                {getFilterName}
            </Typography>
        </Grid>
    );
}

export default FilterIcon;
