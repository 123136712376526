import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import * as Style from './styles';
import LoadingComponent from '~/components/SalesPersonPanelPiloto/LoadingComponent';
import ChartPanelCard from '~/components/PanelCards/ChartPanelCard';
import SecondaryPanelCard from '~/components/PanelCards/SecondaryPanelCard';
import { LOJA_MULTIMARCA } from '~/util/perfilId';

function DefaultPagePanel({
    balanceText,
    indicatorsText,
    panelLoaded,
    chartData,
    dateRange,
}) {
    const theme = useTheme();
    const user = useSelector(state => state.user.profile);
    const [activeStep, setActiveStep] = useState(0);
    const [isCardSwipeDisabled, setIsSwipeCardDisabled] = useState(false);
    const maxSteps = dateRange.length > 1 ? 2 : 1;
    const isUserMultimarcaManager = user.id_perfil === LOJA_MULTIMARCA;
    const handleStepChange = step => {
        setActiveStep(step);
    };

    const getBalanceText = isUserMultimarcaManager
        ? balanceText
        : indicatorsText.totalValue;

    return (
        <>
            {panelLoaded ? (
                <>
                    <Style.Root>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            disabled={isCardSwipeDisabled}
                        >
                            {[
                                dateRange.length > 1 && (
                                    <ChartPanelCard
                                        balanceText={getBalanceText}
                                        chartData={chartData}
                                        setIsSwipeCardDisabled={
                                            setIsSwipeCardDisabled
                                        }
                                    />
                                ),
                                <SecondaryPanelCard
                                    totalValue={getBalanceText}
                                    averageValue={
                                        indicatorsText.totalAverageValue
                                    }
                                    averageProductQuantity={
                                        indicatorsText.totalAverageQuantity
                                    }
                                    productQuantity={
                                        indicatorsText.totalProductQuantity
                                    }
                                    orderQuantity={
                                        indicatorsText.totalOrderQuantity
                                    }
                                />,
                                // eslint-disable-next-line array-callback-return
                            ].filter((stepComponent, index) => {
                                if (
                                    dateRange.length > 1 ||
                                    (dateRange.length <= 1 && index !== 0)
                                ) {
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <div key={`swipable-card-${index}`}>
                                            {Math.abs(activeStep - index) <= 2
                                                ? stepComponent
                                                : null}
                                        </div>
                                    );
                                }
                            })}
                        </SwipeableViews>
                        <Style.Stepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                        />
                    </Style.Root>
                    <Style.DesktopRoot container>
                        {dateRange.length > 1 && (
                            <ChartPanelCard
                                balanceText={getBalanceText}
                                chartData={chartData}
                                setIsSwipeCardDisabled={setIsSwipeCardDisabled}
                            />
                        )}
                        <SecondaryPanelCard
                            totalValue={getBalanceText}
                            averageValue={indicatorsText.totalAverageValue}
                            averageProductQuantity={
                                indicatorsText.totalAverageQuantity
                            }
                            productQuantity={
                                indicatorsText.totalProductQuantity
                            }
                            orderQuantity={indicatorsText.totalOrderQuantity}
                        />
                    </Style.DesktopRoot>
                </>
            ) : (
                <LoadingComponent />
            )}
        </>
    );
}

export default DefaultPagePanel;
