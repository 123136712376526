import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as moment from 'moment';

import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import 'moment/locale/pt-br';
import DateRangePicker from '~/components/DateRangePicker';
import Header from '~/components/Header';
import NPSForm from '~/components/NPSForm';
import SalesTerms from '~/components/SalesTerms';
import api, { omniApi } from '~/services/api';
import Snackbar from '~/util/SnackBar';
import { changeShouldShowNPS } from '../../store/modules/user/actions';
import { signOut } from '../../store/modules/auth/actions';
import DesktopLayout from './DesktopLayout';
import { handleOrdersResponse } from '~/util/handleOrdersResponse';
import NewDataWarning from './NewDataWarning';
import { PageTitle } from './styles';

function StoreOmni() {
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [panelLoaded, setPanelLoaded] = useState(false);
    const [showNPSForm, setShowNPSForm] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);
    const [omniData, setOmniData] = useState({});
    const [showNewDataWarning, setShowNewDataWarning] = useState(false);

    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate, isFaturado } = useSelector(
        state => state.status
    );

    async function handleSubmit() {
        try {
            await api.post('/nps', {
                answer: '',
                comment: '',
                isSalesperson: userProfile.isSalesperson,
                login: userProfile.isSalesperson
                    ? userProfile.salesCode
                    : userProfile.login,
            });

            dispatch(changeShouldShowNPS(false));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    useEffect(() => {
        if (shouldLogout) {
            dispatch(signOut());
        }
    }, [dispatch, shouldLogout]);

    const checkIfCanMakeRequests = useCallback(() => {
        return finalDate && userProfile?.users_sales_code[0].codigo_vendedor;
    }, [finalDate, userProfile]);

    useEffect(() => {
        const isFarm = userProfile?.marcaFilial === 2;
        const beforeDateLimit = new Date() < new Date('2023-05-26');
        const shouldAnswerNPS = userProfile?.shouldAnswerNPS;

        if (shouldAnswerNPS) {
            if (!(beforeDateLimit && isFarm)) {
                setShowNPSForm(userProfile.shouldAnswerNPS);
            }
        }
    }, [userProfile]);

    useEffect(() => {
        const hasWarningBeenShown = localStorage.getItem('OmniDataWarning');
        const END_DATE_NOTICE = moment('2024-05-11 23:59:59');
        const IS_ON_DEADLINE = moment().isSameOrBefore(END_DATE_NOTICE);

        if (!hasWarningBeenShown && IS_ON_DEADLINE) {
            setShowNewDataWarning(true);
        }
    }, []);

    useEffect(() => {
        if (checkIfCanMakeRequests()) {
            setOrders([]);
            setPanelLoaded(false);
            setOrdersLoaded(false);

            const getOmniData = async () => {
                try {
                    const response = await omniApi.get(
                        `/storeData/maisVendas/consolided?startDate=${initialDate.format(
                            'YYYY-MM-DD'
                        )}&endDate=${finalDate.format('YYYY-MM-DD')}&filial=${
                            userProfile.nomeFilial
                        }`,
                        {
                            headers: {
                                Authorization: `Ra0YaHTblw7MkUbcCpSKklfCA15ew74S`,
                            },
                        }
                    );

                    const data = response?.data || {};
                    setOmniData(data);
                } catch (err) {
                    const notFoundError = err.response.status === 404;
                    if (!notFoundError) {
                        Snackbar.error(
                            'Erro retornando os indicadores de pedidos capturados'
                        );
                        return;
                    }
                    setOmniData({
                        receitas: 0,
                        tma_horas: 0,
                        taxa_cancelamento: 0,
                    });
                }
            };

            const getSalespersonOrders = async () => {
                try {
                    const response = await omniApi.get(
                        `/storeData/maisVendas?startDate=${initialDate.format(
                            'YYYY-MM-DD'
                        )}&endDate=${finalDate.format('YYYY-MM-DD')}&filial=${
                            userProfile.nomeFilial
                        }`,
                        {
                            headers: {
                                Authorization: `Ra0YaHTblw7MkUbcCpSKklfCA15ew74S`,
                            },
                        }
                    );

                    const formattedOrders = await handleOrdersResponse(
                        response.data,
                        true
                    );

                    const filterOutStoreSales = formattedOrders.filter(
                        order => order.soldByStore === false
                    );

                    setOrders(filterOutStoreSales);
                } catch (err) {
                    const notFoundError = err?.response?.data?.error?.includes(
                        'Não foi encontrado nenhum sellerId'
                    );
                    if (!notFoundError) {
                        Snackbar.error(
                            'Erro retornando a lista de pedidos Omni'
                        );
                    }
                    setOrders([]);
                }
            };

            Promise.all([getOmniData(), getSalespersonOrders()])
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        Snackbar.error('Token expirado. Faça login novamente');
                        setShouldLogout(true);
                    }
                })
                .finally(() => {
                    setPanelLoaded(true);
                    setOrdersLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        finalDate,
        checkIfCanMakeRequests,
        userProfile,
        isFaturado,
        initialDate,
    ]);

    return (
        <>
            <NPSForm
                open={showNPSForm}
                onClose={() => {
                    setShowNPSForm(false);
                    handleSubmit();
                }}
            />
            <SalesTerms />
            {showNewDataWarning && (
                <NewDataWarning
                    openModal={showNewDataWarning}
                    setOpenModal={setShowNewDataWarning}
                />
            )}
            <Header />
            <DateRangePicker hidden={!panelLoaded} assessment />
            {ordersLoaded && <PageTitle>Pedidos Realizados</PageTitle>}
            {panelLoaded || (
                <Grid
                    container
                    justifyContent="center"
                    spacing={1}
                    style={{ paddingTop: '10px' }}
                >
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="150px" />
                    </Grid>
                    <Grid container justifyContent="center" item xs={12}>
                        <Skeleton animation="wave" width="200px" />
                    </Grid>
                </Grid>
            )}
            <DesktopLayout
                panelLoaded={panelLoaded}
                ordersLoaded={ordersLoaded}
                orders={orders}
                omniData={omniData}
            />
        </>
    );
}

export default StoreOmni;
