import { Grid, Typography, Link } from '@mui/material';
import React from 'react';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { formatDateStringToText } from '~/util/format';
import SnackBar from '~/util/SnackBar';

const CardTracking = ({ orderData }) => {
    const copyToClipboard = str => {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            SnackBar.success('Código copiado com sucesso');
            return navigator.clipboard.writeText(str);
        }
    };

    const hasDeliveredDate = !!orderData?.trackingDetail[0]?.deliveredDate;
    const hasShippingDetail = orderData?.shippingDetail.length > 0;
    const hasCourier =
        orderData.trackingDetail[0]?.courier &&
        orderData.trackingDetail[0]?.courier !== null;
    const hasTrackingNumber =
        orderData.trackingDetail[0]?.trackingNumber &&
        orderData.trackingDetail[0]?.trackingNumber !== null;

    return (
        <Grid
            item
            xs={12}
            style={{
                backgroundColor: '#373435',
                gap: '20px',
                padding: '16px',
                paddingLeft: '16px',
                borderRadius: '4px',
                marginBottom: '16px',
            }}
            container
        >
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item container style={{ width: 'fit-content' }}>
                    <div
                        style={{
                            marginRight: '8px',
                        }}
                    >
                        <LocalShippingOutlinedIcon color="primary" />
                    </div>

                    <Typography
                        style={{
                            fontSize: '16px',
                            fontWeight: '600',
                        }}
                    >
                        Entrega
                    </Typography>
                </Grid>
            </Grid>

            {hasShippingDetail ? (
                <>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item container xs={6}>
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                {hasDeliveredDate
                                    ? 'Entregue em'
                                    : 'Estimativa de Entrega'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    textAlign: 'end',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {formatDateStringToText(
                                    hasDeliveredDate
                                        ? orderData?.trackingDetail[0]
                                              .deliveredDate
                                        : orderData?.shippingDetail[0]
                                              ?.shippingEstimateDate
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={6}>
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                Código de Rastreio
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {hasTrackingNumber ? (
                                <Grid
                                    item
                                    onClick={() =>{
                                        copyToClipboard(
                                            orderData.trackingDetail[0]
                                                ?.trackingNumber
                                        )
                                    }
                                    }
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            textAlign: 'end',
                                            marginRight: '7.5px',
                                            textDecoration: 'underline',
                                        }}
                                        color="primary"
                                    >
                                        Copiar código
                                    </Typography>
                                    <FileCopyOutlinedIcon color="primary" />
                                </Grid>
                            ) : (
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            textAlign: 'end',
                                        }}
                                    >
                                        Indisponível
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container alignItems="center">
                        <Grid item container xs={6}>
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                }}
                            >
                                Transportadora
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {hasCourier ? (
                                <Link
                                    href={
                                        orderData.trackingDetail[0]?.trackingUrl
                                    }
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        textAlign: 'end',
                                        textTransform: 'capitalize',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        textDecoration: `${orderData
                                            .trackingDetail[0]?.trackingUrl &&
                                            'underline'}`,
                                        color: `${
                                            orderData.trackingDetail[0]
                                                ?.trackingUrl
                                                ? '#DCC6B7'
                                                : '#fff'
                                        }`,
                                    }}
                                >
                                    {orderData.trackingDetail[0]?.courier}
                                    {orderData.trackingDetail[0]
                                        ?.trackingUrl && (
                                        <OpenInNewOutlinedIcon
                                            color="primary"
                                            style={{ marginLeft: '7.5px' }}
                                        />
                                    )}
                                </Link>
                            ) : (
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            textAlign: 'end',
                                        }}
                                    >
                                        Indisponível
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Typography
                    style={{
                        fontSize: '16px',
                        fontWeight: '400',
                    }}
                >
                    Informações sobre esta entrega estão indisponíveis no
                    momento.
                </Typography>
            )}
        </Grid>
    );
};

export default CardTracking;
