import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import getPageBackgroundColorByUrl from './getPageBackgroundColorByUrl';

const GlobalStyle = createGlobalStyle`

    * {
        margin:0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: 'Inter';
    }

    body {
        background: ${props => props.backgroundColor};
        -webkit-font-smoothing:antialiased;
    }

    html,body,#root {
        min-height:100%;
        background: ${props => props.backgroundColor};
    }

`;
export default () => {
    const [backgroundColor, setBackgroundColor] = useState('black');
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const mainPath = currentPath.split('/')[1];
        const newBackgroundColor = getPageBackgroundColorByUrl(mainPath);
        setBackgroundColor(newBackgroundColor);
    }, [location]);

    return <GlobalStyle backgroundColor={backgroundColor} />;
};
