import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router';

import Tag from '../Tag';

import * as Style from './styles';

function ListItemComponent({
    icon,
    handleClick,
    index,
    count,
    showText,
    showTag,
}) {
    const location = useLocation().pathname;

    const isItemSelected = () => {
        const iconRoute = icon.route;
        return iconRoute === location;
    }
    const isSelected = isItemSelected();

    function renderText(icon) {
        if (showText) {
            return (
                <ListItemText
                    disableTypography
                    primary={icon.text}
                    style={{
                        color: '#E3E3E3',
                        fontWeight: 'none',
                    }}
                />
            );
        }
        return null;
    }

    return (
        <Style.ListItemComponent
            primary={isSelected}
            key={`${icon.name}-${index}`}
            onClick={() => handleClick(icon)}
            disableGutters
        >
            <Style.ListItemBorder primary={isSelected} />
            <Style.ListItemIconContainer>
                {icon.name === 'insights' ? (
                    <Style.StyledBadge badgeContent={count}>
                        <Style.IconComponent
                            key={icon.name}
                            primary={isSelected}
                        >
                            {icon.name}
                        </Style.IconComponent>
                    </Style.StyledBadge>
                ) : (
                    <Style.IconComponent key={icon.name} primary inputMargin>
                        {icon.name}
                    </Style.IconComponent>
                )}
            </Style.ListItemIconContainer>
            {renderText(icon)}

            {icon.text === 'Operacional Omni' && showTag && (
                <Tag title="Novidade" />
            )}
        </Style.ListItemComponent>
    );
}

export default ListItemComponent;
