import React from "react";
import { useHistory } from "react-router-dom";
import {Grid} from '@mui/material';
import ChevronRight from "@mui/icons-material/ChevronRight";
import TrophyIcon from '~/assets/icons/trofeu_pink.svg';
import ribbonPrize from '~/assets/icons/ribbonPrize.svg';
import OmniIcon from '~/assets/icons/omni_large.svg';
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import * as Style from './styles';


function PageLayout({
    storeRanking,
    omniIndicators,
    salesCard,
    totalValue,
    salesPercentage,
    omniPercentage,
    userProfile,
}){
    const history=useHistory();

    return(
        <Style.PageWrapper container >
            <Style.HeaderTitle item xs={12}>
                <Style.SmallTypography heavy >
                    Total 
                </Style.SmallTypography>
                <Style.SmallTypography>
                    ({salesPercentage?.totalPercentageOfQuota}%)
                </Style.SmallTypography>
            </Style.HeaderTitle>
            <Grid item xs={12}>
                <Style.BigTypography>
                    {totalValue?.value}
                </Style.BigTypography>
            </Grid>
            <Grid container xs={12}>
                <LinearProgressWithLabel
                    salesQuotaValue={salesPercentage?.percentageOfQuota}
                    omniQuotaValue={omniPercentage?.percentageOfQuota}
                    salesTotalValue={salesPercentage?.percentageOfSold}
                />
            </Grid>
            <Grid item xs={12}>
                <Style.MediumTypography>
                    Cota: {salesCard?.secondaryText}
                </Style.MediumTypography>
            </Grid>
            <Style.SectionContainer container xs={12}>
                <Style.HalfPageContainer
                    left
                    inputMargin="12px 0px 8px"
                    container
                    onClick={()=>{
                        history.push('/storeCota');
                    }
                    } 
                >
                    <Style.HalfContainerTitle container >
                        <Style.ColoredCircles quota></Style.ColoredCircles>
                        <Style.IndicatorTypography primary>
                            Cota ({salesPercentage?.percentageOfQuota}%)
                        </Style.IndicatorTypography>
                    </Style.HalfContainerTitle>
                    <Style.LineElement item xs ></Style.LineElement>
                    <Style.HalfContainerValues container >
                        <Style.IndicatorTypography>
                            {salesCard?.mainText}
                        </Style.IndicatorTypography>
                        <ChevronRight
                            style={{ 
                                color: 'white',
                            }} 
                        />
                    </Style.HalfContainerValues>
                </Style.HalfPageContainer>
                <Style.HalfPageContainer
                    container
                    xs
                    onClick={()=>{
                        history.push('/storeOmni');
                    }}
                >
                    <Style.HalfContainerTitle container >
                        <Style.ColoredCircles></Style.ColoredCircles>
                        <Style.IndicatorTypography primary>
                            Omni ({omniPercentage?.percentageOfQuota}%)
                        </Style.IndicatorTypography>
                    </Style.HalfContainerTitle>
                    <Style.LineElement item xs></Style.LineElement>
                    <Style.HalfContainerValues container >
                        <Style.IndicatorTypography>
                            {omniIndicators?.secondaryText}
                        </Style.IndicatorTypography>
                        <ChevronRight style={{ color: 'white' }} />
                    </Style.HalfContainerValues>
                </Style.HalfPageContainer>
            </Style.SectionContainer>
            <Style.LineElement primary item xs={12} ></Style.LineElement>
            <Style.FullPageContainer container xs={12}>
                <Style.HalfContainerTitle fullPage inputPadding="15px 0" container >
                    <img
                        src={OmniIcon}
                        alt="omni-icon"
                        style={{ height: '20px', width: '20px' }}
                    />
                    <Style.IndicatorTypography primary>
                        Indicadores Omni
                    </Style.IndicatorTypography>
                </Style.HalfContainerTitle>
                <Style.HalfContainerValues omni container >
                    <Style.OmniInnerContainer container >
                        <Style.OmniValuesTitle item primary>
                            Tempo médio de atendimento (TMA)
                        </Style.OmniValuesTitle>
                        <Style.OmniValues>
                            {omniIndicators?.mainText?.omniTMA}
                        </Style.OmniValues>
                    </Style.OmniInnerContainer>
                    <Style.OmniInnerContainer rightContainer item xs>
                        <Style.OmniValuesTitle>
                            Taxa de cancelamento
                        </Style.OmniValuesTitle>
                        <Style.OmniValues>
                            {omniIndicators?.mainText?.omniTC}
                        </Style.OmniValues>
                    </Style.OmniInnerContainer>
                </Style.HalfContainerValues>
            </Style.FullPageContainer>
            <Style.SectionContainer lowerContainer container xs={12} >
                <Style.HalfPageContainer
                    left
                    lower
                    inputMargin="0px 0px 8px"
                    container
                    onClick={()=> history.push(`/storeRanking/${userProfile.users_branches[0].codigo_filial}`)}
                >
                    <Style.HalfContainerTitle container>
                        <img
                            src={TrophyIcon}
                            alt="trophy-icon"
                            style={{ height: '20px', width: '20px' , marginRight: '1px'}}
                        />
                        <Style.IndicatorTypography primary>
                                Ranking de vendedores
                        </Style.IndicatorTypography>
                    </Style.HalfContainerTitle>
                    <Style.Chevron item xs={1}>
                        <ChevronRight style={{ color: 'white' }} />
                    </Style.Chevron>
                </Style.HalfPageContainer>
                <Style.HalfPageContainer lower paddingVariation container xs>
                    <Style.RankingTitle container >
                        <img
                            src={ribbonPrize}
                            alt="prize-icon"
                            style={{ height: '20px', width: '20px' }}
                        />
                        <Style.IndicatorTypography primary>
                            Ranking da rede
                        </Style.IndicatorTypography>
                    </Style.RankingTitle>
                    <Style.RankingTitle subtitle item >
                        <Style.OmniValues>
                            {storeRanking?.placement}º
                        </Style.OmniValues>
                        <Style.RankingText>
                            de {storeRanking?.totalStores} lojas
                        </Style.RankingText>
                    </Style.RankingTitle>
                </Style.HalfPageContainer>
            </Style.SectionContainer>
        </Style.PageWrapper>
    )
}

export default PageLayout;