import React from 'react';
import { Box } from '@mui/material';

import { getColorFromPercentage } from '~/util/convertFunctions';

import * as Style from './styles';

function QuotaPercentageContainer({ value }) {
    return (
        <Box minWidth={35}>
            <Style.QuotaPercentageTypography
                inputColor={getColorFromPercentage(value)}
                variant="body1"
            >
                {value}
            </Style.QuotaPercentageTypography>
        </Box>
    );
}

export default QuotaPercentageContainer;
