import 'moment/locale/pt-br';
import React from 'react';
import logoSoma from '../../../assets/logo_soma.png';
import changePasswordSuccess from '../../../assets/change_password_success.png';

import * as Style from './styles';

function SuccessPage({ ...props }) {
    return (
        <>
            <Style.MainContainer container >
                <Style.HeaderContainer item >
                    <img
                        src={logoSoma}
                        alt="Grupo Soma"
                    />
                </Style.HeaderContainer>
                <Style.HeaderContainer item success>
                    <img
                        src={changePasswordSuccess}
                        alt="success"
                    />
                </Style.HeaderContainer>
                <Style.TextContainer item primary>
                    <Style.Text primary>
                        Senha redefinida com <br />
                        sucesso!
                    </Style.Text>
                </Style.TextContainer>
                <Style.TextContainer item >
                    <Style.Text>
                        Acesse os produtos <b>+Vendas</b>, <b>Somastore</b>{' '}
                        e <br />
                        <b>Somabox</b> utilizando sua nova senha.
                    </Style.Text>
                </Style.TextContainer>
            </Style.MainContainer>
        </>
    );
}

export default SuccessPage;