import {styled} from '@mui/material/styles';
import { Button, CardMedia, Grid, Typography } from '@mui/material';

export const Logo = styled(CardMedia)`
    width: 80px;
    padding-top: 20vh;
`

export const MainTextContainer = styled(Grid)`
    margin: 32px 0 96px;
`

export const MainTextTypography = styled(Typography)`
    color: #A8A8B3;
`

export const DefaultButton = styled(Button)`
    background-color: #29292E;
    color: #E1E1E6;
    width: 156px;
    height: 56px;
    font-size: 15px;
    text-transform: none;
    margin-bottom: 5px;
`