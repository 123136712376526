import React from 'react';

import QuotaPercentageContainer from '../MainPanelCards/QuotaPercentageContainer';
import PanelBodyText from '../MainPanelCards/PanelBodyText';

import { formatDecimalToPercentage, formatPrice } from '~/util/format';

import * as Style from './styles';

function ChallengeProgress({ challengeData, totalSalesValue }) {
    const challengeProgress = totalSalesValue / challengeData.total;
    const linearProgressPercentage = parseInt(challengeProgress * 100);
    const progressPercentage = formatDecimalToPercentage(challengeProgress);
    const formattedChallengeTotal = formatPrice(challengeData.total);

    const getLinearProgressValue = () => {
        if (linearProgressPercentage > 100) {
            return 100;
        }
        return linearProgressPercentage;
    };
    const linearProgressValue = getLinearProgressValue();

    return (
        <Style.MainPanelComponentContainer primary>
            <Style.InnerMainPanelComponent>
                <PanelBodyText
                    text={'Desafio'}
                    value={formattedChallengeTotal}
                />
                <Style.StyledLinearProgress
                    variant="determinate"
                    value={linearProgressValue}
                    quota={challengeData.total}
                />
            </Style.InnerMainPanelComponent>
            <QuotaPercentageContainer value={progressPercentage} />
        </Style.MainPanelComponentContainer>
    );
}

export default ChallengeProgress;
