import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TagContainer = styled(Grid)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    background-color: #DCC6B7;
    border-radius: 16px;
    margin: 10px;
`

export const TagTypography = styled(Typography)`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.005em;
    color: #373435;
`