import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import 'moment/locale/pt-br';
import React from 'react';
import doneImage from '~/assets/firstAccess/done.png';
import * as Style from './styles';

function Done({ ...props }) {
    const history = useHistory();
    return (
        <>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} container justifyContent="center">
                    <Style.Logo
                        component="img"
                        src={doneImage}
                        title="Grupo Soma"
                    />
                </Grid>
                <Style.MainTextContainer item xs={8}>
                    <Typography align="center" variant="h4">
                        Aprovação pendente!
                    </Typography>
                </Style.MainTextContainer>
                <Style.MainTextContainer item xs={6}>
                    <Style.MainTextTypography
                        align="center"
                        variant="body1"
                    >
                        Você já pode visualizar as vendas feitas com o seu
                        código. Assim que seu perfil for aprovado, você terá
                        acesso a mais informações!
                    </Style.MainTextTypography>
                </Style.MainTextContainer>
                <Grid item xs={12} container justifyContent="center">
                    <Style.DefaultButton
                        onClick={() => history.push('/login')}
                    >
                        {' '}
                        Ok
                    </Style.DefaultButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Done;
