/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import 'moment/locale/pt-br';
import moment from 'moment';

import { Grid, Link } from '@mui/material';
import { Close } from '@mui/icons-material';

import api from '~/services/api';
import Snackbar from '~/util/SnackBar';
import { getDateRangeList, formattedBranchCode } from '~/util/functions';
import { ANIMALE } from '~/util/brands';
import { SAVASSI, BATEL } from '~/util/branches';
import { GERENTE_SOMA, CAIXA, VENDEDOR, ESTOQUISTA } from '~/util/perfilId';

import * as Style from './styles';

const FIRST_EVALUATION = 35;
const SECOND_EVALUATION = 80;

function EvaluationNotification(props) {
    const [data, setData] = useState([]);
    const [hideNotification, setHideNotification] = useState([]);
    const [hideNotificationTemp, setHideNotificationTemp] = useState([]);

    const userProfile = useSelector(state => state.user.profile);
    const { initialDate, finalDate } = useSelector(state => state.status);

    const checkIfCanMakeRequests = useCallback(() => {
        return initialDate && finalDate;
    }, [finalDate, initialDate]);

    useEffect(() => {
        const { codigo_vendedor } = userProfile.users_sales_code[0];
        const branch = userProfile.users_branches.find(value =>
            [SAVASSI, BATEL].includes(formattedBranchCode(value.codigo_filial))
        );
        const getSalespeopleInExperience = async () => {
            try {
                const currentDate = moment().format('YYYY-MM-DD');

                const thirtyDays = moment(currentDate)
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD');

                const thirtyFiveDays = moment(currentDate)
                    .subtract(35, 'days')
                    .format('YYYY-MM-DD');

                const seventyFiveDays = moment(currentDate)
                    .subtract(75, 'days')
                    .format('YYYY-MM-DD');

                const eightyDays = moment(currentDate)
                    .subtract(80, 'days')
                    .format('YYYY-MM-DD');

                const response = await api.get(`/v2/salespeople`, {
                    params: {
                        branchCode: formattedBranchCode(branch.codigo_filial),
                        onStartDate: [
                            currentDate,
                            ...getDateRangeList(thirtyFiveDays, thirtyDays),
                            ...getDateRangeList(eightyDays, seventyFiveDays),
                        ],
                        profileId: [CAIXA, VENDEDOR, ESTOQUISTA],
                    },
                });

                const cacheList = await api.get(
                    `redis/${codigo_vendedor}/getList`,
                    {
                        params: {
                            key: 'notifications:SalespeopleInExperienceViewed',
                        },
                    }
                );

                setData(response?.data?.data || []);
                setHideNotification(cacheList.data);
            } catch (err) {
                Snackbar.error('Erro retornando getSalespeopleInExperience');
            }
        };

        if (checkIfCanMakeRequests()) {
            const brandsAllowed =
                userProfile.users_brands.find(value =>
                    [ANIMALE].includes(value.id_marca)
                ) || null;
            const profilesAllowed = [GERENTE_SOMA].includes(
                userProfile.id_perfil
            );

            if (brandsAllowed && profilesAllowed && branch) {
                getSalespeopleInExperience();
            }
        }
    }, [checkIfCanMakeRequests, userProfile]);

    async function handleClose(salespeople) {
        if (moment().isSame(salespeople.startDate)) {
            const { codigo_vendedor } = userProfile.users_sales_code[0];
            setHideNotification([...hideNotification, salespeople.salesCode]);

            await api.get(`redis/${codigo_vendedor}/setList`, {
                params: {
                    key: 'notifications:SalespeopleInExperienceViewed',
                    element: salespeople.salesCode,
                    ttl: 86400, // 24 hours
                },
            });
        } else {
            setHideNotificationTemp([
                ...hideNotificationTemp,
                salespeople.salesCode,
            ]);
        }
    }

    return (
        <>
            {data.map(value => {
                if (moment().isSame(value.startDate)) {
                    if (!hideNotification.includes(value.salesCode)) {
                        return (
                            <Style.PrimaryGrid
                                key={`key-${value.salesCode}`}
                                item
                                xs={12}
                            >
                                <Grid
                                    item
                                    style={{
                                        margin: '16px 0',
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={11}
                                            direction="row"
                                            alignItems="center"
                                            style={{ height: '100%' }}
                                        >
                                            <Grid item xs={1}>
                                                <Style.StyledInfoIcon/>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={10}
                                                style={{
                                                    marginLeft: '20px',
                                                }}
                                            >
                                                <Style.PrimaryTypography>
                                                    {value.shortName} (
                                                    {value.salesCode}) começou a
                                                    trabalhar na sua loja hoje.
                                                    Você será convidado a
                                                    avaliar este novo
                                                    colaborador em 2 momentos
                                                    (de 30 - 35 dias de
                                                    experiência e de 75 - 80
                                                    dias de experiência).
                                                </Style.PrimaryTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Grid container justifyContent="flex-end">
                                                <Grid
                                                    item
                                                    style={{
                                                        marginRight: '8px',
                                                    }}
                                                >
                                                    <Close
                                                        onClick={() =>
                                                            handleClose(value)
                                                        }
                                                        style={{
                                                            color: '#DCC6B7',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Style.PrimaryGrid>
                        );
                    }
                } else if (!hideNotificationTemp.includes(value.salesCode)) {
                    return (
                        <Style.PrimaryGrid
                            key={`key-${value.salesCode}`}
                            item
                            xs={12}
                        >
                            <Grid
                                item
                                style={{
                                    marginTop: '16px',
                                }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    style={{ height: '100%' }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={11}
                                        direction="row"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item xs={1}>
                                            <Style.StyledInfoIcon/>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={10}
                                            style={{
                                                marginLeft: '20px',
                                            }}
                                        >
                                            {FIRST_EVALUATION -
                                                moment().diff(
                                                    value.startDate,
                                                    'days'
                                                ) >=
                                            0 ? (
                                                <Style.PrimaryTypography>
                                                    {FIRST_EVALUATION -
                                                        moment().diff(
                                                            value.startDate,
                                                            'days'
                                                        ) ===
                                                    0
                                                        ? `Último dia para que você responda ao formulário sobre Avaliação de Período de Experiência de ${value.shortName} (${value.salesCode})`
                                                        : `Faltam ${FIRST_EVALUATION -
                                                              moment().diff(
                                                                  value.startDate,
                                                                  'days'
                                                              )} dias para que você responda ao formulário sobre Avaliação de Período de Experiência de ${
                                                              value.shortName
                                                          } (${
                                                              value.salesCode
                                                          })`}
                                                </Style.PrimaryTypography>
                                            ) : (
                                                <Style.PrimaryTypography>
                                                    {SECOND_EVALUATION -
                                                        moment().diff(
                                                            value.startDate,
                                                            'days'
                                                        ) ===
                                                    0
                                                        ? `Último dia para você responder ao formulário sobre a Avaliação do SEGUNDO período de Experiência de ${value.shortName} (${value.salesCode})`
                                                        : `Faltam ${SECOND_EVALUATION -
                                                              moment().diff(
                                                                  value.startDate,
                                                                  'days'
                                                              )} dias para você responder ao formulário sobre a Avaliação do SEGUNDO período de Experiência de ${
                                                              value.shortName
                                                          } (${
                                                              value.salesCode
                                                          })`}
                                                </Style.PrimaryTypography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid container justify="flex-end">
                                            <Grid
                                                item
                                                style={{ marginRight: '8px' }}
                                            >
                                                <Close
                                                    onClick={() =>
                                                        handleClose(value)
                                                    }
                                                    style={{
                                                        color: '#DCC6B7',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    margin: '30px 0',
                                }}
                            >
                                <Link
                                    href="https://forms.gle/xirmA8So8nrv5BC68"
                                    underline="none"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        border: '1px solid #DCC6B7',
                                        borderRadius: '12px',
                                        padding: '8px 54px',
                                        fontSize: '14px',
                                        color: '#DCC6B7',
                                    }}
                                >
                                    Avaliar
                                </Link>
                            </Grid>
                        </Style.PrimaryGrid>
                    );
                } else {
                    return <></>;
                }
            })}
        </>
    );
}

export default EvaluationNotification;