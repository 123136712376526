export default theme => ({
    datePickerContainer: {
        paddingTop: '16px',
        paddingLeft: '16px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '32px',
        },
    },
    datePickerContainerFixed: {},
    mainButton: {
        backgroundColor: 'transparent',
        borderColor: '#DCC6B7',
        borderRadius: '16px',
        color: '#DCC6B7',
        textTransform: 'capitalize',
    },
    mainButton2: {
        backgroundColor: '#DCC6B7',
        borderColor: '#DCC6B7',
        borderRadius: '16px',
        color: '#373435',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#DCC6B7',
        },
    },
    periodPillsContainer: {
        display: 'flex',
        marginRight: '16px',
        [theme.breakpoints.up('sm')]: {
            marginRight: '68px',
        },
        overflowX: 'scroll',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            color: 'red',
        },
    },
});
