import {styled} from '@mui/material/styles';
import {
    Grid,
    MobileStepper,
    Box,
} from '@mui/material';

export const Wrap = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    @media screen and (max-width: 480px) {
        padding: 120px 4% 0;
    };
`

export const StyledStepper = styled(MobileStepper)`
    justify-content: center;
    background-color: rgba(0,0,0,0);
    @media screen and (min-width: 600px) {
        display: none;
    };
`

export const SkeletonContainer = styled(Grid)`
    padding: 5% 0%;
    display: ${props => props.primary || 'none'};
    justify-content: center;
    @media screen and (max-width: 960px){
        padding: 0;
        display: ${props => props.primary && 'none'};
    };
`