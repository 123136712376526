import {styled} from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';

export const HeaderContainer = styled(Grid)`
    justify-content: space-between;
    margin: 64px 0 100px;
    padding: 0 32px;
`

export const MainTextContainer = styled(Grid)`
    margin: 0;
`

export const MainTextTypography = styled(Typography)`
    color: #DEDEE3;
`

export const PrimaryButton = styled(Button)`
    background-color: #DCC6B7;
    color: black;
    width: 100%;
    height: 56px;
    font-size: 15px;
    text-transform: none;
    margin-top: 100px;
`