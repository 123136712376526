import React, { useState } from 'react';
import {
    Typography,
    DialogContent,
    FormControl,
    RadioGroup,
    Radio,
    SvgIcon,
} from '@mui/material';

// Asset import
import { ReactComponent as FilterAltOutlined } from '~/assets/icons/filter_alt_outlined.svg';
import { ReactComponent as FilterAlt } from '~/assets/icons/filter_alt.svg';

// Util import
import { getBrandNameFromBrandId } from '~/util/brands';

// Style import
import * as Style from '../styles';

function TreinadoraAdoroCodigoBrandFilter({ brandFilter, setBrandFilter }) {
    const [brandFilterDialogIsOpen, setBrandFilterDialogIsOpen] = useState(
        false
    );
    const handleClickOnRadioButton = event => {
        setBrandFilter(event.target.value);
        setBrandFilterDialogIsOpen(false);
    };

    return (
        <>
            <Style.DialogContainer
                onClose={() => setBrandFilterDialogIsOpen(false)}
                open={brandFilterDialogIsOpen}
            >
                <Style.BrandFilterTitleContainer container>
                    <SvgIcon
                        style={{
                            fill: '#DCC6B7',
                            verticalAlign: 'text-bottom',
                        }}
                        component={FilterAltOutlined}
                    />
                    <Typography display="inline">Exibir Somente</Typography>
                </Style.BrandFilterTitleContainer>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={brandFilter}
                            onChange={e => handleClickOnRadioButton(e)}
                        >
                            <Style.BrandsFormControlLabel
                                value="allBrands"
                                control={
                                    <Radio
                                        sx={{
                                            color: '#DCC6B7',
                                            '&.Mui-checked': {
                                                color: '#DCC6B7',
                                            },
                                        }}
                                    />
                                }
                                label="Todos os pedidos"
                            />

                            <>
                                {[2, 5, 7].map(brand => (
                                    <Style.BrandsFormControlLabel
                                        value={`${getBrandNameFromBrandId(
                                            brand
                                        )}`}
                                        control={
                                            <Radio
                                                sx={{
                                                    color: '#DCC6B7',
                                                    '&.Mui-checked': {
                                                        color: '#DCC6B7',
                                                    },
                                                }}
                                            />
                                        }
                                        label={`Pedidos na ${getBrandNameFromBrandId(
                                            brand
                                        )}`}
                                    />
                                ))}
                            </>

                            <Style.BrandsFormControlLabel
                                value="farmLatam"
                                control={
                                    <Radio
                                        sx={{
                                            color: '#DCC6B7',
                                            '&.Mui-checked': {
                                                color: '#DCC6B7',
                                            },
                                        }}
                                    />
                                }
                                label="Pedidos na Farm LATAM"
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Style.DialogContainer>
            <Style.BrandFilterTextContainer
                item
                container
                onClick={() => {
                    setBrandFilterDialogIsOpen(true);
                }}
            >
                <SvgIcon
                    style={{
                        fill: '#DCC6B7',
                        verticalAlign: 'text-bottom',
                    }}
                    component={
                        brandFilter === 'allBrands'
                            ? FilterAltOutlined
                            : FilterAlt
                    }
                />
                <Style.BrandFilterTextType>Filtrar</Style.BrandFilterTextType>
            </Style.BrandFilterTextContainer>
        </>
    );
}

export default TreinadoraAdoroCodigoBrandFilter;
