import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// Style import
import * as Style from './styles';

function TextFieldHeader({ initialRankingData, setRankingData }) {
    const location = useLocation();

    const currentLocation = location.pathname.split('/')[1];

    const renderTitle = () => {
        if (currentLocation === 'storeRankingAffiliates') return 'Afiliadas';
        return 'Vendedores';
    };

    const handleTextFieldInput = e => {
        if (e.length) {
            const newFilteredRankingData = initialRankingData.filter(
                salesperson =>
                    salesperson.shortName
                        .toLowerCase()
                        .includes(e.toLowerCase()) ||
                    salesperson?.vendedor?.toLowerCase()?.includes(e.toLowerCase())
            );
            setRankingData(newFilteredRankingData);
            return;
        }
        setRankingData(initialRankingData);
    };

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                style={{ padding: '16px' }}
            >
                <Grid item xs={6}>
                    <Typography
                        style={{
                            fontWeight: 600,
                            fontSize: '20px',
                        }}
                    >
                        {renderTitle()}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container justifyContent="center">
                <Style.StyledTextField
                    fullWidth
                    shrink="true"
                    variant="outlined"
                    placeholder="Buscar Afiliada"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlinedIcon
                                    style={{
                                        color: '#DCC6B7',
                                    }}
                                />
                            </InputAdornment>
                        ),
                        style: { height: '42px' },
                    }}
                    onChange={e => {
                        handleTextFieldInput(e.target.value);
                    }}
                />
            </Grid>
        </>
    );
}

export default TextFieldHeader;
