// STEP 1 - Include Dependencies
// Include react
import React from 'react';

// Include the react-fusioncharts component
import ReactFC from 'react-fusioncharts';

// Include the fusioncharts library
import FusionCharts from 'fusioncharts';

// Include the chart type
import Column2D from 'fusioncharts/fusioncharts.charts';

// Include the theme as fusion
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { Grid, Typography } from '@mui/material';

// Adding the chart and theme as dependency to the core fusioncharts
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const ChartStoreOverview = ({ chartSalesData, chartOmniData }) => {
    // STEP 3 - Creating the JSON object to store the chart configurations
    const chartConfigs = {
        type: 'msline', // The chart type
        width: '100%', // Width of the chart
        height: '248', // Height of the chart
        dataFormat: 'json', // Data type
    };
    const shouldShowLabel = (totalLabelCount, currentLabelIndex) => {
        const START_INDEX = 0;
        const LAST_INDEX = totalLabelCount - 1;
        const MIDDLE_INDEX =
            totalLabelCount % 2 === 0
                ? Math.floor(totalLabelCount / 2) - 1
                : Math.floor(totalLabelCount / 2);
        if (
            [START_INDEX, LAST_INDEX, MIDDLE_INDEX].includes(currentLabelIndex)
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <Grid
                style={{
                    width: '100%',
                    height: '232px',
                    overflow: 'hidden',
                }}
            >
                <ReactFC
                    {...chartConfigs}
                    dataSource={{
                        // Chart Configuration
                        chart: {
                            theme: 'fusion',
                            showYAxisValues: '0', // hide y-axis labels
                            rotateLabels: '0', // x-axis-labels are horizontal
                            canvasPadding: '0', // remove canvas padding
                            bgColor: '#212121', // change bg color
                            palettecolors: '#DCC6B7', // change bar color
                            baseFontColor: '#9e9e9e', // change base font color
                            plotColorinTooltip: '0', // hide color legend on tooltip
                            labelFontSize: '12', // change label font size
                            adjustDiv: '0', // set horizontal div lines settings to manual
                            numDivLines: 1, // set horizontal div lines number to 1
                            drawCustomLegendIcon: '1',
                            showLabels: '0',
                            drawAnchors: '0',
                            showLegend: '0',
                            lineThickness: '2',
                        },
                        categories: [
                            {
                                category: chartSalesData.map(data => ({
                                    label: data.label,
                                })),
                            },
                        ],
                        dataset: [
                            {
                                seriesName: 'Vendas',
                                data: chartSalesData.map(data => ({
                                    value: data.value
                                        ? Number(data.value).toFixed(2)
                                        : 0,
                                })),
                                plotToolText: '$label <br> Vendas: R$ $value',
                                color: '#1BB55C',
                            },
                            {
                                seriesName: 'Omni',
                                data: chartOmniData.map(data => ({
                                    value: data.value
                                        ? Number(data.value).toFixed(2)
                                        : 0,
                                })),
                                plotToolText: 'Omni: R$ $value',
                                color: '#055998',
                            },
                        ],
                    }}
                />
            </Grid>
            <Grid
                container
                justifyContent="space-between"
                style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    marginBottom: '16px',
                }}
            >
                {chartSalesData
                    .filter((_data, index) =>
                        shouldShowLabel(chartSalesData.length, index)
                    )
                    .map((data, key) => (
                        <Grid item key={`${key}-${data.label}`}>
                            <Typography
                                style={{
                                    color: '#9E9E9E',
                                    fontSize: '12px',
                                }}
                            >
                                {data.label}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
            <Grid
                container
                justifyContent="space-evenly"
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >
                <Grid item container justifyContent="space-evenly">
                    <Grid
                        item
                        container
                        justifyContent="center"
                        style={{ width: 'fit-content' }}
                    >
                        <Grid
                            item
                            style={{
                                alignSelf: 'center',
                                paddingRight: '6px',
                            }}
                        >
                            <Grid
                                style={{
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: '#1BB55C',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    color: '#9E9E9E',
                                    fontSize: '12px',
                                }}
                            >
                                Vendas
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        style={{ width: 'fit-content' }}
                    >
                        <Grid
                            item
                            style={{
                                alignSelf: 'center',
                                paddingRight: '6px',
                            }}
                        >
                            <Grid
                                style={{
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: '#055998',
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography
                                style={{
                                    color: '#9E9E9E',
                                    fontSize: '12px',
                                }}
                            >
                                Operacional Omni
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ChartStoreOverview;
