import React from "react";

import ChallengePanelCard from "~/components/PanelCards/ChallengePanelCard";
import LoadingComponent from "../LoadingComponent";

import * as Style from './styles';

function ChallengePagePanel({panelLoaded, challengeData, totalSalesValue}) {

    return(
        <>
            {panelLoaded ? (
                <Style.Wrap>
                    <ChallengePanelCard
                        challengeData={challengeData}
                        totalSalesValue={totalSalesValue}
                    />
                    <Style.StyledStepper
                        variant="dots"
                        steps={1}
                        position="static"
                        activeStep={0}
                    />
                </Style.Wrap>
            ):
                <LoadingComponent />
            }
        </>
    )
}

export default ChallengePagePanel;