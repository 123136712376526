import { Grid, Typography, IconButton } from '@mui/material';
import { Form } from '@unform/web';
import 'moment/locale/pt-br';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import FirstAccessInput from '~/components/FirstAccessInput';
import api from '~/services/api';
import * as Style from './styles';

function LoginInsert({ ...props }) {
    const history = useHistory();
    const formRef = useRef(null);
    const handleFormSubmit = async formData => {
        try {
            const userData = await api.post('forgot_password', {
                email: formData.salesCode.toLowerCase(),
            });
            props.setUserData(userData.data.user);
            props.setCurrentPage('successPage');
        } catch (error) {
            // SnackBar.error('Erro ao buscar E-mail');
            props.setCurrentPage('successPage');
        }
    };

    const submitForm = () => {
        formRef.current.submitForm();
    };

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <IconButton onClick={() => history.push('/login')}>
                            <Style.StyledIcon/>
                        </IconButton>
                    </Grid>
                    <Style.HeaderContainer container>
                        <Grid item xs={8}>
                            <Typography align="left" variant="h4">
                                Vamos te ajudar!
                            </Typography>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid item container justifyContent="center" spacing={2}>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Informe seu e-mail para redefinir sua senha{' '}
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={10}>
                            <Form onSubmit={handleFormSubmit} ref={formRef}>
                                <FirstAccessInput
                                    label="E-mail"
                                    placeholder="fulano@provedor.com"
                                    name="salesCode"
                                />
                            </Form>
                        </Grid>
                        <Grid item xs={10} container justifyContent="space-evenly">
                            <Style.PrimaryButton
                                onClick={submitForm}
                            >
                                {' '}
                                RECUPERAR SENHA
                            </Style.PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default LoginInsert;
