import {styled} from '@mui/material/styles';
import {
    Grid,
    TextField,
    Typography,
    List,
    ListItem,
    Button,
    Box,
    Accordion,
    AccordionDetails,
} from '@mui/material';

export const Root = styled(Grid)`
    margin: 24px;
    background-color: #373435;
`

export const StyledAccordion = styled(Accordion)`
    background-color: #373435;
    box-shadow: none;
    border-bottom: 1px solid #9E9E9E;
`

export const StyledList = styled(List)`
    background-color: #373435;
    border-top: 1px solid #9E9E9E;
    padding: 0;
    & .MuiButtonBase-root {
        padding: 0;
    };
`

export const ProductContainer = styled(Grid)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledListItem = styled(ListItem)`
    padding: 0;
`

export const StyledDetails = styled(AccordionDetails)`
    background-color: #373435;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const SearchField = styled(TextField)`
    border-radius: 24px;
    border-color: #DCC6B7;
    margin: 16px 0;
    font-weight: 500;
    font-family: inherit;
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #DCC6B7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #DCC6B7;
    };
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #DCC6B7;
            border-radius: 24px;
        };
        &:hover fieldset {
            border-color: #DCC6B7;
        };
        & .Mui-focused {
            border-color: #DCC6B7;
        };
    };
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
    };
    & .MuiInputLabel-root {
        color: #DCC6B7;
    };
    & .Mui-focused {
        border-color: #DCC6B7;
    };
    & .MuiInputBase-root {
        color: white;
    };
`

export const MainCardTypography = styled(Typography)`
    font-weight: ${props => props.inputWeight || '500'};
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: ${props => props.primary && '16px'};
`

export const PrimaryButton = styled(Button)`
    background-color: #DCC6B7;
    color: #373435;
    height: 40px;
    width: 50%;
    justify-self: center;
    align-self: center;
    font-size: 15px;
    text-transform: none;
    margin-top: 16px;
    &:disabled { 
        background-color: #373435; 
        color: #616161;
    };
    &:hover { 
        background-color: #b8a598;
    };
`

export const BoxTest = styled(Box)`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    height: fit-content;
    width: 60%;
    background-color: transparent;
    @media screen and (max-width: 600px){
        width: 100%;
    };
`

export const PageTitle = styled(Box)`
    display: flex;
    margin: 56px 24px 0;
    font-size: 1.5rem;
`;