import {
    InputAdornment,
    IconButton,
} from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Form } from '@unform/web';
import 'moment/locale/pt-br';
import React, { useRef, useState } from 'react';
import logoSoma from '../../../assets/icons/Logo_Grupo_Soma_branco.svg';
import NewPasswordInput from '~/components/NewPasswordInput';
import api from '~/services/api';
import SnackBar from '~/util/SnackBar';
import * as Style from './styles';

function LoginInsert({ ...props }) {
    const formRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async formData => {
        try {
            setLoading(true);
            formRef.current.setErrors({});
            if (newPassword < 6 || newPasswordConfirmation.length < 6) {
                formRef.current.setFieldError(
                    'passwordConfirmation',
                    'a nova senha deve ter no mínimo 6 caracteres'
                );
                return;
            }

            if (newPassword !== newPasswordConfirmation) {
                formRef.current.setFieldError(
                    'passwordConfirmation',
                    'senha e confirmação de senha são diferentes'
                );
                return;
            }

            const userData = await api.post('reset_password', {
                password_reset_token: props.token,
                newPassword,
            });

            props.setUserData(userData.data.user);
            props.setCurrentPage('successPage');
        } catch (error) {
            SnackBar.error('Erro ao redefinir senha');
        } finally {
            setLoading(false);
        }
    };

    const submitForm = () => {
        formRef.current.submitForm();
    };

    return (
        <Style.MainContainer container>
            <Style.HeaderContainer item>
                <Style.Logo
                    component='img'
                    src={logoSoma}
                    alt="Grupo Soma"
                />
            </Style.HeaderContainer>
            <Style.MainTextContainer>
                <Style.MainTextTypography>
                    Redefinição de senha
                </Style.MainTextTypography>
            </Style.MainTextContainer>
            <Style.FormContainer item>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                    <NewPasswordInput
                        label="Nova senha"
                        name="password"
                        disabled={loading}
                        type={showPassword ? 'text' : 'password'}
                        value={newPassword}
                        onChange={e => {
                            setNewPassword(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Mostrar senha"
                                        onClick={() =>
                                            setShowPassword(
                                                !showPassword
                                            )
                                        }
                                        color="#000000"
                                        style={{
                                            width: '10px',
                                            color: '#000000',
                                        }}
                                    >
                                        {showPassword ? (
                                            <VisibilityOffOutlinedIcon />
                                        ) : (
                                            <VisibilityOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <NewPasswordInput
                        label="Confirme sua senha"
                        name="passwordConfirmation"
                        disabled={loading}
                        type={showPassword ? 'text' : 'password'}
                        value={newPasswordConfirmation}
                        onChange={e => {
                            setNewPasswordConfirmation(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Mostrar senha"
                                        onClick={() =>
                                            setShowPassword(
                                                !showPassword
                                            )
                                        }
                                        color="#000000"
                                        style={{
                                            width: '10px',
                                            color: '#000000',
                                        }}
                                    >
                                        {showPassword ? (
                                            <VisibilityOffOutlinedIcon />
                                        ) : (
                                            <VisibilityOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Form>
            </Style.FormContainer>
            <Style.ButtonContainer item>
                {!!newPassword && !!newPasswordConfirmation ? (
                    <Style.PrimaryButton
                        primary
                        onClick={submitForm}
                    >
                        {' '}
                        Confirmar
                    </Style.PrimaryButton>
                ) : (
                    <Style.PrimaryButton>
                        Confirmar
                    </Style.PrimaryButton>
                )}
            </Style.ButtonContainer>
        </Style.MainContainer>
    );
}

export default LoginInsert;
