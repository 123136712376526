import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const HeaderContainer = styled(Grid)`
    height: 88px;
    background-color: #dcc6b7;
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const HeaderTypography = styled(Typography)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #373435;
    font-size: 20px;
    font-family: 'Inter';
    font-weight: 600;
    text-transform: capitalize;
`;