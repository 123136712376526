const getColorFromPercentage = percentage => {
    const percentageAsFloat = parseFloat(percentage);
    if (percentageAsFloat === 0) {
        return '#E26257';
    }
    if (!percentageAsFloat) {
        return '#757575';
    }
    if (percentageAsFloat >= 100) {
        return '#1BB55C';
    }
    return '#E26257';
};

const convertCurrencyToNumber = currencyString => {
    if (!currencyString) return '';
    currencyString = currencyString.replace(/[^0-9,-]+/g, '');
    return Number(currencyString.replace(',', '.'));
};

const getLinearProgressValue = percentage => {
    if (isNaN(parseFloat(percentage))) {
        return 0;
    }
    return parseFloat(percentage) < 100 ? parseFloat(percentage) : 100;
};

export {
    getColorFromPercentage,
    convertCurrencyToNumber,
    getLinearProgressValue,
};
