const getBrandsFromIdBrand = profile => {
    if (profile.marcaFilial) {
        if (profile.marcaFilial === 1) {
            return [6]; //            ANIMALE
        }
        if (profile.marcaFilial === 3) {
            return [1, 6]; //            ABRAND
        }
        if (profile.marcaFilial === 6) {
            return []; //            OFFPREMIUM
        }
        if (profile.marcaFilial === 2) {
            return [5, 7]; //         FARM
        }
        if (profile.marcaFilial === 5) {
            return []; //                FABULA
        }
        if (profile.marcaFilial === 7) {
            return []; //                FOXTON
        }
        if (profile.marcaFilial === 9) {
            return []; //                       CRIS BARROS
        }
        if (profile.marcaFilial === 15) {
            return [6, 15]; //                      MARIA FILO
        }
        return [1, 2, 3, 5, 6, 7, 9, 15]; // CORPORATIVO
    }
    if (profile.marcaFilialMultimarca) {
        return profile.marcaFilialMultimarca; //    MULTIMARCA
    }
    if (profile.id_perfil === 14) {
        return [2];
    }
    return [1, 2, 3, 5, 6, 7, 9, 15];
};
export default getBrandsFromIdBrand;
