import React from 'react';
import { Dialog, Button, Grid } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function QuotaRegisterConfirmationModal({
    open,
    onClose,
    onAccept,
    onFinish,
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '328px',
                },
            }}
            disableScrollLock
        >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                    height: '100%',
                    width: '100%',
                    padding: '16px',
                    backgroundColor: '#373435',
                }}
            >
                <Grid
                    item
                    style={{
                        color: '#FFFFFF',
                        textAlign: 'center',
                        fontSize: '24px',
                        marginBottom: '24px',
                    }}
                >
                    <b>Cotas Cadastradas</b>
                </Grid>

                <Grid
                    item
                    style={{
                        color: '#1BB55C',
                        margin: '16px 0px',
                    }}
                >
                    <CheckCircleOutlineIcon
                        style={{
                            height: '70px',
                            width: '70px',
                        }}
                    />
                </Grid>

                <Grid
                    item
                    style={{
                        color: '#FFFFFF',
                        textAlign: 'center',
                        fontSize: '16px',
                        marginBottom: '24px',
                        maxWidth: '280px',
                        lineHeight: '1.2'
                    }}
                >
                    Você salvou a distribuição de cotas para todo o seu time de
                    vendas.
                </Grid>
                <Grid
                    item
                    style={{
                        color: '#FFFFFF',
                        textAlign: 'center',
                        fontSize: '16px',
                        marginBottom: '24px',
                        maxWidth: '280px',
                        lineHeight: '1.2'
                    }}
                >
                    Para cadastrar ausências de cada vendedor, com impacto ou
                    não nas diárias, clique em ausências.
                </Grid>

                <Grid
                    item
                    container
                    justifyContent="space-evenly"
                    style={{ borderRadius: '8px', marginBottom: '24px' }}
                >
                    <Button
                        onClick={onFinish}
                        style={{
                            backgroundColor: '#dcc6b7',
                            color: '#373435',
                            height: '40px',
                            textTransform: 'capitalize',
                            borderRadius: '8px',
                        }}
                    >
                        Finalizar
                    </Button>
                    <Button
                        onClick={onAccept}
                        style={{
                            backgroundColor: '#373435',
                            color: '#dcc6b7',
                            border: '1px solid #dcc6b7',
                            height: '40px',
                            textTransform: 'capitalize',
                            borderRadius: '8px',
                        }}
                    >
                        Ausências
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}
