import { styled } from '@mui/material/styles';
import { Grid , Typography } from '@mui/material';

export const MainContainer = styled(Grid)`
    background-color: #FFFFFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 592px) {
        padding-top: 42px;
        justify-content: flex-start;
    }
`

export const HeaderContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 32px;
    width: ${props => props.success ? '286px' : '256px' };
    @media screen and (max-width: 592px) {
        width: ${props => props.success ? '190px' : '204px' };
    }
`

export const TextContainer = styled(Grid)`
    width: ${props => props.primary && '100%'};
    margin-bottom: 40px;
    text-align: center;
    color: #151715;
`

export const Text = styled(Typography)`
    color: #151715;
    font-size: ${props => props.primary ? '40px' : '24px'};
    @media screen and (max-width: 592px) {
        font-size: ${props => props.primary ? '24px' : '14px'};
    }
`