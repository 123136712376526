import moment from 'moment';

moment.locale('pt-br');

function formatFloatToTwoDecimals(number) {
    return Math.round(number * 100) / 100;
}

function groupSalesByChannelAndDay(aggregatedOrders) {
    const total = { orderQuantity: 0, value: 0, productQuantity: 0 };
    const returns = { orderQuantity: 0, value: 0, productQuantity: 0 };
    const aggregatedByChannel = {};
    const aggregatedByDay = {};
    const aggregatedByHour = {};

    [...Array(24).keys()].forEach(hour => {
        aggregatedByHour[hour] = {
            hour,
            prettyHour: `${hour}h`,
            value: 0,
        };
    });

    for (let i = 0; i < aggregatedOrders.length; i += 1) {
        const { channel, date } = aggregatedOrders[i];

        aggregatedByChannel[channel] = {
            orderQuantity: 0,
            value: 0,
            productQuantity: 0,
            serviceQuantity: 0,
        };
        aggregatedByDay[date] = {
            orderQuantity: 0,
            value: 0,
            productQuantity: 0,
        };
    }
    const seenServiceKeys = [];

    for (let i = 0; i < aggregatedOrders.length; i += 1) {
        const orderQuantity = Number(aggregatedOrders[i].orderQuantity);
        const value = Number(aggregatedOrders[i].value);
        const productQuantity = Number(aggregatedOrders[i].productQuantity);
        const { channel, date, clientId } = aggregatedOrders[i];
        const hour = moment(aggregatedOrders[i].dateTime).hour();
        let serviceQuantity = 1;
        if (seenServiceKeys.includes(`${clientId}-${date}`)) {
            serviceQuantity = 0;
        } else {
            seenServiceKeys.push(`${clientId}-${date}`);
        }
        aggregatedByChannel[channel].serviceQuantity += serviceQuantity;
        aggregatedByChannel[channel].orderQuantity += orderQuantity;
        aggregatedByChannel[channel].value += value;

        aggregatedByChannel[channel].productQuantity += productQuantity;
        aggregatedByChannel[
            channel
        ].averageProductQttyPerOrder = formatFloatToTwoDecimals(
            aggregatedByChannel[channel].productQuantity /
                aggregatedByChannel[channel].orderQuantity
        );
        aggregatedByChannel[
            channel
        ].averageValuePerOrder = formatFloatToTwoDecimals(
            aggregatedByChannel[channel].value /
                aggregatedByChannel[channel].orderQuantity
        );

        aggregatedByDay[date].orderQuantity += orderQuantity;
        aggregatedByDay[date].value += value;
        aggregatedByDay[date].productQuantity += productQuantity;
        aggregatedByDay[
            date
        ].averageProductQttyPerOrder = formatFloatToTwoDecimals(
            aggregatedByDay[date].productQuantity /
                aggregatedByDay[date].orderQuantity
        );
        aggregatedByDay[date].averageValuePerOrder = formatFloatToTwoDecimals(
            aggregatedByDay[date].value / aggregatedByDay[date].orderQuantity
        );
        aggregatedByHour[hour].value += value;

        if (value > 0) {
            total.orderQuantity += orderQuantity;
            total.value += value;
            total.productQuantity += productQuantity;
            total.averageProductQttyPerOrder = formatFloatToTwoDecimals(
                total.productQuantity / total.orderQuantity
            );
            total.averageValuePerOrder = formatFloatToTwoDecimals(
                total.value / total.orderQuantity
            );
        } else {
            returns.orderQuantity += orderQuantity;
            returns.value += value;

            returns.productQuantity += productQuantity;
            returns.averageProductQttyPerOrder = formatFloatToTwoDecimals(
                returns.productQuantity / returns.orderQuantity
            );
            returns.averageValuePerOrder = formatFloatToTwoDecimals(
                returns.value / returns.orderQuantity
            );
        }
    }

    return {
        total,
        aggregatedByChannel,
        aggregatedByDay,
        aggregatedByHour,
        returns,
    };
}

function groupSalesByBrand(orders) {
    const aggregatedByBrand = {};

    for (let i = 0; i < orders.length; i += 1) {
        const { brandId } = orders[i];

        aggregatedByBrand[brandId] = {
            orderQuantity: 0,
            value: 0,
            productQuantity: 0,
        };
    }

    for (let i = 0; i < orders.length; i += 1) {
        const orderQuantity = Number(orders[i].orderQuantity);
        const value = Number(orders[i].value);
        const productQuantity = Number(orders[i].productQuantity);
        const { brandId } = orders[i];

        aggregatedByBrand[brandId].orderQuantity += orderQuantity;
        aggregatedByBrand[brandId].value += value;

        aggregatedByBrand[brandId].productQuantity += productQuantity;
        aggregatedByBrand[
            brandId
        ].averageProductQttyPerOrder = formatFloatToTwoDecimals(
            aggregatedByBrand[brandId].productQuantity /
                aggregatedByBrand[brandId].orderQuantity
        );
        aggregatedByBrand[
            brandId
        ].averageValuePerOrder = formatFloatToTwoDecimals(
            aggregatedByBrand[brandId].value /
                aggregatedByBrand[brandId].orderQuantity
        );
    }
    return aggregatedByBrand;
}

export default (startDate, endDate, orders) => {
    const isSameDay = moment(startDate).isSame(endDate, 'day');
    const isSameWeek = moment(startDate).isSame(endDate, 'week');
    const isSameMonth = moment(startDate).isSame(endDate, 'month');
    const dayDiff = moment(endDate).diff(startDate, 'days');

    const periodSales = [];

    const formattedSales = orders.map(venda => ({
        date: venda?.devolutionDate
            ? venda?.devolutionDate.slice(0, 10)
            : venda.creationDate.slice(0, 10),
        dateTime: venda?.devolutionDate
            ? venda?.devolutionDate
            : venda.creationDate,
        channel: venda.channel,
        orderQuantity: 1,
        value: venda.value,
        productQuantity: venda.productQuantity,
        brandId: venda.brandId || null,
        clientId: venda.clientId,
        status: venda.ultimo_status,
    }));

    const ordersGroupedByChannelAndDay = groupSalesByChannelAndDay(
        formattedSales
    );

    const aggregatedByBrand = groupSalesByBrand(formattedSales);

    if (isSameDay)
        periodSales.push({
            period: 'day',
            sales: ordersGroupedByChannelAndDay,
        });
    if (isSameWeek && dayDiff === 6)
        periodSales.push({
            period: 'week',
            sales: ordersGroupedByChannelAndDay,
        });
    if (isSameMonth)
        periodSales.push({
            period: 'month',
            sales: ordersGroupedByChannelAndDay,
        });

    return {
        data: {
            ...ordersGroupedByChannelAndDay,
            aggregatedByBrand,
        },
        periodSales,
    };
};
