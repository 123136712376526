export const ROUTES = {
    ADORO_CODIGO_CHALLENGE: '/adoroCodigoChallenge',
    COTA_PILOTO: '/cotaPiloto',
    CONTEUDO_PARA_POSTS: 'conteudoParaPosts',
    LOGIN: '/login',
    STORE_MULTIMARCA: '/storeMultimarca',
    STORE_MULTIMARCA_PILOTO: '/storeMultimarcaPiloto',
    STORE_RANKING: '/storeRanking',
    STORE_RANKING_AFFILIATES: '/storeRankingAffiliates',
    STORE_COTA: '/storeCota',
    OMNI_OPERATIONAL: '/omniOperational',
    EXTRA_ORDERS: '/extraOrders',
    STORE_OMNI: '/storeOmni',
    STORE_OVERVIEW: '/storeOverview',
    VENDEDOR_CORPORATIVO: '/vendedorCorporativo',
    VENDEDORA_PILOTO: '/vendedoraPiloto',
    VENDEDORA_MULTIMARCA_PILOTO: '/vendedoraMultimarcaPiloto',
    VENDEDORA_ADORO_CODIGO: '/vendedoraAdoroCodigo',
    EXPERIMENTATION: '/experimentacao',
    MANAGE_USERS: '/users',
    SHARE_CART: '/maisCarrinho',
    SHARE_ECOMMERCE: '/shareEcommerce',
    LOGOFF: '/logoff',
    VENDEDORES: '/vendedores',
    CADASTRO_DE_COTAS: '/cadastroDeCotas',
};

// export const BASE_URL = "http://localhost:8000";
// export const BASE_URL = "https://sticker-album-backend-dot-apt-bonbon-179602.appspot.com";
//
export const BASE_URL =
    'https://sticker-album-backend-homolog-dot-apt-bonbon-179602.appspot.com';
