import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { maisCarrinhoApi } from '~/services/api';

import SnackBar from '~/util/SnackBar';

import * as Style from './styles';

function RemoveProduct({
    removeProductDialog,
    setRemoveProductDialog,
    setRemoveProduct,
    updatedSelectedCart,
    setProductName,
    productName,
    productToBeRemoved,
}) {
    const SendRemoveToGTM = productId => {
        const selectedProductDetails = updatedSelectedCart?.saved_cart_products?.filter(
            item => item.id_saved_cart_product_color === productId
        )[0];
        window.dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'BRL',
                add: {
                    products: [
                        {
                            name: `${selectedProductDetails?.vtexData?.productName}`,
                            id: `${selectedProductDetails?.vtexData?.productId}`,
                            price: `${selectedProductDetails?.price}`,
                            brand: `${selectedProductDetails?.vtexData?.brand}`,
                            quantity: `${selectedProductDetails?.quantity}`,
                            category: `${selectedProductDetails?.vtexData?.categoryId}`,
                        },
                    ],
                },
            },
        });
    };

    const removeProducts = async product => {
        try {
            await maisCarrinhoApi.patch(
                `saved-cart/remove-products?id_saved_cart_product_color=${product}`
            );
            SnackBar.success('Produto Removido');
            setRemoveProduct(true);
        } catch (err) {
            SnackBar.error('Erro ao remover produto');
        }
    };

    return (
        <Style.DialogProps
            open={removeProductDialog}
            onClose={() => {
                setRemoveProductDialog(false);
            }}
        >
            <Style.DialogRoot container>
                <Style.DialogTitle>Remover Produto</Style.DialogTitle>
                <Style.ErrorIconContainer>
                    <ErrorOutlineOutlinedIcon
                        style={{
                            color: '#E26257',
                            fontSize: '36px',
                            fontFamily: 'Inter',
                        }}
                    />
                </Style.ErrorIconContainer>
                <Style.DialogDefaultTypography item>
                    Tem certeza que quer remover <b>{productName}</b> de{' '}
                    <b>{updatedSelectedCart.cart_name}</b>?<br />
                    Esta é uma ação irreversível.
                </Style.DialogDefaultTypography>
                <Style.ButtonsContainer>
                    <Style.DialogButton
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            setRemoveProductDialog(false);
                        }}
                    >
                        Cancelar
                    </Style.DialogButton>
                    <Style.DialogButton
                        isDelete
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            removeProducts(productToBeRemoved);
                            setRemoveProductDialog(false);
                            setProductName('');
                            SendRemoveToGTM(productToBeRemoved);
                        }}
                    >
                        Excluir
                    </Style.DialogButton>
                </Style.ButtonsContainer>
            </Style.DialogRoot>
        </Style.DialogProps>
    );
}

export default RemoveProduct;
