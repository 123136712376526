import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useHistory } from 'react-router-dom';

import { maisCarrinhoApi } from '~/services/api';

import SnackBar from '~/util/SnackBar';

import * as Style from './styles';

function DeleteCart({
    deleteCartDialogOpen,
    setDeleteCartDialogOpen,
    selectedCart,
}) {
    const history = useHistory();

    const deleteCart = async () => {
        try {
            await maisCarrinhoApi.patch(
                `/saved-cart/delete-cart?id_saved_cart=${selectedCart.id_saved_cart}`,
                {
                    active_cart: 0,
                }
            );
            SnackBar.success('Carrinho Excluído');
            history.push('/maisCarrinho');
        } catch (err) {
            SnackBar.error('Erro ao excluir carrinho');
        }
    };

    return (
        <Style.DialogProps
            open={deleteCartDialogOpen}
            onClose={() => {
                setDeleteCartDialogOpen(false);
            }}
        >
            <Style.DialogRoot container>
                <Style.DialogTitle>Excluir Carrinho</Style.DialogTitle>
                <Style.ErrorIconContainer>
                    <ErrorOutlineOutlinedIcon
                        style={{
                            color: '#E26257',
                            fontSize: '36px',
                            fontFamily: 'Inter',
                        }}
                    />
                </Style.ErrorIconContainer>
                <Style.DialogDefaultTypography>
                    Tem certeza que deseja excluir <br />
                    <b>{selectedCart.cart_name}</b>?<br />
                    Esta é uma ação irreversível.
                </Style.DialogDefaultTypography>
                <Style.ButtonsContainer>
                    <Style.DialogButton
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            setDeleteCartDialogOpen(false);
                        }}
                    >
                        Cancelar
                    </Style.DialogButton>
                    <Style.DialogButton
                        isDelete
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            deleteCart();
                            setDeleteCartDialogOpen(false);
                        }}
                    >
                        Excluir
                    </Style.DialogButton>
                </Style.ButtonsContainer>
            </Style.DialogRoot>
        </Style.DialogProps>
    );
}

export default DeleteCart;
