import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/modules/auth/actions';

function Logoff() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(signOut());
    }, [dispatch]);
    return (
        <>
            <div style={{ height: '100vh' }}>Fazendo Logout...</div>
        </>
    );
}

export default Logoff;
