import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

export const MainButton = styled(Button)`
    height: 56px;
    width: 100%;
    font-weight: 500;
    border-radius: 8px;
    margin-top: 3px;
    font-family: inherit;
    background-color: #DCC6B7;
    color: #212121;
    cursor: pointer;
    &:hover {
        background-color: #A99384;
    }

    &.Mui-disabled {
        background-color: #616161;
        color: #424242;
        cursor: auto;

        &:hover {
            background-color: #616161;
        }
    }
`;

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    align-items: center;
`

export const PageBodyContainer = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const ButtonContainer = styled(Box)`
    width: 50%;
    background-color: #212121;
    color: #212121;
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    padding: 16px;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`