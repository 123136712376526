import { Grid, Typography, IconButton } from '@mui/material';
import { Form } from '@unform/web';
import 'moment/locale/pt-br';
import React, { useRef } from 'react';
import FirstAccessInput from '~/components/FirstAccessInput';
import api from '~/services/api';
import * as Style from './styles';
import SnackBar from '~/util/SnackBar';

function Login({ ...props }) {
    const formRef = useRef(null);
    const handleFormSubmit = async formData => {
        try {
            formData.clifor = formData.clifor.replace(/[^a-zA-Z0-9]/g, '');
            const userData = await api.post('/manage-users/check-user', {
                clifor: formData.clifor,
                cnpj: formData.cnpj.replace(/\D/g, ''),
            });
            SnackBar.success('Usuario Encontrado');
            props.setUserData(userData.data.user);
            props.setCurrentPage('registerAffiliates');
        } catch (error) {
            if (error?.message === 'Erro de conexão') {
                SnackBar.error(
                    'Falha na conexão. Atualize a página e tente novamente.'
                );
            }
            if (error?.response?.data?.error) {
                SnackBar.error(error.response.data.error);
            } else {
                SnackBar.error('Erro ao buscar usuário');
            }
        }
    };

    const submitForm = () => {
        formRef.current.submitForm();
    };
    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <IconButton
                            onClick={() =>
                                props.setCurrentPage('onboardAffiliates')
                            }
                        >
                            <Style.StyledIcon/>
                        </IconButton>
                    </Grid>
                    <Style.HeaderContainer container>
                        <Grid item xs={8}>
                            <Typography align="left" variant="h4">
                                Estamos quase lá.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Style.HeaderStepNumber
                                variant="h2"
                                align="right"
                            >
                                02
                            </Style.HeaderStepNumber>
                        </Grid>
                    </Style.HeaderContainer>
                    <Grid container justifyContent="center" spacing={2}>
                        <Style.MainTextContainer item xs={9}>
                            <Style.MainTextTypography
                                variant="body1"
                            >
                                Insira seu código clifor e CNPJ para que
                                possamos te ajudar a completar o cadastro.
                            </Style.MainTextTypography>
                        </Style.MainTextContainer>
                        <Grid item xs={10}>
                            <Form onSubmit={handleFormSubmit} ref={formRef}>
                                <FirstAccessInput
                                    label="Clifor"
                                    name="clifor"
                                />
                                <FirstAccessInput
                                    label="CNPJ"
                                    name="cnpj"
                                    mask="99.999.999/9999-99"
                                />
                            </Form>
                        </Grid>
                        <Grid item xs={10} container justifyContent="space-evenly">
                            <Style.DefaultButton
                                onClick={submitForm}
                            >
                                {' '}
                                Próximo
                            </Style.DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Login;
