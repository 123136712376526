import React from 'react';
import { Grid, InputAdornment } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// Util import
import {
    getListByFilter,
    applySearchFitler,
    getTotalValue,
    applyOrdersFilter,
} from '~/util/orderPanelFunctions';

import * as Style from './styles';

function SearchBar({
    orders,
    setFilteredOrders,
    setTotalFilterValue,
    filter,
    ordersFilter,
    searchFilter,
    setSearchFilter,
}) {
    const handleSearch = e => {
        let filteredOrders = orders;

        if (filter && filter !== 'Todos') {
            filteredOrders = getListByFilter(filteredOrders, filter);
        }

        if (ordersFilter && ordersFilter !== 'allOrders') {
            filteredOrders = applyOrdersFilter(filteredOrders, ordersFilter);
        }

        filteredOrders = applySearchFitler(filteredOrders, e);

        const getNewTotalFilterValue = getTotalValue(filteredOrders);

        setTotalFilterValue(getNewTotalFilterValue);
        setFilteredOrders(filteredOrders);
        setSearchFilter(e);
    };

    return (
        <Grid item container justifyContent="center">
            <Style.SearchBar
                fullWidth
                shrink="true"
                variant="outlined"
                placeholder="Buscar"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon
                                style={{
                                    color: '#DCC6B7',
                                }}
                            />
                        </InputAdornment>
                    ),
                    style: { height: '42px' },
                }}
                value={searchFilter}
                onChange={e => {
                    handleSearch(e.target.value);
                }}
            />
        </Grid>
    );
}

export default SearchBar;
