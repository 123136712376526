import React from 'react';

import { ReactComponent as AssignmentOutlinedIcon } from '~/assets/icons/AssignmentOutlinedIcon.svg';

import NewFeatureAlert from '~/components/NewFeatureAlert';

import { ModalIcon, ModalText } from './styles';

export default function NewDataWarning({ openModal, setOpenModal }) {
    const handleCloseModal = () => {
        setOpenModal(false);
        localStorage.setItem('OmniDataWarning', 'seen');
    };
    return (
        <NewFeatureAlert
            open={openModal}
            onClose={() => handleCloseModal()}
            handleButtonClick={() => handleCloseModal()}
            title="Novidade no +Vendas"
            icon={
                <ModalIcon
                    component={AssignmentOutlinedIcon}
                    width={1300}
                    height={1300}
                    viewBox="0 0 126 125"
                />
            }
        >
            <ModalText>
                Agora é possível acompanhar a quantidade de pedidos faturados do
                OMNI
            </ModalText>
        </NewFeatureAlert>
    );
}
