import {styled} from '@mui/material/styles';
import {
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Dialog,
    Radio
} from '@mui/material';

export const ComponentRoot = styled(Box)`
    margin: 16px;
    background-color: #373435;
`

export const OrdersPanelTitleContainer = styled(Grid)`
    justify-content: space-between;
    padding: 16px;
`

export const ListContainer = styled(List)`
    width: 100%;
    background-color: transparent;
`

export const ListItemContainer = styled(ListItem)`
    border-top: 1px solid #9E9E9E;
    border-bottom: 1px solid #9E9E9E;
    padding: 10px 0;
    justify-content: center;
`

export const ListItemTextContainer = styled(ListItemText)`
    color: white;
    text-align: center;
`

export const BrandFilterTitleContainer = styled(Grid)`
    display: flex;
    align-items: center;
    background-color: #424242;
    padding: 16px;
`

export const BrandsFormControlLabel = styled(FormControlLabel)`
    margin-bottom: 8px;
    font-family: 'Inter';
    font-size: 1rem;
    font-weight: 400;
    & .MuiButtonBase-root-MuiRadio-root.Mui-checked {
        color: #DCC6B7;
    }
`

export const BrandFilterTextContainer = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    width: auto;
    margin-top: ${props => props.primary && '4px'};
`

export const BrandFilterTextType = styled(Typography)`
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    color: #DCC6B7;
    margin: 0px 8px ;
`

export const DialogContainer = styled(Dialog)`
    & .MuiPaper-root {
        background-color: #424242;
        color: white;
        font-family: 'Inter';
    };
    & .MuiTypography-root {
        font-family: 'Inter'; 
    };
    & .MuiDialogContent-root {
        padding: 8px 24px;
        background-color: #424242;
    }
`

export const RadioComponent = styled(Radio)`
    color: #DCC6B7;
    & .MuiButtonBase-root-MuiRadio-root.Mui-checked {
        color: #DCC6B7;
    }
`