import {styled} from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

export const Main = styled(Grid)`
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
`

export const BrandContainer = styled(Grid)`
    height: auto;
    min-height: ${props => props.secondary && '350px'};
    padding: 20px;
    border-radius: 4px;
    background-color: #373435;
    margin-top: 16px;
    justify-content: ${props => props.primary && 'space-between'};
    align-items: ${props => props.primary && 'center'};
`

export const MainTypography = styled(Typography)`
    font-size: 16px;
    font-weight: ${props => props.primary ? '400' : '600'};
    margin-left: ${props => props.inputMargin || 0};
    @media screen and (max-width: 960px) {
        margin-left: 0;
    }
`

export const ColorTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.inputColor || 'white'};
`

export const SecondaryTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
`

export const PrimaryGridContainer = styled(Grid)`
    justify-content: flex-start;
    @media screen and (max-width: 960px) {
        justify-content: space-between;
    }
`

export default theme => ({
    main: {
        padding: '16px'
    },

    brandContainer: {
        height: 'auto',
        padding: '20px',
        borderRadius: '4px',
        backgroundColor: '#373435',
        marginTop: '16px',
    },

    typographyLight: {
        fontSize: '16px',
        fontWeight: '400',
    },

    typographyNormal: {
        fontSize: '16px',
        fontWeight: '600',
    },

    typographyBold: {
        fontWeight: 'bold'
    },
});
