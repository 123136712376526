import React from 'react';
import { Grid, Box } from '@mui/material';
import { useLocation } from 'react-router';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

// Util import
import { abbreviateFormatDate } from '~/util/format';
import { productFormat, formatWithCent } from '~/util/format';

import * as Style from './styles';

function OrderData({ order }) {
    // Hooks
    const location = useLocation().pathname;
    const isAtStoreOmni = location === '/storeOmni';

    const channel = isAtStoreOmni ? order?.ultimo_status : order?.channel;
    const status = isAtStoreOmni ? order?.ultimo_status : order?.status_evento;

    const renderOrderValue = () => {
        if (channel === 'DEVOLUCAO' || status === 'CANCELADO') {
            return <Style.ValueContainer>{order?.value}</Style.ValueContainer>;
        }
        if (location === '/maisCarrinho') {
            return formatWithCent(order?.totalPrice)
        }
        return (
            <Grid
                sx={{
                    fontSize: '14px',
                }}
            >
                {order?.value}
            </Grid>
        );
    };

    const getOrderInfo = () => {
        if (location === '/maisCarrinho') {
            return `${productFormat(order?.totalProducts)} Produtos`;
        }
        return abbreviateFormatDate(order.creationDate);
    };
    const orderInfo = getOrderInfo();

    return (
        <Style.Container>
            <Style.MobileDataValuesContainer container>
                {!isAtStoreOmni && (
                    <Style.MobileCreationDate>
                        {orderInfo}
                    </Style.MobileCreationDate>
                )}
                <Grid item>
                    <Style.ListItemTextComponent>
                        {renderOrderValue()}
                    </Style.ListItemTextComponent>
                </Grid>
            </Style.MobileDataValuesContainer>
            <Box>
                <Style.CloseButton>
                    <ChevronRightOutlinedIcon
                        style={{
                            color: '#FFFFFF',
                        }}
                    />
                </Style.CloseButton>
            </Box>
        </Style.Container>
    );
}

export default OrderData;
