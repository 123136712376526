import React, { useState } from 'react';
import { Typography } from '@mui/material';

import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory, useLocation } from 'react-router-dom';

import logoMaisVendas from '~/assets/logo_mais_vendas.png';
import logoSoma from '~/assets/logo_grupo_soma_final_BRANCO.png';

import * as Style from './styles';
import { signInRequest } from '~/store/modules/auth/actions';

function Login() {
    const history = useHistory();
    const routeParams = useLocation();
    const dispatch = useDispatch();
    const [userLogin, setUserLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [usernameIsReady, setUsernameIsReady] = useState(false);

    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');

    const loading = useSelector(state => state.auth.loading);

    function isValidForm() {
        if (usernameIsReady) {
            if (!password) {
                setPasswordError('Este campo precisa ser preenchido');
                return false;
            }
            setPasswordError('');
            return true;
        }
        if (!userLogin) {
            setLoginError('Este campo precisa ser preenchido');
            return false;
        }
        setLoginError('');
        return true;
    }
    async function handleSignIn() {
        if (!isValidForm()) return;
        if (!usernameIsReady) {
            setUsernameIsReady(true);
            return;
        }
        const params = new URLSearchParams(routeParams.search);
        const deviceToken = params.get('deviceToken');
        const localStorageToken = localStorage.getItem('device_token');
        const userLoginWithoutSpaces = userLogin.trim();
        if (localStorageToken) {
            dispatch(
                signInRequest(
                    userLoginWithoutSpaces,
                    password,
                    true,
                    localStorageToken
                )
            );
        } else {
            dispatch(
                signInRequest(
                    userLoginWithoutSpaces,
                    password,
                    true,
                    deviceToken
                )
            );
        }
    }

    function handleKeyPress(eve) {
        if (eve.key === 'Enter') {
            handleSignIn();
        }
    }

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: '100vh' }}
            >
                <Style.Root>
                    <Style.GridContainer container>
                        <Style.GridContainer item xs={12} sm={4}>
                            <Style.Main>
                                <Grid
                                    container
                                    item
                                    justifyContent="center"
                                    alignItems="center"
                                    direction="column"
                                >
                                    <Style.Logo
                                        component="img"
                                        src={logoMaisVendas}
                                        title="Grupo Soma"
                                    />
                                </Grid>
                                <Grid container justifyContent="center">
                                    {usernameIsReady ? (
                                        <Grid
                                            container
                                            justifyContent="center"
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <IconButton
                                                size="small"
                                                style={{
                                                    position: 'absolute',
                                                    left: '0px',
                                                }}
                                                onClick={() => {
                                                    setUsernameIsReady(false);
                                                    setUserLogin('');
                                                }}
                                            >
                                                <ChevronLeftIcon
                                                    size="small"
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            </IconButton>
                                            <Typography>{userLogin}</Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Style.MarginTextField
                                                id="username"
                                                color="primary"
                                                helperText={loginError}
                                                variant="outlined"
                                                fullWidth
                                                autoFocus
                                                label="E-mail ou Nome de Usuário"
                                                value={userLogin}
                                                onChange={e => {
                                                    setLoginError('');
                                                    setUserLogin(
                                                        e.target.value
                                                    );
                                                }}
                                                onKeyUp={e => handleKeyPress(e)}
                                            />
                                        </Grid>
                                    )}

                                    {usernameIsReady && (
                                        <Grid item xs={12}>
                                            <Style.MarginTextField
                                                id="password"
                                                helperText={passwordError}
                                                variant="outlined"
                                                fullWidth
                                                label="Senha"
                                                type={
                                                    showPassword
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                value={password}
                                                onChange={e => {
                                                    setPasswordError('');
                                                    setPassword(e.target.value);
                                                }}
                                                onKeyUp={e => handleKeyPress(e)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Mostrar senha"
                                                                onClick={() =>
                                                                    setShowPassword(
                                                                        !showPassword
                                                                    )
                                                                }
                                                                color="primary"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Style.PrimaryButton
                                            id="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleSignIn}
                                            disabled={
                                                !(usernameIsReady
                                                    ? password
                                                    : userLogin)
                                            }
                                            endIcon={
                                                <Style.StyledChevron/>
                                            }
                                        >
                                            {loading ? ( // eslint-disable-line
                                                <Style.StyledCircularProgress
                                                    size={26}
                                                />
                                            ) : usernameIsReady ? (
                                                'ENTRAR'
                                            ) : (
                                                'PRÓXIMO'
                                            )}
                                        </Style.PrimaryButton>
                                    </Grid>

                                    {usernameIsReady ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item>
                                                        <Style.ChangeKeyButton
                                                            fullWidth
                                                            inputJustify='flex-start'
                                                            onClick={() =>
                                                                history.push(
                                                                    '/forgotPassword'
                                                                )
                                                            }
                                                        >
                                                            Esqueceu sua senha?
                                                        </Style.ChangeKeyButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <Style.ChangeKeyButton
                                                            fullWidth
                                                            inputJustify='flex-end'
                                                            onClick={() => {
                                                                window.open(
                                                                    'https://api.whatsapp.com/send/?phone=552137337703&text&app_absent=0',
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            Suporte
                                                        </Style.ChangeKeyButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Style.ForgotPasswordText
                                                align="center"
                                                component="p"
                                            >
                                                Não tem uma conta?
                                            </Style.ForgotPasswordText>
                                            <Style.ChangeKeyButton
                                                id="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() =>
                                                    history.push('/firstAccess')
                                                }
                                            >
                                                Cadastre-se
                                            </Style.ChangeKeyButton>
                                        </>
                                    )}
                                </Grid>
                            </Style.Main>
                        </Style.GridContainer>
                    </Style.GridContainer>
                </Style.Root>
                <Grid item xs={12} style={{ textAlign: '-webkit-center' }}>
                    <Style.LogoSoma
                        component="img"
                        src={logoSoma}
                        title="Grupo Soma"
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default Login;