import React from 'react';
import { useLocation } from 'react-router';

// Util import
import {
    defaultPanelFilters,
    maisCarrinhoFilters,
} from '~/util/ordersPanelArrays';
import {
    applyOrdersFilter,
    applySearchFitler,
    getListByFilter,
    getCartsListByFilter,
    getTotalValue,
} from '~/util/orderPanelFunctions';

import * as Style from './styles';

function FilterButtons({
    orders,
    setFilteredOrders,
    filter,
    setFilter,
    ordersFilter,
    setOrdersFilter,
    setHideOrdersFilter,
    setTotalFilterValue,
    searchFilter,
    filteredOrders,
}) {
    // Hooks
    const location = useLocation().pathname;

    const getFiltersList = () => {
        if (location === '/storeOmni') return defaultPanelFilters.slice(0, 3);
        if (location === '/maisCarrinho') return maisCarrinhoFilters;
        return defaultPanelFilters;
    };

    const filtersList = getFiltersList();

    const getFilterQuantity = filterName => {
        let getFilteredList;
        if (location === '/maisCarrinho') {
            getFilteredList = getCartsListByFilter(orders, filterName);
        } else {
            getFilteredList = getListByFilter(filteredOrders, filterName);
        }
        const getListLength = getFilteredList?.length;

        return getListLength;
    };

    const getButtonText = filterName => {
        if (location === '/storeOmni') {
            return filterName;
        }
        return `${filterName} (${getFilterQuantity(filterName)})`;
    };

    const handleClick = filterName => {
        let filteredList = orders;

        if (location === '/maisCarrinho') {
            const getNewCartsList = getCartsListByFilter(orders, filterName);

            setFilteredOrders(getNewCartsList);
            setFilter(filterName);

            return;
        }

        if (
            filterName !== 'Todos' &&
            filterName !== 'Vendas' &&
            ordersFilter !== 'allOrders'
        ) {
            setOrdersFilter('allOrders');
        }

        if (!['Todos', 'Vendas'].includes(filterName)) {
            setHideOrdersFilter(true);
        } else {
            setHideOrdersFilter(false);
        }

        filteredList = getListByFilter(filteredList, filterName);

        if (ordersFilter && ordersFilter !== 'allOrders') {
            filteredList = applyOrdersFilter(filteredList, ordersFilter);
        }

        if (searchFilter) {
            filteredList = applySearchFitler(filteredList, searchFilter);
        }

        const newTotalFilterValue = getTotalValue(filteredList);

        setFilter(filterName);
        setFilteredOrders(filteredList);
        setTotalFilterValue(newTotalFilterValue);
    };

    return (
        <Style.PillsFilterContainer>
            {filtersList.map(filterName => (
                <Style.StyledButtonPill
                    key={`pill-filter-${filterName} `}
                    primary={filterName === filter}
                    variant={filterName === filter ? 'contained' : 'outlined'}
                    size="medium"
                    onClick={() => {
                        handleClick(filterName);
                    }}
                >
                    {getButtonText(filterName)}
                </Style.StyledButtonPill>
            ))}
        </Style.PillsFilterContainer>
    );
}

export default FilterButtons;
