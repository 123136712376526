import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const SkeletonTopBox = styled(Grid)`
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 5% 0%;
    @media screen and (min-width: 960px) {
        display: none;
    }
`;

export const DesktopSkeletonContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    @media screen and (max-width: 960px) {
        display: none;
    }
`;
