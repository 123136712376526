import React from 'react';

import * as Style from './styles';
import { Grid } from '@mui/material';
import OmniPagePanel from '~/components/SalesPersonPanelPiloto/OmniPagePanel';
import OrderListPanel from '~/components/OrderListPanel';

const DesktopLayout = ({
    panelLoaded,
    ordersLoaded,
    orders,
    omniData
}) => (
    <>
        <Grid container justifyContent="space-evenly">
            <Grid item xs={12}>
                <OmniPagePanel
                    panelLoaded={panelLoaded}
                    omniData={omniData}
                    invoicedOrders={omniData?.operational?.invoicedOrders || 0}
                />
            </Grid>
            <Style.LateralPanelContainer item xs={12}>
                <OrderListPanel
                    orders={orders}
                    ordersLoaded={ordersLoaded}
                />
            </Style.LateralPanelContainer>
        </Grid>
    </>
);

export default DesktopLayout;