import {styled} from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

export const PrimaryGrid = styled(Grid)`
    margin: 16px 0;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
`

export const StyledInfoIcon = styled(InfoOutlined)`
    color: #DCC6B7;
    margin-left: 10px;
`

export const PrimaryTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 400;
    text-align: left;
`