import { styled } from '@mui/material/styles';
import { Grid , Typography } from '@mui/material';

export const PrimaryContainer = styled(Grid)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${props => props.primary ? '16px' : 0};
`

export const SecondaryContainer = styled(Grid)`
    width: 100%;
    margin: ${props => props.primary ? '16px 0 0' : '6px 0 8px 16px'};
`

export const BrandContainer = styled(Grid)`
    height: 56px;
    padding-right: 10px;
    padding-left: 16px;
    border-radius: 4px;
    background-color: #373435;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #424242;
    };
`
export const Text = styled(Typography)`
    font-size: ${props => props.primary ? '24px' : '16px'} ;
    font-weight: 600;
    text-transform: ${props => props.primary ? 'capitalize' : 'none'};
`

export const IconContainer = styled(Grid)`
    color: ${props => props.primary ? '#DCC6B7' : 'white'};
    margin-right: ${props => props.primary ? '16px' : 0 };
`