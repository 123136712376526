/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
    Grid,
    Typography,
    InputAdornment,
    Link,
    ListItemText,
    Button,
    AccordionSummary,
    Avatar,
    Dialog,
    CircularProgress,
} from '@mui/material';

import {
    Assignment,
    AssignmentOutlined,
    ExpandMoreOutlined,
    OpenInNewOutlined,
    SearchOutlined,
    LocalPlayOutlined,
    ErrorOutline,
} from '@mui/icons-material';

import { useSelector } from 'react-redux';
import Header from '~/components/Header';
import { formatPrice, formatWithCent } from '~/util/format';
import Snackbar from '~/util/SnackBar';

import api, { extraOrdersApi } from '~/services/api';
import * as Style from './styles';

const generateSnackBar = () => {
    return Snackbar.success(
        <Grid item xs={12}>
            <Grid item xs={12}>
                <Typography
                    style={{
                        color: '#fff',
                        fontFamily: 'Inter',
                        fontWeight: 'bold',
                        fontSize: '16px',
                    }}
                >
                    Pedido realocado com sucesso!
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography
                    style={{
                        color: '#fff',
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        fontSize: '12px',
                    }}
                >
                    O pedido leva algum tempo para ser faturado. A lista no
                    SinOMS pode demorar para atualizar.
                </Typography>
            </Grid>
        </Grid>
    );
};

function ExtraOrders({ ...props }) {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState(orders);
    const [viewedOrders, setViewedOrders] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [itemQuantity, setItemQuantity] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState('');
    const [relocatedOrder, setRelocatedOrder] = useState([]);

    const userProfile = useSelector(state => state.user.profile);
    const { codigo_vendedor } = userProfile.users_sales_code[0];

    const getOrders = async () => {
        try {
            const { id_marca } = userProfile.users_brands[0];

            const { data } = await extraOrdersApi.get(
                `/orders/window?brandId=${id_marca}`
            );

            setOrders(data.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Snackbar.error('Erro ao retornar pedidos extras');
        }
    };

    const checkViewedOrders = async () => {
        try {
            const { data } = await api.get(
                `/cache/${codigo_vendedor}/checkKey`,
                {
                    params: {
                        key: 'viewedOrders',
                        service: 'extraOrders',
                        list: true,
                    },
                }
            );
            setViewedOrders(data);
        } catch (err) {
            console.log(err);
        }
    };

    const checkRelocatedOrders = async () => {
        try {
            const { data } = await api.get(
                `/cache/${codigo_vendedor}/checkKey`,
                {
                    params: {
                        key: 'relocatedOrders',
                        service: 'extraOrders',
                        list: true,
                    },
                }
            );
            setRelocatedOrder(data);
        } catch (err) {
            console.log(err);
        }
    };

    const handlePushViewedOrders = async orderId => {
        if (viewedOrders.length && viewedOrders.includes(orderId)) return;

        try {
            await api.get(`/cache/${codigo_vendedor}/setKey`, {
                params: {
                    service: 'extraOrders',
                    key: 'viewedOrders',
                    value: orderId,
                    list: true,
                },
            });
            checkViewedOrders();
        } catch (err) {
            console.log(err);
        }
    };

    const handlePushRelocatedOrders = async () => {
        try {
            await api.get(`/cache/${codigo_vendedor}/setKey`, {
                params: {
                    service: 'extraOrders',
                    key: 'relocatedOrders',
                    value: selectedOrder,
                    list: true,
                    ttl: 300,
                },
            });
            checkRelocatedOrders();
        } catch (err) {
            console.log(err);
        }
    };

    const removeElementViewedOrders = async () => {
        try {
            const { data } = await api.get(
                `/cache/${codigo_vendedor}/removeElementListKey`,
                {
                    params: {
                        service: 'extraOrders',
                        key: 'viewedOrders',
                        value: selectedOrder,
                    },
                }
            );
            setViewedOrders(data);
        } catch (err) {
            console.log(err);
        }
    };

    const reallocateOrder = async () => {
        setLoading(true);
        try {
            const { nomeFilial } = userProfile;

            await extraOrdersApi.put(`/sellers/change`, {
                orderId: selectedOrder,
                filial: nomeFilial,
            });

            setSearchFilter('');
            setExpanded(false);
            removeElementViewedOrders();
            handlePushRelocatedOrders();
            generateSnackBar();
            getOrders();
        } catch (err) {
            setLoading(false);
            const GENERIC_ERROR = 7;
            const message =
                err.response.data.code === GENERIC_ERROR
                    ? 'Erro ao realocar pedido'
                    : err.response.data.error;

            Snackbar.error(message);
        }
    };

    useEffect(() => {
        let newFilteredOrders = orders;

        if (searchFilter) {
            const lowerCaseSearchFilter = searchFilter.toLowerCase();
            newFilteredOrders = orders.filter(order => {
                const orderId = order?.orderId || '';
                return orderId.toLowerCase().includes(lowerCaseSearchFilter);
            });
        }

        setFilteredOrders(
            newFilteredOrders.filter(
                value => !relocatedOrder.includes(value?.orderId)
            )
        );
    }, [orders, searchFilter]);

    useEffect(() => {
        checkViewedOrders();
        checkRelocatedOrders();
        getOrders();
    }, []);

    const handleChange = panel => (event, isExpanded) =>
        setExpanded(isExpanded ? panel : false);

    const handleOpenModal = () => setOpenModal(true);

    const resizeProductImageUrl = originalUrl => {
        if (originalUrl) {
            const splitOriginalUrl = originalUrl.split('/');
            const IMAGE_SIZE_PARAM_INDEX = 5;
            splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX] = `${
                splitOriginalUrl[IMAGE_SIZE_PARAM_INDEX].split('-')[0]
            }-148-224`;
            const resizedOriginalUrl = splitOriginalUrl.join('/');
            return resizedOriginalUrl;
        }
        return '';
    };

    const formatProductViewed = orderId => {
        const fontWeight =
            viewedOrders.length && viewedOrders.includes(orderId)
                ? 'normal'
                : 'bold';
        return fontWeight;
    };

    const formatProductName = product => {
        if (product.refId === 'VALE_VALE') return 'Vale Compras';
        return product?.name?.toLowerCase();
    };

    return (
        <>
            <Header />
            <Style.PageTitle>Pedidos Extras</Style.PageTitle>
            <Style.Root>
                <Grid
                    item
                    style={{
                        margin: '16px',
                        paddingTop: '16px',
                    }}
                >
                    <Grid item>
                        <Typography
                            style={{
                                fontFamily: 'Inter, sans-serif',
                                weight: '600',
                                fontSize: '20px',
                                color: '#DCC6B7',
                            }}
                        >
                            Lista de Pedidos Extras
                        </Typography>
                    </Grid>

                    <Grid item container justifyContent="center">
                        <Style.SearchField
                            fullWidth
                            shrink="true"
                            variant="outlined"
                            placeholder="Buscar"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined
                                            style={{
                                                color: '#DCC6B7',
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                                style: { height: '42px' },
                            }}
                            value={searchFilter}
                            onChange={e => {
                                setSearchFilter(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Link
                            href="https://gruposoma.sintesesolucoes.com.br/SinOMS"
                            underline="always"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                                color: '#DCC6B7',
                                marginRight: '10px',
                                fontSize: '14px',
                            }}
                        >
                            Ir para SinOMS
                        </Link>
                        <OpenInNewOutlined
                            style={{
                                color: '#DCC6B7',
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    style={{
                        padding: '0 16px 40px',
                    }}
                >
                    {loading ? (
                        <Grid
                            container
                            justifyContent="center"
                            style={{ paddingTop: '32px' }}
                        >
                            <CircularProgress size={50} />
                        </Grid>
                    ) : (
                        <>
                            <Style.StyledList>
                                {filteredOrders
                                    .slice(0, itemQuantity)
                                    .map((order, index) => (
                                        <Style.StyledAccordion
                                            disableGutters
                                            expanded={
                                                expanded === `panel-${index}`
                                            }
                                            onChange={handleChange(
                                                `panel-${index}`
                                            )}
                                            key={`panel-${index}`}
                                            onClick={() =>
                                                handlePushViewedOrders(
                                                    order?.orderId
                                                )
                                            }
                                        >
                                            <AccordionSummary
                                                style={{
                                                    backgroundColor: '#373435',
                                                }}
                                                expandIcon={
                                                    <ExpandMoreOutlined />
                                                }
                                                aria-controls={`panel-${index}-content`}
                                                id={`panel-${index}-header`}
                                            >
                                                <Style.StyledListItem
                                                    key={`list-item-orders-${index}`}
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-start"
                                                                alignItems="center"
                                                            >
                                                                <Grid item>
                                                                    {viewedOrders.length &&
                                                                    viewedOrders.includes(
                                                                        order?.orderId
                                                                    ) ? (
                                                                        <AssignmentOutlined
                                                                            style={{
                                                                                color:
                                                                                    '#DCC6B7',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Assignment
                                                                            style={{
                                                                                color:
                                                                                    '#DCC6B7',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Grid>

                                                                <Grid item>
                                                                    <ListItemText
                                                                        style={{
                                                                            marginLeft:
                                                                                '16px',
                                                                        }}
                                                                    >
                                                                        <Style.MainCardTypography
                                                                            inputWeight={formatProductViewed(
                                                                                order?.orderId
                                                                            )}
                                                                        >
                                                                            {
                                                                                order?.orderId
                                                                            }
                                                                        </Style.MainCardTypography>

                                                                        <Style.MainCardTypography>
                                                                            Qtd.
                                                                            de
                                                                            Produtos:{' '}
                                                                            {
                                                                                order.products
                                                                            }
                                                                        </Style.MainCardTypography>
                                                                    </ListItemText>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                justifyContent="flex-end"
                                                                alignItems="center"
                                                            >
                                                                <Grid item>
                                                                    <ListItemText>
                                                                        <Style.MainCardTypography
                                                                            primary
                                                                            inputWeight={formatProductViewed(
                                                                                order?.orderId
                                                                            )}
                                                                        >
                                                                            {formatPrice(
                                                                                order.price
                                                                            )}
                                                                        </Style.MainCardTypography>
                                                                    </ListItemText>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Style.StyledListItem>
                                            </AccordionSummary>
                                            <Style.StyledDetails>
                                                <Style.BoxTest>
                                                    {order.items.map(
                                                        (product, index) => (
                                                            <Style.ProductContainer
                                                                item
                                                                xs={12}
                                                                container
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={`product-item-${product.refId}-${index}`}
                                                                style={{
                                                                    marginBottom:
                                                                        '16px',
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        height:
                                                                            '224px',
                                                                        backgroundColor:
                                                                            '#373435',
                                                                        border:
                                                                            '1px solid rgb(117, 117, 117)',
                                                                        borderRadius:
                                                                            '12px',
                                                                        flexFlow:
                                                                            'row',
                                                                        boxShadow:
                                                                            '0px 3.45852px 8.64629px rgba(33, 33, 33, 0.25)',
                                                                    }}
                                                                    container
                                                                    justifyContent="flex-start"
                                                                    xs={12}
                                                                    xl={11}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        onClick={() =>
                                                                            window.open(
                                                                                product?.imageURL,
                                                                                '_blank'
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor:
                                                                                'pointer',
                                                                        }}
                                                                    >
                                                                        {// eslint-disable-next-line no-nested-ternary
                                                                        product.refId ===
                                                                        'VALE_VALE' ? (
                                                                            <Grid
                                                                                style={{
                                                                                    width:
                                                                                        '148px',
                                                                                    height:
                                                                                        '224px',
                                                                                    borderRadius:
                                                                                        '12px 0px 0px 12px',
                                                                                    textAlign:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                <LocalPlayOutlined
                                                                                    style={{
                                                                                        width:
                                                                                            '100px',
                                                                                        height:
                                                                                            '200px',
                                                                                        color:
                                                                                            '#DCC6B7',
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        ) : product?.imageURL ? (
                                                                            <img
                                                                                src={resizeProductImageUrl(
                                                                                    product?.imageURL
                                                                                )}
                                                                                alt="imagem do produto"
                                                                                style={{
                                                                                    width:
                                                                                        '148px',
                                                                                    height:
                                                                                        '224px',
                                                                                    borderRadius:
                                                                                        '12px 0px 0px 12px',
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <CircularProgress
                                                                                style={{
                                                                                    margin:
                                                                                        '48px',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={7}
                                                                        xl={8}
                                                                        container
                                                                        direction="column"
                                                                        justifyContent="space-evenly"
                                                                        style={{
                                                                            paddingLeft:
                                                                                '24px',
                                                                            paddingRight:
                                                                                '8px',
                                                                            flexBasis:
                                                                                'auto',
                                                                            maxWidth:
                                                                                'fit-content',
                                                                        }}
                                                                    >
                                                                        {product?.name ? (
                                                                            <Typography
                                                                                style={{
                                                                                    fontWeight:
                                                                                        '600',
                                                                                    fontSize:
                                                                                        '20px',
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                    overflow:
                                                                                        'hidden',
                                                                                    textOverflow:
                                                                                        'ellipsis',
                                                                                    display:
                                                                                        '-webkit-box',
                                                                                    WebkitLineClamp:
                                                                                        '3',
                                                                                    WebkitBoxOrient:
                                                                                        'vertical',
                                                                                }}
                                                                            >
                                                                                {formatProductName(
                                                                                    product
                                                                                )}
                                                                            </Typography>
                                                                        ) : (
                                                                            <CircularProgress />
                                                                        )}

                                                                        <Typography
                                                                            style={{
                                                                                fontWeight:
                                                                                    '500',
                                                                                fontSize:
                                                                                    '14px',
                                                                                color:
                                                                                    '#9E9E9E',
                                                                            }}
                                                                        >
                                                                            {product.refId ===
                                                                            'VALE_VALE'
                                                                                ? ''
                                                                                : `Ref: ${product?.refId}`}
                                                                        </Typography>
                                                                        <Typography
                                                                            style={{
                                                                                fontWeight:
                                                                                    '600',
                                                                                fontSize:
                                                                                    '20px',
                                                                            }}
                                                                        >
                                                                            {formatWithCent(
                                                                                product?.price
                                                                            )}
                                                                        </Typography>
                                                                        {product.refId ===
                                                                            'VALE_VALE' || (
                                                                            <Avatar
                                                                                style={{
                                                                                    color:
                                                                                        '#757575',
                                                                                    backgroundColor:
                                                                                        'rgba(0,0,0,0)',
                                                                                    border:
                                                                                        '1px solid #757575',
                                                                                    borderRadius:
                                                                                        '8px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    product?.grade
                                                                                }
                                                                            </Avatar>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Style.ProductContainer>
                                                        )
                                                    )}
                                                    <Style.PrimaryButton
                                                        onClick={() => {
                                                            handleOpenModal();
                                                            setSelectedOrder(
                                                                order?.orderId
                                                            );
                                                        }}
                                                    >
                                                        Aceitar Pedido
                                                    </Style.PrimaryButton>
                                                </Style.BoxTest>
                                            </Style.StyledDetails>
                                        </Style.StyledAccordion>
                                    ))}
                            </Style.StyledList>
                            {filteredOrders.length >= itemQuantity && (
                                <Grid style={{ textAlign: 'center' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setItemQuantity(
                                                currentItemQuantity =>
                                                    currentItemQuantity + 10
                                            );
                                        }}
                                        style={{
                                            border: 'none',
                                            color: '#DCC6B7',
                                        }}
                                    >
                                        Ver Mais
                                    </Button>
                                </Grid>
                            )}
                            {filteredOrders.length === 0 && (
                                <Grid style={{ textAlign: 'center' }}>
                                    <Typography>Sem pedidos</Typography>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Style.Root>

            <Dialog
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                PaperProps={{
                    style: {
                        width: '312px',
                        padding: '28px 0px 0px 0px',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(33, 33, 33, 0.25)',
                    },
                }}
                disableScrollLock
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        style={{
                            color: '#373435',
                            textAlign: 'center',
                            fontSize: '24px',
                            marginBottom: '14px',
                            fontFamily: 'Inter',
                            fontWeight: '600',
                            lineHeight: '29px',
                            alignItems: 'center',
                        }}
                    >
                        <b>Confirmação</b>
                    </Grid>

                    <Grid
                        item
                        style={{
                            color: '#E3BE25',
                            marginBottom: '29px',
                        }}
                    >
                        <ErrorOutline
                            style={{
                                fontSize: '40px',
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        style={{
                            color: '#373435',
                            textAlign: 'center',
                            fontSize: '14px',
                            marginBottom: '24px',
                            maxWidth: '232px',
                        }}
                    >
                        Você confirma que possui este(s) item(s) em mãos?
                    </Grid>

                    <Grid
                        item
                        container
                        justifyContent="center"
                        style={{
                            borderRadius: '8px',
                            marginBottom: '24px',
                            gap: '20px',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            style={{
                                color: '#373435',
                                border: '1px solid #373435',
                                height: '40px',
                                textTransform: 'capitalize',
                                borderRadius: '8px',
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenModal(false);
                                reallocateOrder();
                            }}
                            style={{
                                color: '#1BB55C',
                                height: '40px',
                                textTransform: 'capitalize',
                                border: '1px solid #1BB55C',
                                borderRadius: '8px',
                            }}
                        >
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

export default ExtraOrders;
