import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import {
    Typography,
    Grid,
    Box,
    CardActions,
    CardContent,
    TextField,
    Button,
    Dialog,
    Checkbox,
    FormGroup,
} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Calendar, MuiPickersUtilsProvider, Day } from '@material-ui/pickers';

import Divider from '@mui/material/Divider';

import moment from 'moment';

import MomentUtils from '@date-io/moment';
import CustomHeader from '~/components/CustomHeader';

import * as Style from './styles';
import getAssessmentDatesByDate from '~/util/getAssessmentDatesByDate';
import api from '~/services/api';
import SnackBar from '~/util/SnackBar';

let assessmentDate = moment().endOf('month');
if (
    moment().date() > 25 &&
    moment().isAfter(
        moment()
            .hour(0)
            .date(1)
            .month(4)
            .year(2021)
    )
) {
    assessmentDate = assessmentDate.add(1, 'month');
}

const initialDate = getAssessmentDatesByDate(assessmentDate).startDate;
const finalDate = getAssessmentDatesByDate(assessmentDate).endDate;

function CadastroAusencias({
    setCurrentPage,
    activeSalesperson,
    salesTeamData,
    quotaDistribution,
    quotaValues,
}) {
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [pendingAbsenceDays, setPendingAbsenceDays] = useState([]);
    const [absenceDays, setAbsenceDays] = useState([]);
    const [formattedAbsenceDays, setFormattedAbsenceDays] = useState([]);
    const [lastClickedDate, setLastClickedDate] = useState(moment());
    const [
        quotaValueIsZeroForAbsences,
        setQuotaValueIsZeroForAbsences,
    ] = useState(true);

    useEffect(() => {
        if (activeSalesperson.hasAbsenceRegistered) {
            const absenceDaysActive = activeSalesperson.absences.filter(
                absence => absence.isAbsent
            );

            const temporaryArray = absenceDaysActive.map(absence => {
                return moment(absence.day, 'YYYY-MM-DD');
            });

            setAbsenceDays(temporaryArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const sortedAbsenceDays = absenceDays.sort((a, b) => a.diff(b));

        const monthsInAbsenceDays = sortedAbsenceDays.map(absenceDay => ({
            monthValue: absenceDay.month(),
            monthText: absenceDay.format('MMMM, YYYY'),
            days: [],
        }));
        const uniqueMonthsInAbsenceDays = [
            ...new Map(
                monthsInAbsenceDays.map(item => [item.monthValue, item])
            ).values(),
        ];
        absenceDays.forEach(absenceDay => {
            const matchingMonth = uniqueMonthsInAbsenceDays.find(
                monthObj => monthObj.monthValue === absenceDay.month()
            );
            matchingMonth.days.push(absenceDay.date());
        });
        setFormattedAbsenceDays(uniqueMonthsInAbsenceDays);
    }, [absenceDays]);

    const handleClickOnDate = date => {
        setLastClickedDate(date);
        setPendingAbsenceDays(currentAbsenceDays => {
            let newAbsenceDays = [...currentAbsenceDays];
            const isDateAlreadyInAbsenceDays = newAbsenceDays.reduce(
                (isDateDuplicated, absenceDay) =>
                    isDateDuplicated === true
                        ? true
                        : moment(date).isSame(absenceDay, 'day'),
                false
            );
            if (isDateAlreadyInAbsenceDays) {
                newAbsenceDays = newAbsenceDays.filter(
                    absenceDay => !moment(date).isSame(absenceDay, 'day')
                );
            } else {
                newAbsenceDays.push(date);
            }
            return newAbsenceDays;
        });
    };

    const getCustomDateComponent = useCallback(
        (day, dayComponent) => {
            const isDateAlreadyInAbsenceDays = pendingAbsenceDays.reduce(
                (isDateDuplicated, absenceDay) =>
                    isDateDuplicated === true
                        ? true
                        : moment(day).isSame(absenceDay, 'day'),
                false
            );
            const customDateComponent = cloneElement(dayComponent, {
                selected: isDateAlreadyInAbsenceDays,
            });

            return customDateComponent;
        },
        [pendingAbsenceDays]
    );

    const commitAbsenceDays = useCallback(() => {
        setAbsenceDays(pendingAbsenceDays);
        setDatePickerIsOpen(false);
    }, [pendingAbsenceDays]);

    const handleCloseOnDatePickerDialog = () => {
        setDatePickerIsOpen(false);
        setPendingAbsenceDays([]);
    };
    const handleOpenOnDatePickerDialog = () => {
        setPendingAbsenceDays(absenceDays);
        setDatePickerIsOpen(true);
    };

    const mountQuotaObjectAndUpdateQuotas = useCallback(
        async endAction => {
            try {
                if (quotaValueIsZeroForAbsences) {
                    const salespersonMonthQuota = activeSalesperson.quota;
                    const daysAbsent = absenceDays.length;
                    const daysInAfferitionPeriod = Object.keys(
                        activeSalesperson.quotasByDay
                    ).length;
                    const recalculatedDailyQuota =
                        salespersonMonthQuota /
                        (daysInAfferitionPeriod - daysAbsent);
                    const formattedRequestBody = salesTeamData
                        .filter(
                            salesperson =>
                                salesperson.vendedor ===
                                activeSalesperson.vendedor
                        )
                        .map(salesperson => ({
                            salesCode: salesperson.vendedor,
                            dailyQuotas: Object.keys(
                                salesperson.quotasByDay
                            ).reduce(
                                (attrs, date) => ({
                                    ...attrs,
                                    [date]: {
                                        value: absenceDays.filter(absentDay =>
                                            moment(date).isSame(
                                                absentDay,
                                                'day'
                                            )
                                        ).length
                                            ? 0
                                            : recalculatedDailyQuota,
                                        isAbsent: !!absenceDays.filter(
                                            absentDay =>
                                                moment(date).isSame(
                                                    absentDay,
                                                    'day'
                                                )
                                        ).length,
                                    },
                                }),
                                {}
                            ),
                        }));
                    await api.post(
                        'store-manager/sales-team-quota',
                        formattedRequestBody
                    );
                    // setOpenConfirmationDialog(false);
                    SnackBar.success('Cotas cadastradas com sucesso');
                    if (endAction) {
                        endAction();
                    }
                } else {
                    const formattedRequestBody = salesTeamData
                        .filter(
                            salesperson =>
                                salesperson.vendedor ===
                                activeSalesperson.vendedor
                        )
                        .map(salesperson => ({
                            salesCode: salesperson.vendedor,
                            dailyQuotas: Object.keys(
                                salesperson.quotasByDay
                            ).reduce(
                                (attrs, date) => ({
                                    ...attrs,
                                    [date]: {
                                        value: salesperson.quotasByDay[date],
                                        isAbsent: !!absenceDays.filter(
                                            absentDay =>
                                                moment(date).isSame(
                                                    absentDay,
                                                    'day'
                                                )
                                        ).length,
                                    },
                                }),
                                {}
                            ),
                        }));
                    await api.post(
                        'store-manager/sales-team-quota',
                        formattedRequestBody
                    );
                    // setOpenConfirmationDialog(false);
                    SnackBar.success('Cotas cadastradas com sucesso');
                    if (endAction) {
                        endAction();
                    }
                }
            } catch (e) {
                SnackBar.error('Erro ao cadastrar cotas');
            }
        },
        [
            absenceDays,
            activeSalesperson,
            quotaValueIsZeroForAbsences,
            salesTeamData,
        ]
    );

    return (
        <>
            <CustomHeader
                title="Cadastro de Cotas"
                goBackPage={() => setCurrentPage('ResumoAusenciasFilial')}
            />
            <Day />
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{
                    padding: '16px',
                }}
            >
                <Grid item xs={12}>
                    <Style.BrandContainer container>
                        <Grid
                            item
                            style={{ margin: '16px 0' }}
                        >
                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    textAlign: 'left',
                                }}
                            >
                                Cadastro de Ausencias
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                            }}
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <PersonOutlinedIcon
                                    style={{
                                        color: '#DCC6B7',
                                        marginRight: '10px',
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {activeSalesperson?.vendedor_apelido.toLowerCase()}
                                </Typography>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        textAlign: 'left',
                                        color: '#9E9E9E',
                                    }}
                                >
                                    {activeSalesperson.vendedor}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{ width: '100%', padding: '16px 0px' }}
                        >
                            <Divider variant="fullWidth" />
                        </Grid>

                        {absenceDays.length ? (
                            <Grid item container>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    style={{
                                        marginBottom: '16px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        handleOpenOnDatePickerDialog()
                                    }
                                >
                                    <Grid
                                        item
                                        style={{
                                            alignSelf: 'center',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <CalendarTodayOutlinedIcon
                                            style={{
                                                color: '#DCC6B7',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                textAlign: 'left',
                                                color: '#DCC6B7',
                                            }}
                                        >
                                            Ausências
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {formattedAbsenceDays.map(absenceMonth => (
                                    <Grid item container xs={12}>
                                        {absenceMonth.days.map(day => (
                                            <Grid
                                                item
                                                style={{
                                                    width: '28px',
                                                    height: '28px',
                                                    backgroundColor: '#DCC6B7',
                                                    color: '#373435',
                                                    lineHeight: '28px',
                                                    textAlign: 'center',
                                                    borderRadius: '28px',
                                                    marginRight: '8px',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                {day}
                                            </Grid>
                                        ))}
                                        <Typography
                                            style={{
                                                color: '#DCC6B7',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {absenceMonth.monthText}
                                        </Typography>
                                    </Grid>
                                ))}
                                <Grid
                                    item
                                    style={{
                                        width: '100%',
                                        padding: '16px 0px',
                                    }}
                                >
                                    <Divider variant="fullWidth" />
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        quotaValueIsZeroForAbsences
                                                    }
                                                    onChange={() =>
                                                        setQuotaValueIsZeroForAbsences(
                                                            currentState =>
                                                                !currentState
                                                        )
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography
                                                    style={{ color: '#DCC6B7' }}
                                                >
                                                    Definir cota R$ 0,00 para
                                                    dias ausentes
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                container
                                xs={12}
                                onClick={() => handleOpenOnDatePickerDialog()}
                                style={{ cursor: 'pointer' }}
                            >
                                <Grid
                                    item
                                    style={{
                                        alignSelf: 'center',
                                        marginRight: '15px',
                                    }}
                                >
                                    <CalendarTodayOutlinedIcon
                                        style={{
                                            color: '#DCC6B7',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Cadastrar ausências
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sm={2}
                                    style={{
                                        textAlign: 'end',
                                        color: 'white',
                                    }}
                                >
                                    <NavigateNextOutlinedIcon />
                                </Grid>
                            </Grid>
                        )}
                    </Style.BrandContainer>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        style={{
                            backgroundColor: '#373435',
                            marginTop: '16px',
                        }}
                    >
                        <CardActions
                            style={{
                                padding: '0 16px',
                            }}
                        >
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            margin: '8px 0'
                                        }}
                                    >
                                        Observações
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>

                        <CardContent
                            style={{
                                paddingTop: '0px',
                                textAlign: 'center',
                            }}
                        >
                            <TextField
                                placeholder="Escrever nota adicional"
                                variant="outlined"
                                fullWidth
                            />
                        </CardContent>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Style.PrimaryButton
                        primary
                        onClick={() =>
                            mountQuotaObjectAndUpdateQuotas(() => {
                                setCurrentPage('ResumoAusenciasFilial');
                            })
                        }
                        disabled={!absenceDays.length}
                    >
                        Avançar
                    </Style.PrimaryButton>
                    <Style.PrimaryButton
                        onClick={() => {
                            setCurrentPage('ResumoAusenciasFilial');
                        }}
                    >
                        Voltar
                    </Style.PrimaryButton>
                </Grid>
            </Grid>
            <Dialog
                open={datePickerIsOpen}
                onClose={() => handleCloseOnDatePickerDialog()}
                PaperProps={{ style: { padding: '16px' } }}
            >
                <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale="pt-br"
                >
                    <Calendar
                        minDate={initialDate}
                        maxDate={finalDate}
                        date={lastClickedDate}
                        onChange={date => handleClickOnDate(date)}
                        renderDay={(
                            day,
                            selectedDate,
                            dayInCurrentMonth,
                            dayComponent
                        ) => getCustomDateComponent(day, dayComponent)}
                    />
                </MuiPickersUtilsProvider>
                <Grid container justifyContent="space-evenly">
                    <Button
                        style={{
                            fontSize: '14px',
                            textTransform: 'none',
                            color: '#DCC6B7',
                        }}
                        onClick={() => handleCloseOnDatePickerDialog()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        style={{
                            fontSize: '14px',
                            textTransform: 'none',
                            backgroundColor: '#DCC6B7',
                            color: '#373435',
                        }}
                        onClick={() => commitAbsenceDays()}
                    >
                        Ok
                    </Button>
                </Grid>
            </Dialog>
        </>
    );
}

export default CadastroAusencias;
