import {styled} from '@mui/material/styles';
import {
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

export const BrandContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding:  0 10px 0 16px;
    border-radius: 4px;
    background-color: #373435;
    margin-bottom: ${props => props.primary && '16px'};
    cursor: pointer;
    &:hover {
        background-color: #424242;
    };
`

export const PrimaryTypography = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.primary ? '#616161' : 'white'};
`

export const SecondaryTypography = styled(Typography)`
    font-size: 12px;
    color: #9E9E9E;
`

export const AccordionContainer = styled(Accordion)`
    width: 100%;
    box-shadow: none;
    margin-top: 16px;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    border-radius: 4px;
    background-color: #373435;
    box-shadow: none;
    cursor: pointer;
    &:hover {
        background-color: #424242;
    };
`

export const PanelDetails = styled(AccordionDetails)`
    padding: 16px 0px 16px 36px;
    box-shadow: none;
    background-color: #212121;
`