import React from 'react';

import { getBrandNameFromBrandId } from '~/util/brands';

import * as Style from './styles';

function MaisCarrinhoDropdown({
    setCartBrand,
    setActiveButton,
    brands,
    defaultBrand,
    uniqueBrand,
}) {
    return (
        <>
            {!uniqueBrand && (
                <Style.CssTextField
                    fullWidth
                    select
                    label="Marca"
                    defaultValue={defaultBrand}
                    onChange={e => {
                        setCartBrand(e.target.value);
                        setActiveButton(true);
                    }}
                    variant="outlined"
                >
                    {brands.map(userBrands => (
                        <Style.StyledMenuItem value={userBrands}>
                            {getBrandNameFromBrandId(userBrands)}
                        </Style.StyledMenuItem>
                    ))}
                </Style.CssTextField>
            )}
        </>
    );
}

export default MaisCarrinhoDropdown;