const availableChannelFilters = [
    {
        channel: 'all',
        label: 'Todas as vendas',
        priceParam: 'valueAsPrice',
        valueParam: 'value',
        valueParamPA: 'averageProduct',
        valueParamVA: 'averageValue',
    },
    {
        channel: 'online',
        label: 'Vendas Online',
        priceParam: 'onlineValueAsPrice',
        valueParam: 'onlineValue',
        valueParamPA: 'onlineAverageProduct',
        valueParamVA: 'onlineAverageValue',
    },
    {
        channel: 'physical',
        label: 'Vendas Físicas',
        priceParam: 'physicalValueAsPrice',
        valueParam: 'physicalValue',
        valueParamPA: 'physicalAverageProduct',
        valueParamVA: 'physicalAverageValue',
    },
];

const availableOrderFilters = [
    {
        label: 'Cota',
        priceParam: 'valueAsPrice',
        valueParam: 'quota',
    },
    {
        label: 'Valor',
        priceParam: 'onlineValueAsPrice',
        valueParam: 'money',
    },
    {
        label: 'PA',
        valueParam: 'pa',
    },
    {
        label: 'VA',
        valueParam: 'va',
    },
];

const timePeriodNames = {
    day: 'dia',
    month: 'mês',
    custom: 'período',
};

export { availableChannelFilters, availableOrderFilters, timePeriodNames };
