import { styled } from '@mui/material/styles';
import {
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material';

export const DialogTitleContainer = styled(DialogTitle)`
    font-family: inherit;
    background-color: black;
    color: white;
`

export const DialogActionsContainer = styled(DialogActions)`
    font-family: inherit;
    background-color: black;
    color: white;
`

export const DialogButton = styled(Button)`
    color: white;
    &:disabled{
        color: #b2b2b2;
    }
`