import 'moment/locale/pt-br';
import React, { useState } from 'react';
import Done from './Done';
import DonePending from './DonePending';
import Login from './Login';
import LoginAffiliates from './LoginAffiliates';
import Onboard from './Onboard';
import OnboardAffiliates from './OnboardAffiliates';
import Register from './Register';
import RegisterAffiliates from './RegisterAffiliates';
import CannotRegister from './CannotRegister';
import Welcome from './Welcome';

function FirstAccess() {
    const [currentPage, setCurrentPage] = useState('onboard');
    const [userData, setUserData] = useState(null);

    const getCurrentStepPage = () => {
        switch (currentPage) {
            case 'welcome':
                return <Welcome setCurrentPage={setCurrentPage} />;
            case 'onboard':
                return <Onboard setCurrentPage={setCurrentPage} />;
            case 'onboardAffiliates':
                return <OnboardAffiliates setCurrentPage={setCurrentPage} />;
            case 'loginAffiliates':
                return (
                    <LoginAffiliates
                        setCurrentPage={setCurrentPage}
                        setUserData={setUserData}
                    />
                );
            case 'login':
                return (
                    <Login
                        setCurrentPage={setCurrentPage}
                        setUserData={setUserData}
                    />
                );
            case 'done':
                return <Done setCurrentPage={setCurrentPage} />;
            case 'donePending':
                return <DonePending setCurrentPage={setCurrentPage} />;
            case 'register':
                return (
                    <Register
                        setCurrentPage={setCurrentPage}
                        userData={userData}
                    />
                );
            case 'registerAffiliates':
                return (
                    <RegisterAffiliates
                        setCurrentPage={setCurrentPage}
                        userData={userData}
                    />
                );
            case 'cannotRegister':
                return <CannotRegister setCurrentPage={setCurrentPage} />;
            // case 'registerOthers':
            //     return <RegisterOthers setCurrentPage={setCurrentPage} />;
            default:
                return <Onboard setCurrentPage={setCurrentPage} />;
        }
    };
    return <>{getCurrentStepPage()}</>;
}

export default FirstAccess;
