import { styled } from '@mui/material/styles';
import { TextField, Box } from '@mui/material';

export const SearchAreaContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    padding: 8px 16px;
    gap: 8px;
    align-self: center;

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`

export const Title = styled(Box)`
    font-size: 24px;
    font-weight: bold;
`

export const CssTextField = styled(TextField)`
    border-radius: 8px;
    border-color: #dcc6b7;
    color: #dcc6b7;
    font-weight: 500;
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #dcc6b7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #dcc6b7;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #dcc6b7;
            border-radius: 8px;
        }
        &:hover fieldset {
            border-color: #dcc6b7;
        }
        & .Mui-focused fieldset {
            border-color: #dcc6b7;
        }
    }
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0px;
    }
    & .MuiInputLabel-root {
        color: #dcc6b7;
    }
    & .MuiInputLabel-root.Mui-focused {
        color: #dcc6b7;
    }
    & .Mui-focused {
        border-color: #dcc6b7;
        color: #dcc6b7;
    }
    & .MuiInputBase-root {
        color: white;
    }
    & .MuiSvgIcon-root {
        color: white;
    }
`;

export const LoadingContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`