import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import { useHistory } from 'react-router-dom';
import CustomHeader from '~/components/CustomHeader';
import { useLocation } from 'react-router';
import SearchArea from './SearchArea';
import * as Style from './styles';
import { maisCarrinhoApi } from '~/services/api';

function SearchProduct() {
    const location = useLocation();
    const history = useHistory();
    const selectedCartFromLocation = location.state.selectedCart;
    const [searchProductName, setSearchProductName] = useState('');
    const [selectedCart, setSelectedCart] = useState({});
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const [addedProduct, setAddedProduct] = useState(false);

    const searchParam = useDebounce(searchProductName, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getProductFromSelectedCart = async selectedCartId => {
        const response = await maisCarrinhoApi.get(
            `/saved-cart/by-id-saved-cart?id_saved_cart=${selectedCartId}`
        );
        setSelectedCart(response.data[0]);
    };

    const handleAddProduct = () => {
        const id = selectedCart.id_saved_cart;
        getProductFromSelectedCart(id);
        history.push({
            pathname: '/maisCarrinho/detailCarts',
            state: { selectedCart },
        });
    };

    useEffect(() => {
        setSelectedCart(selectedCartFromLocation);
    }, [selectedCartFromLocation]);

    return (
        <>
            <Style.Container>
                <Style.PageBodyContainer>
                    <CustomHeader
                        goBackPage={() =>
                            history.push({
                                pathname: '/maisCarrinho/detailCarts',
                                state: { selectedCart },
                            })
                        }
                        title={selectedCart.cart_name}
                    />
                    <SearchArea
                        searchProductName={searchProductName}
                        setSearchProductName={setSearchProductName}
                        loading={loading}
                        setLoading={setLoading}
                        searchedProducts={searchedProducts}
                        setSearchedProducts={setSearchedProducts}
                        selectedCart={selectedCart}
                        setAddedProduct={setAddedProduct}
                        searchParam={searchParam}
                    />
                </Style.PageBodyContainer>

                <Style.ButtonContainer>
                    <Style.MainButton
                        disabled={!addedProduct}
                        onClick={() => {
                            handleAddProduct();
                        }}
                    >
                        Finalizar
                    </Style.MainButton>
                </Style.ButtonContainer>
            </Style.Container>
        </>
    );
}

export default SearchProduct;
