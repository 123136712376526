import React from 'react';

import * as Style from './styles';

function AveragesContainer({ title, value }) {
    return (
        <Style.PanelCardField item>
            <Style.PanelCardText>{title}</Style.PanelCardText>
            <Style.PanelCardText isValue>
                {value || 0}
            </Style.PanelCardText>
        </Style.PanelCardField>
    );
}

export default AveragesContainer;
