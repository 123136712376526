import React from 'react';
import { Grid, Typography, ListItem } from '@mui/material';

import 'moment/locale/pt-br';
import { ChevronRight } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import * as Style from './styles';

function SalesPersonListItem({
    salesTeamData,
    initialDate,
    finalDate,
}) {
    const history = useHistory();
    return (
        <>
            {salesTeamData.map((salesperson, index) => (
                <ListItem
                    key={`list-item-${salesperson.vendedor}`}
                    style={{
                        borderTop: index === 0 && '1px solid #9E9E9E',
                        cursor: 'pointer',
                        height: '72px',
                        borderBottom: '1px solid #9E9E9E',
                    }}
                    onClick={() =>
                        history.push(
                            `/consultarCotasDiariasVendedor?salesCode=${salesperson.vendedor}&initialDate=${initialDate}&finalDate=${finalDate}`
                        )
                    }
                >
                    <Grid container justifyContent="space-between">
                        <Grid
                            item
                            container
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <Style.SalesPersonName>
                                    {salesperson.shortName.toLowerCase()}
                                </Style.SalesPersonName>
                                <Typography
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        color: '#9e9e9e',
                                    }}
                                >
                                    {salesperson.vendedor}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            style={{
                                width: 'fit-content',
                                alignItems: 'center',
                            }}
                        >
                            <Grid item>
                                <Typography
                                    style={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        textAlign: 'end',
                                    }}
                                >
                                    {(
                                        salesperson.quotaValueMonth || 0
                                    ).toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                style={{
                                    alignSelf: 'center',
                                    marginLeft: '16px',
                                }}
                            >
                                <ChevronRight
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            ))}
        </>
    );
}

export default SalesPersonListItem;