import {styled} from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
    border-radius: 24px;
    border-color: #DCC6B7;
    margin-bottom: 16px;
    padding: 0 16px;
    font-weight: 500;
    font-family: inherit;
    outline: none;
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #DCC6B7;
    }
    & .MuiInput-underline:after {
        border-bottom-color: #DCC6B7;
    };
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: #DCC6B7;
            border-radius: 24px;
        };
        &:hover fieldset {
            border-color: #DCC6B7;
        };
        & .Mui-focused {
            border-color: #DCC6B7;
        };
    };
    & .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
    };
    & .MuiInputLabel-root {
        color: #DCC6B7;
    };
    & .Mui-focused {
        border-color: #DCC6B7;
    };
    & .MuiInputBase-root {
        color: white;
    };
`